import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SignUpForm, OTPVerificationScreen } from "../../../components";
import { ROUTE_CONSTANTS, STRINGS } from "../../../helpers";
import logo from "../../../assets/images/logo_icon.png";
import "./style.scss";

const SingUp: React.FC = () => {
  const [showOtpScreen, setShowOtpScreen] = useState<boolean>(false);
  const [user, setUser] = useState<{ email: string }>({ email: "" });
  const navigate = useNavigate();

  const onVerifySuccess = () => {
    navigate(ROUTE_CONSTANTS.LOGIN);
  };

  const onRegisterSuccess = (user: { email: string }) => {
    setShowOtpScreen(true);
    setUser(user);
  };

  return (
    <div className="login-auth-wrapper">
      <div className="auth-container">
        <div className="authentication_page">
          <div className="auth-bg-wrapper">
            <div className={`auth-bg-section ${showOtpScreen ? "otp-bg" : "signup-bg"}`}>
              <img src={logo} alt="logo" className="logo" />
              <div className="logo-text">
                <h2 className="logo-heading">Welcome to One Counsel</h2>
                <span className="logo-sub-heading">
                  360-degree insights in to legal operations & business transactions
                </span>
              </div>
            </div>
          </div>

          <div className="auth-form-section">
            {showOtpScreen ? (
              <OTPVerificationScreen onVerifySuccess={() => onVerifySuccess()} email={user.email} />
            ) : (
              <>
                <h3 className="auth-title">{STRINGS.SIGNUP_PAGE_TITLE}</h3>
                <p className="pg-content">
                  <span className="pg-content-text">
                    Join our community. Getting started is easy, first 14 days are free.
                  </span>
                </p>
                <div className="auth-form">
                  <SignUpForm onRegisterSuccess={onRegisterSuccess} />
                </div>
                <div className="login-form-footer">
                  <div className="auth-signup-link">
                    <span>Joined us before?</span>
                    <Link to={ROUTE_CONSTANTS.LOGIN}>{"Login"}</Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingUp;
