import { API_ROUTES, postRequest, showToast } from "helpers";
import { setIsRepositoryDataFetching } from "redux/actions";
import { store } from "redux/store";

const fetchExtractionNotification = async () => {
  const {
    common: {
      userData: { user_id },
    },
  } = store.getState();
  // fetch notification stay here
  const { data, status }: any = await postRequest(
    API_ROUTES.FETCH_DATA_FOR_AI_EXTRACTION_NOTIFICATION,
    true,
    {
      user_id: user_id,
    },
  );

  if (status === 200) {
    if (window.location.pathname === "/repository") {
      store.dispatch(setIsRepositoryDataFetching(true));
    }
    showToast(data.message, true);
  }
};

export { fetchExtractionNotification };
