export const ADD_USER_FORM = "ADD_USER_FORM";
export const DISABLE_USER_FORM = "DISABLE_USER_FORM";
export const GET_USER_DATA = "GET_USER_DATA";
export const STORE_ADMIN_KPI_DATA = "STORE_ADMIN_KPI_DATA";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

export const userFormAction = (data: any) => {
  return {
    type: ADD_USER_FORM,
    data: data,
  };
};

export const saveAdminKpiBoxData = (data: any) => {
  return {
    type: STORE_ADMIN_KPI_DATA,
    data: data,
  };
};

export const getAgreementData = (data: any) => {
  return {
    type: GET_DASHBOARD_DATA,
    data: data,
  };
};
