import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { nanoid } from "nanoid";
import {
  DocumentEditorComponent,
  WordExport, SfdtExport,
  DocumentEditorContainerComponent,
  Toolbar,
  ToolbarItem,
  DocumentEditor,
  DocumentEditorContainer,
} from "@syncfusion/ej2-react-documenteditor";
import Popup from "reactjs-popup";
import { isValid, parseISO } from "date-fns";
import $ from "jquery";
import { FaEdit } from "react-icons/fa";
import FileViewer from "react-file-viewer";
import { AiFillFileText, AiOutlineClockCircle } from "react-icons/ai";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import DashboardLayout from "layouts/dashboardLayout";
import AuditLog from "layouts/dashboardLayout/AuditLog";
import AgreementForm from "./agreementForm";
import { Button, LoaderHOC, UploadAgreementForm } from "components";
import VersionList from "../../agreement/versionList/index";
//
import "components/Table/style.scss";
import {
  API_ROUTES,
  postRequest,
  RESPONSE_MSGS,
  showToast,
  USER_ROLES,
  NEW_AGREEMENT_STATUS,
  showLogInConsole,
  STATUS_CODE,
  ROUTE_CONSTANTS,
  getSIgnedURLFromS3,
  TemplateType,
  IMPORT_TYPES,
  monthNames,
  getClassName,
  addParentDetails,
  STRINGS,
  blobToFile,
  isFileCompareDataEmpty,
} from "../../../../helpers";
import {
  DraftActionButton,
  ApproverActionButtons,
  ReviewerActionButtons,
  VendorActions,
} from "./agreementActions";
import { updateUserAgreement, formsData, defaultFormData, previewFile } from "../../../../redux/actions";
import { forceLogout } from "../../../../redux/services";
// import SignedDocAgreement from './signedDocAgreement'
import "./style.scss";
import PreviewAgreement from "../previewAgreement";
import debounce from "lodash.debounce";
import moment from "moment";
import { ParentSelectionModal } from "../../../../components/SearchableParentAgreements";
import { ModalProps } from "../../../../components/Modal";
import { FileCompareDialog, IFrameFileCompareModal } from 'customComponents';


DocumentEditorContainerComponent.Inject(Toolbar);
DocumentEditorComponent.Inject(WordExport, SfdtExport);


const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const CreateNewAgreement = () => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  // CONSTANTS
  // let docRef: any = null;
  let docRef: DocumentEditorContainer = new DocumentEditorContainer({});

  // SELECTORS
  const userTokens = useSelector((state: any) => state.common.userTokens) || {};
  const userData = useSelector((state: any) => state.common.userData) || {};
  const user = useSelector((state: any) => state.common.userData) || {};
  const previewFileData = useSelector((state: any) => state.common.fileData);
  const clientId = useSelector((state: any) => state.common.userData.client_id || "");
  const prevFormData = useSelector((state: any) => state.common.formsData);
  const defaultData = useSelector((state: any) => state.common.defaultFormData);

  // REFS
  const agreementRef = useRef<any>({ current: null });

  // STATES
  const [parentAgreementModalState, setParentAgreementModalState] = useState<Partial<ModalProps>>({
    isOpen: false,
    onClose: () => {
      setParentAgreementModalState({ ...parentAgreementModalState, isOpen: false });
    },
  });
  const [agreementData, setAgreementData] = useState<any>([]);
  const [usersList, setUsersList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [versionData, setVersionData] = useState([]);
  const [docComments, setDocComments] = useState([]);
  const [data, setData] = useState<any>(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [isFormValueSet, setIsFormValueSet] = useState(false);
  const [customRef, setCustomRef] = useState<any>(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isCommentModleOpen, setCommentModleOpen] = useState(false);
  const [isUserModleOpen, setUserModleOpen] = useState<boolean>(false);
  const [openSharePopup, setOpenSharePopup] = useState<boolean>(false);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState<boolean>(false);
  const [submitType, setSubmitType] = useState<string>("");
  const [url, setSignedURL] = useState("");
  const [isOpenAgreementForm, setIsOpenAgreementForm] = useState<boolean>(true);
  const [isOpenVersionList, setIsOpenVersionList] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [versionCompareModalState, setVersionCompareModalState] = useState(false);
  const [fileCompareModalState, setFileCompareModalState] = useState(false);
  const [importFileCompareModalState, setImportFileCompareModalState] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFileCompareLoading, setIsFileCompareLoading] = useState(false);
  const [fileCompareData, setFileCompareData] = useState({});
  const [currentFileData, setCurrentFileData] = useState("");
  const [isDocumentImporting, setIsDocumentImporting] = useState(false);
  const [importCompareFiles, setImportCompareFiles] = useState({ currentVersionFile: "", latestVersionFile: "" })
  // const [formFieldChanges, setFormFieldChanges] = useState<{name: string; value: string; isChanged: boolean;}>({name: '', value: '', isChanged: false })


  // FUNCTIONS


  const toolItem: any = {
    prefixIcon: "e-import",
    tooltipText: "Import Document",
    text: "Import",
    id: "import",
  };
  const downloadItem: any = {
    prefixIcon: "e-download",
    tooltipText: "Download",
    text: "Download",
    id: "download",
  };
  const [ToolBarItemArray, setToolBarItemArray] = useState<Array<ToolbarItem>>([
    "Separator",
    "Undo",
    "Redo",
    "Separator",
    "Table",
    "Separator",
    "Find",
    // "FormFields",
    "Separator",
    "TrackChanges",
    "Separator",
    "Comments",
    "Separator",
    downloadItem,
    "Separator",
    toolItem,
  ]);

  const onFormFieldChangeUpdateAgreementForm = (event) => {
    const { fieldName, value, isCanceled } = event;
    if (isCanceled) return;
    if (userData.persona !== USER_ROLES["vendor"]) {
      agreementRef.current.setFieldValue(fieldName, value);
    }
  };

  const onContentChangeChangeUpdateAgreementForm = debounce((e) => {
    // if(formFieldChanges.isChanged) {
    //   setFormFieldChanges({ name: '', value: '', isChanged: false });
    //   return;
    // }
    if (docRef) {
      const formFieldData = e.source.documentEditor?.exportFormData();

      formFieldData.forEach((formFiledsName) => {
        if (formFiledsName.fieldName === "agreementStatus") {
          return;
        }
        let data =
          formFiledsName.value?.trim() || prevFormData[formFiledsName.fieldName]?.trim() || "";

        if (moment(data).isValid() && !moment(data, "DD/MM/YYYY").isValid()) {
          const isValidDate = isValid(parseISO(data));
          if (isValidDate && isNaN(Number(data))) {
            data = moment(data).format("DD/MM/YYYY");
          }
        }
        if (isOpenAgreementForm && userData.persona !== USER_ROLES["vendor"]) {
          // if(Object.keys(prevFormData).length === 0 && defaultData[formFiledsName.fieldName]?.trim() === data?.trim()) {
          //   return;
          // }
          if (defaultData[formFiledsName.fieldName]?.trim() === data?.trim()) {
            return;
          }
          agreementRef.current.setFieldValue(formFiledsName.fieldName, data);
        }
      });

      // To update version after accept or reject all revisions
      if (
        Object.keys(prevFormData).length !== 0 &&
        docRef?.documentEditor?.showRevisions &&
        docRef?.documentEditor?.trackChangesPane?.setNoChangesVisibility
      ) {
        const revisions: any = docRef?.documentEditor?.revisions;
        if (revisions.changes.length === 0) {
          dispatch(formsData({}));
          createTempCopy(agreementRef.current.values, "merged");
        }
      }
    }
    if (
      user.persona === USER_ROLES["approver"] &&
      agreementData.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] &&
      agreementData.formData?.customerName !== user.email
    ) {
      if (agreementRef.current.values) {
        setIsFormValueSet(true);
      }
    }
  }, 1000);

  const hasFeildReadonly = (f: { status: string | undefined; forAll?: boolean }) => {
    const isShared = (agreementData?.formData?.isShared || location?.state?.isShared);

    if (f.forAll) {
      return f?.status?.toLowerCase() !== NEW_AGREEMENT_STATUS["draft"]?.toLowerCase() || isShared;
    }
    if (
      !userData.persona ||
      !agreementData?.formData?.agreementStatus ||
      agreementData?.formData?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]
    ) {
      return false || isShared;
    }
    if (
      userData.persona === USER_ROLES["drafter"] ||
      agreementData?.formData?.customerName === userData.email
    ) {
      return (agreementData?.formData?.skipReviewStep === "true"
        ? agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
        agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"]
        : agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["reviewRejected"] &&
        agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"]) || isShared;
    }
    if (userData.persona === USER_ROLES["reviewer"]) {
      return (
        agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
        agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["review"]
      ) || isShared;
    }
    if (userData.persona === USER_ROLES["approver"]) {
      return (
        agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["approve"] &&
        agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["approverPending"] &&
        agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["rejected"]
      ) || isShared;
    }
    if (userData.persona === USER_ROLES["vendor"]) {
      return !(agreementData?.formData?.agreementStatus === NEW_AGREEMENT_STATUS["vendorReview"]) || isShared;
    }
  };

  const fetchUsers = async (obj: { role: string; current_user: string; customer_name: string }) => {
    try {
      const res = await postRequest(API_ROUTES.GET_ROLE_BASED_USERS, true, obj);
      const { data }: any = res;
      await forceLogout({ status: data?.status_code, message: data?.message });
      setUsersList(data);
    } catch (err) {
      setUsersList([]);
    }
  };

  const handleSyncfuctionComments = (p: { sfdt: any; type: string }) => {
    let sfdtJson = p.sfdt;
    if (!p.sfdt.length) return "";

    if (typeof p.sfdt === "string") {
      sfdtJson = JSON.parse(p.sfdt);
    }

    let comments = sfdtJson?.comments ? sfdtJson?.comments : [];
    if (p.type === "remove") {
      setDocComments(comments);
      sfdtJson = { ...sfdtJson, comments: [] };
    } else if (p.type === "add") {
      comments = comments.filter(
        (i: { author: string; blocks: any }) => i.author && i.blocks[0].inlines[0].text,
      );
      const allComments: any = [...docComments, ...comments];
      sfdtJson = { ...sfdtJson, comments: allComments };
    }

    return sfdtJson;
    // return JSON.stringify(sfdtJson);
  };

  const fetchData = async (p: {
    _id: string;
    url: string;
    type?: string;
    current_user?: string;
    de: DocumentEditorContainer;
  }) => {
    try {
      setLoading(true);

      const res = await postRequest(p.url, true, {
        _id: p._id,
        current_user: p.current_user,
      });
      const { data }: any = res;
      await forceLogout({ status: data?.status_code, message: data?.message });
      if (data && data.response) {
        const agreement = data.response || {};
        let sfdtText = agreement.editor_data || "";
        setCurrentFileData(sfdtText);
        const type = location?.state?.template_name || agreement?.type;

        if (p.type === "userAgreement") {
          delete agreement["_id"];
        }
        setAgreementData({ sfdt: sfdtText, formData: { ...agreement, type } } || {});
        if (p.de?.documentEditor) {
          if (
            agreement.agreementStatus === NEW_AGREEMENT_STATUS["vendorReview"] &&
            userData?.persona === "vendor"
          ) {
            sfdtText = handleSyncfuctionComments({ sfdt: sfdtText, type: "remove" });
          }
          if (sfdtText) {
            p.de.documentEditor.open(sfdtText);
          }
          // const revisions: any = p.de.documentEditor.revisions;
          p.de.documentEditor.currentUser = userData?.email || "";
          p.de.showPropertiesPane = false;
          // p.de.documentEditor.editor.stopProtection("123");

          //enable track changes on initial reander
          if (userData?.persona === USER_ROLES["vendor"]) {
            p.de.enableTrackChanges = true;
          } else {
            p.de.enableTrackChanges = false;
          }
          const query = new URLSearchParams(location.search);

          if (location?.state?.isNewAgreement || query.get("isAutosave")) {
            let defaultValue = {};
            const formValues: any[] = p.de.documentEditor.exportFormData();

            formValues.forEach((formFiledsName) => {
              if (
                moment(formFiledsName.value).isValid() &&
                !moment(formFiledsName.value, "DD/MM/YYYY").isValid()
              ) {
                formFiledsName.value = moment(formFiledsName.value).format("DD/MM/YYYY");
              }
              defaultValue = {
                ...defaultValue,
                [formFiledsName.fieldName]: formFiledsName.value,
              };
            });
            dispatch(defaultFormData(defaultValue));
          } else if (
            Object.keys(prevFormData).length === 0 &&
            userData?.persona !== USER_ROLES["vendor"]
          ) {
            const revisions: any = p.de.documentEditor.revisions;
            if (revisions.changes.length) {
              p.de.documentEditor.showRevisions = true;
            } else {
              p.de.documentEditor.showRevisions = false;
            }
          } else {
            p.de.documentEditor.showRevisions = true;
          }
          // if (
          //   agreement.agreementStatus === NEW_AGREEMENT_STATUS["vendorReview"] ||
          //   agreement.agreementStatus === NEW_AGREEMENT_STATUS["vendorEsign"]
          // ) {
          //   // p.de.documentEditor.editor.insertComment("");
          //   //p.de.documentEditor.editor.deleteAllComments();

          //   if (revisions?.changes?.length) {
          //     revisions.acceptAll();
          //   }
          // }
        }
        setLoading(false);
        // showToast(RESPONSE_MSGS.EXTRACTION_AGREEMENT_SUCCESS, true);
        if (
          agreement.agreementStatus === NEW_AGREEMENT_STATUS["review"] ||
          agreement.agreementStatus === NEW_AGREEMENT_STATUS["approveRejected"] ||
          agreement.skipReviewStep === "true"
        ) {
          fetchUsers({
            role: "approver",
            current_user: userData.email,
            customer_name: agreement?.customerName || userData.email,
          });
        } else if (
          (agreement.agreementStatus === NEW_AGREEMENT_STATUS["draft"] ||
            agreement.agreementStatus === NEW_AGREEMENT_STATUS["reviewRejected"]) &&
          agreement.skipReviewStep !== "true"
        ) {
          fetchUsers({
            role: "reviewer",
            current_user: userData.email,
            customer_name: agreement?.customerName || userData.email,
          });
        }
      } else {
        showToast(data?.message || RESPONSE_MSGS.ERROR, false);
      }
    } catch (err) {
      setLoading(false);
      showToast(RESPONSE_MSGS.ERROR, false);
    }
  };

  const handlePrint = debounce(() => {
    if (docRef) {
      docRef.enableTrackChanges = true;
      const contractName = sessionStorage.getItem("initial-contract-name");

      setTimeout(() => {
        docRef.documentEditor?.save(agreementData?.formData?.contract_name || contractName, "Docx");
        docRef.enableTrackChanges = false;
      }, 500);
    }
  }, 500);

  //@todo: save temp doc API call
  const createTempCopy = async (
    values: any,
    versionType,
    status?: any,
    disableFormButton?: Function,
  ) => {
    try {
      if (disableFormButton) disableFormButton(true);
      const sfdtText = getSfdtText();
      values = {
        ...values,
        editor_data: sfdtText,
        agreementStatus: status || values?.agreementStatus,
        customerName:
          values?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]
            ? userData?.email
            : values?.customerName,
        actioner: userData?.email,
        // agreementDate: $("#agreementDate").val(),
        // expiryDate: $("#expiryDate").val(),
        version_type: versionType ? versionType : "imported",
      };
      const id = localStorage.getItem("_id");
      if (id && values?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]) {
        values._id = id;
      }
      values = addParentDetails(values);
      const res = await postRequest(API_ROUTES.CREATE_NEW_AGREEMENT, true, values);
      const { data }: any = res || {};
      if (data._id) {
        localStorage.setItem("_id", data._id);
      }
      setData(data);
    } catch (err: any) {
      console.error("err", err);
    }
  };

  //save form and doc data on reject
  //@todo: store data in one API call
  const handleAutoSave = async (values: any, status?: any, disableFormButton?: Function) => {
    try {
      if (disableFormButton) disableFormButton(true);
      const sfdtText = getSfdtText();
      values = {
        ...values,
        editor_data: sfdtText,
        agreementStatus: status || values?.agreementStatus,
        customerName:
          values?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]
            ? userData?.email
            : values?.customerName,
        actioner: userData?.email,
        // agreementDate: $("#agreementDate").val(),
        // expiryDate: $("#expiryDate").val(),
        version_type: "drafted",
      };
      values = addParentDetails(values);
      const id = localStorage.getItem("_id");
      if (id && values?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]) {
        values._id = id;
      }
      const res = await postRequest(API_ROUTES.CREATE_NEW_AGREEMENT, true, values);
      const { data }: any = res || {};
      await forceLogout({ status: data?.status_code, message: data?.message });

      if (data.status !== STATUS_CODE.SUCCESS) {
        if (data.status === 404 || data.status === 400) {
          showToast(data?.message, false);
        } else {
          showToast(RESPONSE_MSGS.ERROR, false);
        }
      } else {
        dispatch(updateUserAgreement(values._id, values));
        //showToast(RESPONSE_MSGS.NEW_AGREEMENT_SUCCESS, true);
      }
      if (disableFormButton) disableFormButton(false);
    } catch (err: any) {
      if (disableFormButton) disableFormButton(false);
      showToast(RESPONSE_MSGS.ERROR, false);
    }
  };

  const handleEStamp = async (
    eSignUserEmail: any,
    eSignUsername: any,
    formValues: any,
    updateAgreementStatus: Function,
  ) => {
    try {
      if (!validateEmail(eSignUserEmail)) {
        showToast("Invalid email address", false);
        return;
      }
      if (!eSignUsername) {
        showToast("Invalid signer name", false);
        return;
      }
      if (docRef) {
        setLoading(true);
        if (eSignUserEmail === location?.state?.vendor) {
          showToast("This email cannot be same as vendor's email", false);
          setLoading(false);
          return false;
        }

        const revisions: any = docRef?.documentEditor?.revisions;

        if (revisions?.changes && revisions?.changes?.length) {
          revisions.acceptAll();
        }
        const blob = await docRef?.documentEditor?.saveAsBlob("Docx");

        const formData = new FormData();
        formData.append("file_content", blob);
        formData.append("agreement_name", formValues?.contract_name);
        formData.append("filename", formValues?.contract_name);
        formData.append("_id", formValues?._id);
        formData.append("expiryDays", process.env.REACT_EXPIRY_DAYS || "1");
        formData.append("email", formValues?.vendor);
        formData.append("invites", formValues?.vendor);
        formData.append("denomination_amount", "500"); // @todo pass dynamic value: location?.state?.price
        formData.append("ApproverEsignUser", eSignUserEmail);
        formData.append("ApproverEsignUserName", eSignUsername);

        const updatedValues = formValues;
        delete updatedValues["e_sign_url"];
        await updateAgreementStatus(updatedValues, NEW_AGREEMENT_STATUS["approverEStamp"]);
        const res: any = await postRequest(API_ROUTES.AGREEMENT_AUTHORIZATION, true, formData);
        const { data }: any = res || {};
        await forceLogout({ status: data?.status_code, message: data?.message });

        if (data?.data?.invitees[0]) {
          setLoading(false);
          // window.open(data?.data?.invitees[0].signUrl, "_blank");
        } else {
          showToast(data?.message || RESPONSE_MSGS.ERROR, false);
        }
        setLoading(false);
      }
    } catch (e) {
      showToast(RESPONSE_MSGS.ERROR, false);
      setLoading(false);
      console.log("handleEStamp::Error ", e);
    }
  };

  const config = {
    [TemplateType.EmploymentAgreement]: { secondPartyName: 8, secondPartyAddress: 3 },
    [TemplateType.Consultant]: { secondPartyName: 2 },
    [TemplateType.CXMCEmploymentAgreement]: { secondPartyName: 8, secondPartyAddress: 3 },
  };

  const updateDocField = (fieldName: string, value: string | number, type: string) => {
    const dateField: any = docRef.documentEditor.getFormFieldInfo(fieldName);

    if (config[type] && fieldName in config[type]) {
      return setFormFieldInfoFn(fieldName, config[type][fieldName], value);
    }

    if (dateField) {
      docRef.documentEditor.setFormFieldInfo(fieldName, { ...dateField, defaultValue: value });
    }
  };

  const setFormFieldInfoFn = (fieldName, value, defaultValue) => {
    Array.from({ length: value || 1 }).forEach(function Iterator() {
      const dateField = docRef.documentEditor.getFormFieldInfo(fieldName);
      docRef.documentEditor.setFormFieldInfo(fieldName, {
        ...dateField,
        defaultValue: defaultValue,
      });
    });
  };

  const handleCustomField = (p: { name: string; value: string }) => {
    if (p.value.length > 0 && customRef) {
      const formFieldsNames: string[] = customRef.documentEditor.getFormFieldNames();

      const { name } = p;

      const regexObj = new RegExp("" + name + "+_[0-9]+");

      const currentElements = formFieldsNames.filter((item) => {
        const isMatch = regexObj.test(item);
        if (isMatch) {
          return item;
        }
        // [A-Za-z]+-[0-9]+
      });

      currentElements.forEach((item) => {
        const fieldInfo = customRef.documentEditor.getFormFieldInfo(item);

        customRef.documentEditor.setFormFieldInfo(item, {
          ...fieldInfo,
          defaultValue: p.value?.trim(),
        });
      });
      // formFieldsNames.forEach((item) => {
      //   if (name === item) {
      //     const fieldInfo = customRef.documentEditor.getFormFieldInfo(item);

      //     console.log(fieldInfo);

      //     // customRef.documentEditor.setFormFieldInfo(item, {
      //     //   ...fieldInfo,
      //     //   defaultValue: p.value?.trim(),
      //     // });
      //   }
      // });
    }

    // const fieldName = p.name;
    // if (!docRef && !docRef?.documentEditor) return;
    // if ([TemplateType.BTAgreement, TemplateType.LLA].includes(agreementData.formData?.type || "")) {
    //   Array(3)
    //     .fill(0)
    //     .map((_, i: number) => {
    //       updateDocField(
    //         `${fieldName}-${i + 1}`,
    //         `${p.value?.trim()} `,
    //         agreementData.formData?.type,
    //       );
    //     });
    // }
    // updateDocField(fieldName, `${p.value?.trim()} `, agreementData.formData?.type);
    // return;
  };
  const handleField = (p: { name: string; value: string }) => {
    const fieldName = p.name;
    if (!docRef?.documentEditor) return;
    if ([TemplateType.BTAgreement, TemplateType.LLA].includes(agreementData.formData?.type || "")) {
      Array(3)
        .fill(0)
        .map((_, i: number) => {
          updateDocField(
            `${fieldName}-${i + 1}`,
            `${p.value?.trim()} `,
            agreementData.formData?.type,
          );
        });
    }

    updateDocField(fieldName, `${p.value?.trim()} `, agreementData.formData?.type);
    return;
  };

  const getDocFieldValue = (p: { name: string }) => {
    if (!docRef?.documentEditor) return;
    const textfieldInfo: any = docRef.documentEditor.getFormFieldInfo(p.name);

    return textfieldInfo?.defaultValue || "";
  };

  const getSfdtText = () => {
    if (docRef) {
      const sfdtText = docRef.documentEditor.serialize();

      return sfdtText;
    }
    if (customRef) {
      const sfdtText = customRef.documentEditor.serialize();

      return sfdtText;
    }
  };

  const fetchVersionList = async (p) => {
    try {
      const res: any = await postRequest(p.url, true, {
        _id: p._id,
      });
      if (res.data && res.data.status === 200) {
        setVersionData(res.data?.response || []);
        return;
      }
      await forceLogout({ status: res?.data?.status_code, message: res?.data?.message });
    } catch (err: any) {
      showLogInConsole("--fetchAgreementList--", err);
    }
  };

  const handleClickOpen = () => {
    fetchVersionList({
      url: API_ROUTES.GET_VERSION_LIST,
      _id: localStorage.getItem("_id") || location?.state?._id,
    });
    setIsOpenAgreementForm(false);
    setIsOpenVersionList(true);
  };

  const handleClose = () => {
    setIsOpenVersionList(false);
    setVersionData([]);
  };

  const onApproverFinish = async (values: any, status?: any, action?: any, isSubmitting = true) => {
    try {
      const sfdtText = getSfdtText();
      if (isSubmitting) setButtonDisabled(true);
      values = {
        ...values,
        editor_data: sfdtText,
        agreementStatus: status || submitType || agreementData.formData?.agreementStatus,
        customerName:
          agreementData.formData?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]
            ? user?.email
            : agreementData.formData?.customerName,
        actioner: user?.email,
        // agreementDate: $("#agreementDate").val(),
        // expiryDate: $("#expiryDate").val(),
        version_type: "drafted",
      };
      const id = localStorage.getItem("_id");
      if (id && agreementData.formData?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]) {
        values._id = id;
      }
      if (agreementData.formData?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]) {
        values.client_id = clientId;
      }
      values = addParentDetails(values);
      const res = await postRequest(API_ROUTES.CREATE_NEW_AGREEMENT, true, values);

      const { data }: any = res || {};
      await forceLogout({ status: data?.status_code, message: data?.message });
      if (data.status !== STATUS_CODE.SUCCESS) {
        if (data.status === 404 || data.status === 400) {
          showToast(data?.message, false);
        } else {
          showToast(RESPONSE_MSGS.ERROR, false);
        }
        if (isSubmitting) setButtonDisabled(false);
        return;
      } else {
        if (data._id) {
          localStorage.setItem("_id", data._id);
        }
        dispatch(updateUserAgreement(values._id, values));
        if (action) {
          action.setValues({ ...values, _id: values?._id });
        }
        if (isSubmitting) setButtonDisabled(false);
        if (
          submitType === agreementData.formData?.agreementStatus ||
          status === agreementData.formData?.agreementStatus
        ) {
          return;
        }
        showToast(RESPONSE_MSGS.NEW_AGREEMENT_SUCCESS, true);

        if (
          submitType === NEW_AGREEMENT_STATUS["approved"] ||
          submitType === NEW_AGREEMENT_STATUS["approverEStamp"] ||
          status === NEW_AGREEMENT_STATUS["approved"] ||
          status === NEW_AGREEMENT_STATUS["approverEStamp"]
        ) {
          fetchData({
            _id: location.state?._id,
            url: API_ROUTES.GET_NEW_USER_AGREEMENT,
            current_user: userData.email,
            de: docRef,
          });
        } else {
          setTimeout(() => {
            navigate(ROUTE_CONSTANTS.My_Tasks);
          }, 1000);
        }
      }
    } catch (err: any) {
      showToast(RESPONSE_MSGS.ERROR, false);
      if (isSubmitting) setButtonDisabled(false);
    }
  };

  const onFinish = async (values: any, status: any) => {
    try {
      const sfdtText = handleSyncfuctionComments({ sfdt: getSfdtText(), type: "add" });
      const price = getDocFieldValue({ name: "price" });
      // const expiryDate = getDocFieldValue({ name: "expiryDate" });
      // const agreementDate = getDocFieldValue({ name: "agreementDate" });
      values = {
        ...values,
        price,
        // agreementDate,
        // expiryDate,
        version_type: "drafted",
      };
      setBtnDisabled(true);
      const id = localStorage.getItem("_id");
      if (id && values?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]) {
        values._id = id;
      }
      values = addParentDetails(values);
      const res = await postRequest(API_ROUTES.CREATE_NEW_AGREEMENT, true, {
        ...values,
        editor_data: sfdtText,
        agreementStatus: status,
        actioner: userData?.email,
      });
      const { data }: any = res || {};
      await forceLogout({ status: data?.status_code, message: data?.message });

      if (data.status !== STATUS_CODE.SUCCESS) {
        if (data.status === 404) {
          showToast(data?.message, false);
        } else {
          showToast(RESPONSE_MSGS.ERROR, false);
        }
      } else if (data.status === 400) {
        showToast(data?.message, false);
        setBtnDisabled(false);
      } else {
        setBtnDisabled(false);
        showToast(RESPONSE_MSGS.NEW_AGREEMENT_SUCCESS, true);
        navigate(ROUTE_CONSTANTS.My_Tasks);
      }
      setBtnDisabled(false);
    } catch (err: any) {
      console.log(err);
      showToast(RESPONSE_MSGS.ERROR, false);
      setBtnDisabled(false);
    }
  };

  const onToolbarClick = (args): void => {
    switch (args.item.id) {
      case "import":
        setIsOpenImportDialog(true);
        setIsOpenPreviewDialog(false);
        break;
      case "download":
        handlePrint();
        break;
      default:
        break;
    }
  };

  const openDoc = async () => {
    if (previewFileData && docRef) {
      docRef.enableTrackChanges = true;
      docRef.documentEditor.open(previewFileData);
      if (userData.persona !== USER_ROLES["vendor"]) {
        agreementRef.current.createNewAgreement();
        docRef.enableTrackChanges = false;
      }
    } else {
      showToast(RESPONSE_MSGS.ERROR, false);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  };

  const handleArrow = () => {
    setIsOpenAgreementForm(!isOpenAgreementForm);
    setIsOpenVersionList(false);
  };

  const handleRejectStatus = async (agreementStatus: string, role: string, formValues?: any) => {
    try {
      setButtonDisabled(true);

      await handleAutoSave({ ...formValues, actioner: user?.email }, agreementStatus);
      const res = await postRequest(API_ROUTES.REVIEW_REJECT_COMMENTS, true, {
        _id: agreementData.formData?._id,
        reviewerName: user.email,
        role: role,
        // comment: commentData,
        comment: [],
        agreementStatus: agreementStatus,
        actioner: user?.email,
      });

      const { data }: any = res || {};
      await forceLogout({ status: data?.status_code, message: data?.message });

      if (data.status !== STATUS_CODE.SUCCESS) {
        showToast(RESPONSE_MSGS.ERROR, false);
      } else {
        //dispatch(updateUserAgreement(p.data?._id || "", { ...p.data, agreementStatus }));
        showToast(RESPONSE_MSGS.AGREEMENT_REJECTED_STATUS, true);

        setTimeout(() => {
          navigate(ROUTE_CONSTANTS.My_Tasks);
        }, 1000);
      }
      setButtonDisabled(false);
    } catch (err: any) {
      //console.log(err);
      showToast(RESPONSE_MSGS.ERROR, false);
      setButtonDisabled(false);
    }
  };

  // PRASHANT CODE
  const openVersionCompareModalHandler = (selectedIndex: any) => {
    setCurrentIndex(selectedIndex);
    setVersionCompareModalState(() => true);
  }
  // VERSION COMPARE HANDLER
  const onCompareHandler = async ({
    baseVersion,
    comparisonVersion,
    baseVersionData,
    comparisonVersionData
  }: any) => {
    let documenteditor: DocumentEditor = new DocumentEditor({ enableSfdtExport: true, enableWordExport: true, enableTextExport: true });
    try {
      setIsFileCompareLoading(true);
      documenteditor.appendTo('#ej2-compareVersionDoc');


      // CONVERTING DATA TO FILES
      documenteditor.open(baseVersionData);
      const baseVersionBlobFile = await documenteditor.saveAsBlob('Docx');
      const baseVersionFile: any = blobToFile(baseVersionBlobFile, `${nanoid().toString()}.docx`);

      documenteditor.open(comparisonVersionData);
      const compareVersionBlobFile = await documenteditor.saveAsBlob("Docx");
      const compareVersionFile: any = blobToFile(compareVersionBlobFile, `${nanoid().toString()}.docx`);
      // END CONVERTING DATA TO FILES


      const formData = new FormData();

      formData.append("client_id", userData.client_id)
      formData.append("user_id", userData.user_id)
      formData.append("document_id", location.state._id)
      formData.append("base_version", baseVersion)
      formData.append("comparison_version", comparisonVersion)
      formData.append("original_document", baseVersionFile)
      formData.append("modified_document", compareVersionFile)

      const res: any = await postRequest(API_ROUTES.DOC_COMPARE, true, formData);

      if (res?.data?.response?.[0]?.error?.length > 0 || res?.data?.response?.error?.length > 0) {
        showToast("Something went wrong", false);
        setVersionCompareModalState(false);
        return;
      }

      if (isFileCompareDataEmpty(res.data.response?.[0]?.result?.annotations || res.data.response?.result?.annotations)) {
        showToast("Files have same content", true);
        setVersionCompareModalState(false);
        return;
      }

      if (res.status === 200) {
        const compareData = res.data.response?.[0] ?? res.data.response;
        setFileCompareData(compareData);
        setFileCompareModalState(true);
        return;
      } else {
        showToast("Something went wrong", false);
        setFileCompareModalState(false);
        setVersionCompareModalState(false);
        return;
      }

    } catch (err) {
      console.log("onCompareHandler ==>", err);
      showToast("Something went wrong", false);
      setVersionCompareModalState(false);
    } finally {
      setIsFileCompareLoading(false);
      documenteditor.destroy();
    }
  }
  const closeVersionCompareModalHandler = () => {
    setVersionCompareModalState(() => false);
  }
  const onCloseFileCompareModal = (value: any) => {
    setFileCompareModalState(() => false)
    setVersionCompareModalState(() => false);
  }
  // IMPORT DOC COMPARE HANDLER
  const openPreviewPopup = async (latestVersionData: string) => {
    try {

      let documenteditor: DocumentEditor = new DocumentEditor({ enableSfdtExport: true, enableWordExport: true, enableTextExport: true });
      documenteditor.appendTo('#ej2-compareVersionDoc');

      // CONVERTING LOADED FILES TO BLOB FORMAT
      documenteditor.open(currentFileData);
      const currentVersionBlobFile: any = await documenteditor.saveAsBlob('Docx');
      const currentVersionFile: any = blobToFile(currentVersionBlobFile, `${nanoid().toString()}.docx`);

      documenteditor.open(latestVersionData);
      const latestVersionBlobFile: any = await documenteditor.saveAsBlob('Docx');
      const latestVersionFile: any = blobToFile(latestVersionBlobFile, `${nanoid().toString()}.docx`);
      // END CONVERTING LOADED FILES TO BLOB FORMAT

      setImportCompareFiles(() => { return { currentVersionFile: currentFileData, latestVersionFile: latestVersionData } });

      const formData = new FormData();

      formData.append("client_id", userData.client_id);
      formData.append("original_document", currentVersionFile);
      formData.append("modified_document", latestVersionFile);

      const compareData: any = await postRequest(API_ROUTES.DOC_IMPORT_COMPARE, true, formData);

      onDocImportLoadingFinish();

      if (compareData?.data?.response?.error?.length > 0) {
        showToast("Something went wrong", false);
        setIsOpenImportDialog(false);
        return;
      }

      if (isFileCompareDataEmpty(compareData?.data?.response?.result?.annotations)) {
        showToast("Files have same content", true);
        setIsOpenImportDialog(false);
        return;
      }

      // if (compareData.status === 200) {
      //   setFileCompareData(compareData);
      //   setImportFileCompareModalState(true);
      // } else {
      //   showToast("Something went wrong", false);
      //   setImportFileCompareModalState(false);
      //   setIsOpenImportDialog(false);
      // }

      setFileCompareData(compareData?.data?.response);
      setImportFileCompareModalState(true);


      // OLD CODE
      // let prevValue = {};
      // const formFieldsNames: string[] = docRef?.documentEditor?.getFormFieldNames();
      // formFieldsNames.forEach((formFiledsName) => {
      //   if (formFiledsName === "agreementStatus") {
      //     return;
      //   }
      //   const value = docRef?.documentEditor?.getFormFieldInfo(formFiledsName).defaultValue;
      //   prevValue = {
      //     ...prevValue,
      //     [formFiledsName]: value,
      //   };
      // });
      // // setImportFileCompareModalState(true)
      // setIsOpenPreviewDialog(true);
      // setIsOpenImportDialog(false);
      // dispatch(formsData(prevValue));
      // setIsOpenPreviewDialog(true);
    } catch (err) {
      console.log("error ===>", err);
      showToast("Something went wrong", false)
      setIsOpenImportDialog(false);
      onDocImportLoadingFinish();
    }
  };
  const fileCompareModalHandler = (confirm: boolean) => {
    try {
      if (confirm) {
        dispatch(previewFile(importCompareFiles.latestVersionFile));
        setCurrentFileData(importCompareFiles.latestVersionFile);
        docRef.enableTrackChanges = true;
        docRef.documentEditor.open(importCompareFiles.latestVersionFile);
        if (userData.persona !== USER_ROLES["vendor"]) {
          agreementRef.current.createNewAgreement();
          docRef.enableTrackChanges = false;
          fetchVersionList({
            url: API_ROUTES.GET_VERSION_LIST,
            _id: localStorage.getItem("_id") || location?.state?._id,
          });
        }
      }
    } catch (error) {
      console.log("error --->", error);
    } finally {
      setImportFileCompareModalState(() => false);
      setIsOpenImportDialog(() => false);
      setImportCompareFiles(() => { return { currentVersionFile: "", latestVersionFile: "" } });
    }
  }
  const onDocImportLoadingStart = () => {
    setIsDocumentImporting(true);
  }
  const onDocImportLoadingFinish = () => {
    setIsDocumentImporting(false);
  }
  // PRASHANT OCDE

  // EFFETCS
  useEffect(() => {
    // fix Warning: Can't perform a React state update on an unmounted component
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      // fetchVersionList({
      //   url: API_ROUTES.GET_VERSION_LIST,
      //   _id: location?.state?._id,
      // });
      try {
        const query = new URLSearchParams(location.search);

        if (location?.state?.dynamicAgreement) {
          await fetchData({
            _id: location.state?._id,
            url: API_ROUTES.GET_NEW_USER_AGREEMENT,
            current_user: userData.email,
            de: docRef,
          });
          return;
        }

        if (query.get("isAutosave")) {
          await fetchData({
            _id: location.pathname.split("/").pop(),
            url: API_ROUTES.GET_NEW_USER_AGREEMENT,
            current_user: userData.email,
            de: docRef,
          });
          return;
        }

        await fetchData({
          _id: location?.state?._id || "template_1",
          url: API_ROUTES.GET_NEW_AGREEMENT,
          type: "userAgreement",
          de: docRef,
        });
        return;
      } catch (err) {
        console.log(err);
      }
    })();
  }, [location]);
  useEffect(() => {
    if (data && data?._id) {
      if (docRef?.documentEditor) {
        docRef.documentEditor.showRevisions = true;
      }
      setTimeout(() => {
        (async () => {
          const res = await postRequest(API_ROUTES.GET_NEW_USER_AGREEMENT, true, {
            _id: data?._id,
            current_user: userData.email,
          });
          const { data: resp }: any = res;
          if (resp && resp.response) {
            setAgreementData({
              ...agreementData,
              formData: { ...agreementData.formData, version: resp.response.version },
            });
          }
        })();
      }, 1500);
    }
  }, [data]);
  useEffect(() => {
    {
      agreementData?.formData?.agreementStatus === NEW_AGREEMENT_STATUS["vendorEsign"]
        ? getSIgnedURLFromS3(
          process?.env?.REACT_APP_AWS_REGION || "",
          process?.env?.REACT_APP_AWS_IDENTITY_POOL_ID || "",
          process?.env?.REACT_APP_AWS_USER_POOL || "",
          process?.env?.REACT_APP_AGREEMENT_BUCKET_NAME_LEGALITY || "",
          userTokens?.IdToken,
          // key_name,
          agreementData?.formData?._id,
          (signed_url: string) => setSignedURL(signed_url),
        )
        : null;
    }
  }, [location]);
  useEffect(() => {
    if (userData.persona === USER_ROLES["vendor"]) {
      const tempArray = [...ToolBarItemArray];
      tempArray.splice(tempArray.indexOf("TrackChanges"), 1);
      tempArray.pop();
      setToolBarItemArray(["Open", ...tempArray]);
    }
  }, []);
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("initial-contract-name");
      sessionStorage.removeItem("tempFileName");
      sessionStorage.removeItem("previewFile");
      sessionStorage.removeItem("parentId");
      sessionStorage.removeItem("parentIndex");
      sessionStorage.removeItem("parentName");
      dispatch(defaultFormData({}));
    };
  }, []);


  // COMPONENTS
  const PreviewPopup = () => {

    const closePopup = (type) => {
      if (type !== "submit") {
        dispatch(formsData({}));
      }
      setIsOpenPreviewDialog(false);
      setImportFileCompareModalState(false);
    };

    return (
      <>

        <Popup
          modal
          className="preview-popup"
          open={isOpenPreviewDialog}
          closeOnDocumentClick={false}
          closeOnEscape={false}
          onClose={closePopup}
        >
          <PreviewAgreement
            closePopup={closePopup}
            openDoc={openDoc}
          />
        </Popup>
      </>
    );
  };
  const ImportPopup = () => {
    return (
      <Popup
        modal
        open={isOpenImportDialog}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        className="agreement-upload-popup"
        onClose={() => setIsOpenImportDialog(false)}
      >
        {(close) => (
          <UploadAgreementForm
            closePopup={close}
            cautionMessage={STRINGS.CAUTION_MSG}
            importType={IMPORT_TYPES.AGREEMENT_PREVIEW}
            onPreview={openPreviewPopup}
            isUploading={isDocumentImporting}
            onUploadStart={onDocImportLoadingStart}
            onUploadFinish={onDocImportLoadingStart}
          />
        )}
      </Popup>
    );
  };

  const restrictEditingForRoleAndAgreementStatusWise = (role: string, status: string) => {
    if (role && status) {
      if (role === "drafter" && (status === "Draft" || status === "Review Rejected")) {
        return false
      };
      if (role === "reviewer" && (status === "Review" || status === "Approve Rejected")) {
        return false
      };
      if (role === "approver" && (status === "Approve" || status === "Approved" || status === "Rejected" || status === "Approver Pending")) {
        return false
      };
      if (role === "vendor" && (status === "Vendor Review")) {
        return false
      };
    }
    return true;
  }

  return (
    <>
      {importFileCompareModalState &&
        <IFrameFileCompareModal
          data={fileCompareData}
          state={importFileCompareModalState}
          confirmModalMsg="Are you sure you want save the latest version?"
          onClose={fileCompareModalHandler}
        />}
      <ImportPopup />
      <PreviewPopup />
      <DashboardLayout
        headerLogo={<AiFillFileText />}
        hederTitle={
          <>
            {!agreementData?.formData?.agreementStatus
              ? <div>
                <p className="text-[1rem] font-bold">Create Agreement</p>
                <p className="text-sm">{agreementData?.formData?.contract_name || ""}</p>
              </div>
              : userData.persona === USER_ROLES["reviewer"] &&
                agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] &&
                agreementData?.formData?.customerName !== userData.email
                ? <div>
                  <p className="text-[1rem] font-bold">Review Agreement</p>
                  <p className="text-sm">{agreementData?.formData?.contract_name || ""}</p>
                </div>
                : userData.persona === USER_ROLES["approver"] &&
                  agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] &&
                  agreementData?.formData?.customerName !== userData.email
                  ? <div>
                    <p className="text-[1rem] font-bold">Approve Agreement</p>
                    <p className="text-sm">{agreementData?.formData?.contract_name || ""}</p>
                  </div>
                  : <div>
                    <p className="text-[1rem] font-bold">Create Agreement</p>
                    <p className="text-sm">{agreementData?.formData?.contract_name || ""}</p>
                  </div>}
            {agreementData?.formData?.customerName !== userData.email &&
              userData.persona === USER_ROLES["vendor"] ? (
              ""
            ) : (
              // agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] ?
              <div className="versionClock flex items-center">
                <AiOutlineClockCircle />
                <span
                  className="header-sub-title"
                  {...(agreementData?.formData?.version > 1 && {
                    onClick: handleClickOpen,
                    style: { cursor: "pointer", textDecorationLine: "underline" },
                  })}
                >
                  Version {agreementData?.formData?.version || "0"}
                </span>
              </div>
            )}
          </>
        }
        sideText={
          agreementData?.formData?.customerName !== userData.email &&
            userData.persona === USER_ROLES["vendor"] ? (
            ""
          ) : agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] ? (
            <Popup
              trigger={
                <span className="header-sub-title" style={{ cursor: "pointer" }}>
                  Audit Log
                </span>
              }
              modal
              closeOnDocumentClick={true}
              closeOnEscape={false}
              className="agreement-list-popup audit-log-popup"
            >
              {() => <AuditLog id={location?.state?._id || ""} />}
            </Popup>
          ) : (
            ""
          )
        }
        childer={
          <>
            <span className="topBar">
              <div>
                <div className="leftSide">
                  <span className="block">
                    <span className="heading">Create Date</span>
                    <span className="value">
                      {/* {agreementData?.formData?.created &&
                      formatDate(agreementData?.formData?.created)} */}
                      {agreementData?.formData?.created}
                    </span>
                  </span>
                  <span className="block">
                    <span className="heading">Status</span>
                    <span className="value">
                      <div
                        className={`statusBtnStatus ${getClassName(
                          agreementData?.formData?.agreementStatus,
                        )}`}
                      >
                        {agreementData?.formData?.agreementStatus}
                      </div>
                    </span>
                  </span>
                  {user.persona !== USER_ROLES["drafter"] && (
                    <span className="block">
                      <span className="heading">Drafter</span>
                      <span className="value">{agreementData?.formData?.customerName}</span>
                    </span>
                  )}
                  {user.persona !== USER_ROLES["reviewer"] && (
                    <span className="block">
                      <span className="heading">Reviewer</span>
                      <span className="value">{agreementData?.formData?.reviewer}</span>
                    </span>
                  )}
                  {user.persona !== USER_ROLES["approver"] && (
                    <span className="block">
                      <span className="heading">Approver</span>
                      <span className="value">{agreementData?.formData?.approver}</span>
                    </span>
                  )}
                  {(sessionStorage.getItem("parentName") ||
                    (agreementData?.formData?.parent_name &&
                      agreementData?.formData?.parent_name !== "not_assigned")) && (
                      <span className="block">
                        <span className="heading">Parent</span>
                        <span className="value">
                          {sessionStorage.getItem("parentName") ||
                            agreementData?.formData?.parent_name}
                        </span>
                      </span>
                    )}
                </div>
                <div className=" text-primary-500 mt-2">
                  Note: The document will be autosaved after every 30 seconds.
                </div>
              </div>
              <span className="rightSide">
                {
                  /** START: For Draft State Only */
                  (agreementData.formData?.agreementStatus === NEW_AGREEMENT_STATUS["draft"] ||
                    user.persona === USER_ROLES["drafter"] ||
                    agreementData.formData?.customerName === user.email ||
                    !user.persona) && (
                    <div className={`form-header-row-create vednor-btns`}>
                      <DraftActionButton
                        role={
                          agreementData.formData?.skipReviewStep === "true"
                            ? agreementData.formData?.approver
                            : agreementData.formData?.reviewer
                        }
                        btnDisabled={buttonDisabled || (agreementData?.formData?.isShared || location?.state?.isShared)}
                        skipReviewStep={agreementData.formData?.skipReviewStep}
                        agreementStatus={agreementData.formData?.agreementStatus}
                        setSubmitType={setSubmitType}
                        handleSubmit={agreementRef.current.handleSubmit}
                        setOpen={setIsOpen}
                        validateForm={agreementRef.current.validateForm}
                        setParentAgreementModalState={(newState) => {
                          setParentAgreementModalState((prevState) => ({
                            ...prevState,
                            ...newState,
                          }));
                        }}
                      />
                    </div>
                  )
                }
                {
                  /** START: For Reviewer Role Only */
                  user.persona === USER_ROLES["reviewer"] &&
                  agreementData.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] &&
                  agreementData.formData?.customerName !== user.email && (
                    <div className={`form-header-row-create`}>
                      <ReviewerActionButtons
                        btnDisabled={buttonDisabled || (agreementData?.formData?.isShared || location?.state?.isShared)}
                        agreementStatus={agreementData.formData?.agreementStatus}
                        selectedApproversByAllReviewers={
                          agreementData.formData?.selectedApproversByAllReviewers
                        }
                        handleSubmit={agreementRef.current.handleSubmit}
                        setSubmitType={setSubmitType}
                        setUserModleOpen={setUserModleOpen}
                        validateForm={agreementRef.current.validateForm}
                        handleRejectStatus={handleRejectStatus}
                        values={agreementRef.current.values}
                      />
                    </div>
                  )
                }
                {
                  /** START: For Approver Role Only */
                  user.persona === USER_ROLES["approver"] &&
                  agreementData.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] &&
                  agreementData.formData?.customerName !== user.email && (
                    <div className={`form-header-row-create`}>
                      <ApproverActionButtons
                        agreementStatus={agreementData.formData?.agreementStatus}
                        btnDisabled={buttonDisabled || (agreementData?.formData?.isShared || location?.state?.isShared)}
                        role={agreementData.formData?.vendor}
                        onFinish={onApproverFinish}
                        values={agreementRef.current.values}
                        approvedByAllApprovers={agreementData.formData?.approvedByAllApprovers}
                        setSubmitType={setSubmitType}
                        userData={userData}
                        setOpenSharePopup={setOpenSharePopup}
                        handleRejectStatus={handleRejectStatus}
                        setIsEmailModelOpen={setIsEmailModelOpen}
                        isFormValueSet={isFormValueSet}
                      />
                    </div>
                  )
                }
                {
                  /** START: For Vendor Role Only */
                  userData.persona === USER_ROLES["vendor"] &&
                  agreementData?.formData?.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] &&
                  agreementData?.formData?.customerName !== userData.email && (
                    <div className="d-flex vendor-action-btn">
                      {VendorActions({
                        values: agreementData?.formData,
                        agreementStatus: agreementData?.formData?.agreementStatus,
                        btnDisabled,
                        onFinish,
                        // handlePrint,
                      })}
                    </div>
                  )
                  /** END: For Vendor Role Only **/
                }
              </span>
            </span>

            <div className="create-agreement-container">
              <div
                className="agreement-content"
                style={
                  userData.persona === USER_ROLES["vendor"] ||
                    agreementData?.formData?.agreementStatus ===
                    NEW_AGREEMENT_STATUS["vendorEsign"] ||
                    agreementData?.formData?.agreementStatus === NEW_AGREEMENT_STATUS["executed"]
                    ? { justifyContent: "center" }
                    : undefined
                }
              >
                <div className="agreement-viewer-section">
                  {userData.persona === USER_ROLES["vendor"] &&
                    agreementData?.formData?.agreementStatus ===
                    NEW_AGREEMENT_STATUS["vendorEsign"] ? (
                    <div className="d-flex vendor-action-btn">
                      {/* <button
                          className="docx-button"
                          onClick={handlePrint}
                          title={"Download docx"}
                        >
                          Download
                        </button> */}
                      <div className="e-sign-btn-div">
                        <Button
                          // className="docx-button"
                          disabled={btnDisabled || (agreementData?.formData?.isShared || location?.state?.isShared)}
                          onClick={async () => {
                            window.open(agreementData?.formData?.e_sign_url);
                            await onFinish(
                              agreementData.formData,
                              NEW_AGREEMENT_STATUS["executed"],
                            );
                          }}
                          label={
                            <>
                              <FaEdit /> E-sign
                            </>
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                  {agreementData?.formData?.agreementStatus ===
                    NEW_AGREEMENT_STATUS["vendorEsign"] ? (
                    <FileViewer
                      fileType={"pdf"}
                      filePath={url}
                      errorComponent={<div> Error in File Redering</div>}
                      onError={() => console.log("Error in File Redering ....")}
                    />
                  ) : (
                    <DocumentEditorContainerComponent
                      id="ej2-container"
                      ref={(scope: DocumentEditorContainerComponent) => {
                        docRef = scope;

                        if (docRef) {
                          setCustomRef(docRef);
                          docRef.documentEditor.afterFormFieldFill =
                            onFormFieldChangeUpdateAgreementForm;
                        }
                      }}
                      toolbarItems={ToolBarItemArray}
                      toolbarClick={onToolbarClick}
                      serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
                      enableToolbar={
                        !(
                          agreementData?.formData?.agreementStatus ===
                          NEW_AGREEMENT_STATUS["vendorEsign"] ||
                          agreementData?.formData?.agreementStatus ===
                          NEW_AGREEMENT_STATUS["executed"]
                        )
                      }
                      // enableToolbar={false}
                      showPropertiesPane={false}
                      restrictEditing={
                        (agreementData?.formData?.isShared || location?.state?.isShared) ||
                        (agreementData?.formData?.agreementStatus ===
                          NEW_AGREEMENT_STATUS["vendorEsign"] ||
                          agreementData?.formData?.agreementStatus ===
                          NEW_AGREEMENT_STATUS["executed"]) ||
                        restrictEditingForRoleAndAgreementStatusWise(userData.persona, agreementData?.formData?.agreementStatus)
                      }
                      // restrictEditing={true}
                      // restrictEditing={userData.role == "drafter" ?? false}
                      // enableLockAndEdit={userData.role == "drafter" ?? false}
                      enableComment={true}
                      enableTrackChanges={true}
                      contentChange={onContentChangeChangeUpdateAgreementForm}
                    // layoutType="Continuous"
                    />
                  )}
                </div>
                {agreementData?.formData?.agreementStatus ===
                  NEW_AGREEMENT_STATUS["vendorEsign"] ||
                  agreementData?.formData?.agreementStatus === NEW_AGREEMENT_STATUS["executed"] ||
                  userData.persona === USER_ROLES["vendor"] ? null : (
                  <div
                    className={`${isOpenAgreementForm || isOpenVersionList
                      ? "extraction-validate-form-section"
                      : null
                      }`}
                  >
                    {
                      <>
                        {!loading && (
                          <span
                            className={`sliderArrow ${isOpenAgreementForm ? "open-form" : "close-form"
                              }`}
                            onClick={handleArrow}
                          >
                            {isOpenAgreementForm ? <RiArrowRightSLine /> : <RiArrowLeftSLine />}
                          </span>
                        )}
                        {!loading ? (
                          isOpenAgreementForm && (
                            <span
                              style={{
                                opacity: !isOpenAgreementForm ? "0" : "1",
                                animation: "slide 2s forwards",
                                animationDelay: "5s",
                                visibility: !isOpenAgreementForm ? "hidden" : "visible",
                              }}
                            >
                              <AgreementForm
                                setAgreementData={setAgreementData}
                                isEmailModelOpen={isEmailModelOpen}
                                setIsEmailModelOpen={setIsEmailModelOpen}
                                openSharePopup={openSharePopup}
                                setOpenSharePopup={setOpenSharePopup}
                                isUserModleOpen={isUserModleOpen}
                                setUserModleOpen={setUserModleOpen}
                                setCommentModleOpen={setCommentModleOpen}
                                isCommentModleOpen={isCommentModleOpen}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                data={agreementData.formData || {}}
                                handleField={handleField}
                                getSfdtText={getSfdtText}
                                agreementRef={agreementRef}
                                usersList={usersList}
                                buttonDisabled={buttonDisabled}
                                setButtonDisabled={setButtonDisabled}
                                submitType={submitType}
                                setSubmitType={setSubmitType}
                                createTempCopy={createTempCopy}
                                fetchData={() => {
                                  fetchData({
                                    _id: location.state?._id,
                                    url: API_ROUTES.GET_NEW_USER_AGREEMENT,
                                    current_user: userData.email,
                                    de: docRef,
                                  });
                                }}
                                hasFeildReadonly={hasFeildReadonly}
                                handleEStamp={handleEStamp}
                                handleAutoSave={handleAutoSave}
                                //
                                handleCustomField={handleCustomField}
                              />
                            </span>
                          )
                        ) : (
                          <div className="loading">
                            <LoaderHOC />
                          </div>
                        )}
                        {isOpenVersionList && versionData.length ? (
                          <VersionList
                            data={versionData}
                            close={handleClose}
                            onClickVersionCompare={openVersionCompareModalHandler}
                          />
                        ) : (
                          !loading &&
                          isOpenVersionList && (
                            <div className="loading">
                              <LoaderHOC />
                            </div>
                          )
                        )}
                      </>
                    }
                  </div>
                )}
                <ParentSelectionModal
                  defaultValue={agreementData?.formData?.parent_name}
                  {...parentAgreementModalState}
                />
              </div>
            </div>
          </>
        }
      />

      {/* MODAL */}
      {versionCompareModalState &&
        <FileCompareDialog
          isLoading={isFileCompareLoading}
          fileCompareData={fileCompareData}
          versionCompareModalState={versionCompareModalState}
          onCompareHandler={onCompareHandler}
          onCloseVersionCompareModal={closeVersionCompareModalHandler}

          fileCompareModalState={fileCompareModalState}
          onCloseFileCompareModal={onCloseFileCompareModal}

          confirmModalMsg='Are you sure you want to exit?'

          data={versionData}
          selectedVersion={versionData?.[currentIndex]}
        />}

      <div id="ej2-compareVersionDoc" className="hidden"></div>

    </>
  );
};

export default CreateNewAgreement;
