export const API_ROUTES = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  Guest_LOGIN: "/guest-login",
  SIGN_UP: "/signup",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/confirm_forgot_password",
  CHANGE_PASSWORD: "/change_password",
  SUBMIT_CODE: "/submit_code",
  RESEND_CODE: "/resend_code",
  UPLOAD_AGREEMENT: "/upload",
  GET_REPO_DATA: "/get_repo_data",
  GET_KPI_DATA: "/kpi-data",
  VALIDATE_EXTRACTION: "/update_data",
  GET_AGREEMENT_LIST: "/fetch_popup_data",
  GET_AUDIT_LOGS: "/get_audit_log",
  GET_NEW_AGREEMENT: "/get_templates",
  CREATE_NEW_AGREEMENT: "/create-new-agreement",
  MY_TASKS: "my-tasks-list",
  REVIEW_REJECT_COMMENTS: "/review-reject-comments",
  GET_VERSION_LIST: "/get-version-list",
  GET_NEW_USER_AGREEMENT: "/get-id-data",
  SHARE_AGREEMENT_EMAIL_API: "/send-agreement-mail",
  Vendor_Reset_Password: "/vendor-reset-password",
  GET_USER_DATA: "/get-user",
  GET_ROLE_BASED_USERS: "/role-based-users",
  CREATE_NEW_USER: "/add-user",
  UPDATE_USER: "/update-user",
  DELETE_USER: "/delete-user",
  AGREEMENT_AUTHORIZATION: "/create-esigned-request",
  AGREEMENT_TEMPLATE_UPDATE: "/agreement-template-update",
  KPI_UPDATE: "/add-kpi-data",
  GET_NOTIFICATIONS: "/get-notifications",
  READ_NOTIFICATIONS: "/read-notifications",
  RISK_TASKS: "/get-risk-assessments",
  UPDATE_NOTIFICATION_COUNT: "/update-notifications-count",
  MARK_ALL_AS_READ: "/mark-as-read",
  DELETE_EXTRACTION: "/delete-agreement",
  SIGNED_URL: "/signed-url",
  UPLOAD_FILE: "/upload-file",
  GET_DASHBOARD_DATA: "/dashboard-data",
  GET_LICENSE_DATA: "/dashboard-data2",
  //
  ADD_BYOT: "/byot/upload-new-template",
  FETCH_ORG_CONFIG: "/fetch-org-config",
  UPDATE_ORG_CONFIG: "/update-org-config",
  IMPORT_NEW_AGREEMENT: "/upload-import-agreement",
  REFRESH_TOKEN: "/refresh_token",
  FETCH_DATA_FOR_AI_EXTRACTION_NOTIFICATION: "/fetch-data-for-notification",
  UPDATE_IMPORTED_USER_NOTIFIED_FOR_AI: "/update-imported-user-notified-for-ai",
  FETCH_AI_EXTRACTION_DATA: "/fetch-ai-extraction-data",
  AI_EXTRACTION_RESPONSE: "/ai-extraction-response",
  UPDATE_AI_EXTRACTIONDATA: "/update-ai-extraction-data",
  VALIDATE_HISTORIC_AGREEMENT: "/validate-historic-agreement",
  FETCH_IMPORT_AGREEMENT_TYPES: "/fetch-import-agreement-types",

  // SHARE WITH
  // SHARE_WITH: "/share_user",
  SHARE_AGREEMENT: "/share_agreement_template",
  FETCH_USER_DETAILS_FOR_SHARING: "/fetch_user_details_for_sharing",
  SHARE_AGREEMENT_WITH_USERS: "/share_agreement_with_users",
  GET_SHARED_AGREEMENT_DATA: "/share_agreement_get_data",
  // GET_SHARED_USERS_BY_DOC_ID: "/get_user_by_doc_id",

  // DOC COMPARE APIS
  DOC_COMPARE: "/doc_compare",
  DOC_IMPORT_COMPARE: "/doc_import_compare",

  // USER PROFILE
  UPDATE_USER_PROFILE: "/update-profile",
  GET_USER_PROFILE_DATA: "/get_profile_data",
  VALIDATE_USER_PASSWORD: "/validate_password",
  UPDATE_USER_PASSWORD: "/update_user_password",

  // LIBRARRY
  GET_LIBRARY_TEMPLATES: "/fetch_template_library",
  UPLOAD_TEMPLATE_LIBRARY: "/upload_template_library"
};

export const STATUS_CODE = {
  SUCCESS: 200,
  UNAPPROVED: 400,
  UNAUTHORIZED: 401,
  ERROR: 404,
};

export const RESPONSE_MSGS = {
  ERROR: "Something went wrong!",
  OTP_SENT: "OTP sent successfully!",
  OTP_VERIFIED: "OTP verified successfully!",
  LOGGED_IN: "LoggedIn successfully!",
  EMAIL_SENT: "Email sent successfully!",
  PASSWORD_CHANGED: "Password changed successfully!",
  AGREEMENT_UPLOAD_SUCCESS: "Successfully Imported!",
  AGREEMENT_UPLOAD_FAILED: "Importing Failed!",
  EXTRACTION_VALIDATE_SUCCESS: "Extraction validated successfully!",
  NO_RESULT_FOUND: "No Result Found",
  EXTRACTION_AGREEMENT_SUCCESS: "Extraction Agreement successfully!",
  NEW_AGREEMENT_SUCCESS: "Successfully Done!",
  AGREEMENT_REJECTED_STATUS: "Agreement Rejected",
  AGREEMENT_SHARED_STATUS: "Agreement shared successfully",
  AGREEMENT_SIGNED_STATUS: "Agreement signed successfully",
  VENDOR_CREATED_SUCCESS: "User created successfully",
  TOKEN_EXPIRED_AND_LOGIN_AGAIN: "Session Expired. Please login again.",
  EXTRACTION_DELETE_SUCCESS: "Document deleted successfully!",
};
