import { postRequest } from "./axios";
import { API_ROUTES } from "./apis";
import { ParentAgreement } from "../components/SearchableParentAgreements";

function getFormData(
  file: any,
  bucketName: string,
  metadata: string,
  parentInfo: ParentAgreement | undefined,
) {
  const unassigned = "not_assigned";

  const formdata = new FormData();
  formdata.append("files", file);
  formdata.append("bucketName", bucketName);
  formdata.append("key", `${metadata}/${file.name}`);
  formdata.append("client_id", metadata);
  formdata.append("parent_type", parentInfo?.agreement_index || unassigned);
  formdata.append("parent_id", parentInfo?.agreement_id || unassigned);
  formdata.append("parent_name", parentInfo?.agreement_name || unassigned);
  formdata.append("node_type", parentInfo?.agreement_index ? "child" : unassigned);
  return formdata;
}

export const uploadFileToS3 = (
  region: string,
  indentityPoolID: string,
  userPool: string,
  bucketName: string,
  token: string,
  file: any,
  setStatus: Function,
  metadata: string,
  parentInfo?: ParentAgreement,
) => {
  const formdata = getFormData(file, bucketName, metadata, parentInfo);
  new Promise((resolve, reject) => {
    postRequest(API_ROUTES.UPLOAD_FILE, true, formdata)
      .then(({ data }: any) => {
        if (data.uploaded) {
          setStatus("done", 1);
          resolve(data);
        }
      })
      .catch((err) => {
        if (err) {
          console.error(err, "there was an error uploading your file");
          setStatus("error", 0);
          reject(err);
        }
      });
  });
};
export const getSIgnedURLFromS3 = async (
  region: string,
  indentityPoolID: string,
  userPool: string,
  bucketName: string,
  token: string,
  key: string,
  callback: Function,
) => {
  try {
    const data: any = await getSignedUrlPromise(bucketName, key);

    callback(data.data);
  } catch (err) {
    console.log(err);
  }

  // promise.then(
  //   function ({ data }: any) {
  //     callback(data);
  //   },
  //   function (err) {
  //     console.log(err);
  //   },
  // );
};

async function getSignedUrlPromise(bucketName, key) {
  return await postRequest(API_ROUTES.SIGNED_URL, true, { bucketName, key });
}
