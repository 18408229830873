import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FaCheck, FaTimes, FaRedoAlt, FaRegClock } from "react-icons/fa";

import {
  AGREEMENT_UPLOAD_STATUS,
  importFileNameSanitizing,
  IMPORT_TYPES,
  uploadFileToS3,
} from "../../helpers";
import "./style.scss";
import { ParentAgreement } from "../SearchableParentAgreements";

interface otherProps {
  file: any;
  index: number;
  setEachAgreementProcessingStatus: Function;
  reUploadAgreement: Function;
  importType: string;
  parentObject: ParentAgreement;
}

const ImportAgreementListItem = (p: otherProps) => {
  const { file, index, setEachAgreementProcessingStatus, reUploadAgreement, importType } = p;
  const [fileStatus, setFileStatus] = useState<string>("");
  const token = useSelector((state: any) => state.common.userTokens.IdToken) || "";
  const clientId = useSelector((state: any) => state.common.userData.client_id || "");
  const fileUploadToS3 = () => {
    const filename = file.name;
    const newName = importFileNameSanitizing(filename);
    const updatedFile = new File([file], `${newName}`, {
      type: file.type,
      lastModified: file.lastModified,
    });
    setFileStatus(AGREEMENT_UPLOAD_STATUS.IMPORTING);
    let bucket = process.env.REACT_APP_AGREEMENT_BUCKET_NAME_FOR_IMPORT || "";
    if (importType === IMPORT_TYPES.LICENSE) {
      bucket = process.env.REACT_APP_LICENSE_BUCKET_NAME || "";
    }
    uploadFileToS3(
      process.env.REACT_APP_AWS_REGION || "",
      process.env.REACT_APP_AWS_IDENTITY_POOL_ID || "",
      process.env.REACT_APP_AWS_USER_POOL || "",
      bucket,
      token,
      updatedFile,
      (status: string, value: number) => {
        setFileStatus(status);
        setEachAgreementProcessingStatus(index, value);
      },
      clientId,
      p.parentObject,
    );
  };

  useEffect(() => {
    // fileUploadToS3();
    // eslint-disable-next-line
  }, [index, file]);

  return (
    <div
      className={`agreement-processing-list-view ${index % 2 === 0 ? "processing-list-bg" : ""}`}
    >
      {fileStatus === AGREEMENT_UPLOAD_STATUS.IMPORTING ? (
        <div className="list-row-action-container">
          <FaRegClock className="list-row-action-icon" />
        </div>
      ) : fileStatus === AGREEMENT_UPLOAD_STATUS.NOT_STARTED ? (
        <div className="list-row-action-container">
          <FaTimes className="list-row-action-icon" />
        </div>
      ) : fileStatus === AGREEMENT_UPLOAD_STATUS.DONE ? (
        <div className="list-row-action-container success-icon">
          <FaCheck className="list-row-action-icon" />
        </div>
      ) : fileStatus === AGREEMENT_UPLOAD_STATUS.ERROR ? (
        <div className="list-row-action-container retry-icon">
          <FaRedoAlt
            className="list-row-action-icon"
            onClick={() => {
              reUploadAgreement();
              fileUploadToS3();
            }}
          />
        </div>
      ) : (
        ""
      )}

      <div className="list-row-title">
        <span>{`Importing "${importFileNameSanitizing(file.name)}"`} </span>
      </div>
      <div className="list-row-progress-bar">
        {fileStatus === AGREEMENT_UPLOAD_STATUS.IMPORTING ? (
          <LinearProgress className="list-row-progress-linear-bar" />
        ) : fileStatus === AGREEMENT_UPLOAD_STATUS.NOT_STARTED ? (
          <LinearProgress
            style={{ background: "#c2c5d9", color: "#c2c5d9" }}
            variant="determinate"
            value={100}
          />
        ) : fileStatus === AGREEMENT_UPLOAD_STATUS.DONE ? (
          <LinearProgress
            className="list-row-progress-linear-bar"
            variant="determinate"
            value={100}
          />
        ) : fileStatus === AGREEMENT_UPLOAD_STATUS.ERROR ? (
          <LinearProgress
            style={{ background: "red", color: "red" }}
            variant="determinate"
            value={100}
          />
        ) : (
          ""
        )}
      </div>
      <div className="processing-status">
        <span
          style={
            fileStatus === AGREEMENT_UPLOAD_STATUS.ERROR
              ? {
                  color: "#F4AE51",
                }
              : {}
          }
        >
          {fileStatus === AGREEMENT_UPLOAD_STATUS.IMPORTING
            ? "Importing..."
            : fileStatus === AGREEMENT_UPLOAD_STATUS.NOT_STARTED
            ? "Yet to start..."
            : fileStatus === AGREEMENT_UPLOAD_STATUS.DONE
            ? "Done!"
            : fileStatus === AGREEMENT_UPLOAD_STATUS.ERROR
            ? "Error: Click reload to import again..."
            : ""}
        </span>
      </div>
    </div>
  );
};

export default ImportAgreementListItem;
