import React from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";

import { Button, ButtonProps, styled } from "@mui/material";

import "./style.scss";

interface otherProps {
  label: any;
  className?: string;
  variant?: "contained" | "outlined";
  type?: "button" | "submit";
  disabled?: boolean;
  style?: object;
  onClick?: Function;
  color?: "primary" | "secondary";

  isFullWidth?: true | false;

  //
  onMouseOver?: Function;
  onMouseLeave?: Function;

  // readOnly?: boolean;
  // onAction?: Function;
  // onActionBlur?: Function;
  // highlightText?: string;
  // showRiskWarning?: boolean;
  // id?: string;
  // isRequired?: boolean;
}

const ButtonComponent = (p: otherProps) => {
  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => {
    // theme.components?.MuiButtonBase
    return {
      // backgroundColor: `${theme.palette.primary.main} !important`,
    };
  });

  const customClasses = () => {
    let classList = "";

    if (p.variant === "contained" || p.variant === undefined) {
      switch (p.color) {
        case "primary":
        case undefined:
          classList += ` !bg-primary-500 !text-white disabled:opacity-50`;
          break;
        case "secondary":
          classList += ` !bg-secondary-500 !text-white disabled:opacity-50`;
          break;
      }
    } else if (p.variant === "outlined") {
      switch (p.color) {
        case "primary":
        case undefined:
          classList += ` !border !border-solid !border-primary-400 !text-primary-500 disabled:opacity-50 hover:bg-primary-500 hover:!text-white`;
          break;
        case "secondary":
          classList += ` `;
          break;
      }
    }

    return classList;
  };

  return (
    <ColorButton
      {...p}
      variant={p.variant || "contained"}
      color={p.color || "primary"}
      type={p.type || "button"}
      disabled={p.disabled || false}
      className={`
      ${customClasses()}
      ${p.isFullWidth || p.isFullWidth === undefined ? "w-full" : ""} !rounded-md !py-1.5 !px-3 ${p.className ? p.className : ""
        }`}
      style={p.style}
      size="small"
    // sx={{
    //   ":hover": {
    //     // bgcolor: "primary.main", // theme.palette.primary.main
    //     // color: "white !important",
    //   },
    // }}
    >
      {p.label}
    </ColorButton>
  );
};

export default ButtonComponent;
