import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlinePlusCircle } from "react-icons/ai";

import {
  importFileNameSanitizing,
  IMPORT_TYPES,
  FileTypes,
  DocxFileExtentions,
  PdfFileExtentions,
  AgreementFileExtentions,
} from "../../helpers";
import "./style.scss";

interface otherProps {
  agreementToImport: any;
  setAgreementFilesToImport: Function;
  importType: any;
}

const FileUpload = (p: otherProps) => {
  const { agreementToImport, setAgreementFilesToImport, importType } = p;

  const onDrop = useCallback(
    (acceptedFiles) => {
      // const fileArr = [...agreementToImport, ...acceptedFiles];
      // const maxFiles = parseInt(process.env.REACT_APP_MAX_AGREEMENTS_TO_IMPORT || "100");
      const fileArr = [...acceptedFiles];
      const maxFiles = parseInt("1");
      if (fileArr.length <= maxFiles) {
        setAgreementFilesToImport(fileArr);
      } else {
        setAgreementFilesToImport(fileArr.slice(0, maxFiles));
      }
    },
    [agreementToImport, setAgreementFilesToImport],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: importType as any,
    maxSize: parseInt(process.env.REACT_APP_MAX_AGREEMENT_SIZE || "25000000"),
  });

  const previewClass = p.importType === IMPORT_TYPES.AGREEMENT_PREVIEW ? "preview" : "";
  return (
    <>
      <div
        className={`${
          agreementToImport.length ? "selected-file-block" : "custom-file-upload-block"
        } px-7`}
        {...getRootProps()}
      >
        <div className="flex">
          <input {...getInputProps()} />
          <div className="form-group">
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                {agreementToImport.length ? (
                  <div className="selected-file-items">
                    <div className={`select-title ${previewClass}`}>
                      <strong>
                        {p.importType === IMPORT_TYPES.AGREEMENT_PREVIEW
                          ? `FileName: ${importFileNameSanitizing(agreementToImport[0]?.name)}`
                          : "File:"}
                      </strong>
                    </div>
                    {p.importType !== IMPORT_TYPES.AGREEMENT_PREVIEW && (
                      <ul>
                        {agreementToImport.map((file: any) => (
                          <li key={file.name}>{importFileNameSanitizing(file.name)}</li>
                        ))}
                        {/* <div className="add-more-to-upload">
                          <AiOutlinePlusCircle />
                          Add More
                        </div> */}
                        <div style={{ height: "24px" }}></div>
                      </ul>
                    )}
                  </div>
                ) : (
                  <span className="p-2">
                    <p>
                      Drop your file here, or <span className="browse-files">browse</span>
                    </p>
                    <span className="supported-files">
                      <div>
                        Supports:{" "}
                        {importType === DocxFileExtentions.ACCEPT_LIST
                          ? "Doc, Docx"
                          : importType === PdfFileExtentions.ACCEPT_LIST
                          ? "Pdf"
                          : importType === AgreementFileExtentions.ACCEPT_LIST
                          ? "Doc, Docx, Pdf"
                          : // ? "Any type of file"
                            ""}
                        {/* {p.importType === IMPORT_TYPES.LICENSE
                          ? "PDF"
                          : p.importType === IMPORT_TYPES.AGREEMENT_PREVIEW
                          ? "Docs"
                          : "PDF, Docs, Word"} */}
                      </div>
                      {/* {p.importType !== IMPORT_TYPES.AGREEMENT_PREVIEW && (
                        <div>
                          Bulk Import Upto {process.env.REACT_APP_MAX_AGREEMENTS_TO_IMPORT || "100"}{" "}
                          {p.importType === IMPORT_TYPES.LICENSE ? "Licenses" : "Agreements"}{" "}
                        </div>
                      )} */}
                      <div>
                        Maximum File Size:{" "}
                        {parseInt(process.env.REACT_APP_MAX_AGREEMENT_SIZE || "25000000") / 1000000}{" "}
                        MB
                      </div>
                    </span>
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
