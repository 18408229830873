import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileViewer from "react-file-viewer";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Popup from "reactjs-popup";
import { Worker, Viewer } from "@react-pdf-viewer/core";

import { TextInput } from "components/FormFields";
import DashboardLayout from "layouts/dashboardLayout";
import {
  AGREEMENT_IN_REPO_STATUS,
  API_ROUTES,
  getSIgnedURLFromS3,
  IMAGES,
  postRequest,
  RESPONSE_MSGS,
  ROUTE_CONSTANTS,
  showLogInConsole,
  showToast,
  STATUS_CODE,
  deleteRequest,
} from "../../../helpers";
import "./style.scss";
import { useNavigate } from "react-router";
import { setDataToValidateExtraction } from "../../../redux/actions";
import { ExtractionValidateConfirmation } from "../../../components";
import { forceLogout } from "../../../redux/services";

interface asgreementProps {
  data: {
    Title: string;
    Contract_name: string;
    CustomerName: string;
    RequesterName: string;
    CustomerAddress: string;
    RequesterAddress: string;
    ServiceStartDate: string;
    EffectiveDate: string;
    ExpiryDate: string;
    RenewalDate: string;
    amounts: string;
    Value1: string;
    agreementStatus: string;
    Jurisdiction: string;
    Obligations: any;
    LiabilitiesLimitation: any;
    Indemnitites: any;
    expiry_date_for_highlight: any;
    effective_date_for_highlight: any;
    jurisdiction_for_highlight: any;
    courts: any;
    laws: any;
    courts_for_highlight: any;
    laws_for_highlight: any;
    license: any;
    license_number: any;
    area: string;
    escalation: string;
    purpose: string;
  };
  userTokens: {
    AccessToken: string;
    IdToken: string;
    RefreshToken: string;
  };
}

const AgreementSchema = Yup.object().shape({
  Title: Yup.string().required("Required"),
  CustomerName: Yup.string().required("Required"),
  RequesterName: Yup.string().required("Required"),
  CustomerAddress: Yup.string().required("Required"),
  RequesterAddress: Yup.string().required("Required"),
  ServiceStartDate: Yup.string().required("Required"),
  EffectiveDate: Yup.string().required("Required"),
  ExpiryDate: Yup.string().required("Required"),
  RenewalDate: Yup.string().required("Required"),
  amounts: Yup.string().required("Required"),
  Value1: Yup.string().required("Required"),
  Jurisdiction: Yup.string().required("Required"),
  courts: Yup.string().required("Required"),
  laws: Yup.string().required("Required"),
  license_number: Yup.string().required("Required"),
});

const removeAndHighlight = () => {
  const highlightedText: any = document.querySelector(".highlight") || null;

  if (highlightedText) {
    //highlightedText.classList.remove("highlight");
    highlightedText.outerHTML = highlightedText.innerText;
  }
};

const findAndHighlight = (p: { text: string }) => {
  try {
    if (p.text === "Not Found") return;

    const inputText: any = document.querySelector(".agreement-viewer-section") || null;
    const innerHTML = inputText?.innerHTML || "";
    const splitHtml = innerHTML.split(" ");
    const searchTextSplit = p.text.split(" ");

    let newHTML: any = [];
    let outerLoopFlag = true;
    for (let y = 0; y < splitHtml.length; y++) {
      if (outerLoopFlag) {
        for (let z = 0; z < searchTextSplit.length; z++) {
          if (!newHTML.length) {
            if (z === 0 && splitHtml[y].indexOf(searchTextSplit[z]) !== -1) {
              newHTML.push({ text: splitHtml[y], index: y });
            }
          } else {
            // Break the both loops when complete text has been found
            if (newHTML.length === searchTextSplit.length) {
              outerLoopFlag = false;
              break;
            }
            const lastIndex = newHTML.length - 1;
            // Break the inner loop when word has been found
            if (newHTML[lastIndex].index === y) {
              break;
            }
            if (newHTML[lastIndex].index + 1 === y) {
              if (splitHtml[y].indexOf(searchTextSplit[z]) !== -1) {
                newHTML.push({ text: splitHtml[y], index: y });
              }
            } else {
              newHTML = [];
            }
          }
        }
      } else {
        break;
      }
    }
    if (newHTML.length && searchTextSplit.length) {
      const lengthOfNewArr = newHTML.length;

      let startTag = "";
      if (!lengthOfNewArr) return;
      if (lengthOfNewArr === 1) {
        if (newHTML[0].text.indexOf(`<span>${searchTextSplit[0]}</span>`) !== -1) {
          startTag = newHTML[0].text.replace(
            `<span>${searchTextSplit[0]}</span>`,
            `<span class="highlight">${searchTextSplit[0]}</span>`,
          );
        } else if (newHTML[0].text.indexOf(`${searchTextSplit[0]}</span>`) !== -1) {
          startTag = newHTML[0].text.replace(
            `${searchTextSplit[0]}</span>`,
            `<span class="highlight">${searchTextSplit[0]}</span>`,
          );
        } else {
          startTag = newHTML[0].text.replace(
            searchTextSplit[0],
            `<span class="highlight">${searchTextSplit[0]}</span>`,
          );
        }
        splitHtml[newHTML[0].index] = startTag;
      } else {
        if (newHTML[0].text.indexOf(`<span>${searchTextSplit[0]}</span>`) !== -1) {
          startTag = newHTML[0].text.replace(
            `<span>${searchTextSplit[0]}</span>`,
            `<span class="highlight">${searchTextSplit[0]}`,
          );
        } else if (newHTML[0].text.indexOf(`${searchTextSplit[0]}</span>`) !== -1) {
          startTag = newHTML[0].text.replace(
            `${searchTextSplit[0]}</span>`,
            `<span class="highlight">${searchTextSplit[0]}`,
          );
        } else {
          startTag = newHTML[0].text.replace(
            searchTextSplit[0],
            `<span class="highlight">${searchTextSplit[0]}`,
          );
        }
        splitHtml[newHTML[0].index] = startTag;
        let endTag = "";
        if (
          newHTML[lengthOfNewArr - 1].text.indexOf(
            `<span>${searchTextSplit[searchTextSplit.length - 1]}</span>`,
          ) !== -1
        ) {
          endTag = newHTML[lengthOfNewArr - 1].text.replace(
            `<span>${searchTextSplit[searchTextSplit.length - 1]}</span>`,
            `${searchTextSplit[searchTextSplit.length - 1]}</span>`,
          );
        } else if (
          newHTML[lengthOfNewArr - 1].text.indexOf(
            `${searchTextSplit[searchTextSplit.length - 1]}</span>`,
          ) !== -1
        ) {
          endTag = newHTML[lengthOfNewArr - 1].text.replace(
            `${searchTextSplit[searchTextSplit.length - 1]}</span>`,
            `${searchTextSplit[searchTextSplit.length - 1]}</span>`,
          );
        } else {
          endTag = newHTML[lengthOfNewArr - 1].text.replace(
            searchTextSplit[searchTextSplit.length - 1],
            `${searchTextSplit[searchTextSplit.length - 1]}</span>`,
          );
        }
        splitHtml[newHTML[lengthOfNewArr - 1].index] = endTag;
      }
      const newInnerHTML = splitHtml.join(" ");
      if (inputText) {
        inputText.innerHTML = newInnerHTML;
      }
    }
  } catch (err: any) {
    console.log(err);
  }
};

const handleHighlightText = (text: string) => {
  const findText = text || "";
  removeAndHighlight();
  findAndHighlight({ text: findText.trim() });

  const selectedText: any = document.querySelector(".highlight");
  const main = document.querySelector(".agreement-viewer-section");

  if (selectedText && main) {
    main.scrollTop = selectedText?.offsetTop - 50 || 0;
    main.scrollLeft = selectedText.offsetLeft;
    return;
  }
  //showToast(RESPONSE_MSGS.NO_RESULT_FOUND, false);
};

const CollapseMode = (p: { title: string; children: any }) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  return (
    <>
      <div className="agreement-form-collapse">
        <div className="collapse-header">
          <span>{p.title}</span>
          {isHidden ? (
            <MdKeyboardArrowDown onClick={() => setIsHidden(false)} />
          ) : (
            <MdKeyboardArrowUp onClick={() => setIsHidden(true)} />
          )}
        </div>
        {!isHidden && <div className="collapse-body-row">{p.children}</div>}
      </div>
    </>
  );
};

const AgreementForm = (p: asgreementProps) => {
  const [btnDisabled, setButtonDisabled] = useState(
    (p?.data?.agreementStatus || "").toLowerCase() ===
    AGREEMENT_IN_REPO_STATUS["validate"].toLowerCase(),
  );

  const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDelete = (values: any) => {
    setDeleteBtnDisabled(true);
    deleteRequest(API_ROUTES.DELETE_EXTRACTION, true, {
      contract_name: values,
    })
      .then(async (res: any) => {
        const { status } = res || {};
        if (status !== STATUS_CODE.SUCCESS) {
          showToast(RESPONSE_MSGS.ERROR, false);
        } else {
          showToast(RESPONSE_MSGS.EXTRACTION_DELETE_SUCCESS, true);
          navigate(ROUTE_CONSTANTS.Repository);
          dispatch(setDataToValidateExtraction({}));
        }
      })
      .catch((err) => {
        showLogInConsole("ERROR In delete document repo data ", err);
      })
      .finally(() => {
        setDeleteBtnDisabled(false);
      });
  };

  const onFinish = (values: any) => {
    setButtonDisabled(true);

    let formValues: any = {
      Contract_name: values.Contract_name,
      CustomerAddress: values.CustomerAddress,
      agreementStatus: AGREEMENT_IN_REPO_STATUS["validate"],
      CustomerName: values.CustomerName,
      EffectiveDate: values.EffectiveData,
      ExpiryDate: values.ExpiryData,
      Extension: values.Extension,
      Jurisdiction: values.Jurisdiction,
      Key: values.Key,
      Region: values.Region,
      RenewalDate: values.RenewalDate,
      RequesterAddress: values.RequesterAddress,
      RequesterName: values.RequesterName,
      ServiceStartDate: values.ServiceStartDate,
      Title: values.Title,
      Type: values.Type,
      amounts: values.amounts,
      Value1: values.Value1,
      Version: values.Version,
      laws: values.laws,
      courts: values.courts,
      license: p?.data?.license,
      license_number: values.license_number,
    };

    if (values.area) {
      formValues = { ...formValues, area: values.area, escalation: values.escalation };
    }
    if (values.purpose) {
      formValues = { ...formValues, purpose: values.purpose };
    }

    postRequest(API_ROUTES.VALIDATE_EXTRACTION, true, formValues)
      .then(async (res: any) => {
        const { data } = res || {};
        const { status } = data || {};
        forceLogout({ status: data?.status_code, message: data?.message });

        if (status !== STATUS_CODE.SUCCESS) {
          showToast(RESPONSE_MSGS.ERROR, false);
        } else {
          showToast(RESPONSE_MSGS.EXTRACTION_VALIDATE_SUCCESS, true);
          navigate(ROUTE_CONSTANTS.Repository);
          dispatch(setDataToValidateExtraction({}));
        }
      })
      .catch((err) => {
        showLogInConsole("ERROR In Ftech repo data ", err);
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  const hasFeildReadonly = (f: { status: string | undefined }) => {
    return f?.status?.toLowerCase() === AGREEMENT_IN_REPO_STATUS["validate"].toLowerCase();
  };

  return (
    <Formik
      initialValues={p.data}
      validationSchema={AgreementSchema}
      onSubmit={() => {
        return;
      }}
    >
      {({ values }) => (
        <Form>
          <div className="agreement-form-container">
            <div className="form-header">
              <div className="form-header-row">
                <span>Validate Extracted Data</span>
                {!hasFeildReadonly({ status: p?.data?.agreementStatus }) ? (
                  <span>
                    <Popup
                      trigger={
                        <button type="button" className="delete-button">
                          Delete
                        </button>
                      }
                      modal
                      closeOnDocumentClick={false}
                      closeOnEscape={false}
                      className="agreement-upload-popup validate-extraction-popup"
                    >
                      {(close) => (
                        <ExtractionValidateConfirmation
                          btnDisabled={deleteBtnDisabled}
                          closePopup={close}
                          onFinish={() => onDelete(values.Contract_name)}
                          isValidate={false}
                        />
                      )}
                    </Popup>
                    <Popup
                      trigger={
                        <button disabled={btnDisabled} type="button">
                          {"Validate"}
                        </button>
                      }
                      modal
                      closeOnDocumentClick={false}
                      closeOnEscape={false}
                      className="agreement-upload-popup validate-extraction-popup"
                    >
                      {(close) => (
                        <ExtractionValidateConfirmation
                          btnDisabled={btnDisabled}
                          closePopup={close}
                          onFinish={() => onFinish(values)}
                          isValidate
                        />
                      )}
                    </Popup>
                  </span>
                ) : null}
              </div>
              <span>{p?.data?.Title}</span>
            </div>
            {p?.data?.license?.toString() === "true" ? (
              <>
                <CollapseMode
                  title={"License Details"}
                  children={
                    <>
                      {/* <TextInput
                        name={"Jurisdiction"}
                        type="text"
                        label="License Jurisdiction"
                        placeholder={"License Jurisdiction"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                        highlightText={p?.data?.jurisdiction_for_highlight}
                      /> */}

                      <TextInput
                        name={"license_number"}
                        type="text"
                        label="License Number"
                        placeholder={"License Number"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                        highlightText={p?.data?.license_number}
                      />
                    </>
                  }
                />
                <CollapseMode
                  title={"Party Details"}
                  children={
                    <>
                      <TextInput
                        name={"CustomerName"}
                        type="text"
                        label="Party Name"
                        placeholder={"Party Name"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                      <TextInput
                        name={"CustomerAddress"}
                        type="text"
                        label="Party Address"
                        placeholder={"Party Address"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                    </>
                  }
                />
                <CollapseMode
                  title={"Dates"}
                  children={
                    <>
                      <TextInput
                        name={"EffectiveDate"}
                        type="text"
                        label="Start Date"
                        placeholder={"Start Date"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                        highlightText={p?.data?.effective_date_for_highlight}
                      />
                      <TextInput
                        name={"ExpiryDate"}
                        type="text"
                        label="Expiry Date"
                        //label="Renewal Date"
                        placeholder={"Renewal Date"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                        highlightText={p?.data?.expiry_date_for_highlight}
                      />
                    </>
                  }
                />
                <CollapseMode
                  title={"Amounts"}
                  children={
                    <>
                      <TextInput
                        name={"amounts"}
                        type="text"
                        label="Amounts"
                        placeholder={"Amounts"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                    </>
                  }
                />
              </>
            ) : (
              <>
                <CollapseMode
                  title={"Title"}
                  children={
                    <>
                      <TextInput
                        name={"Title"}
                        type="text"
                        label="Title"
                        placeholder={"Title"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                    </>
                  }
                />
                <CollapseMode
                  title={"Party Names"}
                  children={
                    <>
                      <TextInput
                        name={"CustomerName"}
                        type="text"
                        label="Customer Name"
                        placeholder={"First Party Name"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                      <TextInput
                        name={"RequesterName"}
                        type="text"
                        label="Requester Name"
                        placeholder={"Second Party Name"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                    </>
                  }
                />
                <CollapseMode
                  title={"Party Addresses And Jurisdiction"}
                  children={
                    <>
                      <TextInput
                        name={"CustomerAddress"}
                        type="text"
                        label="Customer Address"
                        placeholder={"First Party Address"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                      <TextInput
                        name={"RequesterAddress"}
                        type="text"
                        label="Requester Address"
                        placeholder={"Second Party Address"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                      <TextInput
                        name={"courts"}
                        type="text"
                        label="Court"
                        placeholder={"Court"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                        highlightText={p?.data?.courts_for_highlight}
                      />
                      <TextInput
                        name={"laws"}
                        type="text"
                        label="Law"
                        placeholder={"Law"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                        highlightText={p?.data?.laws_for_highlight}
                      />
                    </>
                  }
                />
                <CollapseMode
                  title={"Dates"}
                  children={
                    <>
                      {/* <TextInput
                    name={"ServiceStartDate"}
                    type="text"
                    label="Service Start Date"
                    placeholder={"Date"}
                    className={"agreement-text-field"}
                    readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                    onAction={handleHighlightText}
                  /> */}
                      <TextInput
                        name={"EffectiveDate"}
                        type="text"
                        label="Effective Date"
                        placeholder={"Date"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                        highlightText={p?.data?.effective_date_for_highlight}
                      />
                      <TextInput
                        name={"ExpiryDate"}
                        type="text"
                        label="Expiry Date"
                        placeholder={"Date"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                        highlightText={p?.data?.expiry_date_for_highlight}
                      />
                      {/* <TextInput
                    name={"RenewalDate"}
                    type="text"
                    label="Renewal Date"
                    placeholder={"Date"}
                    className={"agreement-text-field"}
                    readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                    onAction={handleHighlightText}
                  /> */}
                    </>
                  }
                />
                <CollapseMode
                  title={"Amounts"}
                  children={
                    <>
                      <TextInput
                        name={"amounts"}
                        type="text"
                        // label="Amounts 1"
                        label="Amounts"
                        placeholder={"Amounts"}
                        className={"agreement-text-field"}
                        readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                        onAction={handleHighlightText}
                      />
                    </>
                  }
                />

                {p.data?.purpose && (
                  <CollapseMode
                    title={"Purpose"}
                    children={
                      <>
                        <TextInput
                          name={"purpose"}
                          type="text"
                          label="Purpose"
                          placeholder={"Purpose"}
                          className={"agreement-text-field"}
                          readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                          onAction={handleHighlightText}
                        />
                      </>
                    }
                  />
                )}

                {p.data?.area && p.data?.escalation && (
                  <CollapseMode
                    title={"Area"}
                    children={
                      <>
                        <TextInput
                          name={"escalation"}
                          type="text"
                          label="Escalation"
                          placeholder={"Escalation"}
                          className={"agreement-text-field"}
                          readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                          onAction={handleHighlightText}
                        />
                        <TextInput
                          name={"area"}
                          type="text"
                          label="Area"
                          placeholder={"Area"}
                          className={"agreement-text-field"}
                          readOnly={hasFeildReadonly({ status: p?.data?.agreementStatus })}
                          onAction={handleHighlightText}
                        />
                      </>
                    }
                  />
                )}
                <CollapseMode
                  title={"Obligations"}
                  children={
                    <>
                      <ul className="clickable-list">
                        {p?.data?.Obligations?.map((obligation: any) =>
                          obligation?.trim() ? (
                            <li onClick={() => handleHighlightText(obligation)} key={obligation}>
                              {" "}
                              {obligation}{" "}
                            </li>
                          ) : null,
                        )}
                      </ul>
                    </>
                  }
                />
                <CollapseMode
                  title={"Limitation Liabilities"}
                  children={
                    <>
                      <ul className="clickable-list">
                        {p?.data?.LiabilitiesLimitation?.map((limitation: any) =>
                          limitation?.trim() ? (
                            <li onClick={() => handleHighlightText(limitation)} key={limitation}>
                              {" "}
                              {limitation}{" "}
                            </li>
                          ) : null,
                        )}
                      </ul>
                    </>
                  }
                />
                <CollapseMode
                  title={"Indemnitites"}
                  children={
                    <>
                      <ul className="clickable-list">
                        {p?.data?.Indemnitites?.map((indemnitity: any) =>
                          indemnitity?.trim() ? (
                            <li onClick={() => handleHighlightText(indemnitity)} key={indemnitity}>
                              {" "}
                              {indemnitity}{" "}
                            </li>
                          ) : null,
                        )}
                      </ul>
                    </>
                  }
                />
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const ValidateExtraction = () => {
  // SELECTORS
  const extractedData = useSelector((state: any) => state.repository.dataToValidateExtraction);
  const userTokens = useSelector((state: any) => state.common.userTokens) || {};

  // STATES
  const [url, setSignedURL] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // FUNCTIONS
  const getSignedUrl = async ({ region, poolId, userPoolId, bucket, token, key_name }) => {
    try {
      await getSIgnedURLFromS3(
        region,
        poolId,
        userPoolId,
        bucket,
        token,
        key_name,
        (signed_url: string) => {
          if (signed_url) {
            setSignedURL(signed_url);
          }
        },
      );
    } catch (err) {
      console.log("getSIgnedURLFromS3 error ===>", err);
    } finally {
      setIsLoading(false);
    }
  }

  // EFFECTS
  useEffect(() => {
    let key_name = extractedData.executedPath || extractedData.fileUrl || "";
    // const isExecuted = extractedData?.agreementStatus === "Executed" || extractedData?.Status === "Executed";
    const token = userTokens.IdToken;
    const region = process.env.REACT_APP_AWS_REGION;
    const poolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID;
    const userPoolId = process.env.REACT_APP_AWS_USER_POOL;
    const agreementStatus = (extractedData?.agreementStatus === "Executed" || extractedData?.Status === "Executed") ? "executed" : (extractedData?.agreementStatus === "Validate" || extractedData?.Status === "Validate") ? "validate" : "imported";
    let bucket = extractedData.exceutedBucketName || extractedData.bucketName || (agreementStatus == "executed" ? "executed-agreements" : agreementStatus == "validate" ? "import-historic-agreements" : "");

    // if (isExecuted) {
    //   key_name = key_name.replace(
    //     key_name.substr(key_name.lastIndexOf("."), key_name.length),
    //     ".pdf",
    //   )
    // } else {
    //   key_name = key_name.replace(
    //     key_name.substr(key_name.lastIndexOf("."), key_name.length),
    //     ".docx",
    //   )
    // }

    if (extractedData?.license?.toString() === "true") {
      bucket = process.env.REACT_APP_LICENSE_BUCKET_NAME || "";
    }

    if (region && poolId && userPoolId && bucket && token && key_name) {
      getSignedUrl({ region, poolId, userPoolId, bucket, token, key_name });
    } else {
      showToast("Something went wrong", false);
      // navigate(-1);
    }

  }, []);

  return (
    <>
      <DashboardLayout
        hederTitle={<>{extractedData.Contract_name}</>}
        childer={
          <div className="agreement-container">
            <div
              className="agreement-executed-content"
            // className={
            //   extractedData.agreementStatus === "Executed" || extractedData.Status === "Executed"
            //     ? "agreement-executed-content"
            //     : "agreement-content"
            // }
            >
              {isLoading ? (
                <div>loading...</div>
              ) : (
                <>
                  <div className="agreement-viewer-section">
                    {/* {url &&
                      extractedData.agreementStatus?.toLowerCase() !== "executed" &&
                      extractedData.Status?.toLowerCase() !== "executed" ? (
                      <FileViewer
                        fileType={"docx"}
                        filePath={url}
                        errorComponent={<div> Error in File Redering</div>}
                        onError={() => console.log("Error in File Rendering ....")}
                      />
                    ) : (url && extractedData.agreementStatus?.toLowerCase() === "executed") ||
                      extractedData.Status?.toLowerCase() === "executed" ? (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                        <Viewer fileUrl={url} />
                      </Worker>
                    ) : (
                      <div className="no-agreement-found">
                        {" "}
                        <img src={IMAGES.NoDataFound} alt="" /> No doc found
                      </div>
                    )} */}

                    {url ?
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                        <Viewer fileUrl={url} />
                      </Worker> :
                      <div className="no-agreement-found">
                        <img src={IMAGES.NoDataFound} alt="" /> No doc found
                      </div>
                    }

                  </div>
                  {/* {extractedData.agreementStatus === "Executed" ||
                    extractedData.Status === "Executed" ? null : (
                    <div className="extraction-validate-form-section">
                      <AgreementForm data={extractedData} userTokens={userTokens} />
                    </div>
                  )} */}
                </>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

export default ValidateExtraction;
