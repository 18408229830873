import {
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
  usePagination,
  useExpanded,
} from "react-table";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

import TableSearchBar from "./SearchBar";
// import IndeterminateCheckbox from "./Checkbox";
import CustomPagination from "./CustomPagination";

import "./style.scss";
import React, { useEffect, useState } from "react";
import { addRepositoryDocIds } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { InputAdornment, TextField } from "@material-ui/core";
import { AiOutlineSearch } from "react-icons/ai";

const TableJs = (
  {
    columns,
    data,
    isRowSelectEnable,
    isOtherMenuEnable = true,
    customHeaderComponent,
    headerProps,
    isCurrentRowsCountVisibilityRequired,
    isRowsPerPageChangeRequired,
    headerContainerClass = "",
    getRowProps = (row) => {
      return row ? {} : {};
    },
    sortBy = [],
    renderRowSubComponent = (props) => {
      return <React.Fragment {...props} />;
    },
    searchHandler = (e) => {},
    // access
  },
  ref,
) => {
  const dispatch = useDispatch();

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    toggleAllRowsSelected,
    headerGroups,
    //rows,
    prepareRow,
    setGlobalFilter,
    //pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy },
      // pageCount: number;
      // manualPagination: true
      // autoResetPage?: boolean | undefined;
      paginateExpandedRows: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!isRowSelectEnable) return null;
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          Header: (d) => {
            const { getToggleAllRowsSelectedProps, toggleAllRowsSelected, rows } = d;
            const orignal = rows?.[0]?.original;

            const selectAllHandler = (e) => {
              let selectedDocs = selectedDocsIds;
              toggleAllRowsSelected();

              if (!d.isAllRowsSelected) {
                const docs = rows.map((item) => {
                  return {
                    documentId: item.original?._id || "",
                    documentName:
                      item.original?.Contract_name || item.original?.contract_name || "",
                    agreementStatus: item.original?.agreementStatus || "",
                    accessLevel: item.original?.accessLevel || "Read-Only",
                  };
                });
                selectedDocs = docs;
              } else {
                selectedDocs = [];
              }
              dispatch(addRepositoryDocIds(selectedDocs));
            };

            return (
              <div>
                <input
                  id="table-header-checkbox"
                  className="table-checkbox"
                  type="checkbox"
                  disabled={orignal?.isShared == "true" || orignal?.isShared == true}
                  onClick={selectAllHandler}
                  {...getToggleAllRowsSelectedProps()}
                />
              </div>
            );
          },
          Cell: (d) => {
            const { row } = d;

            const markSpecificDocHandler = (data) => {
              let selectedDocs = selectedDocsIds;
              if (!data.isSelected) {
                if (!selectedDocs.find((item) => item?.documentId == data.original._id)) {
                  selectedDocs.push({
                    documentId: data?.original?._id || "",
                    documentName:
                      data?.original?.Contract_name || data?.original?.contract_name || "",
                    agreementStatus: data?.original?.agreementStatus || "",
                    accessLevel: "Read-Only",
                    indexName:
                      data?.original?.agreementStatus == "Executed"
                        ? "new-agreement"
                        : "historic-agreement",
                  });
                }
              } else {
                if (selectedDocs.find((item) => item?.documentId == data.original._id)) {
                  const index = selectedDocs.findIndex(
                    (item) => item?.documentId == data?.original?._id,
                  );
                  if (index > -1) {
                    selectedDocs.splice(index, 1);
                  }
                }
              }
              dispatch(addRepositoryDocIds(selectedDocs));
            };

            return (
              <div className="">
                <input
                  className="table-checkbox"
                  type="checkbox"
                  disabled={row?.orignal?.isShared == "true" || row?.orignal?.isShared == true}
                  onClick={() => markSpecificDocHandler(row)}
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  // STATES
  const [currentPage, setCurrentPage] = useState(1);

  // SELECTORS
  const selectedDocsIds = useSelector((state) => state.repository.selectedDocIds) || [];

  // EFFECTS
  useEffect(() => {
    setPageSize(10);
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedDocsIds.length == 0) {
      toggleAllRowsSelected(false);
    }
  }, [selectedDocsIds]);

  // Render the UI for your table
  return (
    <>
      <div className="table-header">
        <div className={`${headerContainerClass} w-full flex justify-between items-center`}>
          <div className="flex justify-start items-center">
            <TextField
              size="small"
              className={`Search-wrapper`}
              variant="outlined"
              onChange={(e) => {
                searchHandler(e);
                setCurrentPage(1);
              }}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ color: "#7D889B" }}>
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
            />
            {/* {customHeaderComponent && customHeaderComponent} */}
          </div>

          {customHeaderComponent && customHeaderComponent}

          {isOtherMenuEnable && (
            <div>
              <TableSearchBar
                setPageSize={setPageSize}
                headerProps={headerProps}
                setGlobalFilter={setGlobalFilter}
                isRowsPerPageChangeRequired={isRowsPerPageChangeRequired}
                ref={ref}
                // access={access}
              />
            </div>
          )}
        </div>
      </div>
      <table className="react-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} className="tb-header" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="table-header-text">
                    {column.render("Header")}
                    {column.id !== "selection" &&
                      column.id !== "selection1" &&
                      column.id !== "selection2" &&
                      column.id !== "expander" && (
                        <span className="table-sort-wrapper">
                          <MdKeyboardArrowUp
                            className={
                              column.isSorted && (column?.isSortedDesc ? "sort-active" : "")
                            }
                          />
                          <MdKeyboardArrowDown
                            className={
                              column.isSorted && (!column.isSortedDesc ? "sort-active" : "")
                            }
                          />
                        </span>
                      )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps(getRowProps(row));
            return (
              <tr
                className={`
              ${row.original.node_type === "child" && row?.id?.includes(".") ? "animate" : ""}`}
                {...rowProps}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="table-footer">
        <div className="table-footer-result-text">
          {data.length && isCurrentRowsCountVisibilityRequired ? (
            <span>
              Showing {pageIndex * pageSize + 1} to{" "}
              {pageSize * (pageIndex + 1) > data.length ? data.length : pageSize * (pageIndex + 1)}{" "}
              of {data.length} rows
            </span>
          ) : null}
        </div>
        <div className="table-footer-result-row">
          <CustomPagination
            currentPage={currentPage}
            totalCount={data.length}
            pageSize={pageSize}
            onPageChange={(page) => {
              gotoPage(page - 1);
              setCurrentPage(page);
            }}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </div>
      </div>
    </>
  );
};

export default React.forwardRef(TableJs);

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = React.useRef();
//     const resolvedRef = ref || defaultRef;

//     React.useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate;
//     }, [resolvedRef, indeterminate]);

//     return (
//       <>
//         <input type="checkbox" ref={resolvedRef} {...rest} />
//       </>
//     );
//   }
// );
