import { useState } from "react";
import { TextInput, DateInput } from "../../../../../components/FormFields";
import CollapseMode from "./collapse";
interface props {
  data: any;
  values: any;
  errors: any;
  hasFeildReadonly: Function;
  handleField: Function;
}

const Type3Form = (p: props) => {
  const [isExpandAll, setIsExpandAll] = useState(true);
  return (
    <>
      <div className="new-agreement-form-container">
        <span className="enterFieldText">Enter the fields to see the inputs on the left</span>
        <span className="collapseAllText" onClick={() => setIsExpandAll(!isExpandAll)}>
          {isExpandAll ? "Collapse All" : "Expand All"}
        </span>
        <CollapseMode
          title="Name of Contract"
          forceOpen={isExpandAll}
          children={
            <TextInput
              name={"contract_name"}
              type="text"
              label="File Name"
              placeholder={"File Name"}
              // className={"agreement-text-field"}
              id={"fileName"}
              readOnly={p.hasFeildReadonly({
                status: p?.data?.agreementStatus,
                forAll: true,
              })}
            />
          }
        />
        <CollapseMode
          title="General"
          forceOpen={isExpandAll}
          children={
            <>
              <DateInput
                name={"agreementDate"}
                label="Effective Date"
                placeholder={"Effective Date"}
                // className={"agreement-text-field"}
                className="my-1"
                maxDate={p.values.expiryDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"expiryDate"}
                type="date"
                label="Expiry Date"
                placeholder={"Expiry Date"}
                // className={"agreement-text-field"}
                className="my-1"
                minDate={p.values.agreementDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                showRiskWarning={true}
              />

              <TextInput
                name={"firstPartyName"}
                type="text"
                label="First Party Name"
                placeholder={"First Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyAddress"}
                type="text"
                label="First Party Address"
                placeholder={"First Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyName"}
                type="text"
                label="Second Party Name"
                placeholder={"Second Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyAddress"}
                type="text"
                label="Second Party Address"
                placeholder={"Second Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstyPartyPan"}
                type="text"
                label="First Party Pan"
                placeholder={"First Party Pan"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyPan"}
                type="text"
                label="Second Party Pan"
                placeholder={"Second Party Pan"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"purpose"}
                type="text"
                label="Purpose"
                placeholder={"Purpose"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"businessDetails"}
                type="text"
                label="Business Details"
                placeholder={"Business Details"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"transferredBusiness"}
                type="text"
                label="Transferred Business"
                placeholder={"Transferred Business"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"includeDetails"}
                type="text"
                label="Include Details"
                placeholder={"Include Details"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"purchaseConsideration"}
                type="text"
                label="Purchase Consideration"
                placeholder={"Purchase Consideration"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyAttn"}
                type="text"
                label="First Party Atnn"
                placeholder={"First Party Attn"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyEmail"}
                type="text"
                label="First Party Email"
                placeholder={"First Party Email"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyAttn"}
                type="text"
                label="Second Party Atnn"
                placeholder={"Second Party Attn"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyEmail"}
                type="text"
                label="Second Party Email"
                placeholder={"Second Party Email"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyRepresentative"}
                type="text"
                label="First Party Representative"
                placeholder={"First Party Representative"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyRepresentative"}
                type="text"
                label="Second Party Representative"
                placeholder={"Second Party Representative"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyBoardResolution"}
                type="text"
                label="First Paryt Board Resolution"
                placeholder={"First Party BoardResolution"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              {/* <TextInput
                  name={"secondPartyBoardResolution"}
                  type="text"
                  label="Second Paryt Board Resolution"
                  placeholder={"Second Paryt Board Resolution"}
                  className={"agreement-text-field"}
                  onActionBlur={p.handleField}
                  readOnly={p.hasFeildReadonly({
                    status: p?.data?.agreementStatus,
                    forAll: false,
                  })}
                /> */}
              {/* </div> */}
            </>
          }
        />
      </div>
    </>
  );
};

export default Type3Form;
