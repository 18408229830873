import React from 'react'

// ASSETS
import { FaCheck } from "react-icons/fa";


const AvatarCard = ({ userId, userImage, userName, userEmail, isSelected, onClick }) => {

    return <div className="flex flex-col justify-center items-center max-w-[97px] max-h-[80px]">
        {
            isSelected
                ?
                <div onClick={() => onClick({ userId, userEmail }, "remove")} className="select-none relative cursor-pointer w-10 h-10 rounded-full flex justify-center items-center">
                    <img className="absolute opacity-30 cursor-pointer w-10 h-10 rounded-full" src={userImage} alt="avatar" />
                    <FaCheck color='#560BAD' className='absolute' />
                </div>
                :
                <img onClick={() => onClick({ userId, userEmail }, "add")} className="select-none cursor-pointer w-10 h-10 rounded-full" src={userImage} alt="avatar" />
        }
        <p className="select-none text-xs mt-2 truncate">{userName.length > 9 ? userName.slice(0, 9) + "..." : userName}</p>
    </div>
}

export default AvatarCard