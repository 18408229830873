import {
  API_ROUTES,
  postRequest,
  getRequest,
  showToast,
  STATUS_CODE,
  ROUTE_CONSTANTS,
  clearFromLocalStoarge,
} from "../../helpers";
import {
  GET_USER_DATA,
  DISABLE_USER_FORM,
  LOGOUT_USER,
  SET_USER_TOKEN,
  setUserToken,
} from "../actions";
import { store } from "../store";

export const userFormServices = async (formData: any, closeModal: any) => {
  try {
    const res = await postRequest(API_ROUTES.CREATE_NEW_USER, true, formData);
    const { data, status }: any = res || {};
    if (status !== STATUS_CODE.SUCCESS) {
      showToast(data?.response, false);
    } else {
      await forceLogout({ status: data?.status_code, message: data?.message });

      //todo: user already exsit should come in catch method.
      if (data?.status == 400) {
        showToast(data?.message, false);
      } else {
        store.dispatch({
          type: DISABLE_USER_FORM,
          isUserCreated: true,
        });
        store.dispatch(getUserServices(""));
        // @todo: need complete data in response
        showToast("User created successfully" || data?.response, true);
        closeModal();
      }
    }
  } catch (error) {
    console.error("Error in Admin form  Services", error);
    return error;
  }
};

export const userUpdateFormServices = async (id: number, formData: any, closeModal: any) => {
  try {
    const res = await postRequest(API_ROUTES.UPDATE_USER, true, formData);
    const { data, status }: any = res || {};
    if (status !== STATUS_CODE.SUCCESS) {
      showToast(data?.response, false);
    } else {
      // @todo: need complete data in response
      await forceLogout({ status: data?.status_code, message: data?.message });

      store.dispatch({
        type: DISABLE_USER_FORM,
        isUserCreated: true,
      });
      store.dispatch(getUserServices(""));
      showToast("User updated successfully" || data?.response, true);
      closeModal();
    }
  } catch (error) {
    console.error("Error in update form   Services", error);
    return error;
  }
};

export const getUserServices = (setLoading: any) => async (dispatch: any) => {
  try {
    const api = API_ROUTES.GET_USER_DATA;
    const res: any = await getRequest(api, true);
    await forceLogout({ status: res?.data?.status_code, message: res?.data?.message });

    dispatch({
      type: GET_USER_DATA,
      data: res?.data || [],
    });
    setLoading(false);
  } catch (err) {
    console.error(err);
  }
};

export const deleteUserServices = async (username: any) => {
  try {
    const res = await postRequest(API_ROUTES.DELETE_USER, true, { username: username });
    const { data, status }: any = res || {};
    if (status !== STATUS_CODE.SUCCESS) {
      showToast(data?.response, false);
    } else {
      await forceLogout({ status: data?.status_code, message: data?.message });

      // @todo: need complete data in response
      store.dispatch(getUserServices(""));
      showToast("User deleted successfully" || data?.response, true);
    }
  } catch (error) {
    console.error("Error in update form   Services", error);
    return error;
  }
};

export const forceLogout = async (p: { status: number; message: string }) => {
  try {
    if (p.status === STATUS_CODE.UNAUTHORIZED) {
      console.log("forceLogout called");

      // console.log(p);
      // {
      //   "status": 401,
      //   "message": "Unauthorized Access Expired Token."
      // }
      /*comment by hp
      const {
        common: { userData, userTokens },
      } = store.getState();

      const { data }: any = await postRequest(API_ROUTES.REFRESH_TOKEN, false, {
        username: userData.username,
        refreshToken: userTokens.RefreshToken,
      });

      store.dispatch(setUserToken({ ...userTokens, ...data.AuthenticationResult }));

      */
      // clearFromLocalStoarge();
      // store.dispatch({
      //   type: LOGOUT_USER,
      // });
      // showToast(p.message, false);
      // setTimeout(() => {
      //   window.location.replace(ROUTE_CONSTANTS.LOGIN);
      // }, 200);
    }

    return;
  } catch (err) {
    console.error(err);
  }
};
