import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextInput } from "components";
import {
  API_ROUTES,
  INPUT_FIELD_NAMES,
  RESPONSE_MSGS,
  ROUTE_CONSTANTS,
  STATUS_CODE,
  STRINGS,
  VALIDATION_MSGS,
  postRequest,
  showLogInConsole,
  showToast,
} from "helpers";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import * as Yup from "yup";

//
import lock from "assets/images/lock.png";
import reset from "assets/images/reset.png";
import logo from "assets/images/logo_icon.png";
import { Form, Formik } from "formik";

const ForgotPassword: React.FC = () => {
  const [processing, setProcessing] = useState<boolean>(false);
  const navigate = useNavigate();

  //
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(VALIDATION_MSGS.INVALID_EMAIL).required(VALIDATION_MSGS.REQUIRED),
  });
  //
  const onFinish = async (values: { email: string }, actions: any) => {
    try {
      setProcessing(true);
      const { data }: any = await postRequest(API_ROUTES.FORGOT_PASSWORD, false, values);

      const { ResponseMetadata, message } = data || {};
      const { HTTPStatusCode } = ResponseMetadata || {};
      if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
        showToast(message, false);
      } else {
        showToast(RESPONSE_MSGS.EMAIL_SENT, true);
        actions.resetForm({ email: "" });
        navigate(ROUTE_CONSTANTS.RESET_PASSWORD, {
          state: {
            email: values.email,
          },
        });
      }
      setProcessing(false);
    } catch (err) {
      console.log(err);
      showToast(RESPONSE_MSGS.ERROR, false);
      setProcessing(false);
    }
  };

  return (
    <div className="pass-auth-wrapper">
      <div className="auth-container">
        <div className="authentication_page">
          <div className="auth-bg-wrapper">
            <div className="auth-bg-section forgot-bg">
              <img src={logo} alt="logo" className="logo" />
              <div className="logo-text">
                <h2 className="logo-heading">Welcome to One Counsel</h2>
                <span className="logo-sub-heading">
                  360-degree insights in to legal operations & business transactions
                </span>
              </div>
            </div>
          </div>

          <div className="auth-form-section">
            <div className="auth-breadcrumbs ">
              <div className="active-breadcrumb flex items-center">
                <img src={lock} alt="lock" />
                {STRINGS.FORGOT_PASSWORD}
              </div>
              <NavigateNextIcon fontSize="small" color="inherit" className="icon-arrow" />
              <div className="inactive-breadcrumb flex items-center">
                <img src={reset} alt="reset" />
                {STRINGS.CREATE_PASSWORD}
              </div>
            </div>
            <h3 className="auth-title">{STRINGS.FORGOT_PASSWORD}</h3>
            <p className="pg-content">{STRINGS.FORGOT_PWD_SUBTITLE}</p>
            <div className="auth-form">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={ForgotPasswordSchema}
                // eslint-disable-next-line class-methods-use-this, no-unused-vars
                onSubmit={(values, actions) => {
                  onFinish(values, actions);
                }}
              >
                {() => (
                  <Form className="forgot-pwd-form-container">
                    <TextInput
                      color="primary"
                      name={INPUT_FIELD_NAMES.EMAIL}
                      type="email"
                      label={STRINGS.EMAIL_ADDRESS}
                      placeholder={STRINGS.EMAIL_ADDRESS}
                    />
                    <Button
                      label={STRINGS.SUBMIT}
                      color="primary"
                      className="!mt-4"
                      variant="contained"
                      type="submit"
                      disabled={processing}
                    />
                  </Form>
                )}
              </Formik>
            </div>

            <div className="login-form-footer">
              <div className="auth-signup-link">
                <span>Don’t remeber email?</span>
                <Link to={ROUTE_CONSTANTS.LOGIN}>{STRINGS.CONTACT_US}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
