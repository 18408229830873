import React from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

import "./style.scss";

interface otherProps {
  className?: string;
  variant?: "contained" | "outlined";
  type?: "button" | "submit";
  disabled?: boolean;
  style?: object;
  onClick?: Function;
  color?: "primary" | "secondary";
  label: string;

  options: Array<string>;
  groupName: string;
  onChange?: Function;
  value?: string;
  // readOnly?: boolean;
  // onAction?: Function;
  // onActionBlur?: Function;
  // highlightText?: string;
  // showRiskWarning?: boolean;
  // id?: string;
  // isRequired?: boolean;
}

const RadioGroupComponent = (p: otherProps) => {
  const customClasses = () => {
    let classList = "";

    if (p.variant === "contained" || p.variant === undefined) {
      switch (p.color) {
        case "primary":
        case undefined:
          classList += ` !bg-primary-500 !text-white disabled:opacity-70`;
          break;
        case "secondary":
          classList += ` !bg-secondary-500 !text-white disabled:opacity-70`;
          break;
      }
    } else if (p.variant === "outlined") {
      switch (p.color) {
        case "primary":
        case undefined:
          classList += ` !border !border-solid !border-primary-400 !text-primary-500 disabled:opacity-70`;
          break;
        case "secondary":
          classList += ` `;
          break;
      }
    }

    return classList;
  };

  return (
    <>
      <FormControl className="w-full h-full" size="small">
        <FormLabel id="demo-radio-buttons-group-label">{p?.label}</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name={p.groupName}
          onChange={(e) => {
            if (p?.onChange) {
              p?.onChange(e);
            }
          }}
          value={p?.value}
        >
          {p.options.map((item) => {
            return (
              <FormControlLabel
                value={item}
                control={<Radio size="small" className="!mr-2" />}
                label={item}
                className="py-2 "
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default RadioGroupComponent;
