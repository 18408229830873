import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
import moment from "moment";
import * as docxLib from "docx-preview";

import FileUpload from "../fileUpload";
import { ImportAgreementListItem, Button, TextInput, DateInput, SelectInput } from "components";
import SearchableParentAgreements, {
  ParentAgreement,
} from "../../components/SearchableParentAgreements";
//

//
import {
  AGREEMENT_UPLOAD_STATUS,
  AgreementFileExtentions,
  API_ROUTES,
  clearFromLocalStoarge,
  getRequest,
  IMPORT_TYPES,
  postRequest,
  RESPONSE_MSGS,
  ROUTE_CONSTANTS,
  showToast,
  STATUS_CODE,
  STRINGS,
} from "../../helpers";
import { previewFile, userLogout } from "../../redux/actions";

//
import "./styles.scss";

interface otherProps {
  closePopup: () => {};
  refetchDataAfterImport?: Function;
  importType: string;
  onPreview?: Function;
  showParentAgreementSelection?: boolean;
  cautionMessage?: string;
}

const UploadAgreementForm = (p: otherProps) => {
  const { closePopup, refetchDataAfterImport } = p;

  //
  const [processing, setProcessing] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [failedImportCount, setCountForFailedImport] = useState<number>(0);
  const [agreementToImport, setAgreementFilesToImport] = useState<any>([]);
  const [processedAgreement, setProcessedAgreement] = useState<Array<number>>([]);
  const [redirectToRepository, setRedirectToRepository] = useState<boolean>(false);
  const [parentObject, setParentObject] = useState<Partial<ParentAgreement>>({});

  //
  const [isFormShown, setIsFormShown] = useState<boolean>(false);
  const [allFormFieldsData, setAllFormFieldsData] = useState([
    {
      key: "nameOfAgreement",
      label: "Name Of Agreement*",
      type: "text",
      value: "",
      isRequired: true,
    },
    // { key: "effectiveDate", label: "Effective Date", type: "date", value: "", isRequired: false },
    // { key: "expiryDate", label: "Expiry Date", type: "date", value: "", isRequired: false },
    // { key: "nameOfPartyA", label: "Name Of Party A", type: "text", value: "", isRequired: false },
    // { key: "nameOfPartyB", label: "Name Of Party B", type: "text", value: "", isRequired: false },
    {
      key: "agreement_type",
      label: "Agreement Type",
      type: "selectOption",
      value: "",
      list: [],
      // list: [
      //   {
      //     key: "Andhra Pradesh",
      //     val: "Hyderabad",
      //   },
      //   {
      //     key: "Arunachal Pradesh",
      //     val: "Itanagar",
      //   },
      //   {
      //     key: "Assam",
      //     val: "Dispur",
      //   },
      //   {
      //     key: "Bihar",
      //     val: "Patna",
      //   },
      //   {
      //     key: "Chhattisgarh",
      //     val: "Raipur",
      //   },
      //   {
      //     key: "Goa",
      //     val: "Panaji",
      //   },

      // ],
      isRequired: true,
    },
    // { key: "value", label: "Value", type: "text", value: "", isRequired: false },
    // { key: "parentId", label: "Parent Id", type: "text" ,value:""},
    // { key: "parentName", label: "Parent Name", type: "text" ,value:""},
    // { key: "parentType", label: "Parent Type", type: "text" ,value:""},
    // { key: "clientId", label: "Client Id", type: "text" ,value:""},
  ]);
  const [formikInitialValue, setFormikInitialValue] = useState(() => {
    const tempObject = {};
    allFormFieldsData.map((item) => {
      tempObject[item.key] = "";
    });

    return tempObject;
  });

  //
  const userTokens = useSelector((state: any) => state.common.userTokens);
  const userData = useSelector((state: any) => state.common.userData);
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async () => {
    const uploadedFile = agreementToImport[0];
    const fileType = uploadedFile.type;

    const tempDocxReader: any = document.getElementById("temp-docx-reader");

    if (
      fileType === "application/msword" ||
      fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      try {
        await docxLib.renderAsync(uploadedFile, tempDocxReader);

        tempDocxReader.innerHTML = "";

        setFormikInitialValue({
          ...formikInitialValue,
          nameOfAgreement: uploadedFile.name.slice(0, uploadedFile.name.lastIndexOf(".")),
        });
        setIsFormShown(true);
      } catch (err) {
        showToast("This document file is corrupted please try other", false);
      }
    } else if (fileType === "application/pdf") {
      let fileReader = new FileReader();
      fileReader.readAsText(uploadedFile);
      fileReader.onload = function () {
        const { result }: any = fileReader;
        const isValidPdf = result?.lastIndexOf("%PDF-") === 0 && result.lastIndexOf("%%EOF") > -1;

        if (isValidPdf) {
          setFormikInitialValue({
            ...formikInitialValue,
            nameOfAgreement: uploadedFile.name.slice(0, uploadedFile.name.lastIndexOf(".")),
          });
          setIsFormShown(true);
        } else {
          showToast("File is corrupted please try again", false);
        }
      };
      fileReader.onerror = function () {
        showToast("File is corrupted please try again", false);
      };
    } else {
      showToast("Please upload Pdf or Docx only", false);
    }

    // start
    // setFormikInitialValue({
    //   ...formikInitialValue,
    //   nameOfAgreement: agreementToImport[0].name.slice(
    //     0,
    //     agreementToImport[0].name.lastIndexOf("."),
    //   ),
    // });
    // setIsFormShown(true);

    // end
    // setProcessing(AGREEMENT_UPLOAD_STATUS.IMPORTING);
    // if (p.onPreview) {
    // const formData = new FormData();
    // formData.append("files", agreementToImport[0]);
    // postRequest("https://wordapi-dev.app.onecounsel.in/api/documenteditor/Import", true, formData)
    //   .then((res: any) => {
    //     const { data } = res || {};
    //     const { ResponseMetadata } = data || {};
    //     const { HTTPStatusCode } = ResponseMetadata || {};
    //     if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
    //       dispatch(previewFile(data));
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
    // sessionStorage.setItem("previewFile", URL.createObjectURL(agreementToImport[0]));
    // const fileName = agreementToImport[0].name;
    // sessionStorage.setItem("tempFileName", fileName);
    // p.onPreview();
    // } else {
    //   setProcessing(AGREEMENT_UPLOAD_STATUS.IMPORTING);
    // }
  };

  const handleUploadDocumentWithData = async (currentValues) => {
    try {
      setIsFormShown(false);
      setProcessing(AGREEMENT_UPLOAD_STATUS.IMPORTING);

      const values = {
        ...currentValues,
        fileExtension: agreementToImport[0].name.slice(agreementToImport[0].name.lastIndexOf(".")),
      };

      const formData = new FormData();

      formData.append("files", agreementToImport[0], agreementToImport[0].name);

      for (const key in values) {
        formData.append(key, values[key]);
      }
      for (const key in parentObject) {
        formData.append(key, parentObject[key]);
      }
      formData.append("clientId", userData.client_id);
      formData.append("user_id", userData.user_id);
      formData.append("owner_email", userData.username);

      const { data } = await postRequest(API_ROUTES.IMPORT_NEW_AGREEMENT, true, formData);

      showToast("Agreement Imported Successfully", true);

      if (refetchDataAfterImport) {
        await refetchDataAfterImport();
        await refetchDataAfterImport();
        await refetchDataAfterImport();
      }

      closePopup();
    } catch (err: any) {
      console.log(err);
      showToast(err.message, false);
      setIsFormShown(true);
    }
  };

  const setEachAgreementProcessingStatus = (index: number, value: number) => {
    const processedAgreementArr = processedAgreement;
    processedAgreementArr[index] = value;
    setProcessedAgreement(processedAgreementArr);
    let successCount = 0,
      failureCount = 0;
    for (let i = 0; i < processedAgreementArr.length; i++) {
      if (processedAgreementArr[i] === 0) {
        failureCount++;
      } else if (processedAgreementArr[i] === 1) {
        successCount++;
      }
    }
    setRedirectToRepository(true);
    if (
      successCount + failureCount === agreementToImport.length &&
      (successCount !== 0 || failureCount !== 0)
    ) {
      if (!failureCount) {
        setProcessing(AGREEMENT_UPLOAD_STATUS.DONE);
        showToast(RESPONSE_MSGS.AGREEMENT_UPLOAD_SUCCESS, true);
      } else {
        setCountForFailedImport(failureCount);
        setProcessing(AGREEMENT_UPLOAD_STATUS.PARTIAL_DONE);
        showToast(RESPONSE_MSGS.AGREEMENT_UPLOAD_FAILED, false);
      }
    }
  };

  const reUploadAgreement = (index: number) => {
    setProcessing(AGREEMENT_UPLOAD_STATUS.IMPORTING);
    processedAgreement[index] = 2;
    setProcessedAgreement(processedAgreement);
  };

  const renderFileList = () => {
    return agreementToImport.map((file: any, index: number) => (
      <ImportAgreementListItem
        parentObject={parentObject}
        file={file}
        index={index}
        setEachAgreementProcessingStatus={setEachAgreementProcessingStatus}
        reUploadAgreement={reUploadAgreement}
        key={`File-To-Import-${index}`}
        importType={p.importType}
      />
    ));
  };

  const handleOnClose = () => {
    closePopup();
    if (redirectToRepository) {
      navigate(ROUTE_CONSTANTS.Repository);
      refetchDataAfterImport && refetchDataAfterImport();
    }
  };

  const fetchOrgConfigData = async () => {
    try {

      const resData: any = await getRequest(API_ROUTES.FETCH_IMPORT_AGREEMENT_TYPES, true)
      const aggrementTypes = resData?.data?.response?.map((item: any) => {
        return {
          key: item?._id,
          value: item?._id,
        }
      });

      const finalData = [...allFormFieldsData].map((item) => {
        if (item.key === "agreement_type") {
          item["list"] = aggrementTypes
        }
        return item;
      });

      setAllFormFieldsData(finalData);

    } catch (err: any) {
      showToast(err.message, false);
      console.log(err);
    }
  };

  useEffect(() => {
    let subTitle: string;
    subTitle = `Importing ${agreementToImport.length} ${p.importType === IMPORT_TYPES.LICENSE ? "license..." : "agreement..."
      }`;
    if (agreementToImport.length > 1) {
      subTitle = `Bulk importing ${agreementToImport.length} ${p.importType === IMPORT_TYPES.LICENSE ? "licenses..." : "agreements..."
        }`;
    }
    setSubTitle(subTitle);

    //
    fetchOrgConfigData();
  }, [agreementToImport.length]);

  return (
    <div
      className={`custom-upload-agreement-container ${p.showParentAgreementSelection ? "parent-selector" : ""
        }`}
    >
      <button
        className="upload-cross-wrapper"
        title="Close"
        onClick={() => handleOnClose()}
        disabled={processing === AGREEMENT_UPLOAD_STATUS.IMPORTING}
      >
        <AiOutlineClose className="close" />
      </button>

      <h3
        className={`agreement-title ${processing === AGREEMENT_UPLOAD_STATUS.IMPORTING ? "upload-processing-title" : ""
          }`}
      >
        {p.importType === IMPORT_TYPES.LICENSE
          ? `${!processing ? "Import an License" : "Importing License"}`
          : p.importType === IMPORT_TYPES.AGREEMENT
            ? `${!processing ? "Import Agreement" : "Importing Agreement"}`
            : `${!processing ? "Agreement Preview" : "Previewing Agreement"}`}
      </h3>

      {isFormShown ? (
        <div className="w-full mt-10">
          <Formik
            initialValues={formikInitialValue}
            // validationSchema={getByotSchema(Yup)}
            // enableReinitialize={true}
            enableReinitialize={false}
          // onSubmit={(values, action) => {
          //   // onFinish(values, "", action);

          //   handleSaveByotData(values);
          // }}
          >
            {({ values, handleSubmit, setValues, errors, validateForm, dirty }) => {
              //

              return (
                <FormikForm
                  className="mt-3 px-5"
                  onSubmit={(e) => {
                    e.preventDefault();

                    for (let key in values) {
                      if (values[key].indexOf("<script>") !== -1) {
                        showToast("Please insert valid string", false);
                        return;
                      }
                    }

                    handleUploadDocumentWithData(values);
                  }}
                >
                  <div className="grid grid-cols-2 gap-x-3">
                    <div>
                      <SearchableParentAgreements setParent={setParentObject} />
                    </div>
                    {allFormFieldsData.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          {item.type == "text" ? (
                            <TextInput
                              name={item.key}
                              type="text"
                              label={item.label}
                              placeholder={`Enter ${item.label}`}
                              // className={"customInputs"}
                              isRequired={item.isRequired}
                              // isLabelShown={false}
                              // isCustomInput={true}
                              onActionBlur={(data) => {
                                // console.log(data);
                              }}
                              required
                            />
                          ) : item.type == "date" ? (
                            <DateInput
                              name={item.key}
                              label={item.label}
                              // id="agreementDate"
                              placeholder={`Enter ${item.label}`}
                              // className={"customInputs w-full"}
                              onActionBlur={(data) => {
                                // console.log(data);
                              }}
                              // isLabelShown={false}
                              // isCustomInput={true}
                              // maxDate={p.values.agreementDate}
                              // readOnly={p.hasFeildReadonly({
                              //   status: p?.data?.agreementStatus,
                              //   forAll: false,
                              // })}
                              key={index}
                              required={item.isRequired}
                            />
                          ) : item.type == "selectOption" ? (
                            <SelectInput
                              options={item.list.map((item: any) => {
                                return {
                                  value: item.key,
                                  label: item.key,
                                };
                              })}
                              label={item.label}
                              name={item.key}
                              required={item.isRequired}
                              onCustomChange={(e, innerItem: any) => {
                                setValues({ ...values, [item.key]: innerItem.value });
                                // setSelectedValueOfSelectedType(item);
                              }}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                  </div>

                  {/*  */}
                  <div className="flex justify-between">
                    {/* <Button onClick={handleShow} className="customNextBtn">
                Add New Field
              </Button> */}

                    <div></div>

                    <div className="flex gap-3 mt-2">
                      <Button
                        type="button"
                        label="Back"
                        variant="outlined"
                        className="!w-fit !mx-0.5"
                        onClick={() => {
                          // closePopup();
                          setIsFormShown(false);
                        }}
                      />

                      <Button
                        type="submit"
                        label="Save"
                        variant="contained"
                        className="!w-fit !mx-0.5"
                      />
                    </div>
                  </div>
                </FormikForm>
              );
            }}
          </Formik>
        </div>
      ) : (
        <>
          {/* {p.showParentAgreementSelection ? (
            <div className="grid grid-cols-2 gap-3 w-full mx-10 max-w-[90%] mt-5 mb-3">
              <div className="">
                
              </div>
              <div className="">
                
              </div>
            </div>
          ) : null} */}

          {!processing ? (
            <>
              <p className="agreement-upload-content-form my-3">
                {p.importType === IMPORT_TYPES.LICENSE ? (
                  ""
                ) : (
                  <>
                    {/* <span className="agreement-upload-content-text">
                      Please drag and drop your existing agreements and let OneCounsel do its magic.
                    </span> */}
                    <div className="dont-forget-to-validate mt-20">
                      {/* Once uploaded, dont forget to validate */}
                      Drag and Drop you Agreement & Validate
                    </div>
                  </>
                )}
                {p.cautionMessage && <span className="cautionMessage">{p.cautionMessage}</span>}
              </p>
              <FileUpload
                agreementToImport={agreementToImport}
                setAgreementFilesToImport={setAgreementFilesToImport}
                importType={AgreementFileExtentions.ACCEPT_LIST}
              />
              <div
                className="upload-agreement-submit"
                {...(p.cautionMessage && {
                  style: { marginTop: "1rem" },
                })}
              >
                <Button
                  type="submit"
                  label={STRINGS.UPLOAD}
                  variant="contained"
                  className="w-fit !mx-0.5"
                  onClick={onFinish}
                  disabled={!!processing || !agreementToImport.length}
                />
              </div>
            </>
          ) : (
            <div className="processing-view-content">
              <div className="processing-title">
                <div className="processing-loader">
                  {processing === AGREEMENT_UPLOAD_STATUS.DONE ? (
                    <FaCheck className="agreements-processed" />
                  ) : processing === AGREEMENT_UPLOAD_STATUS.PARTIAL_DONE ? (
                    <FaExclamationCircle className="agreements-failed" />
                  ) : (
                    <CircularProgress className="agreements-processing" />
                  )}
                </div>
                <div className="processing-file-message">
                  <p className="sub-title1">{subTitle}</p>
                  <p className="sub-title2">
                    {processing === AGREEMENT_UPLOAD_STATUS.DONE
                      ? "Done!"
                      : processing === AGREEMENT_UPLOAD_STATUS.PARTIAL_DONE
                        ? `${failedImportCount} ${p.importType === IMPORT_TYPES.LICENSE ? "licenses" : "agreements"
                        } import failed!`
                        : "Please wait!"}
                  </p>
                </div>
              </div>

              <div className="processing-file-list">{renderFileList()}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UploadAgreementForm;
