import { useNavigate } from "react-router-dom";

import { ROUTE_CONSTANTS } from "../../../../helpers";
import styled from "styled-components";
import HeaderLogo from "../../../../assets/images/Logo1.png";

const Header = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <div className="nav-container">
          <div>
            <img src={HeaderLogo} width={170} alt="HeaderLogo" />
            <i className="fa fa-code"></i>
          </div>

          <ul className={"nav-menu"}>
            <li className="nav-item">Home</li>
            <li className="nav-item">About</li>
            <li className="nav-item">Blog</li>
            <li className="nav-item">Contact Us</li>
          </ul>
          <button onClick={() => navigate(ROUTE_CONSTANTS.LOGIN)} className="cursor-pointer">
            Get Started
          </button>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Header;

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 30px 40px;
  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
    button {
      font-family: "matter_sq_trialsemibold";
      height: 53px;
      font-size: 17px;
      font-weight: 600;
      border-radius: 5px;
      padding: 10px 46px;
      color: #4631ee;
      border: 2px solid #4631ee;
      background-color: transparent;
    }
  }
  .nav-menu {
    list-style: none;
    display: flex;
    gap: 64px;

    li {
      font-family: "matter_sq_trialmedium";
      font-size: 17px;
      color: #495057;
    }
  }
`;
