import React, { useRef, useState, useEffect } from 'react';
import { Box, CircularProgress, Tab, Tabs, makeStyles } from '@material-ui/core';
import { InputAdornment, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

// Redux
import { fetchUserData } from 'redux/actions';

// HELPER
import { VALIDATION_MSGS, API_ROUTES, postRequest, getRequest, showToast, ROUTE_CONSTANTS } from 'helpers';

// ASSETS
import { FaUserEdit, FaUser, FaUserAlt, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { IoAlertCircleSharp } from "react-icons/io5";



// COMPONENTS
import DashboardLayout from 'layouts/dashboardLayout';
import { HRLine } from 'customComponents';
import { Button } from 'components';
import { useNavigate } from 'react-router';


const useStyles = makeStyles({
    tabs: {
        "& .MuiTab-wrapper": {
            textTransform: "capitalize"
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#560BAD",
            height: 2,
            textTransform: "capitalize"
        },
        "& .MuiTab-root.Mui-selected": {
            color: '#560BAD',
            textTransform: "capitalize"
        }
    }
})

const index = () => {
    // STATES
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const tabContents = [<ProfileTab />, <PasswordTab />]


    // FUNCTIONS
    const handleChange = (_: any, newValue: number) => {
        setValue(newValue);
    };


    return (
        <DashboardLayout
            hederTitle={"Profile"}
            headerLogo={<FaUserEdit />}
            childer={
                <div className='w-full h-full flex flex-col justify-center items-center mt-10'>
                    <div className='w-[90vw] h-full p-1'>
                        <Box sx={{ bgcolor: 'background.paper', padding: "1%" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                className={`${classes.tabs}`}
                            >
                                <Tab label="Profile" />
                                <Tab label="Password" />
                            </Tabs>
                            <div className='w-full h-full overflow-auto'>
                                <div className='flex justify-center items-center  w-full h-full'>
                                    {tabContents[value]}
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
            }
        />
    )
}

export default index


const ProfileTab = () => {
    const dispatch = useDispatch();

    // SELECTORS
    const userData = useSelector((state: any) => state.common.userData);

    // STATES
    const [chooseImage, setChooseImage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const inputFileRef = useRef<any>();

    // VALIDATION SCHEMA
    const validationSchema = Yup.object().shape({
        // userName: Yup.string().required(VALIDATION_MSGS.REQUIRED),
        firstName: Yup.string().required(VALIDATION_MSGS.REQUIRED),
        lastName: Yup.string().required(VALIDATION_MSGS.REQUIRED),
        // email: Yup.string().email(VALIDATION_MSGS.INVALID_EMAIL).required(VALIDATION_MSGS.REQUIRED),
        phone: Yup.string().required(VALIDATION_MSGS.REQUIRED),
    });

    // FUNCTIONS
    const fetchUserProfileData = async () => {
        const response: any = await postRequest(`${API_ROUTES.GET_USER_PROFILE_DATA}`, true, { email: userData?.email });
        if (response?.data?.success !== null) {
            const { success } = response?.data;
            dispatch(fetchUserData(success));
        }
        // else {
        //     showToast("unable to fetch user profile data", false);
        // }
    }
    const chooseImageHandler = (e) => {
        const imageFile: any = e.target.files;

        if (imageFile?.[0]?.size > 500000) {
            showToast("Your file size it too big", false);
        } else {
            setChooseImage(imageFile[0]);
        }
    }
    const submitHandler = async (values, actions) => {
        try {
            setIsLoading(true);
            const formData = new FormData()
            formData.append("profileImage", chooseImage ? chooseImage : userData?.profile_img);
            formData.append("userName", userData?.username);
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("phone", values.phone);
            formData.append("userId", userData?.user_id);
            formData.append("clientId", userData?.client_id);
            formData.append("email", userData?.email);

            // UPDATE USER DATA API
            const profileDataResponse: any = await postRequest(API_ROUTES.UPDATE_USER_PROFILE, true, formData);

            if (profileDataResponse?.data?.success == "data should send succefully") {
                // FETCH USER DATA API
                const userDataResponse: any = await postRequest(`${API_ROUTES.GET_USER_PROFILE_DATA}`, true, { email: userData?.email });

                if (userDataResponse?.data?.success !== null) {
                    // const { success } = userDataResponse?.data;

                    const updatedData = {
                        ...userData,
                        profile_img: chooseImage ? URL.createObjectURL(chooseImage) : userData?.profile_img,
                        first_name: values.firstName,
                        last_name: values.lastName,
                        phone: values.phone,
                    };

                    dispatch(fetchUserData(updatedData));
                    showToast("Updated successfully", true);
                } else {
                    showToast("Something went wrong", false);
                }

            } else {
                showToast("Something went wrong", false);
            }
        } catch (error) {
            console.log("error ===>", error);
            showToast("Something went wrong", false);
        } finally {
            setIsLoading(false);
        }
    }

    // EFFECTS
    useEffect(() => {
        fetchUserProfileData();
    }, [])

    return <>
        <div className='w-[95%] h-full'>
            <div className='flex flex-col mt-10'>
                <p className='text-black text-2xl font-bold mb-2'>General</p>
                <p className='text-[#6B7280] text-sm'>Basic info, like your name and address that will displayed in public</p>
            </div>

            <Formik
                initialValues={{
                    // userName: userData?.username || "",
                    firstName: userData?.first_name || "",
                    lastName: userData?.last_name || "",
                    // email: userData?.email || "",
                    phone: userData?.phone || ""
                }}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
            >
                {({ setValues, values, errors, touched }) => {
                    return <Form>

                        {/* PROFILE IMAGE */}
                        <div className='border-4 flex justify-between items-center mt-1'>
                            <p className=' text-[#6B7280] text-sm font-bold w-[40%]'>Avatar</p>

                            <div className='flex flex-col w-[60%]'>
                                <input
                                    ref={inputFileRef}
                                    type='file'
                                    className='invisible'
                                    onChange={chooseImageHandler}
                                />
                                <img
                                    className="shadow-lg drop-shadow-md cursor-pointer inline-block h-[55px] w-[55px] rounded-full ring-2 ring-white"
                                    src={chooseImage ? URL.createObjectURL(chooseImage) : userData?.profile_img}
                                    alt=""
                                    onClick={() => inputFileRef?.current?.click()}
                                />
                                <p className='text-[10px] text-[#FF5733] mt-[10px] font-bold'>Max size: 500KB</p>
                                {/* {
                                    chooseImage ?
                                        <img
                                            className="shadow-lg drop-shadow-md cursor-pointer inline-block h-[55px] w-[55px] rounded-full ring-2 ring-white"
                                            src={URL.createObjectURL(chooseImage)}
                                            alt=""
                                            onClick={() => inputFileRef?.current?.click()}
                                        /> :
                                        <div
                                            onClick={() => inputFileRef?.current?.click()}
                                            className="shadow-lg flex justify-center items-center cursor-pointer inline-block h-[55px] w-[55px] rounded-full ring-2"
                                        >
                                            <FaUserAlt color='#d1d5db' size={38} style={{ opacity: "50%" }} />
                                        </div>

                                } */}
                            </div>

                        </div>
                        <HRLine className="mt-5" />

                        {/* Username */}
                        <div className='flex justify-between items-center mt-5'>
                            <p className='text-[#6B7280] text-sm font-bold w-[40%]'>Username</p>
                            <div className='flex flex-col w-[60%]'>
                                <TextField
                                    disabled={true}
                                    size="small"
                                    className="w-full"
                                    variant="outlined"
                                    // onChange={(e) => {
                                    //     setValues({ ...values, userName: e.target.value });
                                    // }}
                                    value={userData?.username || ""}
                                    placeholder="Enter Username"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" style={{ color: "#7D889B" }}>
                                                <FaUser size={18} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {/* {touched.userName && errors.userName && <span className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">{errors.userName}</span>} */}
                            </div>
                        </div>
                        <HRLine className="mt-5" />

                        {/* FIRST NAME */}
                        <div className='flex justify-between items-center mt-5'>
                            <p className='text-[#6B7280] text-sm font-bold w-[40%]'>First Name</p>
                            <div className='flex flex-col w-[60%]'>
                                <TextField
                                    size="small"
                                    className="w-full"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setValues({ ...values, firstName: e.target.value });
                                    }}
                                    value={values.firstName}
                                    placeholder="Enter First Name"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" style={{ color: "#7D889B" }}>
                                                <FaUser size={18} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {touched.firstName && errors.firstName && <span className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">{errors.firstName}</span>}
                            </div>
                        </div>
                        <HRLine className="mt-5" />

                        {/* LAST NAME */}
                        <div className='flex justify-between items-center mt-5'>
                            <p className='text-[#6B7280] text-sm font-bold w-[40%]'>Last Name</p>
                            <div className='flex flex-col w-[60%]'>
                                <TextField
                                    size="small"
                                    className="w-full"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setValues({ ...values, lastName: e.target.value });
                                    }}
                                    value={values.lastName}
                                    placeholder="Enter Last Name"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" style={{ color: "#7D889B" }}>
                                                <FaUser size={18} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {touched.lastName && errors.lastName && <span className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">{errors.lastName}</span>}
                            </div>
                        </div>
                        <HRLine className="mt-5" />

                        {/* EMAIL */}
                        <div className='flex justify-between items-center mt-5'>
                            <p className='text-[#6B7280] text-sm font-bold w-[40%]'>Email</p>
                            <div className='flex flex-col w-[60%]'>
                                <TextField
                                    size="small"
                                    className="w-full"
                                    variant="outlined"
                                    // onChange={(e) => {
                                    //     setValues({ ...values, email: e.target.value });
                                    // }}
                                    // value={values.email}
                                    value={userData?.email || ""}
                                    placeholder="Enter Email"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" style={{ color: "#7D889B" }}>
                                                <MdEmail size={18} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled={true}
                                />
                                {/* {touched.email && errors.email && <span className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">{errors.email}</span>} */}
                            </div>
                        </div>
                        <HRLine className="mt-5" />

                        {/* PHONE */}
                        <div className='flex justify-between items-center mt-5'>
                            <p className='text-[#6B7280] text-sm font-bold w-[40%]'>Phone</p>
                            <div className="flex flex-col w-[60%]">
                                <TextField
                                    size="small"
                                    className="w-full"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setValues({ ...values, phone: e.target.value });
                                    }}
                                    value={values.phone}
                                    placeholder="Enter Phone"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" style={{ color: "#7D889B" }}>
                                                <FaPhoneAlt size={18} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {touched.phone && errors.phone && <span className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">{errors.phone}</span>}
                            </div>
                        </div>

                        {/* SUBMIT BUTTON */}
                        <div className='flex justify-end items-center w-full mt-5'>
                            <Button
                                label={isLoading ? "Please Wait" : "Update Profile"}
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="!w-[10rem] !h-[2.7rem]"
                                disabled={isLoading ? true : false}
                            />
                        </div>
                    </Form>
                }}
            </Formik>
        </div >
    </>
}
const PasswordTab = () => {
    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required(VALIDATION_MSGS.REQUIRED),
        newPassword: Yup.string().required(VALIDATION_MSGS.REQUIRED).matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
        confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords does not match").required(VALIDATION_MSGS.REQUIRED),
    });
    const navigate = useNavigate();

    // SELECTORS
    const userData = useSelector((state: any) => state.common.userData);

    // STATES
    const [isLoading, setIsLoading] = useState(false);
    const [currentPwd, setCurrentPwd] = useState<any>(null);
    const [isPwdTying, setIsPwdTying] = useState(false);
    const [isCurrentPwdValid, setIsCurrentPwdValid] = useState(false);

    // FUNCTIONS
    const submitHandler = async (values, actions) => {
        try {
            setIsLoading(true);
            const res: any = await postRequest(API_ROUTES.UPDATE_USER_PASSWORD, true, {
                email: userData?.email,
                new_password: values?.confirmPassword
            });
            const { response } = res?.data;
            if (response) {
                showToast("Password changed successfully", true);
                setCurrentPwd(null);
                actions.resetForm();
                navigate(ROUTE_CONSTANTS.DASHBOARD);
            } else {
                showToast("Unable to change password!", false);
            }
        } catch (error) {
            console.log("error ===>", error);
        } finally {
            setIsLoading(false);
        }
    }
    const validateCurrentPwd = async () => {
        try {
            const res: any = await postRequest(API_ROUTES.VALIDATE_USER_PASSWORD, true, { email: userData?.email, old_password: currentPwd || "" });
            const { response } = res?.data;

            setIsPwdTying(false);
            if (response) {
                setIsCurrentPwdValid(true);
            } else {
                setIsCurrentPwdValid(false);
            }
        } catch (err) {
            console.log("error ==>", err);
            setIsPwdTying(false);
        }
    }

    // EFFETCS
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            if (currentPwd !== null) {
                validateCurrentPwd();
            }
        }, 600);
        return () => clearTimeout(delayInputTimeoutId);
    }, [currentPwd]);

    return <>
        <div className='w-[95%] h-full'>
            <div className='flex flex-col mt-10'>
                <p className='text-black text-2xl font-bold mb-2'>Password</p>
                <p className='text-[#6B7280] text-sm'>Enter your current & new password to reset your password</p>
            </div>

            <Formik
                initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                }}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
            >
                {({ setValues, values, errors, setFieldTouched, touched }) => {
                    return <Form>
                        <div className='flex justify-between items-center mt-10'>
                            <p className='text-[#6B7280] text-sm font-bold w-[40%]'>Current Password</p>
                            <div className='flex flex-col w-[60%]'>
                                <TextField
                                    size="small"
                                    className="w-full"
                                    variant="outlined"
                                    name="currentPassword"
                                    onChange={(e) => {
                                        setValues({ ...values, currentPassword: e.target.value });
                                        setCurrentPwd(e.target.value);
                                        setIsPwdTying(true);
                                    }}
                                    onBlur={() => {
                                        setFieldTouched("currentPassword");
                                    }}
                                    value={values.currentPassword}
                                    disabled={isCurrentPwdValid}
                                    placeholder="Enter Current Password"
                                    type='password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {currentPwd == null ? null : isPwdTying ? <CircularProgress thickness={5} size={20} style={{ color: "#560BAD !important" }} /> : isCurrentPwdValid ? <FaCheckCircle color='green' size={18} /> : <IoAlertCircleSharp color="red" size={20} />}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {touched.currentPassword && errors.currentPassword && <span className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">{errors.currentPassword}</span>}
                            </div>
                        </div>
                        <HRLine className="mt-5" />
                        <div className='flex justify-between items-center mt-5'>
                            <p className='text-[#6B7280] text-sm font-bold w-[40%]'>New Password</p>
                            <div className='flex flex-col w-[60%]'>
                                <TextField
                                    size="small"
                                    className="w-full"
                                    variant="outlined"
                                    name="newPassword"
                                    onChange={(e) => {
                                        setValues({ ...values, newPassword: e.target.value });
                                    }}
                                    onBlur={() => {
                                        setFieldTouched("newPassword");
                                    }}
                                    value={values.newPassword}
                                    placeholder="Enter New Password"
                                    type='password'
                                    disabled={!isCurrentPwdValid}
                                />
                                {(isCurrentPwdValid && touched.newPassword && errors.newPassword) && <span className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">{errors.newPassword}</span>}
                            </div>
                        </div>
                        <HRLine className="mt-5" />
                        <div className='flex justify-between items-center mt-5'>
                            <p className='text-[#6B7280] text-sm font-bold w-[40%]'>Confirm Password</p>
                            <div className="flex flex-col w-[60%]">
                                <TextField
                                    size="small"
                                    className="w-full"
                                    variant="outlined"
                                    name="confirmPassword"
                                    onChange={(e) => {
                                        setValues({ ...values, confirmPassword: e.target.value });
                                    }}
                                    onBlur={() => {
                                        setFieldTouched("confirmPassword");
                                    }}
                                    value={values.confirmPassword}
                                    placeholder="Enter Confirm Password"
                                    type='password'
                                    disabled={!isCurrentPwdValid}
                                />
                                {(isCurrentPwdValid && touched.confirmPassword && errors.confirmPassword) && <span className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">{errors.confirmPassword}</span>}
                            </div>
                        </div>
                        <div className='flex justify-end items-center w-full mt-5'>
                            <Button
                                label={isLoading ? "Please Wait" : "Update Password"}
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="!w-[10rem] !h-[2.7rem]"
                                disabled={isLoading}
                            />
                        </div>
                    </Form>
                }}

            </Formik>
        </div >
    </>
}