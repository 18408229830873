import styled from "styled-components";
import Wave from "../../../../assets/images/EasyWave.png";

const Integration = () => {
  return (
    <Container>
      <Wrapper>
        <div className="text-wrapper">
          <h3>
            Easy integration <span className="white-text ">with your business</span>
          </h3>
          <p className="text">
            No matter how you are managing your business and your contracts, we will provide full
            integration with your current IT environment. Thousands of corporates have made the
            switch.
          </p>
        </div>
        <div className="action-btn">
          <button variant="contained" color="primary">
            See all integration
          </button>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Integration;

const Container = styled.div`
  position: relative;
  padding: 0px 79px 0px 79px;
  max-width: 1440px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 60px 77px;
  background-image: url(${Wave});
  margin-bottom: 103px;
  background-color: #060720;
  box-shadow: 0px 5px 50px rgba(20, 15, 108, 0.05);
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  background-repeat: no-repeat;
  background-position: top;
  .text-wrapper {
    h3 {
      font-family: "matter_sq_trialsemibold";
      font-size: 42px;
      line-height: 48px;
      color: #4361ee;
      font-weight: normal;
      margin-bottom: 24px;
      margin: 0;
      letter-spacing: -0.03em;
      .white-text {
        font-family: "matter_sq_trialregular";
        color: #fff;
      }
    }
    .text {
      font-family: "matter_sq_trialregular";
      color: #adb5bd;
      font-size: 17px;
      line-height: 28px;
      margin: 22px 0 0;
      max-width: 727px;
    }
  }
  .action-btn {
    padding-left: 100px;
    button {
      font-family: "matter_sq_trialsemibold";
      height: 53px;
      font-size: 17px;
      font-weight: 600;
      border-radius: 5px;
      padding: 10px 46px;
      border: none;
      white-space: nowrap;
      :first-child {
        background: linear-gradient(265.31deg, #4361ee 0%, #3f37c9 0.01%);
        border-radius: 5px;
        color: #fff;
      }
    }
  }
`;
