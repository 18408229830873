import React from 'react'

// ASSETS
import { FaCheck } from "react-icons/fa";


const AvatarCard = ({ userId, userImage, userName, userEmail, isSelected, onClick }) => {

    // max-w-[97px] max-h-[80px]
    return <div className="flex justify-start items-center w-full h-full mb-5">
        {
            isSelected
                ?
                <div onClick={() => onClick({ userId, userEmail }, "remove")} className="select-none relative cursor-pointer w-9 h-9 rounded-full flex justify-center items-center">
                    <img className="absolute opacity-30 cursor-pointer w-9 h-9 rounded-full" src={userImage} alt="avatar" />
                    <FaCheck color='#560BAD' className='absolute' />
                </div>
                :
                <img onClick={() => onClick({ userId, userEmail }, "add")} className="select-none cursor-pointer w-9 h-9 rounded-full" src={userImage} alt="avatar" />
        }
        <div className='ml-2'>
            <p className="select-none text-xs font-bold text-[#2F2F2F]">
                {/* {userName.length > 9 ? userName.slice(0, 9) + "..." : userName} */}
                {userName}
            </p>
            <p className="select-none text-xs text-[#909291]">
                {/* {userEmail.length > 9 ? userEmail.slice(0, 9) + "..." : userEmail} */}
                {userEmail}
            </p>
        </div>
    </div>
}

export default AvatarCard