/* eslint-disable */
import { createFalse } from "typescript";
import {
  ADD_USER_FORM,
  GET_USER_DATA,
  LOGOUT_USER,
  DISABLE_USER_FORM,
  STORE_ADMIN_KPI_DATA,
  GET_DASHBOARD_DATA
} from "../actions";

const initialState = {
  userFormData: [],
  isUserCreated: false,
  kpiBoxes: [],
  agreementData: [],
};

const AdminReducer = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case GET_USER_DATA: {
      return { ...state, userFormData: action.data };
    }
    case ADD_USER_FORM: {
      return { ...state };
    }
    case DISABLE_USER_FORM: {
      return { ...state, isUserCreated: action.isUserCreated };
    }
    case STORE_ADMIN_KPI_DATA: {
      return { ...state, kpiBoxes: action.data };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    case GET_DASHBOARD_DATA: {
      return { ...state, agreementData: action.data };
    }
    default:
      return state;
  }
};

export default AdminReducer;
