import { AiOutlineClose } from "react-icons/ai";
import "./styles.scss";

interface otherProps {
  closePopup: () => {};
  btnDisabled: boolean;
  onFinish: Function;
  isValidate: boolean;
}

const ExtractionValidateConfirmation = (p: otherProps) => {
  return (
    <div className="upload-agreement-container">
      <button
        className="upload-cross-wrapper"
        title="Close"
        onClick={p.closePopup}
        disabled={p.btnDisabled}
      >
        <AiOutlineClose className="close" />
      </button>

      <h3 className={`agreement-title`}>{"Confirm Your Action"}</h3>
      <p className="agreement-upload-content">
        <span className="agreement-upload-content-text">
          Please note you can not perform any changes after{" "}
          {p.isValidate ? "validating" : "deleting"} the extraction.
        </span>
        <span className="agreement-upload-content-text">
          Are you sure, you want to {p.isValidate ? "validate" : "delete"} now?
        </span>
      </p>
      <div className="proceed-btn">
        <button
          onClick={() => p.onFinish()}
          disabled={p.btnDisabled}
          className={`${p.isValidate ? "validate-btn" : "delete-btn"}`}
        >
          {p.isValidate ? "Proceed" : "Delete"}
        </button>
      </div>
    </div>
  );
};

export default ExtractionValidateConfirmation;
