export const SET_REPOSITORY_LIST = "SET_REPOSITORY_LIST";
export const SET_AGREEMENT_FILES_TO_IMPORT = "SET_AGREEMENT_FILES_TO_IMPORT";
export const SET_DATA_TO_VALIDATE_EXTRACTION = "SET_DATA_TO_VALIDATE_EXTRACTION";
export const SET_IS_REPOSITORY_DATA_FETCHING = "SET_IS_REPOSITORY_DATA_FETCHING";
export const ADD_REPOSITORY_DOC_IDS = "ADD_REPOSITORY_DOC_IDS";
export const REMOVE_REPOSITORY_DOC_IDS = "REMOVE_REPOSITORY_DOC_IDS";


export const setRepositoryList = (data: any) => {
  return {
    type: SET_REPOSITORY_LIST,
    data: data,
  };
};

export const setAgreementFilesToImport = (data: any) => {
  return {
    type: SET_AGREEMENT_FILES_TO_IMPORT,
    data: data,
  };
};

export const setDataToValidateExtraction = (data: any) => {
  return {
    type: SET_DATA_TO_VALIDATE_EXTRACTION,
    data: data,
  };
};

export const setIsRepositoryDataFetching = (data: any) => {
  return {
    type: SET_IS_REPOSITORY_DATA_FETCHING,
    data: data,
  };
};

export const addRepositoryDocIds = (data: any) => {
  return {
    type: ADD_REPOSITORY_DOC_IDS,
    data: data,
  };
};

export const removeRepositoryDocIds = (data: any) => {
  return {
    type: REMOVE_REPOSITORY_DOC_IDS,
    data: data,
  };
};
