import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSimpleTooltip from "react-simple-tooltip";
import { NavigateFunction, useNavigate } from "react-router";
import { MdTask } from "react-icons/md";
import DashboardLayout from "layouts/dashboardLayout";
import TableJs from "components/Table/table";
import Popup from "reactjs-popup";
import {
  API_ROUTES,
  postRequest,
  showToast,
  RESPONSE_MSGS,
  STATUS_CODE,
  ROUTE_CONSTANTS,
  NEW_AGREEMENT_STATUS,
  getClassName,
  getSIgnedURLFromS3,
  deleteRequest,
} from "helpers";
import { LoaderHOC } from "components";
import {
  addRepositoryDocIds,
  removeRepositoryDocIds,
  setDataToValidateExtraction,
  setUserAgreements
} from "redux/actions";
import { forceLogout } from "redux/services";

//
import "./style.scss";
import { IoMdDownload } from "react-icons/io";
import { GoShareAndroid } from "react-icons/go";
import { RootState } from "redux/store";
import { BulkShare, SingleDocShare } from "customComponents";
import { IoPeople } from "react-icons/io5";

export function renderContractNameForOpenAgreements(
  navigate: NavigateFunction,
  dispatch: any,
  otherProps?: any,
) {
  return (d: { cell: any; value: string; row: any }) => {
    const data = d?.row?.original || {};

    if (
      data.Status &&
      data.Status.toLowerCase() === NEW_AGREEMENT_STATUS["imported"].toLowerCase() &&
      data.isNewUpload
    ) {
      return (
        <div
          onClick={() => {
            if (data.Status.toLowerCase() == "imported") {
              dispatch(setDataToValidateExtraction(data));
              navigate(`${ROUTE_CONSTANTS.Imported_Documents}/${data._id}`);
              return
            }
            otherProps.setFileInfo({
              fileUrl: data.fileUrl,
              isShown: true,
              name: d.value,
              fileExtension: data.fileExtension,
            });
          }}
          title={d.value}
          className={`Contract_name_in_repo`}
        >
          {d.value}
        </div>
      );
    }

    if (
      data.agreementStatus === NEW_AGREEMENT_STATUS["executed"] ||
      data.agreementStatus === NEW_AGREEMENT_STATUS["imported"] ||
      data.Status === NEW_AGREEMENT_STATUS["executed"]
    ) {
      return (
        <div
          onClick={() => {
            if (!data.Processing) {
              dispatch(setDataToValidateExtraction(data));
              navigate(ROUTE_CONSTANTS.VALIDATE_EXTRACTION);
            }
          }}
          title={d.value}
          className={`Contract_name_in_repo ${data.Processing ? "disabled-Contract_name_in_repo" : ""
            }`}
        >
          {d.value}
        </div>
      );
    }

    data.dynamicAgreement = true;
    const handleAgreementRoutes = () => {
      if (!data.Processing) {
        if (
          data.agreementStatus === NEW_AGREEMENT_STATUS["vendorEsign"] ||
          data.agreementStatus === NEW_AGREEMENT_STATUS["executed"]
        ) {
          navigate(`${ROUTE_CONSTANTS.Final_Signed_Agreement}/${data?._id || "template_1"}`, {
            state: data,
          });
        } else {
          navigate(`${ROUTE_CONSTANTS.Create_Agreement}/${data?._id || "template_1"}`, {
            state: data,
          });
        }
      }
    };
    return (
      <div
        onClick={handleAgreementRoutes}
        title={d.value}
        className={`Contract_name_in_repo ${data.Processing ? "disabled-Contract_name_in_repo" : ""
          }`}
      >
        {d.value}
      </div>
    );
  };
}

const MyTasks: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // SELECTORS
  const userData = useSelector((state: any) => state.common.userData) || {};
  const tableDataSelector = useSelector((state: any) => state.agreement.myTasks) || [];
  const userTokens = useSelector((state: RootState) => state.common.userTokens) || {};
  const selectedDocsIds = useSelector((state: RootState) => state.repository.selectedDocIds) || [];

  // STATES
  const [isLoading, setLoading] = useState(false);
  const [shareModalState, setShareModalState] = useState(false);
  const [singleDocShare, setSingleDocShare] = useState(false);
  const [tableData, setTableData] = useState<any>([])
  const searchRef = useRef(null);



  const fetchData = async () => {
    try {
      setLoading(true);
      const res: any = await postRequest(API_ROUTES.MY_TASKS, true, {
        customerName: userData.email,
      });

      if (res.data.status_code && res.data.status_code === 401) {
        dispatch(setUserAgreements([]));
        return;
      }

      const { status }: any = res || {};
      const { data }: any = res;
      await forceLogout({ status: data?.status_code, message: data?.message });

      if (status === STATUS_CODE.SUCCESS && typeof data !== "string") {
        //setTableData(data);
        dispatch(setUserAgreements(data));
        setTableData(data || [])
        if (searchRef && searchRef.current) {
          (searchRef.current as any).setFilter();
        }
        // showToast(RESPONSE_MSGS.EXTRACTION_AGREEMENT_SUCCESS, true);
      } else if (status === STATUS_CODE.SUCCESS && typeof data === "string") {
        //setTableData(data);
        dispatch(setUserAgreements([]));
      } else {
        showToast(data?.message || RESPONSE_MSGS.ERROR, false);
        dispatch(setUserAgreements([]));
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast(RESPONSE_MSGS.ERROR, false);
    } finally {
      setLoading(false);
    }
  };
  const agreementAction = (cell, action) => {
    const data = cell?.row?.original || {};
    const parentId = cell?.row?.original?.parent_id !== "not_assigned" ? cell?.row?.original?.parent_id : cell?.row?.original?._id;
    const parentData: any = cell?.page?.find((item: any) => item?.original?._id == parentId)?.original;

    const awsRegion = process.env.REACT_APP_AWS_REGION || "";
    const awsPoolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID || "";
    const awsUserPool = process.env.REACT_APP_AWS_USER_POOL || "";

    const { license } = data;

    let key_name = data?.executedPath || parentData?.executedPath || parentData?.fileUrl || "";
    let bucket = data?.exceutedBucketName || parentData?.exceutedBucketName || "";
    if (license && license?.toString() === "true") {
      bucket = process.env.REACT_APP_LICENSE_BUCKET_NAME || "";
    }

    if (key_name && bucket && awsRegion && awsPoolId && awsUserPool) {
      getSIgnedURLFromS3(
        awsRegion,
        awsPoolId,
        awsUserPool,
        bucket,
        userTokens.IdToken,
        key_name,
        (signed_url: string) => {
          if (action === "download") {
            let link = document.createElement("a") as HTMLAnchorElement;
            link.download = data.Key.split("/")[1];
            link.href = signed_url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // window.open(signed_url);
            return;
          } else if (action === "delete") {
            deleteRequest(API_ROUTES.DELETE_EXTRACTION, true, {
              contract_name: cell.row.values.Contract_name,
            })
              .then(async (res: any) => {
                const { status } = res || {};
                if (status !== STATUS_CODE.SUCCESS) {
                  showToast(RESPONSE_MSGS.ERROR, false);
                } else {
                  setTimeout(() => {
                    fetchData();
                  }, 2000);
                  showToast(RESPONSE_MSGS.EXTRACTION_DELETE_SUCCESS, true);
                  dispatch(setDataToValidateExtraction({}));
                }
              })
              .catch((err) => {
                // showLogInConsole("ERROR In delete document repo data ", err);
              });
            return;
          }
        },
      );
    }
  };
  const singleDocShareHandler = (doc) => {
    let selectedDocs = selectedDocsIds;
    const index = selectedDocs.find(item => item?.documentId == doc._id);
    if (!index) {
      selectedDocs.push(
        {
          documentId: doc?._id || "",
          documentName: doc?.Contract_name || doc?.contract_name || "",
          agreementStatus: doc?.agreementStatus || "",
          accessLevel: "Read-Only",
          indexName: "new-agreement",
        }
      );
      dispatch(addRepositoryDocIds(selectedDocs));
      setSingleDocShare(true);
    }
  }
  const onCloseShareModal = () => {
    dispatch(removeRepositoryDocIds([]));
    setShareModalState(false);
  }
  const shareSubmitHandler = async (selectedUsers: any) => {
    try {


      const selectedDocs = selectedDocsIds;

      const data = {
        document_id: selectedDocs?.[0]?.documentId || "",
        index_name: selectedDocs?.[0]?.indexName || "",
        users_list: selectedUsers?.map(item => item?.userId) || [],
        documentName: selectedDocs?.[0]?.documentName || "",
        loggedInUserEmail: userData?.email || ""
      }

      const response: any = await postRequest(API_ROUTES.SHARE_AGREEMENT_WITH_USERS, true, data);
      if (response?.data == "success") {
        showToast("Document Shared", true);
      } else {
        showToast("Something went wrong", false);
      }
    } catch (err) {
      console.log("shareSubmitHandler error ==>", err);
      showToast("something went wrong.", false);
    } finally {
      dispatch(removeRepositoryDocIds([]));
    }
  }
  const closeSingleDocShare = () => {
    dispatch(removeRepositoryDocIds([]));
    setSingleDocShare(false);
  }
  const searchHandler = (e) => {
    const filteredData = tableDataSelector?.filter((item: any) => (item?.contract_name?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
      item?.type?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
      item?.agreementStatus?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
      item?.price?.toLowerCase()?.includes(e.target.value?.toLowerCase())));

    setTableData(filteredData);

    if (e.target.value == "") {
      setTableData(tableDataSelector);
    }
  }

  useEffect(() => {
    dispatch(setUserAgreements([]));
  }, []);
  useEffect(() => {
    // fix Warning: Can't perform a React state update on an unmounted component
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      await fetchData();
    })();
    return () => {
      dispatch(setUserAgreements([]));
    };
  }, []);
  useEffect(() => {
    function handleEscape(e) {
      if (e.key === "Escape") {
        setSingleDocShare(false);
        dispatch(removeRepositoryDocIds([]))
      }
    }
    document.addEventListener('keydown', handleEscape);
    return function cleanup() {
      document.removeEventListener('keydown', handleEscape);
    }
  }, []);

  const columns = [
    {
      Header: "Contract Name",
      accessor: "contract_name",
      Cell: renderContractNameForOpenAgreements(navigate, dispatch),
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (d: { cell: any; value: string }) => (
        <div
          className="type_grey w-[150px] whitespace-nowrap overflow-hidden text-ellipsis"
          title={d.value}
        >
          {d.value}
        </div>
      ),
    },
    {
      Header: "Customer",
      accessor: "customerName",
      Cell: (d: { cell: any; value: string }) => <div className="type_black">{d.value}</div>,
    },
    {
      Header: "Effective Date",
      accessor: "agreementDate",
      Cell: (d: { cell: any; value: string }) => <div className="type_grey">{d.value}</div>,
    },
    {
      Header: "Expiry Date",
      accessor: "expiryDate",
      Cell: (d: { cell: any; value: string }) => <div className="type_grey">{d.value}</div>,
    },
    {
      Header: "Status",
      accessor: "agreementStatus",
      Cell: (d: { cell: any; value: string }) => {
        return <div className={`status status-my-task ${getClassName(d?.value)}`}>{d?.value}</div>;
      },
    },
    {
      Header: "Value",
      accessor: "price",
      Cell: (d: { cell: any; value: string }) => <div className="type_grey">{d.value}</div>,
    },
    {
      Header: "Parent Agreement",
      accessor: "parent_name",
      Cell: (d: { cell: any; value: string }) => (
        <div className="type_grey">{(d.value === "not_assigned" ? "None" : d.value) || ""}</div>
      ),
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: (d: { cell: any; value: string; rows: any; row: { values, original } }) => {
        const data = d?.row?.original || {};
        return (
          <div className="flex">
            {/* <span className="table-actions-wrapper hidden">
              <AiOutlinePrinter
                className="table-actions-icon"
                onClick={() => agreementAction(d, "print")}
              />
            </span> */}
            <span className={`table-actions-wrapper`}>
              <IoMdDownload
                className="download-icon"
                onClick={() => agreementAction(d, "download")}
              />
            </span>
            <div className="table-actions-wrapper">
              {
                data?.isShared ?
                  <ReactSimpleTooltip
                    content={<p className="text-xs">Shared</p>}
                    arrow={7}
                    background="#000"
                    border="#000"
                    color="#fff"
                    customCss={{
                      opacity: 0.8,
                    }}
                    fadeDuration={200}
                    fadeEasing="linear"
                    fixed={false}
                    fontFamily="inherit"
                    fontSize="inherit"
                    offset={0}
                    padding={5}
                    placement="top"
                    radius={5}
                    zIndex={1}
                  >
                    <IoPeople className="shared-doc-icon" />
                  </ReactSimpleTooltip>
                  :
                  <GoShareAndroid
                    className="share-icon"
                    onClick={() => {
                      if (selectedDocsIds.length != data?.length) {
                        singleDocShareHandler(data);
                      }
                    }}
                  />
              }
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DashboardLayout
        hederTitle={"My Tasks"}
        headerLogo={<MdTask />}
        childer={
          <>
            <div className="mytask-container">
              <div className="repo-table">
                <TableJs
                  sortBy={[{ id: "contract_name", desc: true }]}
                  columns={columns}
                  data={tableData}
                  isRowSelectEnable={false}
                  headerProps={{ isEnable: true, type: 3 }}
                  isRowsPerPageChangeRequired={false}
                  isCurrentRowsCountVisibilityRequired={false}
                  ref={searchRef}
                  searchHandler={searchHandler}
                />
                {isLoading ? (
                  <LoaderHOC></LoaderHOC>
                ) : tableData.length ? null : (
                  <div className="mt-3">No Data Found...</div>
                )}
              </div>
            </div>
          </>
        }
      />
      {/* BulkShare Modal */}
      <Popup
        modal
        open={shareModalState}
        contentStyle={{
          width: "480px",
          maxWidth: "480px",
          minHeight: "400px",
          maxHeight: "auto",
          borderRadius: "10px",
        }}
        closeOnDocumentClick={false}
      >
        <BulkShare
          onCloseModal={onCloseShareModal}
          onSubmit={shareSubmitHandler}
        />
      </Popup>

      {/* SingleShare Modal */}
      <Popup
        modal
        open={singleDocShare}
        contentStyle={{
          width: "480px",
          maxWidth: "480px",
          minHeight: "400px",
          maxHeight: "auto",
          borderRadius: "10px",
        }}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className='w-full h-full flex flex-col justify-center items-center'>
          <SingleDocShare
            onCloseModal={closeSingleDocShare}
            onSubmit={shareSubmitHandler}
          />
        </div>
      </Popup >
    </>
  );
};

export default MyTasks;
