import { IMAGES } from "./images";

export const ROUTE_CONSTANTS = {
  ROOT: "/",
  LOGIN: "/login",
  SIGN_UP: "/sign-up",
  Guest_LOGIN: "/guest-login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  HOME_PAGE: "/",
  DASHBOARD: "/dashboard",
  ADMIN: "/admin",
  ACCESS_MANAGEMENT: "/workflow-manager",
  KPI_MANAGEMENT: "/kpi-manager",
  Repository: "/repository",
  REQUEST_AGREEMENT: "/request-agreement",
  AGREEMENT_REPOSITORY: "/agreement-repository",
  KPI_DASHBOARD: "/kpi-dashboard",
  RISK_ASSESSMENT: "/risk-assessment",
  ADMINISTRATOR_CONSOLE: "/administrator-console",
  NEUTRON_CENTRAL: "/neutron-central",
  VALIDATE_EXTRACTION: "/validate-extraction",
  My_Tasks: "/my-tasks",
  Create_Agreement: "/create-agreement",
  GET_VERSION_LIST: "/get-version-list",
  Approve_Agreement: "/approve-agreement",
  Create_Password_Guest: "/vendor-reset-password",
  View_Risk_Agreement: "/view-risk-agreement",
  Final_Signed_Agreement: "/signed_agreement",
  EDIT_PROFILE: "/edit-profile",
  LIBRARY: "/library",
  //
  TempUpload: "/tempupload",
  Imported_Documents: "/imported-documents",
};

export const STRINGS = {
  /** Login page */
  NEUTRON: "Neutron",
  LOGIN_PAGE_TITLE: "Login",
  LOGIN_PAGE_CONTENT: "Please sign-in to your account and start the Legalism",
  GUEST_LOGIN_SUBTITLE: "Please wait, while you are auto login in OneCounsel portal",
  SIGNUP_PAGE_TITLE: "Sign Up",
  SIGNUP_PAGE_CONTENT: "Enter your username password details",
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
  FORGOT_PASSWORD: "Forgot Password",
  READ_AND_AGREE_TO: "I have read and agree to ",
  TERMS_AND_CONDITIONS: "Terms & Conditions",
  CREATE_AN_ACCOUNT: "Create an account",
  HAVE_AN_ACCOUNT: "Do you have an account?",
  SIGN_UP_EXM: "Sign Up!",
  ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
  SIGN_IN_EXM: "Sign In!",
  ENTER_YOUR_EMAIL: "Email ID",
  ENTER_PASSWORD: "Enter Password",
  CONFIRM_PASSWORD: "Confirm Password",
  NEW_PASSWORD: "New Password",
  LOGIN_TO_ACCOUNT: "Login",
  CREATE_ACCOUNT: "Create Account",
  FIRST_NAME: "Name",
  LAST_NAME: "Last Name",
  ABOUT_US: "About Us",
  CONTACT_US: "Contact Us",
  PRIVACY_POLICY: "Privacy Policy",
  COPYRIGHT: "Copyright © 2021 Neutron",
  CONFIRM: "Confirm",
  RESEND_OTP: "Resend OTP",
  OTP_SCREEN_TITLE: "Enter OTP",
  OTP_SENT_LINE_1: `We’ve sent a verification code to your email.`,
  OTP_SENT_LINE_2: ` Enter the code from your email in the field below.`,
  PHONE_AND_EMAIL: "Phone Number and Email Address",
  PHONE_NUMBER: "Phone Number",
  COMPANY_NAME: "Username",
  COUNTRY: "Country",
  START_TRIAL: "Create Account",
  SEE_PLANS: "See Plans",
  FORGOT_PWD_SUBTITLE:
    "Don’t worry! It happens. Please enter the email address associated with your account.",
  BACK_TO_SIGN_IN: "Back to Sign In",
  EMAIL_ADDRESS: "Email Address",
  CREATE_PASSWORD: "Reset Password",
  RESET_PWD_SUBTITLE: "Reset your password to access your OneCounsel portal",
  PASSWORD_RESET_CODE: "Password Reset Code",
  UPDATE_PASSWORD: "Update Password",
  LOGOUT: "Logout",
  COMING_SOON: "Coming Soon!",
  SKIP: "Skip",
  UPLOAD_AGREEMENT: "Upload Agreement",
  SUBMIT: "Submit",
  UPLOAD: "Upload",
  AGREEMENT_NAME: "Agreement Name",
  AGREEMENT_TYPE: "Type of Agreement",
  AGREEMNET_DATE: "Select Date (To - From)",
  REASON_FOR_RETURN_REVIEWER: "Please add reason",
  SHARE_TO_VENDOR: "Please enter vendor email",
  PLACEHOLDER_VENDOR: "⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ",
  ESIGN_PROCESS: "Please enter member details for e-sign process",

  //User Role Actions
  DRAFTER_ACTION: "Can Create only agreements",
  REVIEWER_ACTION: "Can Create, Edit and Review agreements",
  APPROVER_ACTION: "Can Create, Edit, Review and Approve the agreements",
  VENDOR_ACTION: "Can Review and Edit the agreement",
  ADMIN_ACTION: "Can manage Users and Work Flow",
  VENDOR_REG_MESSAGE: `If selected then the user will be marked as an official vendor
                       and can access its contract/tasks by system generated password
                       received over the mail and can only be altered by admin.
                       If not then selected vendor will be marked as guest and its
                       account will be automatically deleted after a few days.
                       NOTE - Once account is marked as a registered vendor it cannot
                       be converted back to guest user or vice versa.`,
  CAUTION_MSG:
    "Caution: Avoid importing the same document multiple times to prevent clutter in the system.",
};

export const INPUT_FIELD_NAMES = {
  EMAIL: "email",
  PASSWORD: "password",
  C_PASSWORD: "cpassword",
  // TERMS_ACCEPTED: "termsAccepted",
  NAME: "firstName",
  PHONE_NUMBER: "phoneNumber",
  COUNTRY_CODE: "countryCode",
  COMPANY_NAME: "companyName",
  COUNTRY: "country",
  RESET_CODE: "resetCode",
  AGREEMENT_NAME: "name",
  AGREEMENT_TYPE: "type",
  AGREEMNET_DATE: "date",
};

export const VALIDATION_MSGS = {
  TOO_LONG: "Too Long!",
  TOO_SHORT: "Too Short!",
  OTP_CODE_LENGTH: "Must be 6 digits.",
  INVALID_EMAIL: "Invalid email.",
  REQUIRED: "Required.",
  INVALID_PHONENO: "Invalid phone number.",
  INVALID_CODE: "Invalid code.",
  PASSWORD_MISMATCH: "Passwords must match",
  PASSWORD_PATTERN_INVALID:
    "Use a combination of uppercase, lowercase, digits and specical characters.",
};

export const DASHBOARD_CONTENT = [
  {
    title: "Request Agreement",
    link: ROUTE_CONSTANTS.REQUEST_AGREEMENT,
    logo: IMAGES.AgreementLogo,
  },
  {
    title: "Agreement Repository",
    link: ROUTE_CONSTANTS.AGREEMENT_REPOSITORY,
    logo: IMAGES.RepositoryLogo,
  },
  {
    title: "KPI Dashboard",
    link: ROUTE_CONSTANTS.KPI_DASHBOARD,
    logo: IMAGES.KpiLogo,
  },
  {
    title: "Risk Assessment",
    link: ROUTE_CONSTANTS.RISK_ASSESSMENT,
    logo: IMAGES.AssessmentLogo,
  },
  {
    title: "Administrator Console",
    link: ROUTE_CONSTANTS.ADMINISTRATOR_CONSOLE,
    logo: IMAGES.ConsoleLogo,
  },
  {
    title: "Neutron Central",
    link: ROUTE_CONSTANTS.NEUTRON_CENTRAL,
    logo: IMAGES.NcentralLogo,
  },
];

export const LOGIN_DUMMY_DATA = {
  email: "neutrontest@yopmail.com",
  password: "pass1234",
  code: "123456",
};

export const AGREEMENT_TYPE_OPTIONS = [
  { label: "Option 1", value: "opt-1" },
  { label: "Option 2", value: "opt-2" },
  { label: "Option 3", value: "opt-3" },
  { label: "Option 4", value: "opt-4" },
  { label: "Option 5", value: "opt-5" },
];

export const AGREEMENT_UPLOAD_STATUS = {
  ERROR: "error",
  DONE: "done",
  IMPORTING: "importing",
  NOT_STARTED: "not-started",
  PARTIAL_DONE: "partial-done",
};

export const AGREEMENT_IN_REPO_STATUS = {
  imported: "Imported",
  validate: "Validate",
  licenses: "licenses",
};

export const KPI_TABLE_DATA = {
  kpiBoxesData: {
    avgAgreementCycleTime: "00",
    nonComplianceWithTC: "00",
    penalityLevied: "000",
    dueDiligenceExpiryStatus: "00",
  },
  kpiTableData: [
    {
      agreement: "Trantor-Neutron MSA",
      type: "MSA",
      date: "12/09/2021",
      draftDays: "2",
      reviewDays: "2",
      negotDays: "2",
      signDays: "2",
      totalDays: "2",
      status: "Active",
      penaltyAmount: "$20,000.00",
    },
    {
      agreement: "Trantor-Neutron SOW",
      type: "SOW",
      date: "12/10/2021",
      draftDays: "5",
      reviewDays: "5",
      negotDays: "5",
      signDays: "5",
      totalDays: "5",
      status: "Pending",
      penaltyAmount: "$30,000.00",
    },
    {
      agreement: "Trantor-Neutron MSA 1",
      type: "MSA",
      date: "12/11/2021",
      draftDays: "3",
      reviewDays: "3",
      negotDays: "3",
      signDays: "3",
      totalDays: "3",
      status: "Inactive",
      penaltyAmount: "$20,000.00",
    },
  ],
};

export const REPOSITORY_TABLE_DATA = [
  {
    Contract_name: "Trantor-Neutron MSA5",
    Type: "MSA",
    CustomerName: "Talent Cloud LLP, USA",
    RequesterName: "Shrin Chowdhary",
    EffectiveDate: "12/09/2021",
    ExpiryDate: "12/09/2024",
    ContractStatus: "Drafted",
    Region: "Gurugram",
    Value: "$20,000.00",
    Version: "1",
    Key: "MSA2.docx",
    Extension: "docx",
  },
  {
    Contract_name: "Service Management",
    Type: "Lease Deed",
    CustomerName: "Talent Cloud LLP, USA",
    RequesterName: "Shrin Chowdhary",
    EffectiveDate: "12/09/2021",
    ExpiryDate: "10/09/2024",
    ContractStatus: "Imported",
    Region: "USA",
    Value: "$20,000.00",
    Version: "1",
    Key: "Drawing34.pdf",
    Extension: "pdf",
  },
  {
    Contract_name: "Market Research",
    Type: "Manpower Placement",
    CustomerName: "Talent Cloud LLP, USA",
    RequesterName: "Shrin Chowdhary",
    EffectiveDate: "12/09/2021",
    ExpiryDate: "12/09/2024",
    ContractStatus: "Validate",
    Region: "Chennai",
    Value: "$20,000.00",
    Version: "1",
    Key: "Import Agreement.pdf",
    Extension: "pdf",
  },
  {
    Contract_name: "Non-Disclosuer",
    Type: "NDA",
    CustomerName: "Talent Cloud LLP, USA",
    RequesterName: "Shrin Chowdhary",
    EffectiveDate: "12/09/2021",
    ExpiryDate: "12/09/2024",
    ContractStatus: "Current",
    Region: "USA",
    Value: "$20,000.00",
    Version: "1",
    Key: "Nodal Agreement.docx",
    Extension: "docx",
  },
];

export const USER_ROLES = {
  drafter: "drafter",
  reviewer: "reviewer",
  approver: "approver",
  vendor: "vendor",
  admin: "admin",
};

export const NEW_AGREEMENT_STATUS = {
  draft: "Draft",
  review: "Review",
  reviewRejected: "Review Rejected",
  approve: "Approve",
  approveRejected: "Approve Rejected",
  approved: "Approved",
  vendorReview: "Vendor Review",
  approverPending: "Approver Pending",
  rejected: "Rejected",
  approverEStamp: "Approver EStamp",
  vendorEsign: "Vendor Esign",
  executed: "Executed",
  imported: "Imported",
  validate: "validate",
};

export const IMPORT_TYPES = {
  LICENSE: "license",
  AGREEMENT: "agreement",
  AGREEMENT_PREVIEW: "agreement_preview",
  PREVIEW: "preview",
};

export const TemplateType = {
  Rental: "Rental Agreement",
  Rental2: "rental agrement",
  Lease: "Lease Deed",
  Lease2: "Lease Agreement",
  BTAgreement: "Business Agreement",
  LLA: "LLA Agreements",
  PSA1: "PSA1",
  Consultant: "Consultant Contract",
  EmploymentAgreement: "Employment Agreement",
  CXMCEmploymentAgreement: "CXMC Employment Agreement",
};

export const FileTypes = {
  PDF: "pdf",
  DOCX: "docx",
};

export const DocxFileExtentions = {
  ACCEPT_LIST: {
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  },
};
export const PdfFileExtentions = {
  ACCEPT_LIST: {
    "application/pdf": [".pdf"],
  },
};
export const AgreementFileExtentions = {
  ACCEPT_LIST: {
    // "image/png": [".png"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    // "image/jpeg": [".jpg", ".jpeg"],
  },
};
