import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineBell } from "react-icons/ai";
import { Badge } from "@mui/material";
import Popup from "reactjs-popup";
// import TimeAgo from "timeago-react";
import "reactjs-popup/dist/index.css";
import Checkbox from "@material-ui/core/Checkbox";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { addBellNotifications, setDataToValidateExtraction } from "redux/actions";
import avatar from "assets/images/avtar.png";
import {
  fetchNotifications,
  readNotifications,
  updateNotificationCount,
  markAllAsRead,
  ROUTE_CONSTANTS,
  NEW_AGREEMENT_STATUS,
  formattedDate,
} from "helpers";
import { debounce } from "@material-ui/core";

//
import { SelectInput } from "components";
//
import "./styles.scss";

interface Props {
  data: [];
  loading: boolean;
  handleReadNotifications: any;
  setIsOpen: any;
  markAllAsRead: any;
}

const NotificationModal = (n: Props) => {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [size, setSize] = useState(0);
  const [notificationList, setNotificationList] = useState<object[]>(n.data);
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedFilter(value);
    selectRef?.current?.blur();
    let filteredList: object[] = n.data;
    if (value !== "all") {
      filteredList = n.data.filter((item: any) =>
        value === "unread" ? item.seen === false : item.seen === true,
      );
    }
    setNotificationList(filteredList);
  };

  const handleMarkAllAsRead = (event) => {
    const { checked } = event.target;
    if (checked) {
      n.markAllAsRead();
      const markAllAsReadNotifications = n.data.map((item: any) => {
        return {
          ...item,
          seen: false,
        };
      });
      setNotificationList(markAllAsReadNotifications);
    }
  };

  useEffect(() => {
    selectRef?.current?.blur();
  }, []);

  const onFocus = (e) => {
    e.preventDefault();
    setSize(3);
  };

  return (
    <div className="notification-content">
      <div className="heading">
        <span className="notification-heading">
          <span>Notifications</span>
          <select
            onFocus={(e) => onFocus(e)}
            onBlur={() => setSize(0)}
            ref={selectRef}
            value={selectedFilter}
            className={size > 0 ? "open" : "close"}
            multiple={size > 0}
            onChange={handleChange}
          >
            <option value="all" className={selectedFilter === "all" ? "selected" : ""}>
              All
            </option>
            <option value="unread" className={selectedFilter === "unread" ? "selected" : ""}>
              Unread
            </option>
            <option value="read" className={selectedFilter === "read" ? "selected" : ""}>
              Read
            </option>
          </select>
        </span>
        <span>
          <label htmlFor="markAsRead">Mark all as read</label>
          <Checkbox
            id="markAsRead"
            icon={<CircleUnchecked />}
            checkedIcon={<CircleChecked />}
            style={{ color: "black" }}
            onChange={handleMarkAllAsRead}
          />
        </span>
      </div>

      {!notificationList.length ? (
        <div>You do not have any notification yet.</div>
      ) : (
        <div className="msg-list-container">
          <ul>
            {notificationList?.map((notification: any, index) => {
              const mailId = notification.message.slice(
                notification.message.indexOf("by") + "by".length,
              );
              let message = notification.message;
              const dateUTC = new Date(notification.created_at);
              dateUTC.setHours(dateUTC.getHours() + 5);
              dateUTC.setMinutes(dateUTC.getMinutes() + 30);
              let name;
              const indexOfHas = notification.message.indexOf("has");
              if (indexOfHas !== -1) {
                name = notification.message.slice(0, indexOfHas);
                message = notification.message.slice(indexOfHas);
              }
              message = message.slice(0, message.indexOf("by") + "by".length);
              return (
                <li
                  key={`notification-${index}`}
                  className={`notification-item ${!notification.seen ? "unread-msg-row" : ""}`}
                  onClick={() => {
                    n.setIsOpen(false);
                    n.handleReadNotifications(
                      notification._id,
                      notification.agreementStatus || "",
                      notification.seen,
                    );
                  }}
                >
                  <div className="noti-msg-row">
                    <div className="msg-icon">
                      <img
                        src={notification.profile_img || avatar}
                        width={32}
                        height={32}
                        alt="avatar"
                      />
                    </div>
                    <div className={`noti-msg ${!notification.seen ? "unread-msg" : ""}`}>
                      <span className="boldText">{name}</span>
                      {message}
                      <span className="boldText">{mailId}</span>
                    </div>
                    <div className="noti-msg-time">
                      <span>{formattedDate(notification.created_at)}</span>
                      {/* {Math.abs(new Date(notification.created_at).getTime() - new Date().getTime()) / (60 * 60 * 1000) <= 24 ?
                    <TimeAgo datetime={notification.created_at} /> : 
                    <span>{formattedDate(notification.created_at)}</span>} */}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

const BellNotifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const count = useSelector((state: any) => state.common.bellNotifications?.count) || 0;
  const list = useSelector((state: any) => state.common.bellNotifications?.list) || [];
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setList] = useState(list);
  const userData = useSelector((state: any) => state.common.userData) || {};

  const handleFetchNotifications = async () => {
    await fetchNotifications({
      customer_name: userData.email,
      setData: (obj: any) => {
        const isSameAsPrevious =
          Object.entries(data).toString() === Object.entries(obj.data).toString();
        if (!isSameAsPrevious) {
          setList(obj.data);
        }
        dispatch(addBellNotifications({ count: obj.count, list: obj.data }));
      },
      setLoading: setLoading,
    });
  };

  const handleReadNotifications = async (id: string, status: string, isSeen: boolean) => {
    if (!isSeen) {
      const index = data.findIndex((ele) => ele._id === id);
      if (index !== -1) {
        data[index].seen = true;
        setList(data);
      }

      setTimeout(async () => {
        await readNotifications({ id: id, customer_name: userData.email });
      }, 300);
    }
    if (status === NEW_AGREEMENT_STATUS["vendorEsign"]) {
      navigate(`${ROUTE_CONSTANTS.Final_Signed_Agreement}/${id}`, {
        state: { _id: id, dynamicAgreement: true },
      });
    } else if (status === NEW_AGREEMENT_STATUS["executed"]) {
      // dispatch(setDataToValidateExtraction(data));
      navigate(ROUTE_CONSTANTS.Repository);
    } else {
      navigate(`${ROUTE_CONSTANTS.Create_Agreement}/${id}`, {
        state: { _id: id, dynamicAgreement: true },
      });
    }
  };

  useEffect(() => {
    const fetchNotifications = () => {
      handleFetchNotifications();
    };
    fetchNotifications();

    const interval = setInterval(async () => {
      await fetchNotifications();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const onNotificationClick = debounce(() => {
    setIsOpen(true);
    dispatch(addBellNotifications({ count: 0, list: data }));
    updateNotificationCount(userData.email);
  }, 500);

  const markAllAsReadApiCall = () => {
    markAllAsRead(userData.email);
  };

  return (
    <>
      <Popup
        trigger={
          <div>
            <Badge
              overlap="rectangular"
              badgeContent={count}
              color="primary"
              onClick={onNotificationClick}
              className="cursor-pointer"
            >
              <AiOutlineBell color="action" className="text-2xl" />
            </Badge>
          </div>
        }
        position="bottom right"
        open={isOpen}
        className="notification-popup"
      >
        {isOpen ? (
          <NotificationModal
            data={data}
            loading={loading}
            handleReadNotifications={handleReadNotifications}
            setIsOpen={setIsOpen}
            markAllAsRead={markAllAsReadApiCall}
          />
        ) : null}
      </Popup>
    </>
  );
};

export default BellNotifications;
