import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import $ from "jquery";
import { DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";

import PieChart from "../../dashboard/pieChart";
import { LoaderHOC } from "components";
import DashboardLayout from "layouts/dashboardLayout";
import { API_ROUTES, STATUS_CODE, postRequest, RESPONSE_MSGS, showToast } from "helpers";
import "./style.scss";
import { forceLogout } from "redux/services";

interface asgreementProps {
  data: {
    Title: string;
    Contract_name: string;
    claues: any;
  };
  userTokens: {
    AccessToken: string;
    IdToken: string;
    RefreshToken: string;
  };
  findAndHighlight: Function;
}

const AgreementSchema = Yup.object().shape({
  Title: Yup.string().required("Required"),
  customerName: Yup.string().required("Required"),
});

const CollapseMode = (p: { title: string; children: any }) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  return (
    <>
      <div className="agreement-form-collapse">
        <div className="collapse-header">
          <span>{p.title}</span>
          {isHidden ? (
            <MdKeyboardArrowDown onClick={() => setIsHidden(false)} />
          ) : (
            <MdKeyboardArrowUp onClick={() => setIsHidden(true)} />
          )}
        </div>
        {!isHidden && <div className="collapse-body-row">{p.children}</div>}
      </div>
    </>
  );
};

const getRiskCount = (p: { data: [{ clause: string; num: number }] }) => {
  let total = { term: 0, fin: 0, other: 0 };
  const c1 = p.data.find((i: { clause: string; num: number }) => i.clause.startsWith("FEE"));
  const c2 = p.data.find((i: { clause: string; num: number }) => i.clause === "expires");

  if (c1 && c2) {
    total = { term: 50, fin: 50, other: 0 };
  } else {
    if (c1) {
      total = { ...total, term: 100 };
    }
    if (c2) {
      total = { ...total, fin: 100 };
    }
  }

  return total;
};

const AgreementForm = (p: asgreementProps) => {
  const riskCount = getRiskCount({ data: p.data?.claues || [] });

  return (
    <Formik
      initialValues={p.data}
      validationSchema={AgreementSchema}
      // eslint-disable-next-line class-methods-use-this, no-unused-vars
      onSubmit={() => {
        return;
      }}
    >
      {() => (
        <Form>
          <div className="risk-form-container">
            <div className="form-header">
              <div className="form-header-row">
                <span>Assessment Agreement</span>
              </div>
              <span>{p?.data?.Contract_name || ""}</span>
            </div>

            <>
              <CollapseMode
                title={"Clause"}
                children={
                  <>
                    <div className="clauses">
                      <div className="doc-header doc-row">
                        <span className="clause-heading" title={"Claus Number"}>
                          No.
                        </span>
                        <span className="clause-heading" title={"Claus"}>
                          Clause
                        </span>
                        <span className="clause-heading" title={"Proposed Change"}>
                          Proposed Change
                        </span>
                      </div>
                      {!p.data?.claues && <div className="no-data"> No data found! </div>}
                      {p?.data?.claues?.map((item: any) => (
                        <div className="doc-row" key={item?.num}>
                          <span className="clause-text" title={item?.num}>
                            {item?.num}
                          </span>
                          <span
                            className="clause-text blue"
                            title={item?.clause}
                            onClick={() => p?.findAndHighlight(item?.clause)}
                          >
                            {item?.clause?.charAt(0)?.toUpperCase() +
                              item?.clause?.toLowerCase()?.slice(1)}
                          </span>
                          <span className="clause-text" title={item?.proposedChange}>
                            {item?.proposedChange?.charAt(0)?.toUpperCase() +
                              item?.proposedChange?.slice(1)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                }
              />
              <CollapseMode
                title={"Risk by category"}
                children={
                  <>
                    <PieChart
                      data={[
                        { type: "Financial", value: riskCount.fin },
                        { type: "Term Risk", value: riskCount.term },
                        { type: "Others", value: riskCount.other },
                      ]}
                    />
                  </>
                }
              />
            </>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const ViewRiskAssessment = () => {
  const location: any = useLocation();
  const extractedData = location?.state || {};
  const userTokens = useSelector((state: any) => state.common.userTokens) || {};
  const [isLoading, setLoading] = useState(false);
  const [riskData, setRiskData] = useState<any>();
  let docRef: any = null;

  useEffect(() => {
    const sfdtText = extractedData?.editor_data;
    docRef.documentEditor.open(sfdtText);
    setTimeout(() => {
      $("#close").trigger("click");
    }, 0);
    //return () => clearTimeout();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res: any = await postRequest(API_ROUTES.RISK_TASKS, true, {
        contract_name: extractedData?.contract_name || "testing highlight",
      });
      const { status }: any = res || {};
      const { data }: any = res;
      await forceLogout({ status: data?.status_code, message: data?.message });

      if (status === STATUS_CODE.SUCCESS && typeof data !== "string") {
        setRiskData(res?.data?.data);
      } else {
        showToast(data?.message || RESPONSE_MSGS.ERROR, false);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast(RESPONSE_MSGS.ERROR, false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      await fetchData();
    })();
  }, []);

  const findAndHighlight = (clause) => {
    if (docRef) docRef.documentEditor?.search.find(clause, "None");
  };
  return (
    <>
      <DashboardLayout
        hederTitle={<>{riskData?.contract_name || ""}</>}
        childer={
          <div className="risk-container">
            <div className="risk-content">
              <div className="risk-viewer-section">
                <DocumentEditorContainerComponent
                  id="ej2-container"
                  ref={(scope) => {
                    docRef = scope;
                  }}
                  serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
                  showPropertiesPane={false}
                  restrictEditing={true}
                  enableToolbar={false}
                />
              </div>
              {isLoading && riskData !== undefined ? (
                <div className="loading">
                  <LoaderHOC></LoaderHOC>
                </div>
              ) : (
                <div className="risk-validate-form-section">
                  <AgreementForm
                    data={riskData}
                    userTokens={userTokens}
                    findAndHighlight={findAndHighlight}
                  />
                </div>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

export default ViewRiskAssessment;
