import { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import AdjustIcon from "@material-ui/icons/Adjust";

//
import { LoaderHOC } from "components";
//
import { fetchAuditLog } from "../../helpers";

const AuditLog = ({ id }) => {
  const CustomStepIcon = (props) => {
    const { active, completed } = props;
    const col = active || completed ? "primary" : "disabled";
    return <AdjustIcon color={col} fontSize="small" />;
  };

  const [auditList, setAuditlogs] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    (async () => {
      await fetchAuditLog({
        id: id,
        setData: setAuditlogs,
        setLoading: setLoading,
      });
    })();
  }, []);

  return (
    <>
      <div className="agreement-list-container">
        <h4 className="title">{"Audit Log"}</h4>
        <div className="agreement-list-body ">
          {loading ? (
            <div className="loading">
              <LoaderHOC></LoaderHOC>
            </div>
          ) : auditList?.length ? (
            <Stepper
              activeStep={auditList?.length || 0}
              orientation="vertical"
              className="overflow-y-auto max-h-[25rem] w-full"
            >
              {auditList?.map((step, index) => (
                <Step expanded={true} key={index}>
                  <StepLabel StepIconComponent={CustomStepIcon}>
                    State : {step.agreementStatus}
                  </StepLabel>
                  <StepContent TransitionProps={{ unmountOnExit: false }}>
                    <StepLabel>Date : {step.Date}</StepLabel>
                    <StepLabel>User : {step.actioner}</StepLabel>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          ) : (
            <div className="text-center">No Found.</div>
          )}
        </div>
      </div>
    </>
  );
};

export default AuditLog;
