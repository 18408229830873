import React from "react";
import "./style.scss";
import forgot from "../../../assets/images/forgot.png";
import resetScreen from "../../../assets/images/resetScreen.png";
import { ResetPasswordForm } from "../../../components";
import { STRINGS } from "../../../helpers";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import logo from "../../../assets/images/logo_icon.png";

const ResetPassword: React.FC = () => {
  return (
    <div className="pass-auth-wrapper">
      <div className="auth-container">
        <div className="authentication_page">
          <div className="auth-bg-wrapper">
            <div className="auth-bg-section reset-password-bg">
              <img src={logo} alt="logo" className="logo" />
              <div className="logo-text">
                <h2 className="logo-heading">Welcome to One Counsel</h2>
                <span className="logo-sub-heading">
                  360-degree insights in to legal operations & business transactions
                </span>
              </div>
            </div>
          </div>
          <div className="auth-form-section">
            <div className="auth-breadcrumbs">
              <div className="inactive-breadcrumb flex justify-center items-center">
                <img src={forgot} alt="forgot" />
                {STRINGS.FORGOT_PASSWORD}
              </div>
              <NavigateNextIcon fontSize="small" color="inherit" className="icon-arrow" />
              <div className="active-breadcrumb flex justify-center items-center">
                <img src={resetScreen} alt="resetScreen" />
                {STRINGS.CREATE_PASSWORD}
              </div>
            </div>
            <h3 className="auth-title">{STRINGS.CREATE_PASSWORD}</h3>
            <p className="pg-content">{STRINGS.RESET_PWD_SUBTITLE} </p>
            <div className="auth-form">
              <ResetPasswordForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
