/* eslint-disable */
import { GET_KPI_BOX_DATA, ACTIVE_KPI_BOX, LOGOUT_USER } from "../actions";

const initialState = {
  kpiBoxes: [],
  activeKpiBox: { id: 0, data: { tableData: [], chartData: [] } },
};

const DashboardReducer = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case GET_KPI_BOX_DATA: {
      return { ...state, kpiBoxes: action.data };
    }
    case ACTIVE_KPI_BOX: {
      return { ...state, activeKpiBox: action.data };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default DashboardReducer;
