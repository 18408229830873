import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// ASSETS
//import { AiOutlineBell } from "react-icons/ai";

// REDUX
import { RootState } from "redux/store";
import { userLogout } from "redux/actions";

// HELPERS
import { clearFromLocalStoarge, IMAGES, ROUTE_CONSTANTS, API_ROUTES, postRequest, showToast } from "helpers";

// COMPONENTS
import Tooltip from "components/ToolTip";
import BellNotifications from "./BellNotifications";

const UserMenu = (p: { name: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // SELECTORS
  const userData = useSelector((state: RootState) => state.common.userData) || {};
  const tokens = useSelector((state: RootState) => state.common.userTokens) || {};

  // STATES
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  // FUNCTIONS
  const logout = async () => {
    try {
      const { AccessToken, RefreshToken } = tokens;
      await postRequest(API_ROUTES.LOGOUT, true, {
        accessToken: AccessToken,
        refreshToken: RefreshToken,
      });
    } catch (err) {
      // console.log(err);
      // console.log(err);
    } finally {
      showToast("Logout Successfully", true);
      dispatch(userLogout());
      clearFromLocalStoarge();
      navigate(ROUTE_CONSTANTS.LOGIN);
    }
  };
  const editProfileHandler = () => {
    navigate(ROUTE_CONSTANTS.EDIT_PROFILE);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="user-section">
      <Tooltip title={p.name} arrow={true}>
        <Button
          id="basic-button"
          className="user-menu"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <img src={userData?.profile_img || IMAGES.Avtar} width={30} height={30} alt="profile" className="rounded-full" />
          <span className="online-icon" />
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={editProfileHandler}>Profile</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

const Header = (p: {
  title: string | React.ReactElement;
  sideText?: string | React.ReactElement;
  titleLogo?: any;
}) => {
  const userData = useSelector((state: RootState) => state.common.userData) || {};

  return (
    <>
      <div className="dashboard-header">
        <div className="dashboard-other-contaner">
          <div className=" text-xl flex gap-4 items-center">
            {p.titleLogo && <span className="text-primary-500 text-2xl">{p.titleLogo}</span>}
            <span className="flex">{p.title}</span>
          </div>

          <div className="header-user-container">
            {p.sideText ? <span>{p.sideText}</span> : null}

            <BellNotifications />

            <div className="user-name-wrapper">
              <span className="user-name-text" title={userData?.given_name || ""}>
                {userData?.given_name}
              </span>
              {/* <span className="user-name-constent-text">{"User"}</span> */}
              <span className="user-name-constent-text">
                {userData?.persona ? userData?.persona : userData?.role ? userData?.role : "User"}
              </span>
            </div>

            <UserMenu name={`${userData?.first_name} ${userData?.last_name}` || "user"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
