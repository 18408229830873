import { FormControl, InputLabel, OutlinedInput, TextField, FormHelperText } from "@mui/material";

import "./style.scss";

interface otherProps {
  label: string;
  readOnly?: boolean;
  onAction?: Function;
  onActionBlur?: Function;
  highlightText?: string;
  showRiskWarning?: boolean;
  id?: string;
  isRequired?: boolean;
  innerClassName?: string;

  //
  onCustomClick?: Function;
  onCustomActionBlur?: Function;
  onChange?: Function;
  onBlur?: Function;
  endAdornment?: any;
  className?: string;
  color?: "primary" | "secondary";
  name?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  autoComplete?: string;
  multiline?: any;
  maxRows?: number;
}

const PlainInput = (p: otherProps) => {
  return (
    <div className="form-group">
      <FormControl variant="outlined" className={p.className} color={p.color || "primary"}>
        <InputLabel
          htmlFor={p?.id || `${p.name}-id`}
          style={
            {
              // color: "#929cac",
              // marginTop: "-2px",
            }
          }
        // shrink
        >
          {p.label}
        </InputLabel>
        <OutlinedInput
          {...p}
          placeholder={p.placeholder}
          className={p.innerClassName}
          name={p.name}
          id={p?.id || `${p.name}-id`}
          label={p.label}
          inputProps={{
            readOnly: p.readOnly || false,
            style: {
              // padding: "14px !important",
            },
          }}
          endAdornment={p.endAdornment}
        // notched
        />
        {/* <span style={{ height: "1rem" }} className={`${p?.onCustomActionBlur ? "hidden" : ""}`}>
          {meta.touched && meta.error ? (
            <FormHelperText error={meta.touched && meta.error ? true : false} className="-mt-0.5">
              {meta.error}
            </FormHelperText>
          ) : (
            p.showRiskWarning && (
              <>
                <ShowRisk name={p.name} value={p.value} />
              </>
            )
          )}
        </span> */}
      </FormControl>
    </div>
  );
};

export default PlainInput;
