import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import FileViewer from "react-file-viewer";

//
import "./style.scss";

interface previewProps {
  closePopup: any;
  openDoc: Function;
}

const PreviewAgreement = (p: previewProps) => {
  const { closePopup, openDoc } = p;
  const [url, setUrl] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const file = sessionStorage.getItem("previewFile");
    const fileName = sessionStorage.getItem("tempFileName");
    if (file) {
      setUrl(file);
    }
    if (fileName) {
      setFileName(fileName);
    }
  }, []);

  const handleOnClose = () => {
    closePopup("close");
  };

  const handleSubmit = () => {
    openDoc();
    closePopup("submit");
  };

  return (
    <div className="preview-agreement-container">
      <button className="preview-cross-wrapper" title="Close" onClick={() => handleOnClose()}>
        <AiOutlineClose className="close" />
      </button>
      <span className="preview-title">
        <span className="title">Preview</span>
        <span className="sub-title">See preview before import the agreement</span>
      </span>
      <>
        {url && (
          <div className="fileViewWrapper">
            <div className="heading">{fileName}</div>
            <FileViewer
              fileType="docx"
              filePath={url}
              errorComponent={<div> Error in File Redering</div>}
              onError={() => console.log("Error in File Rendering ....")}
            />
          </div>
        )}
        <div className="preview-footer">
          <button className="cancel" onClick={handleOnClose}>
            Cancel
          </button>
          <button className="import" onClick={handleSubmit}>
            Import
          </button>
        </div>
      </>
    </div>
  );
};

export default PreviewAgreement;
