import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { ROUTE_CONSTANTS } from "../helpers";
import {
  SignInScreen,
  SignUpScreen,
  ForgotPasswordScreen,
  ResetPasswordScreen,
  CreatePasswordGuestScreen,
  HomePage,
  GuestLoginScreen,
} from "../views/public";

const AuthRouter = () => {
  return (
    <>
      <Routes>
        <Route path={ROUTE_CONSTANTS.LOGIN} element={<SignInScreen />} />
        <Route path={ROUTE_CONSTANTS.SIGN_UP} element={<SignUpScreen />} />
        <Route path={ROUTE_CONSTANTS.FORGOT_PASSWORD} element={<ForgotPasswordScreen />} />
        <Route path={ROUTE_CONSTANTS.RESET_PASSWORD} element={<ResetPasswordScreen />} />
        <Route path={ROUTE_CONSTANTS.HOME_PAGE} element={<HomePage />} />
        <Route
          path={`${ROUTE_CONSTANTS.Create_Password_Guest}/:email`}
          element={<CreatePasswordGuestScreen />}
        />
        <Route path={`${ROUTE_CONSTANTS.Guest_LOGIN}/:email`} element={<GuestLoginScreen />} />
        <Route path="*" element={<Navigate to={ROUTE_CONSTANTS.ROOT} />} />
      </Routes>
    </>
  );
};

export default AuthRouter;
