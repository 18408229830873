import { useState } from "react";
import { TextInput, DateInput } from "../../../../../components/FormFields";
import CollapseMode from "./collapse";

//import { asgreementProps } from "../../../../../helpers";

interface props {
  data: any;
  values: any;
  errors: any;
  hasFeildReadonly: Function;
  handleField: Function;
}

const Type2Form = (p: props) => {
  const [isExpandAll, setIsExpandAll] = useState(true);
  return (
    <>
      <div className="new-agreement-form-container">
        <span className="enterFieldText">Enter the fields to see the inputs on the left</span>
        <span className="collapseAllText" onClick={() => setIsExpandAll(!isExpandAll)}>
          {isExpandAll ? "Collapse All" : "Expand All"}
        </span>
        <CollapseMode
          title="Name of Contract"
          forceOpen={isExpandAll}
          children={
            <TextInput
              name={"contract_name"}
              type="text"
              label="File Name"
              placeholder={"File Name"}
              // className={"agreement-text-field"}
              id={"fileName"}
              readOnly={p.hasFeildReadonly({
                status: p?.data?.agreementStatus,
                forAll: true,
              })}
            />
          }
        />
        <CollapseMode
          title="General"
          forceOpen={isExpandAll}
          children={
            <>
              <DateInput
                name={"agreementDate"}
                label="Effective Date"
                placeholder={"Effective Date"}
                // className={"agreement-text-field"}
                className="my-1"
                maxDate={p.values.expiryDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"expiryDate"}
                type="date"
                label="Expiry Date"
                placeholder={"Expiry Date"}
                // className={"agreement-text-field"}
                className="my-1"
                minDate={p.values.agreementDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                showRiskWarning={true}
              />

              <TextInput
                name={"firstPartyName"}
                type="text"
                label="First Party Name"
                placeholder={"First Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyAddress"}
                type="text"
                label="First Party Address"
                placeholder={"First Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyName"}
                type="text"
                label="Second Party Name"
                placeholder={"Second Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyAddress"}
                type="text"
                label="Second Party Address"
                placeholder={"Second Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstyPartyPan"}
                type="text"
                label="First Party Pan"
                placeholder={"First Party Pan"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyFatherName"}
                type="text"
                label="First Party Father Name"
                placeholder={"First Party Father Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyAge"}
                type="text"
                label="First Party Age"
                placeholder={"First Party Age"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"premisesB1"}
                type="text"
                label="Name of premises"
                placeholder={"Name of premises"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"premisesB2"}
                type="text"
                label="Area pr. Sq ft"
                placeholder={"Area pr. Sq ft"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"premisesB3"}
                type="text"
                label="Include Building details"
                placeholder={"Include Building details"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"premisesB4"}
                type="text"
                label="Include Enter location"
                placeholder={"Include Enter location"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"termOfAgreement"}
                type="text"
                label="Term of license"
                placeholder={"Term of license"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                showRiskWarning={true}
              />

              <TextInput
                name={"licenseFeeRent"}
                type="text"
                label="License Fee Rent"
                placeholder={"License Fee Rent"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                showRiskWarning={true}
              />

              <TextInput
                name={"lockInPeriod"}
                type="text"
                label="Lock In Period"
                placeholder={"Lock In Period"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
              <TextInput
                name={"licenseFeeMonths"}
                type="text"
                label="License Fee Months"
                placeholder={"License Fee Months"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"licenseFeePercent"}
                type="text"
                label="License Fee Percent"
                placeholder={"License Fee Percent"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
              <TextInput
                name={"period"}
                type="text"
                label="Period"
                placeholder={"Period"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"monthlyLicenseFee"}
                type="text"
                label="Monthly License Fee (in Rupees)"
                placeholder={"Monthly License Fee"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
              <TextInput
                name={"terminationNotice"}
                type="text"
                label="Termination Notice"
                placeholder={"Termination Notice"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                showRiskWarning={true}
              />

              <TextInput
                name={"compensationAmount"}
                type="text"
                label="Compensation Amount"
                placeholder={"Compensation Amount"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
              <TextInput
                name={"licensor"}
                type="text"
                label="The Licensor"
                placeholder={"The Licensor"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"licensee"}
                type="text"
                label="The Licensee"
                placeholder={"The Licensee"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"securityDepositAmount"}
                type="text"
                label="Security Deposit Amount"
                placeholder={"Security Deposit Amount"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"jurisdiction"}
                type="text"
                label="Jurisdiction"
                placeholder={"Jurisdiction"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
            </>
          }
        />
      </div>
    </>
  );
};

export default Type2Form;
