import React, { useState, useEffect } from "react";
import { AiFillSignal } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import DashboardLayout from "layouts/dashboardLayout";
import TableJs from "components/Table/table";
import {
  API_ROUTES,
  postRequest,
  showToast,
  RESPONSE_MSGS,
  STATUS_CODE,
  ROUTE_CONSTANTS,
  NEW_AGREEMENT_STATUS,
} from "helpers";
import { LoaderHOC } from "components";
import { setRiskList } from "redux/actions";

import { forceLogout } from "redux/services";
//
import "./style.scss";

const RiskAssessment: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  // const [filterTableData , setFilterTableData]= useState();
  const navigate = useNavigate();
  const userData = useSelector((state: any) => state.common.userData) || {};
  const myTaskData = useSelector((state: any) => state.riskAgreement.agreements) || [];
  // useEffect(() => {
  const tableData = myTaskData.filter(function (data: any) {
    if (data.agreementStatus === NEW_AGREEMENT_STATUS.approverPending) {
      return data;
    }
    return null;
  });
  // }, [tableData])

  // console.log(filterTableData , 'DATA2')

  const fetchData = async (isMounted: boolean) => {
    try {
      setLoading(true);
      const res = await postRequest(API_ROUTES.MY_TASKS, true, {
        customerName: userData.email,
      });
      const { status }: any = res || {};
      const { data }: any = res;
      await forceLogout({ status: data?.status_code, message: data?.message });

      if (status === STATUS_CODE.SUCCESS && typeof data !== "string") {
        //setTableData(data);
        if (isMounted) dispatch(setRiskList(data));
        // showToast(RESPONSE_MSGS.EXTRACTION_AGREEMENT_SUCCESS, true);
      } else if (status === STATUS_CODE.SUCCESS && typeof data === "string") {
        //setTableData(data);
        if (isMounted) dispatch(setRiskList([]));
      } else {
        showToast(data?.message || RESPONSE_MSGS.ERROR, false);
        if (isMounted) dispatch(setRiskList([]));
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      showToast(RESPONSE_MSGS.ERROR, false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      // fix Warning: Can't perform a React state update on an unmounted component
      await fetchData(isMounted);
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  const columns = [
    {
      Header: "Contract Name",
      accessor: "contract_name",
      Cell: (d: { cell: any; value: string }) => {
        const data = d.cell?.row?.original || {};
        const handleAgreementRoutes = () => {
          if (!data.Processing) {
            navigate(`${ROUTE_CONSTANTS.View_Risk_Agreement}/${data?.contract_name}`, {
              state: data,
            });
          }
        };
        return (
          <div
            onClick={handleAgreementRoutes}
            className={`Contract_name_in_repo ${data.Processing ? "disabled-Contract_name_in_repo" : ""
              }`}
          >
            {d.value}
          </div>
        );
      },
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Customer",
      accessor: "customerName",
    },
    {
      Header: "Effective Date",
      accessor: "agreementDate",
    },
    {
      Header: "Expiry Date",
      accessor: "expiryDate",
    },
    {
      Header: "Status",
      accessor: "agreementStatus",
      Cell: (d: { cell: any; value: string }) => {
        return (
          <div
            className={
              d?.value === NEW_AGREEMENT_STATUS["draft"] ||
                d?.value === NEW_AGREEMENT_STATUS["vendorReview"]
                ? "status-ongoing-blue"
                : d?.value === NEW_AGREEMENT_STATUS["review"] ||
                  d?.value === NEW_AGREEMENT_STATUS["approve"]
                  ? "status-ongoing"
                  : "status-success"
            }
          >
            {d?.value}
          </div>
        );
      },
    },
    {
      Header: "Value",
      accessor: "value",
    },
  ];

  return (
    <>
      <DashboardLayout
        hederTitle={"Risk Assessment"}
        headerLogo={<AiFillSignal />}
        childer={
          <>
            <div className="mytask-container">
              <div className="repo-table">
                <TableJs
                  sortBy={[{ id: "contract_name", desc: true }]}
                  columns={columns}
                  data={tableData}
                  isRowSelectEnable={false}
                  headerProps={{ isEnable: true, type: 1 }}
                  isRowsPerPageChangeRequired={false}
                  isCurrentRowsCountVisibilityRequired={false}
                />
                {isLoading ? (
                  <LoaderHOC></LoaderHOC>
                ) : tableData.length ? null : (
                  <div className="mt-3">No Data Found...</div>
                )}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default RiskAssessment;
