import { useState } from "react";
import { TextInput, DateInput } from "components/FormFields";
import CollapseMode from "./collapse";
interface props {
  data: any;
  values: any;
  errors: any;
  hasFeildReadonly: Function;
  handleField: Function;
}

const CommonForm = (p: props) => {
  const [isExpandAll, setIsExpandAll] = useState(true);
  return (
    <>
      <div className="new-agreement-form-container">
        <span className="enterFieldText">Enter the fields to see the inputs on the left</span>
        <span className="collapseAllText" onClick={() => setIsExpandAll(!isExpandAll)}>
          {isExpandAll ? "Collapse All" : "Expand All"}
        </span>
        <CollapseMode
          title="Name of Contract"
          forceOpen={isExpandAll}
          children={
            <TextInput
              name={"contract_name"}
              type="text"
              label="File Name"
              placeholder={"File Name"}
              // className={"agreement-text-field"}
              id={"fileName"}
              readOnly={p.hasFeildReadonly({
                status: p?.data?.agreementStatus,
                forAll: true,
              })}
            />
          }
        />
        <CollapseMode
          title="General Information"
          forceOpen={isExpandAll}
          children={
            <>
              <DateInput
                name={"agreementDate"}
                label="Effective Date"
                placeholder={"Effective Date"}
                // className={"agreement-text-field"}
                className="my-1"
                maxDate={p.values.expiryDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
              <DateInput
                name={"expiryDate"}
                type="date"
                label="Expiry Date"
                placeholder={"Expiry Date"}
                // className={"agreement-text-field"}
                className="my-1"
                minDate={p.values.agreementDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                showRiskWarning={true}
              />

              <TextInput
                name={"firstPartyName"}
                type="text"
                label="First Party Name"
                placeholder={"First Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyAddress"}
                type="text"
                label="First Party Address"
                placeholder={"First Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyName"}
                type="text"
                label="Second Party Name"
                placeholder={"Second Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyAddress"}
                type="text"
                label="Second Party Address"
                placeholder={"Second Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"termOfAgreement"}
                type="text"
                label="Term of Agreement"
                placeholder={"Term of Agreement"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
            </>
          }
        />
        <CollapseMode
          title="Business Information"
          forceOpen={isExpandAll}
          children={
            <>
              <TextInput
                name={"product"}
                type="text"
                label="Product"
                placeholder={"Product"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"qualityRequired"}
                type="text"
                label="Quality Required"
                placeholder={"Quality Required"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
              <TextInput
                name={"deliveryLocationAddress"}
                type="text"
                label="Delivery Location Addresss"
                placeholder={"Delivery Location Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
              <TextInput
                name={"price"}
                type="text"
                label="Price"
                placeholder={"Price"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                showRiskWarning={true}
              />

              <TextInput
                name={"incoterms"}
                type="text"
                label="FoB, CIF, incoterms"
                placeholder={"FoB, CIF, incoterms"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
            </>
          }
        />
        <CollapseMode
          title="Commercials"
          forceOpen={isExpandAll}
          children={
            <>
              <TextInput
                name={"productTimeline"}
                type="text"
                label="Product timeline"
                placeholder={"Product timeline"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"penalty"}
                type="text"
                label="Penalty"
                placeholder={"Penalty"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
            </>
          }
        />
      </div>
    </>
  );
};

export default CommonForm;
