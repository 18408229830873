import React, { useState } from "react";
import { MdAdjust } from "react-icons/md";
import { useSelector } from "react-redux";

import { STRINGS, IMAGES } from "helpers";
import SidebarBody from "./sidebarBody";
import { setDataInLocalStorage, getDataFromLocalStorage } from "helpers/utils";

//
import "./style.scss";
import { RootState } from "redux/store";

const SideBarContent = (p: {
  className: string;
  logo: string;
  handleSidebar: any;
  isSidebarOpen: boolean;
}) => {
  return (
    <>
      <div
        className={`sidebar-container ${p.className}`}
        onClick={(e) => {
          e.stopPropagation();
          p.handleSidebar();
        }}
      >
        <div className={`sidebar-logo-contaner ${p.className}`}>
          <img
            className={`sidebar-logo ${p.className}`}
            src={p.logo}
            alt={`${STRINGS.NEUTRON} Logo`}
            onClick={(e) => {
              e.stopPropagation();
              p.handleSidebar();
            }}
          />
          {p.isSidebarOpen && (
            <MdAdjust
              className="sidebar-close-icon"
              onClick={(e) => {
                e.stopPropagation();
                p.handleSidebar();
              }}
            />
          )}
        </div>

        <SidebarBody isSidebarOpen={p.isSidebarOpen} />
      </div>
    </>
  );
};

const SideBar: React.FC = () => {
  // get sidebar enable value from local storage
  let isSidebarEnable = getDataFromLocalStorage("isSidebarEnable");
  if (typeof isSidebarEnable !== "boolean") isSidebarEnable = false;
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(isSidebarEnable || false);

  const logoUrl = useSelector((state: RootState) => state.common.userData.client_logo);

  //
  const className = isSidebarOpen ? "open" : "";
  const logo = !isSidebarOpen ? logoUrl?.verticle : logoUrl?.horizontal;

  const handleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    setDataInLocalStorage("isSidebarEnable", !isSidebarOpen);
  };

  if (isSidebarOpen) {
    return (
      <>
        <div className={"sidebar-backdrop"} />
        <div className={"sidebar-backdrop-wrapper"} onClick={handleSidebar}>
          <div onClick={(e) => e.stopPropagation()}>
            <SideBarContent
              logo={logo}
              className={className}
              handleSidebar={handleSidebar}
              isSidebarOpen={isSidebarOpen}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <SideBarContent
      logo={logo}
      className={className}
      handleSidebar={handleSidebar}
      isSidebarOpen={isSidebarOpen}
    />
  );
};

export default SideBar;
