import React from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";

import "./style.scss";

interface otherProps {
  label: string;
  readOnly?: boolean;
  onAction?: Function;
  onActionBlur?: Function;
  highlightText?: string;
  showRiskWarning?: boolean;
  id?: string;
  isRequired?: boolean;
  isLabelShown?: boolean;
  isCustomInput?: boolean;
}

const getTerminationRisk = (val: number) => {
  if (val > parseInt(process.env.REACT_APP_LLA_Termination_NOTICE_AGREEMENT_RISK_2 || "5")) {
    return 5;
  } else if (val <= parseInt(process.env.REACT_APP_LLA_Termination_NOTICE_AGREEMENT_RISK || "2")) {
    return 2;
  } else {
    return null;
  }
};

const ShowRisk = (p: { name: string; value: string }) => {
  const val = parseInt(p.value || "");

  if (
    p.name === "price" &&
    parseInt((p.value || "").replaceAll(",", "")) >
      parseInt((process.env.REACT_APP_VALUE_TO_HIT_AGREEMENT_RISK || "2000").replaceAll(",", ""))
  ) {
    return (
      <div className="field-error risk-warning">
        {`Risk: Price is more than Rs. ${
          process.env.REACT_APP_VALUE_TO_HIT_AGREEMENT_RISK || "2000"
        }`}
      </div>
    );
  } else if (
    p.name === "termOfAgreement" &&
    val > parseInt(process.env.REACT_APP_LLA_TERM_AGREEMENT_RISK || "5")
  ) {
    return (
      <div className="field-error risk-warning-red">
        {`Risk: Term is beyond ${process.env.REACT_APP_LLA_TERM_AGREEMENT_RISK || "5"} years`}
      </div>
    );
  } else if (
    p.name === "licenseFeeRent" &&
    val > parseInt(process.env.REACT_APP_LLA_RISK_AGREEMENT_RISK || "5")
  ) {
    return (
      <div className="field-error risk-warning-red">
        {`Risk: Rent Escalation is beyond ${
          process.env.REACT_APP_LLA_RISK_AGREEMENT_RISK || "5"
        } %`}
      </div>
    );
  } else if (p.name === "terminationNotice" && getTerminationRisk(val)) {
    const terminRisk = getTerminationRisk(val);
    return terminRisk === 2 ? (
      <div className="field-error risk-warning">
        {`Risk: Termination notice is between ${
          process.env.REACT_APP_LLA_Termination_NOTICE_AGREEMENT_RISK || "1-2"
        } months`}
      </div>
    ) : (
      <div className="field-error risk-warning-red">
        {`Risk: Termination notice is beyond ${
          process.env.REACT_APP_LLA_Termination_NOTICE_AGREEMENT_RISK_2 || "5"
        } months`}
      </div>
    );
  }

  return null;
};

const Textinput = (p: otherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(p);
  const { setFieldValue } = useFormikContext();

  const handleOnAction = () => {
    if (p?.onAction) {
      let value = field.value;
      if (p.highlightText) {
        value = p.highlightText;
      }
      p.onAction(value);
    }
  };

  const handleOnBlur = () => {
    if (p?.onActionBlur) {
      if (field.name == "price") {
        const updatedPriceFormat: any =
          field.name === "price"
            ? field.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : field.value;
        p.onActionBlur({ value: updatedPriceFormat, name: field.name });
        setFieldValue(field.name, updatedPriceFormat);
      } else {
        const name = field.name;
        p.onActionBlur({ value: field.value, name });
      }
    }
  };

  return (
    <div
      className={
        p.isCustomInput === undefined || p.isCustomInput === false
          ? "form-group"
          : "custom-input-form-group"
      }
    >
      <>
        {p.isLabelShown === undefined || p.isLabelShown === true ? <label>{p.label}</label> : null}
        <input
          {...field}
          className={p.className}
          id={p?.id || ""}
          placeholder={p.placeholder}
          type={p.type}
          readOnly={p.readOnly || false}
          onClick={handleOnAction}
          onBlur={handleOnBlur}
          required={p.isRequired || false}
        />
      </>
      {meta.touched && meta.error ? (
        <div className="field-error" title={meta?.error}>
          {meta.error}
        </div>
      ) : (
        p.showRiskWarning && (
          <>
            <ShowRisk name={field.name} value={field.value} />
          </>
        )
      )}
    </div>
  );
};

export default Textinput;
