import { useState } from "react";
import { TextInput, DateInput } from "../../../../../components/FormFields";
import CollapseMode from "./collapse";

interface props {
  data: any;
  values: any;
  errors: any;
  hasFeildReadonly: Function;
  handleField: Function;
}

const CommonForm = (p: props) => {
  const [isExpandAll, setIsExpandAll] = useState(true);
  return (
    <>
      <div className="new-agreement-form-container">
        <span className="enterFieldText">Enter the fields to see the inputs on the left</span>
        <span className="collapseAllText" onClick={() => setIsExpandAll(!isExpandAll)}>
          {isExpandAll ? "Collapse All" : "Expand All"}
        </span>
        <CollapseMode
          title="Name of Contract"
          forceOpen={isExpandAll}
          children={
            <TextInput
              name={"contract_name"}
              type="text"
              label="File Name"
              placeholder={"File Name"}
              // className={"agreement-text-field"}
              id={"fileName"}
              readOnly={p.hasFeildReadonly({
                status: p?.data?.agreementStatus,
                forAll: true,
              })}
            />
          }
        />
        <CollapseMode
          title="General Information"
          forceOpen={isExpandAll}
          children={
            <>
              <DateInput
                name={"agreementDate"}
                label="Effective Date"
                placeholder={"Effective Date"}
                // className={"agreement-text-field"}
                className="my-1"
                maxDate={p.values?.expiryDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"expiryDate"}
                type="date"
                label="Expiry Date"
                placeholder={"Expiry Date"}
                // className={"agreement-text-field"}
                className="my-1"
                minDate={p.values?.agreementDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                showRiskWarning={true}
              />

              <TextInput
                name={"firstPartyName"}
                type="text"
                label="First Party Name"
                placeholder={"First Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyAddress"}
                type="text"
                label="First Party Address"
                placeholder={"First Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyName"}
                type="text"
                label="Second Party Name"
                placeholder={"Second Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyAddress"}
                type="text"
                label="Second Party Address"
                placeholder={"Second Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"termOfAgreement"}
                type="text"
                label="Term of Agreement"
                placeholder={"Term of Agreement"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"propertyDetails"}
                type="text"
                label="Property Details"
                placeholder={"Property Details"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"monthlyRent"}
                type="text"
                label="Monthly Rent"
                placeholder={"Monthly Rent"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"duration"}
                type="text"
                label="Duration"
                placeholder={"Duration"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"negativeCovenant"}
                type="text"
                label="Negative Covenant"
                placeholder={"Negative Covenant"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"securityDepositAmount"}
                type="text"
                label="Security Deposit Amount"
                placeholder={"Security Deposit Amount"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"anyLockIn"}
                type="text"
                label="Any Lock-in"
                placeholder={"Any Lock-in"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"jurisdiction"}
                type="text"
                label="Jurisdiction"
                placeholder={"Jurisdiction"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
            </>
          }
        />
      </div>
    </>
  );
};

export default CommonForm;
