import { FC, useEffect, useState } from 'react'
import { TextField, } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import "./styles.scss"

// HELPER
import { API_ROUTES, postRequest } from 'helpers';

// ASSETS
import { FiSearch } from "react-icons/fi";
import { AiOutlineClose } from 'react-icons/ai';
import { GrDropbox } from "react-icons/gr";

// TYPES
import { IProps } from "./types"

// COMPONENTS
import AvatarCard from "./AvatarCard"
import { LoaderHOC } from 'components';

const DocSharedWith: FC<IProps> = ({ onCloseModal }) => {

    // STATES
    const [isFetching, setIsFetching] = useState(false);
    const [users, setUsers] = useState<any>([]);
    const [searchInput, setSearchInput] = useState("");

    // SELECTORS
    const loginUserDetails = useSelector((state: RootState) => state.common?.userData) || {};

    // FUNCTIONS
    const fetchUsers = async () => {
        try {
            setIsFetching(() => true);
            const postData = { email: loginUserDetails?.email };
            const response: any = await postRequest(API_ROUTES.SHARE_WITH, true, postData);
            setUsers(response.data || []);
        } catch (err) {
            console.log(err);
        } finally {
            setIsFetching(() => false);
        }
    }
    const onClose = () => {
        onCloseModal();
    }
    const searchUserHandler = (value: string) => {
        setSearchInput(value);
    }
    const filterSearchingUsers = (data) => {
        const filteredData = data.filter(item => item?.first_name.toLowerCase().includes(searchInput.toLowerCase())
            || item?.last_name.toLowerCase().includes(searchInput.toLowerCase())
            || item?.username.toLowerCase().includes(searchInput.toLowerCase()));

        return filteredData
    }

    // EFFECTS
    useEffect(() => {
        fetchUsers();
    }, [])

    return (
        <>
            {
                isFetching
                    ?
                    <div className='w-full h-[400px] flex justify-center items-center'>
                        <LoaderHOC />
                    </div>
                    :
                    <div className='w-full h-full flex flex-col justify-center items-center'>
                        <div className='w-[95%]  h-[10%] flex justify-between items-center mt-2'>
                            <div className='w-full h-full  flex justify-start items-center'>
                                <p className='text-sm font-bold text-[#560BAD]'>Document Shared With</p>
                            </div>
                            <div>
                                <AiOutlineClose color="#560BAD" className="close cursor-pointer" onClick={onClose} />
                            </div>
                        </div>
                        <div className='w-[95%] no-scrollbar overflow-y-auto'>
                            <div className='w-full h-[90%]'>
                                <div className="w-full h-[15%] mb-2 mt-2 flex flex-col items-center">
                                    <TextField
                                        sx={{
                                            border: 'none', "& fieldset": {
                                                border: 'none', margin: "0px !important",
                                            },
                                            "& input::placeholder": {
                                                fontSize: "12px",
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: <FiSearch size={22} />,
                                            sx: {
                                                height: "35px",
                                            }
                                        }}
                                        label=""
                                        variant="outlined"
                                        className='w-full'
                                        placeholder='You can find people in your organization.'
                                        value={searchInput}
                                        onChange={(e) => searchUserHandler(e.target.value)}
                                    />
                                </div>

                                <div className='no-scrollbar overflow-scroll bg-[#F4ECFD] rounded-lg w-full h-[18rem] p-2'>
                                    {
                                        filterSearchingUsers(users)?.length <= 0 &&
                                        <div className='w-full h-full flex flex-col justify-center items-center'>
                                            <GrDropbox size={68} color="#D6BEF2" />
                                            <p className='text-[#BE94EE] mt-1 text-sm'>No Data</p>
                                        </div>
                                    }
                                    <div className='w-full h-auto grid grid-cols-4 place-content-center place-items-center gap-x-4 gap-y-4'>
                                        {
                                            filterSearchingUsers(users).map(
                                                (item: any) => <AvatarCard
                                                    key={item?.client_id}
                                                    userId={item?.user_id}
                                                    userImage={item?.profile_img}
                                                    userName={`${item?.first_name} ${item?.last_name}`}
                                                    userEmail={item?.email}
                                                    isSelected={false}
                                                    onClick={() => { }}
                                                />)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default DocSharedWith