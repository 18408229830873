import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { HiPresentationChartBar } from "react-icons/hi";
import { FiFilter } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  BarElement,
} from "chart.js";
import {
  Bar,
  Doughnut,
  // Line,
  Pie,
} from "react-chartjs-2";
// import { PieChart } from '@mui/x-charts/PieChart';
import { Switch } from "@material-ui/core";
// import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
// // import InfoIcon from '@material-ui/icons/InfoOutlined';
// // import Box from '@mui/material/Box';

//
// import LineChart from "./lineChart";
import PieChart from "./pieChart";
// import ColumnChart from "./ColumnChart";
// import ColumnChart2 from "./ColumnChart2";
// import BarChart from "./barChart";
// import LineChart2 from "./lineChart2";
import { ImportedDocuments, UploadAgreementForm, UploadByotFormNew } from "customComponents";
import DashboardLayout from "layouts/dashboardLayout";
import NewAgreementList from "../agreement/newAgreementList";
// import { columns, columns1, columnsId2, columnsId4, columnsId5, columnsId6 } from "./tableTypes";
// import { saveKpiBoxData, activeKpiBox } from "../../../redux/actions";
import { getAgreementData } from "redux/actions";
import ReactSimpleTooltip from "react-simple-tooltip";

import {
  API_ROUTES,
  getRequest,
  //   postRequest,
  showLogInConsole,
  // STATUS_CODE,
  // showToast,
  // RESPONSE_MSGS,
  IMPORT_TYPES,
  fetchAgreementList,
  // AGREEMENT_IN_REPO_STATUS,
  NEW_AGREEMENT_STATUS,
  ROUTE_CONSTANTS,
  getClassName,
  showToast,
} from "helpers";
// import { forceLogout } from "../../../redux/services";

import "./style.scss";

// // import {
// //   DataGrid,

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material/";

import FileViewer from "react-file-viewer";
// import FilterListIcon from '@material-ui/icons/FilterList';

// import config from "../../../config";
// import { store } from "../../../redux/store";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Filler,
);
import { MenuItem } from "@mui/material";

import TableJs from "components/Table/table";
import { Button, LoaderHOC } from "components";
import { IconContext } from "react-icons/lib";
import { renderContractNameForOpenAgreements } from "../myTasks";
// import NewAgreementList from "../agreement/newAgreementList";
// import { DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";
// import { DataGrid } from "@mui/x-data-grid";

//

import { SquareSkeletonCard } from "customComponents/Skeletons/Dashboard";
import { RootState } from "redux/store";
import { SelectInput } from "components";

//

const Dashboad: React.FC = () => {
  const dispatch = useDispatch();
  const agreementData = useSelector((state: any) => state.admin?.agreementData) || [];
  const [loading, setLoading] = useState(true);
  // const kpiBoxes = useSelector((state: any) => state.dashboard?.kpiBoxes) || [];
  // const activeKpiBoxes = useSelector((state: any) => state.dashboard?.activeKpiBox) || {};
  const userData = useSelector((state: RootState) => state.common?.userData) || {};
  const commonInfo = useSelector((state: RootState) => state.common) || {};
  const navigate = useNavigate();
  // const [allTasks, setAllTasks] = React.useState<any>([]);
  // const [rows, setRows] = React.useState<any>([]);
  const [activeBox, setActiveBox] = React.useState("All Agreements");
  const userRole = userData?.persona ? userData?.persona : userData?.role ? userData?.role : "";
  //   const [activeLicense, setActiveLicense] = React.useState('Licenses');

  //   const [licences, setLicences] = useState<any>([]);
  //   const expiredLicense: any[] = [];

  // const historicAgreements: any[] = [];
  // const newAgreements: any[] = [];

  //   const [pieOptions, setPieOptions] = React.useState<any>({});
  const [isOpen, setisOpen] = React.useState<boolean>(false);
  const [historicAgreements, setHistoricAgreements] = React.useState<any>([]);
  const [newAgreements, setNewAgreements] = React.useState<any>([]);
  const [msaCategory, setMsaCategory] = React.useState<any>([]);
  const [cdaCategory, setcdaCategory] = React.useState<any>([]);
  const [ndaCategory, setndaCategory] = React.useState<any>([]);
  const [leaseCategory, setleaseCategory] = React.useState<any>([]);
  const [rentalCategory, setrentalCategory] = React.useState<any>([]);
  const [draft, setDraft] = React.useState<any>([]);
  const [approve, setApprove] = React.useState<any>([]);
  const [approverPending, setApproverPending] = React.useState<any>([]);
  const [executed, setExecuted] = React.useState<any>([]);
  const [review, setReview] = React.useState<any>([]);
  const [reviewRejected, setReviewRejected] = React.useState<any>([]);
  const [validate, setValidate] = React.useState<any>([]);
  const [backdated, setBackdated] = React.useState<any>([]);
  const [expThirty, setExpThirty] = React.useState<any>([]);
  const [expSixty, setExpSixty] = React.useState<any>([]);
  const [expNinty, setExpNinty] = React.useState<any>([]);

  const [isAgreement, setIsAgreement] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [activeCategory, setActiveCategory] = React.useState(false);
  const [category, setCategory] = React.useState("all");
  const [agreementType, setType] = React.useState("all");
  const [status, setStatus] = React.useState("all");

  // const [displayWarning, setDisplayWarning] = useState(true);
  const [isAgListLoading, setAgListLoading] = useState(true);
  const [agreementList, setAgreementList] = useState<any>([]);

  //
  const [categoryInfo, setCategoryInfo] = useState({});
  const [allAgreementCategory, setAllAgreementCategory] = useState<any>([]);
  const [fileInfo, setFileInfo] = useState({
    isShown: false,
    fileUrl: "",
    name: "",
    fileExtension: "",
  });

  //
  const [mainTableData, setMainTableData] = useState<any>([]);

  function setcBarheighte(e) {
    const array = [
      msaCategory.length,
      ndaCategory.length,
      cdaCategory.length,
      leaseCategory.length,
      rentalCategory.length,
    ];
    const b = 150;
    const a = (b * e) / Math.max(...array) + "px";
    return a;
  }
  function setcBartop(e) {
    const array = [
      msaCategory.length,
      ndaCategory.length,
      cdaCategory.length,
      leaseCategory.length,
      rentalCategory.length,
    ];
    const b = 150;
    const a = -((b * e) / Math.max(...array)) + "px";
    return a;
  }
  // const barWidth = 5;
  function setWidth(e) {
    const array = [expThirty.length, expSixty.length, expNinty.length];
    const b = 250;
    const a = (b * e) / Math.max(...array);
    return !a ? "5px" : a + "px";
  }
  // dashboard loading bug fixed by haresh (16-07-2023)
  function backDatedCheck(created, effected) {
    if (created && effected) {
      const createddateArray = created.split("/");
      const effectivedateArray = effected.split("/");
      const newCreatedDate =
        createddateArray[1] + "/" + createddateArray[0] + "/" + createddateArray[2];
      const newEffectiveDate =
        effectivedateArray[1] + "/" + effectivedateArray[0] + "/" + effectivedateArray[2];

      return new Date(newCreatedDate) > new Date(newEffectiveDate);
    }
  }
  // dashboard loading bug fixed by haresh (16-07-2023)
  function checkExpThirty(expiry) {
    const priorDate = new Date(new Date().setDate(new Date().getDate() + 30));
    if (expiry) {
      const expirydateArray = expiry.split("/");
      const newExpiryDate =
        expirydateArray[1] + "/" + expirydateArray[0] + "/" + expirydateArray[2];
      return new Date(newExpiryDate) > new Date() && new Date(newExpiryDate) < priorDate;
    }
  }
  // dashboard loading bug fixed by haresh (16-07-2023)
  function checkExpSixty(expiry) {
    const priorDate = new Date(new Date().setDate(new Date().getDate() + 60));
    if (expiry) {
      const expirydateArray = expiry.split("/");
      const newExpiryDate =
        expirydateArray[1] + "/" + expirydateArray[0] + "/" + expirydateArray[2];
      return (
        new Date(newExpiryDate) > new Date(new Date().setDate(new Date().getDate() + 30)) &&
        new Date(newExpiryDate) < priorDate
      );
    }
  }
  // dashboard loading bug fixed by haresh (16-07-2023)
  function checkExpNinty(expiry) {
    const priorDate = new Date(new Date().setDate(new Date().getDate() + 90));
    if (expiry) {
      const expirydateArray = expiry.split("/");
      const newExpiryDate =
        expirydateArray[1] + "/" + expirydateArray[0] + "/" + expirydateArray[2];
      return (
        new Date(newExpiryDate) > new Date(new Date().setDate(new Date().getDate() + 60)) &&
        new Date(newExpiryDate) < priorDate
      );
    }
  }

  // dashboard loading bug fixed by haresh (16-07-2023)
  const fetchDashBoardData = async () => {
    try {
      setLoading(true);
      const { data }: any = await getRequest(
        `${API_ROUTES.GET_DASHBOARD_DATA}?clientId=${userData.client_id}`,
        true,
      );
      //
      setMainTableData(data);
      //
      dispatch(getAgreementData(data));

      if (data.length > 0) {
        const tempOjectData: any = {
          historicAgreements: {
            data: [],
            hook: setHistoricAgreements,
          },
          newAgreements: {
            data: [],
            hook: setNewAgreements,
          },
          msaCategory: {
            data: [],
            hook: setMsaCategory,
          },
          cdaCategory: {
            data: [],
            hook: setcdaCategory,
          },
          ndaCategory: {
            data: [],
            hook: setndaCategory,
          },
          leaseCategory: {
            data: [],
            hook: setleaseCategory,
          },
          rentalCategory: {
            data: [],
            hook: setrentalCategory,
          },
          draft: {
            data: [],
            hook: setDraft,
          },
          approve: {
            data: [],
            hook: setApprove,
          },
          approverPending: {
            data: [],
            hook: setApproverPending,
          },
          executed: {
            data: [],
            hook: setExecuted,
          },
          review: {
            data: [],
            hook: setReview,
          },
          reviewRejected: {
            data: [],
            hook: setReviewRejected,
          },
          validate: {
            data: [],
            hook: setValidate,
          },
          backdated: {
            data: [],
            hook: setBackdated,
          },
          expThirty: {
            data: [],
            hook: setExpThirty,
          },
          expSixty: {
            data: [],
            hook: setExpSixty,
          },
          expNinty: {
            data: [],
            hook: setExpNinty,
          },
        };

        const colors = [
          "#caf0f8",
          "#00b4d8",
          "#560bad",
          "#b5179e",
          "#3a0ca3",
          "#f72585",
          "#3f37c9",
          "#4361ee",
          "#4895ef",
          "#4cc9f0",
        ];

        const allAgreementCategory: any = [];
        let colorIndex = 0;

        data.map((item: any) => {
          // categoryData[categoryData.findIndex((itemInner) => itemInner.name === item["Category"])]
          //   .total++;

          if (item.Category) {
            const currentItemIndex = allAgreementCategory.findIndex(
              (innerItem: any) => innerItem.name === item["Category"],
            );
            if (currentItemIndex !== -1) {
              //
              allAgreementCategory[currentItemIndex]["total"]++;
            } else {
              allAgreementCategory.push({
                name: item["Category"],
                total: 1,
                color: colors[colorIndex],
              });
              colorIndex++;
            }
          }

          if (item["Agreement type"] === "historic-agreement") {
            tempOjectData["historicAgreements"].data.push(item);
            // setHistoricAgreements((historicAgreements) => [...historicAgreements, item]);
          } else if (item["Agreement type"] === "new-agreement") {
            tempOjectData["newAgreements"].data.push(item);
            // setNewAgreements((newAgreements) => [...newAgreements, item]);
          }
          // if (item["Category"] === "MSA1") {
          //   tempOjectData["msaCategory"].data.push(item);
          //   // setMsaCategory((msaCategory) => [...msaCategory, item]);
          // } else if (item["Category"] === "MSA2") {
          //   tempOjectData["cdaCategory"].data.push(item);
          //   // setcdaCategory((cdaCategory) => [...cdaCategory, item]);
          // } else if (item["Category"] === "LLA Agreements") {
          //   tempOjectData["ndaCategory"].data.push(item);
          //   // setndaCategory((ndaCategory) => [...ndaCategory, item]);
          // } else if (
          //   item["Category"] === "Lease Agreement" ||
          //   item["Category"] === "lease Agreement"
          // ) {
          //   tempOjectData["leaseCategory"].data.push(item);
          //   // setleaseCategory((leaseCategory) => [...leaseCategory, item]);
          // } else if (item["Category"] === "PSA1") {
          //   tempOjectData["rentalCategory"].data.push(item);
          //   // setrentalCategory((rentalCategory) => [...rentalCategory, item]);
          // }
          if (item["Status"] === "Draft") {
            tempOjectData["draft"].data.push(item);
            // setDraft((draft) => [...draft, item]);
          } else if (item["Status"] === "Approve") {
            tempOjectData["approve"].data.push(item);
            // setApprove((approve) => [...approve, item]);
          } else if (item["Status"] === "Approver Pending") {
            tempOjectData["approverPending"].data.push(item);
            // setApproverPending((approverPending) => [...approverPending, item]);
          } else if (item["Status"] === "Executed") {
            tempOjectData["executed"].data.push(item);
            // setExecuted((executed) => [...executed, item]);
          } else if (item["Status"] === "Review") {
            tempOjectData["review"].data.push(item);
            // setReview((review) => [...review, item]);
          } else if (item["Status"] === "Review Rejected") {
            tempOjectData["reviewRejected"].data.push(item);
            // setReviewRejected((reviewRejected) => [...reviewRejected, item]);
          } else if (item["Status"] === "validate") {
            tempOjectData["validate"].data.push(item);
            // setValidate((validate) => [...validate, item]);
          }
          if (backDatedCheck(item["Created Date"], item["Effective Date"])) {
            tempOjectData["backdated"].data.push(item);
            // setBackdated((backdated) => [...backdated, item]);
          }
          if (checkExpThirty(item["Expiry Date"])) {
            tempOjectData["expThirty"].data.push(item);
            // setExpThirty((expThirty) => [...expThirty, item]);
          }
          if (checkExpSixty(item["Expiry Date"])) {
            tempOjectData["expSixty"].data.push(item);
            // setExpSixty((expSixty) => [...expSixty, item]);
          }
          if (checkExpNinty(item["Expiry Date"])) {
            tempOjectData["expNinty"].data.push(item);
            // setExpNinty((expNinty) => [...expNinty, item]);
          }
        });

        setAllAgreementCategory(allAgreementCategory);

        for (const key in tempOjectData) {
          tempOjectData[key].hook(tempOjectData[key].data);
        }
      } else {
        // location.reload();
        // showToast("No Data Found", false);
      }
    } catch (err: any) {
      showLogInConsole("ERROR In Fetch agreement data ", err);

      showToast("Something Went Wrong", false);
    } finally {
      //
      setLoading(false);
    }
  };

  //   const fetchDashBoardLicenseData = async () => {
  //     try {
  //       const res: any = await getRequest(
  //         `${API_ROUTES.GET_LICENSE_DATA}`,
  //         true,
  //       );
  //     //   console.log(res.data);
  //     setLicences(res.data);
  //     //   res.data.map((item: any) => {
  //     //     setLicences(licences => [...licences, item]);
  //     //   });
  //     //   setTimeout(() => {
  //         console.log('licences', licences);

  //     //   }, 5000);

  //     } catch (err) {
  //       console.log(err);

  //     }
  //   };

  useEffect(() => {
    // fix Warning: Can't perform a React state update on an unmounted component
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      // const categoryData = await fetchOrgConfigData();
      await fetchDashBoardData();
    })();
  }, []);

  useEffect(() => {
    let tableData: any = [];

    if (agreementData.length > 0) {
      switch (activeBox) {
        case "All Agreements":
          tableData = agreementData;
          break;
        case "New Agreements":
          tableData = newAgreements;
          break;
        case "Historical Agreements":
          tableData = historicAgreements;
          break;
        case "MSA1 Category":
          tableData = msaCategory;
          break;
        case "LLA Category":
          tableData = ndaCategory;
          break;
        case "MSA2 Category":
          tableData = cdaCategory;
          break;
        case "Lease Category":
          tableData = leaseCategory;
          break;
        case "PSA1 Category":
          tableData = rentalCategory;
          break;
        case "Draft":
          tableData = draft;
          break;
        case "Approve":
          tableData = approve;
          break;
        case "Approver Pending":
          tableData = approverPending;
          break;
        case "Executed":
          tableData = executed;
          break;
        case "Review":
          tableData = review;
          break;
        case "Review Rejected":
          tableData = reviewRejected;
          break;
        case "validate":
          tableData = validate;
          break;
        case "Expiry in 30 Days":
          tableData = expThirty;
          break;
        case "Expiry in 60 Days":
          tableData = expSixty;
          break;
        case "Expiry in 90 Days":
          tableData = expNinty;
          break;

        default:
          tableData = agreementData;
          break;
      }
      setMainTableData(tableData);
    }
    // console.log(tableData);
    // console.log(activeBox);
    // console.log(agreementData);

    // console.log("called");
  }, [activeBox]);

  const fetchOrgConfigData = async () => {
    try {
      const { client_id } = commonInfo.userData;

      const {
        data: { response },
      } = (await getRequest(API_ROUTES.FETCH_ORG_CONFIG + `?clientId=${client_id}`, true)) as any;

      const colors = [
        "#f72585",
        "#caf0f8",
        "#00b4d8",
        "#560bad",
        "#b5179e",
        "#3a0ca3",
        "#3f37c9",
        "#4361ee",
        "#4895ef",
        "#4cc9f0",
      ];

      const tempData = response.templateType.map((item, index) => {
        return {
          ...item,
          color: colors[index],
          total: 0,
        };
      });

      return tempData;
    } catch (err: any) {
      showToast(err.message, false);
      console.log(err);
    }
  };

  // const hideAxis = {
  //   x: {
  //     display: false,
  //     ticks: {
  //       display: false,
  //     },
  //     grid: {
  //       drawBorder: false,
  //       display: false,
  //     },
  //   },
  //   y: {
  //     display: false,
  //     ticks: {
  //       display: false,
  //       beginAtZero: true,
  //     },
  //     grid: {
  //       drawBorder: false,
  //       display: false,
  //     },
  //   },
  // }

  // const lineOptions = {
  //   stroke: {
  //     width: [0, 0, 0, 0, 0, 3],
  //   },
  //   grid: {
  //     show: false,
  //   },
  //   responsive: true,
  //   bezierCurve: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //     title: {
  //       display: false,
  //       text: "Chart.js Line Chart"
  //     }
  //   },
  //   scales: hideAxis,
  // };

  // const labels = ["January", "February", "March", "April", "May", "June", "July"];

  // const lineData = {
  //   labels,
  //   datasets: [
  //     {
  //       fill: true,
  //       label: "Dataset 2",
  //       data: [2, 1.5, 4, 2.5, 2, 3, 1],
  //       borderColor: "#34dbc9",
  //       backgroundColor: "#c7fbec",
  //       lineTension: 0.8
  //     }
  //   ]
  // };

  // const lineOptions2 = {
  //   stroke: {
  //     width: [0, 0, 0, 0, 0, 3],
  //   },
  //   grid: {
  //     show: false,
  //   },
  //   responsive: true,
  //   bezierCurve: false,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //     title: {
  //       display: false,
  //       text: "Chart.js Line Chart"
  //     }
  //   },
  //   scales: hideAxis,
  // };

  // const lineData2 = {
  //   labels,
  //   datasets: [
  //     {
  //       fill: true,
  //       label: "Dataset 2",
  //       data: [2, 1.8, 2.5, 2.3, 3, 2.8, 3.3],
  //       borderColor: "#2bdac7",
  //       backgroundColor: "#95ece3",
  //     }
  //   ]
  // };

  //   // const barOptions = {
  //   //   responsive: true,
  //   //   plugins: {
  //   //     legend: {
  //   //       display: false,
  //   //     },
  //   //     title: {
  //   //       display: false,
  //   //       text: "Chart.js Bar Chart"
  //   //     }
  //   //   },
  //   //   scales: hideAxis,
  //   // };

  //   // const barData = {
  //   //   labels,
  //   //   datasets: [
  //   //     {
  //   //       label: 'Dataset 1',
  //   //       data: [1, 2, 1.5, 2, 3],
  //   //       backgroundColor: '#f72585',
  //   //     }
  //   //   ],
  //   // };

  //   // const verticalbarOptions = {
  //   //   indexAxis: 'y' as const,
  //   //   responsive: true,
  //   //   plugins: {
  //   //     legend: {
  //   //       display: false,
  //   //     },
  //   //     title: {
  //   //       display: false,
  //   //       text: "Chart.js Bar Chart"
  //   //     }
  //   //   },
  //   //   scales: hideAxis,
  //   // };

  //   // const verticalbarData = {
  //   //   labels,
  //   //   datasets: [
  //   //     {
  //   //       barPercentage: 0.9,
  //   //       categoryPercentage: 1,
  //   //       label: 'Dataset 1',
  //   //       data: [1],
  //   //       backgroundColor: '#f72585',
  //   //     },
  //   //   ],
  //   // };

  const handleClickOpen = () => {
    setOpen(true);
    setOpenPreview(true);
    console.log(open);
  };
  const handleClickOpenFilter = () => {
    if (openFilter) {
      setActiveCategory(false);
      setOpenFilter(false);
      onChangesTasks("all");
    } else setOpenFilter(true);
  };
  const searchHandler = (e) => {
    const filteredData = agreementData?.filter(
      (item: any) =>
        item?.["Agreement Name"]?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        item?.["Agreement type"]?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        item?.["Status"]?.toLowerCase()?.includes(e.target.value?.toLowerCase()),
      // || item?.amounts?.toLowerCase()?.includes(e.target.value?.toLowerCase())
    );

    setMainTableData(filteredData);

    if (e.target.value == "") {
      setMainTableData(agreementData);
    }
  };
  const handleClose = () => {
    setisOpen(false);
  };

  const columnsDash = [
    // { headerName: 'id', accessor: 'id', width: 70 },
    {
      Header: "Agreement Name",
      accessor: "Agreement Name",
      width: 300,
      Cell: renderContractNameForOpenAgreements(navigate, dispatch, { setFileInfo }),
      // Cell: renderContractNameForOpenAgreements(navigate, dispatch),
      //### haresh changes start
      // Cell: (d: { cell: any; value: string }) => {
      //   const handleAgreementRoutes = () => {
      //     // const data = d.cell.row.values;
      //     const data = d.cell.row.original;
      //     const { _id } = d.cell.row.original;

      //     if (
      //       data.Status === NEW_AGREEMENT_STATUS["vendorEsign"] ||
      //       data.Status === NEW_AGREEMENT_STATUS["executed"]
      //     ) {
      //       navigate(`${ROUTE_CONSTANTS.Final_Signed_Agreement}/${_id}`, {
      //         state: data,
      //       });
      //       // navigate(`${ROUTE_CONSTANTS.Final_Signed_Agreement}/${d?.value}`, {
      //       //   state: data,
      //       // });
      //     } else {
      //       // navigate(`${ROUTE_CONSTANTS.Create_Agreement}/${d?.value}`, {
      //       //   state: data,
      //       // });
      //       navigate(`${ROUTE_CONSTANTS.Create_Agreement}/${_id}`, {
      //         state: data,
      //       });
      //     }
      //   };

      //   return (
      //     // <a className="table-txt" onClick={() => navigate("create-agreement/" + d?.value)}>
      //     <a
      //       // className="table-txt"
      //       className={`Contract_name_in_repo`}
      //       // onClick={() => navigate(`${ROUTE_CONSTANTS.Create_Agreement}/${d?.value}`)}
      //       onClick={() => handleAgreementRoutes()}
      //     >
      //       {d?.value}
      //     </a>
      //   );
      // },
      //### haresh changes end
    },
    {
      Header: "Agreement Type",
      accessor: "Agreement type",
      width: 200,
      Cell: (d: { cell: any; value: string }) => {
        return <div className="table-txt">{d?.value}</div>;
      },
    },
    // { headerName: 'Approver', accessor: 'Approver', width: 200 },
    {
      Header: "Category",
      accessor: "Category",
      width: 150,
      Cell: (d: { cell: any; value: string }) => {
        return (
          <div
            className="table-txt w-[150px] whitespace-nowrap overflow-hidden text-ellipsis"
            title={d?.value}
          >
            {d?.value}
          </div>
        );
      },
    },
    // { headerName: 'Reviewer', accessor: 'Reviewer', sortable: true, width: 150 },
    // { headerName: 'Drafter', accessor: 'Drafter', width: 100 },
    {
      Header: "Created Date",
      accessor: "Created Date",
      width: 100,
      Cell: (d: { cell: any; value: string }) => {
        return <div className="table-txt">{d?.value}</div>;
      },
    },
    // { headerName: 'Effective Date', accessor: 'Effective Date', width: 100 },
    // { headerName: 'Expiry Date', accessor: 'Expiry Date', width: 100 },
    {
      Header: "Status",
      accessor: "Status",
      width: 130,
      Cell: (d: { cell: any; value: string }) => {
        return (
          <div
            className={`status status-my-task ${getClassName(d?.value)}`}
            // className={
            //   d?.value === NEW_AGREEMENT_STATUS["validate"]
            //     ? "status status-review"
            //     : d?.value === NEW_AGREEMENT_STATUS["approve"]
            //     ? "status status-approved"
            //     : d?.value === NEW_AGREEMENT_STATUS["executed"]
            //     ? "status status-vendor-esign"
            //     : d?.value === NEW_AGREEMENT_STATUS["review"]
            //     ? "status status-vendor-review"
            //     : d?.value === NEW_AGREEMENT_STATUS["draft"]
            //     ? "status status-draft"
            //     : d?.value === NEW_AGREEMENT_STATUS["reviewRejected"]
            //     ? "status status-review-rejected"
            //     : d?.value === NEW_AGREEMENT_STATUS["approveRejected"]
            //     ? "status status-review-rejected"
            //     : d?.value === NEW_AGREEMENT_STATUS["approverPending"]
            //     ? "status status-approver-pending"
            //     : d?.value === NEW_AGREEMENT_STATUS["approverEStamp"]
            //     ? "status status-approve-estamp"
            //     : ""
            // }
          >
            {d?.value}
          </div>
        );
      },
    },
    // {
    //   Header: "",
    //   id: "selection1",
    //   accessor: "Approver",
    //   width: 0,
    //   disableSortBy: true,
    //   Cell: (d: { cell: any; value: string }) => {
    //     return (
    //       <>
    //         <div className="flex d-none">{d?.value}</div>
    //       </>
    //     );
    //   },
    // },
    // {
    //   Header: "",
    //   id: "selection2",
    //   accessor: "Drafter",
    //   width: 0,
    //   disableSortBy: true,
    //   Cell: (d: { cell: any; value: string }) => {
    //     return (
    //       <>
    //         <div className="flex d-none">{d?.value}</div>
    //       </>
    //     );
    //   },
    // },
    // {
    //   Header: "",
    //   id: "selection",
    //   accessor: "Reviewer",
    //   width: 0,
    //   disableSortBy: true,
    //   Cell: (d: { cell: any; value: string }) => {
    //     return (
    //       <>
    //         <div className="flex d-none">{d?.value}</div>
    //       </>
    //     );
    //   },
    // },
    {
      Header: "Actions",
      accessor: "Actions",
      sortable: false,
      width: 180,
      Cell: (d: any) => {
        return (
          <>
            <div className="flex tooltip3">
              {/* <div style={{ cursor: "pointer", color: '#4361ee' }} onClick={handleClickOpen}>
                <RemoveRedEyeOutlinedIcon />
                {d?.value}
              </div> */}
              {d?.row?.original.Approver ||
              d?.row?.original.Drafter ||
              d?.row?.original.Reviewer ? (
                <button
                  type="button"
                  className="i-class"
                  data-toggle="tooltip"
                  data-placement="left"
                >
                  <div style={{ color: "#34dbc9" }} className="flex justify-center items-center">
                    <b>i</b>
                  </div>
                  {d?.value}
                  <div className="tooltip2DispInfo tooltip-main3 p-2">
                    {d?.row?.original.Drafter && (
                      <div className="my-1">Drafter: {d?.row?.original.Drafter}</div>
                    )}
                    {d?.row?.original.Reviewer && (
                      <div className="my-1">Reviewer: {d?.row?.original.Reviewer}</div>
                    )}
                    {d?.row?.original.Approver && (
                      <div className="my-1">Approver: {d?.row?.original.Approver}</div>
                    )}
                    <div className="arw2"></div>
                  </div>
                </button>
              ) : (
                <></>
              )}
            </div>
          </>
        );
      },
    },
  ];

  //   const columnsLicense = [
  //     // { headerName: 'id', accessor: 'id', width: 70 },
  //     { Header: 'License Name', accessor: 'License Name', width: 300 },
  //     {
  //       Header: 'License Type', accessor: 'License type', width: 200,
  //       Cell: (d: { cell: any; value: string }) => {
  //         return (
  //           <div className="wid-day">
  //             {d?.value}
  //           </div>)
  //       }
  //     },
  //     // { headerName: 'Approver', accessor: 'Approver', width: 200 },
  //     { Header: 'Region', accessor: 'Region', width: 100 },
  //     // { headerName: 'Reviewer', accessor: 'Reviewer', sortable: true, width: 150 },
  //     // { headerName: 'Drafter', accessor: 'Drafter', width: 100 },
  //     {
  //       Header: 'Issue Date', accessor: 'Issue Date', width: 100,
  //       Cell: (d: { cell: any; value: string }) => {
  //         return (
  //           <div className="wid-day">
  //             {formatDate(d?.value)}
  //           </div>)
  //       }
  //     },
  //     {
  //       Header: 'Expiry Date', accessor: 'Expiry Date', width: 100,
  //       Cell: (d: { cell: any; value: string }) => {
  //         return (
  //           <div className="wid-day">
  //             {formatDate(d?.value)}
  //           </div>)
  //       }
  //     },
  //     // { headerName: 'Effective Date', accessor: 'Effective Date', width: 100 },
  //     // { headerName: 'Expiry Date', accessor: 'Expiry Date', width: 100 },
  //     // {
  //     //   Header: 'Status', accessor: 'Status', width: 130,
  //     //   Cell: (d: { cell: any; value: string }) => {
  //     //     return (
  //     //       <div
  //     //         className={
  //     //           d?.value === NEW_AGREEMENT_STATUS["validate"]
  //     //             ? "status-ongoing-blue"
  //     //             : d?.value === NEW_AGREEMENT_STATUS["approve"]
  //     //               ? "status-ongoing"
  //     //               : d?.value === NEW_AGREEMENT_STATUS["executed"]
  //     //                 ? "status-success"
  //     //                 : ""
  //     //         }
  //     //       >
  //     //         {d?.value}
  //     //       </div>
  //     //     );
  //     //   },
  //     // },
  //     {
  //       Header: "Actions",
  //       accessor: "Actions",
  //       sortable: false,
  //       width: 180,
  //       Cell: (d: { cell: any; value: string }) => {
  //         console.log(d.value);

  //         return (
  //           <>
  //             <div className="flex">
  //               <div style={{ cursor: "pointer", color: '#4361ee' }} onClick={handleClickOpen}>
  //                 <RemoveRedEyeOutlinedIcon />
  //                 {d?.value}
  //               </div>
  //               <button type="button" className="i-class" data-toggle="tooltip" data-placement="left" title="Approver: nsapprove@yopmail.com">
  //                 <div style={{ marginTop: -5, color: '#34dbc9' }}><b>i</b></div>
  //                 {d?.value}
  //               </button>
  //             </div>
  //           </>
  //         );
  //       },
  //     }
  //   ];

  //   function formatDate(data) {
  //     const date = new Date(data)
  //     let day, month;

  //     if (date.getDate() < 10) {
  //       month = '0' + (date.getMonth() + 1);
  //     } else {
  //       month = date.getMonth() + 1
  //     }

  //     if (date.getDate() < 10) {
  //       day = '0' + date.getDate();
  //     } else {
  //       day = date.getDate();
  //     }

  //     return day + '-' + month + '-' + date.getFullYear();
  //   }

  //   // const rows = allTasks;

  //   licences.map((item: any) => {
  //     if (new Date(item['Expiry Date']) < new Date()) {
  //       expiredLicense.push(item);
  //     }
  //   })

  //   const licensedata = {
  //     labels: ['Total Licenses'],
  //     datasets: [
  //       {
  //         // label: 'Total Agreements',
  //         data: [licences.length],
  //         backgroundColor: [
  //           '#f72585',
  //         ],
  //         borderColor: [
  //           'rgba(255, 99, 132, 1)',
  //         ],
  //         borderWidth: 0,
  //       },
  //     ],
  //   };
  //   const licensepie = {
  //     labels: ['Expired Licenses'],
  //     datasets: [
  //       {
  //         label: '',
  //         data: [expiredLicense.length],
  //         backgroundColor: [
  //           '#00b4d8',
  //         ],
  //         borderColor: [
  //           // '#caf0f8',
  //           // '#37cae8',
  //           // '#00b4d8',
  //           // '#3b009a',
  //           // '#560bad',
  //           // '#7209b7',
  //           // '#b5179e',
  //           // '#f72585'
  //         ],
  //         borderWidth: 0,
  //       },
  //     ],
  //   };

  function handleChange(e) {
    setIsAgreement(e.target.checked);
  }

  function handleTypeChange(e) {
    setType(e.target.value);
    onChangesTasks(e.target.value);
    setCategory("all");
    setStatus("all");
    // if(e.target.value !== 'all' && category !== 'all') {
    //   console.log(' cate-type');

    // }
    // if(e.target.value !== 'all' && status !== 'all') {
    //   console.log(' cate-stat');

    // }
    // if(e.target.value !== 'all' && category !== 'all' && status !== 'all') {
    //   console.log(' cate-typ-stat');

    // }
  }

  function handleCategoryChange(e) {
    setCategory(e.target.value);
    onChangesTasks(e.target.value);
    setType("all");
    setStatus("all");
  }

  function handleStatusChange(e) {
    setStatus(e.target.value);
    setActiveBox(e.target.value);
    setType("all");
    setCategory("all");
  }

  function onChangesTasks(type) {
    window.scrollTo(0, 730);

    if (type === "all") {
      setActiveBox("All Agreements");
      setMainTableData(agreementData);
    } else if (type === "new") {
      setActiveBox("New Agreements");
    } else if (type === "historical") {
      setActiveBox("Historical Agreements");
    }
    // if (type === "msa" || type === "msa-cate") {
    //   type === "msa-cate" ? setActiveCategory(true) : setActiveCategory(false);
    //   type === "msa" ? setActiveBox("MSA1 Category") : setActiveBox("MSA1 Category");
    // } else if (type === "nda" || type === "nda-cate") {
    //   type === "nda-cate" ? setActiveCategory(true) : setActiveCategory(false);
    //   type === "nda" ? setActiveBox("LLA Category") : setActiveBox("LLA Category");
    // } else if (type === "cda" || type === "cda-cate") {
    //   type === "cda-cate" ? setActiveCategory(true) : setActiveCategory(false);
    //   type === "cda" ? setActiveBox("MSA2 Category") : setActiveBox("MSA2 Category");
    // } else if (type === "lease" || type === "lease-cate") {
    //   type === "lease-cate" ? setActiveCategory(true) : setActiveCategory(false);
    //   type === "lease" ? setActiveBox("Lease Category") : setActiveBox("Lease Category");
    // } else if (type === "rental" || type === "rental-cate") {
    //   type === "rental-cate" ? setActiveCategory(true) : setActiveCategory(false);
    //   type === "rental" ? setActiveBox("PSA1 Category") : setActiveBox("PSA1 Category");
    // }
    if (type === "daysThirty") {
      type === "daysThirty" ? setActiveBox("Expiry in 30 Days") : setActiveBox("All Agreements");
    } else if (type === "daysSixty") {
      type === "daysSixty" ? setActiveBox("Expiry in 60 Days") : setActiveBox("All Agreements");
    } else if (type === "daysNinty") {
      type === "daysNinty" ? setActiveBox("Expiry in 90 Days") : setActiveBox("All Agreements");
    }
  }

  //   // function handleChangeName(e) {
  //   //   const filteredArray = allTasks.filter(function (itm) {
  //   //     return e.target.value.indexOf(itm['Agreement Name']) > -1;
  //   //   });
  //   //   console.log(filteredArray);
  //   //   setRows(filteredArray);
  //   //   if (!e.target.value) {
  //   //     setRows(allTasks);
  //   //   }
  //   // }

  //   // function handleStatusChange(e) {
  //   //   setRows([]);
  //   //   if (e.target.value === 'all') {
  //   //     allTasks.map((item: any) => {
  //   //       setRows(rows => [...rows, item]);
  //   //     });
  //   //   } else {
  //   //     allTasks.map((item: any) => {
  //   //       if (item['Status'] === e.target.value) {
  //   //         setRows(rows => [...rows, item]);
  //   //       }
  //   //     });
  //   //   }
  //   // }

  //   // function clearFilter() {
  //   //   setRows(allTasks);
  //   // }

  //   function onChangesLicences(type) {
  //     if (type === 'all-liceses') {
  //       setLicences([]);
  //       setLicences(licences);
  //       setActiveLicense('Licenses')
  //     }
  //     if (type === 'expired-liceses') {
  //       // setLicences(expiredLicense);
  //       setActiveLicense('Expired Licenses')
  //     }
  //   }

  // let docRef: any = null;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      await fetchAgreementList({
        setData: setAgreementList,
        setLoading: setAgListLoading,
      });
      // FETCH_ORG_CONFIG
    })();
  }, []);

  // const setActiveKpiBox = (item: { id: number; data: { tableData: any; chartData: any } }) => {
  //   dispatch(activeKpiBox(item));
  //   return;
  // };
  // const Array = [8,4,2,6,5]
  // console.log(Max.math(...Array));

  return (
    <>
      <DashboardLayout
        hederTitle={"Dashboard"}
        headerLogo={<HiPresentationChartBar />}
        childer={
          <div className="dashbaord-container">
            <div className="dashboard-content">
              <div className="container " style={{ maxWidth: "90vw" }}>
                <div className="flex justify-between items-center mb-5 mx-1">
                  <div className="flex items-center">
                    <div className={!isAgreement ? "widet-title active-text" : "widet-title"}>
                      Agreements
                    </div>
                    <Switch
                      // checked={checked}
                      onChange={handleChange}
                      size="medium"
                      inputProps={{ "aria-label": "controlled" }}
                      color="primary"
                    />
                    <div className={isAgreement ? "widet-title active-text" : "widet-title"}>
                      Licenses
                    </div>
                  </div>
                  <div className="flex justify-between">
                    {/* <span
                      className="buttons btn-import blue-text me-2"
                      onClick={() => setisOpen(true)}
                    >
                      Create Agreement
                    </span> */}

                    {userRole === "drafter" && (
                      <Button
                        type="button"
                        label="Create Agreement"
                        variant="outlined"
                        className="!w-fit !mx-0.5"
                        onClick={() => {
                          setisOpen(true);
                        }}
                      />
                    )}

                    {isOpen && <NewAgreementList isOpen={isOpen} handleClose={handleClose} />}

                    <Popup
                      // trigger={<span className="btn-import blue-text">Import Agreement</span>}
                      trigger={
                        <Button
                          type="button"
                          label="Import Agreement"
                          variant="outlined"
                          className="!w-fit !mx-0.5"
                        />
                      }
                      modal
                      closeOnDocumentClick={false}
                      closeOnEscape={false}
                      className="agreement-upload-popup"
                      disabled={userRole === "vendor"}
                    >
                      {(close: () => {}) => (
                        <UploadAgreementForm
                          closePopup={close}
                          // refetchDataAfterImport={p.refetchDataAfterImport}
                          importType={IMPORT_TYPES.AGREEMENT}
                          showParentAgreementSelection={true}
                        />
                      )}
                    </Popup>

                    {/* ### import License commented here */}
                    {/* <div className="btn-import blue-text ms-2">
                      <Popup
                        trigger={<span>Import License</span>}
                        modal
                        closeOnDocumentClick={false}
                        closeOnEscape={false}
                        className="agreement-upload-popup"
                      >
                        {(close: () => {}) => (
                          <UploadAgreementForm
                            closePopup={close}
                            // refetchDataAfterImport={p.refetchDataAfterImport}
                            importType={IMPORT_TYPES.LICENSE}
                          />
                        )}
                      </Popup>
                    </div> */}
                  </div>
                </div>
                {!isAgreement ? (
                  <>
                    <div className="mt-8 space-x-0 space-y-2 md:space-x-0 md:space-y-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                      <div className=" bg-white p-4 shadow rounded-lg">
                        <div
                          className={
                            activeBox === "New Agreements" || activeBox === "Historical Agreements"
                              ? ""
                              : ""
                          }
                        >
                          <div className="widet-title">Total Agreements</div>
                          <div className="flex h-auto">
                            <div className="wid-left">
                              <div
                                className="wid-num pointer"
                                onClick={() => onChangesTasks("all")}
                              >
                                {agreementData.length}
                              </div>

                              <div className="pie-data mt-3">
                                <div className="flex">
                                  <div className="histori"></div>
                                  <div
                                    className="ml-2 light-grey"
                                    onClick={() => onChangesTasks("historical")}
                                    style={{ marginLeft: 5, cursor: "pointer", zIndex: 9 }}
                                  >
                                    Historical
                                  </div>
                                </div>
                                <div className="flex mt-2">
                                  <div className="agr-new"></div>
                                  <div
                                    className="ml-2 light-grey"
                                    onClick={() => onChangesTasks("new")}
                                    style={{ marginLeft: 5, cursor: "pointer" }}
                                  >
                                    New
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="chart-container flex justify-center w-full max-h-44"
                              style={{ position: "relative" }}
                            >
                              <Pie
                                data={{
                                  labels: ["New", "Historical"],
                                  datasets: [
                                    {
                                      label: "",
                                      data: [newAgreements.length, historicAgreements.length],
                                      backgroundColor: ["#00b4d8", "#f72585"],
                                      borderColor: [],
                                      borderWidth: 0,
                                    },
                                  ],
                                }}
                                options={{
                                  plugins: {
                                    legend: {
                                      display: false,
                                      // position: 'bottom',
                                    },
                                    title: {
                                      display: false,
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*  */}

                      {/* <div className=" bg-white p-4 shadow rounded-lg">
                        <div
                        // className={
                        //   !activeCategory &&
                        //   (activeBox === "MSA1 Category" ||
                        //     activeBox === "LLA Category" ||
                        //     activeBox === "MSA2 Category" ||
                        //     activeBox === "Lease Category" ||
                        //     activeBox === "PSA1 Category")
                        //     ? ""
                        //     : ""
                        // }
                        >
                          <div className="widet-title">Backdated Agreements</div>
                          <div className="flex h-auto">
                            <div className="wid-left">
                              <div
                                className="wid-num pointer"
                                onClick={() => onChangesTasks("all")}
                              >
                                {backdated.length}
                              </div>
                            </div>

                            <div
                              className="chart-container flex justify-center mt-5"
                              style={{ position: "relative", width: "100%" }}
                            >
                              <div>
                                <Bar
                                  data={{
                                    labels: ["MSA", "LLA", "MSA2", "DMT", "TST"],
                                    datasets: [
                                      {
                                        label: "",
                                        data: [2, 3, 3, 4, 5],
                                        backgroundColor: [
                                          "#b5179e",
                                          "#3a0ca3",
                                          "#00b4d8",
                                          "#f72585",
                                          "#560bad",
                                          "#caf0f8",
                                          "#3f37c9",
                                          "#4361ee",
                                          "#4895ef",
                                          "#4cc9f0",
                                        ],
                                        // borderColor: [
                                        //   "rgb(255, 99, 132)",
                                        //   "rgb(255, 159, 64)",
                                        //   "rgb(255, 205, 86)",
                                        //   "rgb(75, 192, 192)",
                                        //   "rgb(54, 162, 235)",
                                        //   "rgb(153, 102, 255)",
                                        //   "rgb(201, 203, 207)",
                                        // ],
                                        // borderWidth: 1,
                                        borderWidth: 0,
                                      },
                                    ],
                                  }}
                                  options={{
                                    scales: {
                                      x: {
                                        grid: {
                                          display: false,
                                        }, ////// ###@@@
                                        display: false,
                                      },
                                      y: {
                                        grid: {
                                          display: false,
                                        },
                                        display: false,
                                      },
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/*  */}
                      <div className=" bg-white p-4 shadow rounded-lg">
                        <div className={activeCategory ? "" : ""}>
                          <div className="widet-title">Agreements Category</div>
                          <div className="flex h-auto">
                            <div className="wid-left">
                              <div
                                className="wid-num pointer"
                                onClick={() => onChangesTasks("all")}
                              >
                                {allAgreementCategory.length > 0 ? allAgreementCategory.length : 0}
                              </div>

                              {/* <div className="pie-data mt-1">
                                {allAgreementCategory.map((item, index) => {
                                  return (
                                    <div className="flex mt-2" key={index}>
                                      <div
                                        className="msa-cate"
                                        style={{ background: item.color }}
                                      ></div>
                                      <div
                                        className="ml-2 light-grey"
                                        onClick={() => onChangesTasks(item)}
                                        style={{ marginLeft: 5, cursor: "pointer" }}
                                      >
                                        {item.name}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div> */}
                            </div>

                            <div
                              className="chart-container flex justify-center w-full max-h-44 mt-2"
                              style={{ position: "relative" }}
                            >
                              <Doughnut
                                data={{
                                  // labels: ["MSA1", "LLA", "MSA2", "Lease", "PSA1"],
                                  labels: allAgreementCategory.map((item) => item.name),
                                  datasets: [
                                    {
                                      label: "Total Agreements",
                                      data: allAgreementCategory.map((item) => item.total),
                                      backgroundColor: [
                                        "#b5179e",
                                        "#3a0ca3",
                                        "#00b4d8",
                                        "#f72585",
                                        "#560bad",
                                        "#caf0f8",
                                        "#3f37c9",
                                        "#4361ee",
                                        "#4895ef",
                                        "#4cc9f0",
                                      ],
                                      borderColor: [
                                        "rgba(255, 99, 132, 1)",
                                        "rgba(54, 162, 235, 1)",
                                        "rgba(255, 206, 86, 1)",
                                        "rgba(75, 192, 192, 1)",
                                        "rgba(153, 102, 255, 1)",
                                        "rgba(255, 159, 64, 1)",
                                      ],
                                      borderWidth: 0,
                                    },
                                  ],
                                }}
                                options={{
                                  plugins: {
                                    legend: {
                                      display: false,
                                      position: "right",
                                    },
                                  },
                                  onClick: (e, element) => {
                                    const selectedElementIndex = element[0].index;
                                    // setActiveBox("hhhh");
                                    // mainTableData

                                    const searchingCategory =
                                      allAgreementCategory[selectedElementIndex].name;

                                    const currentSelectedCategoryList = agreementData.filter(
                                      (item) => item.Category === searchingCategory,
                                    );

                                    setMainTableData(currentSelectedCategoryList);

                                    window.scrollTo(0, 730);
                                    // onChangesTasks()
                                    // type === "msa-cate" ? setActiveCategory(true) : setActiveCategory(false);
                                    // type === "msa" ? setActiveBox("MSA1 Category") : setActiveBox("MSA1 Category");
                                  },
                                  onHover: (event: any, chartElement: any) => {
                                    event!.native!.target!.style!.cursor = chartElement[0]
                                      ? "pointer"
                                      : "default";
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  */}

                      <div className=" bg-white p-4 shadow rounded-lg w-full">
                        <div
                          className={
                            activeBox === "Expiry in 30 Days" ||
                            activeBox === "Expiry in 60 Days" ||
                            activeBox === "Expiry in 90 Days"
                              ? ""
                              : ""
                          }
                        >
                          <div className="widet-title">Expiry Date</div>
                          <div className="flex h-auto">
                            <div className="chart-box mt-3 flex flex-col justify-start items-center">
                              <div className="w-full h-full flex mb-2">
                                <div className="widet-title items-center">
                                  30 &nbsp;
                                  <small className="smlDays">Days</small>&nbsp;&nbsp;
                                </div>
                                <ReactSimpleTooltip
                                  arrow={7}
                                  background="#000"
                                  border="#000"
                                  color="#fff"
                                  content={
                                    <div className="w-10 h-7 p-1 flex justify-center items-center">
                                      <div className="w-full h-full flex justify-between items-center">
                                        <div
                                          className="w-3 h-3 mr-1"
                                          style={{
                                            background: "#f72585",
                                            border: "solid 1px white",
                                          }}
                                        ></div>
                                        <span className="">{expThirty.length}</span>
                                      </div>
                                    </div>
                                  }
                                  customCss={{
                                    opacity: 0.8,
                                  }}
                                  fadeDuration={200}
                                  fadeEasing="linear"
                                  fixed={false}
                                  fontFamily="inherit"
                                  fontSize="inherit"
                                  offset={0}
                                  padding={0}
                                  placement="top"
                                  radius={5}
                                  zIndex={1}
                                >
                                  <div
                                    className="daysThirty pointer tooltip2"
                                    onClick={() => onChangesTasks("daysThirty")}
                                    style={{
                                      background: "#f72585",
                                      width: setWidth(expThirty.length),
                                    }}
                                  ></div>
                                </ReactSimpleTooltip>
                              </div>

                              <div className="w-full h-full flex mb-2">
                                <div className="widet-title flex items-center">
                                  60 &nbsp;
                                  <small className="smlDays">Days</small>&nbsp;&nbsp;
                                </div>
                                <ReactSimpleTooltip
                                  arrow={7}
                                  background="#000"
                                  border="#000"
                                  color="#fff"
                                  content={
                                    <div className="w-10 h-7 p-1 flex justify-center items-center">
                                      <div className="w-full h-full flex justify-between items-center">
                                        <div
                                          className="w-3 h-3 mr-1"
                                          style={{
                                            background: "#4361e0",
                                            border: "solid 1px white",
                                          }}
                                        ></div>
                                        <span className="">{expSixty.length}</span>
                                      </div>
                                    </div>
                                  }
                                  customCss={{
                                    opacity: 0.8,
                                  }}
                                  fadeDuration={200}
                                  fadeEasing="linear"
                                  fixed={false}
                                  fontFamily="inherit"
                                  fontSize="inherit"
                                  offset={0}
                                  padding={0}
                                  placement="top"
                                  radius={5}
                                  zIndex={1}
                                >
                                  <div
                                    className="daysThirty pointer tooltip2"
                                    onClick={() => onChangesTasks("daysSixty")}
                                    style={{
                                      background: "#4361e0",
                                      width: setWidth(expSixty.length),
                                    }}
                                  ></div>
                                </ReactSimpleTooltip>
                              </div>

                              <div className="w-full h-full flex mb-2">
                                <div className="widet-title flex items-center">
                                  90 &nbsp;
                                  <small className="smlDays">Days</small>&nbsp;&nbsp;
                                </div>
                                <ReactSimpleTooltip
                                  arrow={7}
                                  background="#000"
                                  border="#000"
                                  color="#fff"
                                  content={
                                    <div className="w-10 h-7 p-1 flex justify-center items-center">
                                      <div className="w-full h-full flex justify-between items-center">
                                        <div
                                          className="w-3 h-3 mr-1"
                                          style={{
                                            background: "#00b4d8",
                                            border: "solid 1px white",
                                          }}
                                        ></div>
                                        <span className="">{expNinty.length}</span>
                                      </div>
                                    </div>
                                  }
                                  customCss={{
                                    opacity: 0.8,
                                  }}
                                  fadeDuration={200}
                                  fadeEasing="linear"
                                  fixed={false}
                                  fontFamily="inherit"
                                  fontSize="inherit"
                                  offset={0}
                                  padding={0}
                                  placement="top"
                                  radius={5}
                                  zIndex={1}
                                >
                                  <div
                                    className="daysThirty pointer tooltip2"
                                    onClick={() => onChangesTasks("daysNinty")}
                                    style={{
                                      background: "#00b4d8",
                                      width: setWidth(expNinty.length),
                                    }}
                                  ></div>
                                </ReactSimpleTooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </>
                ) : (
                  <>
                    {/* <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 p-2">
                        <div onClick={() => onChangesLicences('all-liceses')} className={(activeLicense === 'Licenses') ? "widget-box p-3 flex h-auto box active-box" : "widget-box p-3 flex h-auto box"}>
                          <div className="wid-left absolute">
                            <div className="widet-title">
                              Total Licenses
                            </div>
                            <div className="wid-num">
                              {licences.length}
                            </div>

                          </div>
                          <div className="chart-box chart-size" style={{ height: 224, width: 226, marginLeft: 85 }}>
                            <div style={{ height: 200, width: 212, marginTop: 26 }}>
                              <Doughnut data={licensedata} options={{
                                plugins: {
                                  legend: {
                                    display: false,
                                    position: 'right'
                                  },
                                },
                              }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 p-2">
                        <div onClick={() => onChangesLicences('expired-liceses')} className={(activeLicense === 'Expired Licenses') ? "widget-box p-3 box active-box" : "widget-box p-3 box"}>
                          <div className="widet-title" >
                            Expired Licenses
                          </div>
                          <div className="flex h-auto">
                            <div className="wid-left">
                              <div className="wid-num">
                                {expiredLicense.length}
                              </div>
                            </div>
                            <div className="chart-box chart-size" style={{ marginLeft: 60 }}>
                              <Pie data={licensepie} options={pieOptions} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}
                <div className="mt-5">
                  {/* <div className="page-label2">{activeLicense}</div>} */}
                  <div className="table-box mt-2">
                    {!isAgreement ? (
                      <div className="page-label2 bg-white pl-8 pt-5 -mb-3 w-full font-bold text-lg">
                        {activeBox ? activeBox : "Agreements"}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="repo-table w-full">
                      {/* {openFilter ? (
                        <IconContext.Provider value={{ size: "1em" }}>
                          <div
                            title="Add Filter"
                            style={{ marginRight: 30, position: "absolute", marginTop: 2 }}
                            className="filter-icon f-icon-pos"
                            onClick={handleClickOpenFilter}
                          >
                            <FaFilter />
                          </div>
                        </IconContext.Provider>
                      ) : (
                        <IconContext.Provider value={{ size: "1.2em" }}>
                          <div
                            title="Add Filter"
                            style={{ marginRight: 30, position: "absolute", marginTop: 2 }}
                            className="filter-icon f-icon-pos"
                            onClick={handleClickOpenFilter}
                          >
                            <FiFilter />
                          </div>
                        </IconContext.Provider>
                      )} */}
                      <div className="flex absolute" style={{ marginLeft: 235, marginTop: -10 }}>
                        {openFilter ? (
                          <>
                            <div style={{ marginRight: 30 }}>
                              <Select
                                className="filter-drop"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={category}
                                label="Age"
                                onChange={handleCategoryChange}
                              >
                                <MenuItem value={"all"}>Select Category</MenuItem>
                                <MenuItem value={"msa"}>MSA1</MenuItem>
                                <MenuItem value={"nda"}>LLA</MenuItem>
                                <MenuItem value={"cda"}>MSA2</MenuItem>
                                <MenuItem value={"lease"}>Lease</MenuItem>
                                <MenuItem value={"rental"}>PSA1</MenuItem>
                              </Select>
                            </div>
                            <div style={{ marginRight: 30 }}>
                              <Select
                                className="filter-drop"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={agreementType}
                                label="Age"
                                onChange={handleTypeChange}
                              >
                                <MenuItem value={"all"}>Select Type</MenuItem>
                                <MenuItem value={"new"}>New Agreements</MenuItem>
                                <MenuItem value={"historical"}>Historic Agreements</MenuItem>
                              </Select>
                            </div>
                            <div>
                              <Select
                                className="filter-drop"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Age"
                                onChange={handleStatusChange}
                              >
                                <MenuItem value={"all"}>Select Status</MenuItem>
                                <MenuItem value={"Approve"}>Approve</MenuItem>
                                <MenuItem value={"Approver Pending"}>Approver Pending</MenuItem>
                                <MenuItem value={"Draft"}>Draft</MenuItem>
                                <MenuItem value={"Executed"}>Executed</MenuItem>
                                <MenuItem value={"Review"}>Review</MenuItem>
                                <MenuItem value={"Review Rejected"}>Review Rejected</MenuItem>
                                <MenuItem value={"validate"}>Validate</MenuItem>
                              </Select>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {
                        !isAgreement ? (
                          <>
                            <TableJs
                              columns={columnsDash}
                              sortBy={[]}
                              // sortBy={[{ id: "Agreement Name", desc: true }]}
                              data={loading ? [] : mainTableData}
                              isRowSelectEnable={false}
                              headerProps={{ isEnable: true, type: 11 }}
                              isRowsPerPageChangeRequired={false}
                              isCurrentRowsCountVisibilityRequired={false}
                              searchHandler={searchHandler}
                            />
                            {loading ? (
                              <div className="center-elements">
                                <LoaderHOC></LoaderHOC>
                              </div>
                            ) : agreementData.length ? null : (
                              <div className="mt-3 center-elements">No Data Found...</div>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                        // <TableJs
                        //   columns={columnsLicense}
                        //   sortBy={[{ id: "License Name", desc: true }]}
                        //   data={activeLicense === 'Expired Licenses' ? expiredLicense : licences}
                        //   isRowSelectEnable={false}
                        //   headerProps={{ isEnable: true, type: 11 }}
                        //   isRowsPerPageChangeRequired={false}
                        //   isCurrentRowsCountVisibilityRequired={false}
                        // />
                      }
                    </div>
                  </div>
                </div>
              </div>

              <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={openPreview}
                onClose={() => setOpenPreview(false)}
              >
                <DialogTitle>Preview</DialogTitle>
                <DialogContent>
                  <DialogContentText></DialogContentText>

                  <FileViewer
                    fileType={"pdf"}
                    filePath={
                      "https://signed-contracts-legitron.s3.amazonaws.com/73f9d17265ea4ef8b58ef61ca8eade44/DDD1234.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVMEHJZ5PUQOWQKYI%2F20230328%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230328T064937Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=bcdbe60a821aa3a2273ff925c3873f502ef00ab8bc0286941c3fb51d4f4cdcb7"
                    }
                    errorComponent={<div> Error in File Redering</div>}
                    onError={() => console.log("Error in File Rendering ....")}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    className="btn-create"
                    onClick={() => setOpenPreview(false)}
                    label="Close"
                  />
                </DialogActions>
              </Dialog>

              <Popup
                open={fileInfo.isShown}
                modal
                closeOnDocumentClick={true}
                closeOnEscape={true}
                className="agreement-pdf-display-popup"
                onClose={() => {
                  setFileInfo({ fileUrl: "", isShown: false, name: "", fileExtension: "" });
                }}
              >
                {(close) => (
                  <ImportedDocuments
                    fileUrl={fileInfo.fileUrl}
                    bucket={process.env.REACT_APP_IMPORT_AGREEMENTS || ""}
                    close={close}
                    fileName={fileInfo.name}
                    fileExtension={fileInfo.fileExtension}
                  />
                )}
              </Popup>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Dashboad;
