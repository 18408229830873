import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlinePlusCircle } from "react-icons/ai";

import { importFileNameSanitizing, IMPORT_TYPES } from "../../helpers";
import "./style.scss";

interface otherProps {
  agreementToImport: any;
  setAgreementFilesToImport: Function;
  importType: string;
}

const FileUpload = (p: otherProps) => {
  const { agreementToImport, setAgreementFilesToImport } = p;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const fileArr = [...agreementToImport, ...acceptedFiles];
      const maxFiles = parseInt(process.env.REACT_APP_MAX_AGREEMENTS_TO_IMPORT || "100");
      if (fileArr.length <= maxFiles) {
        setAgreementFilesToImport(fileArr);
      } else {
        setAgreementFilesToImport(fileArr.slice(0, maxFiles));
      }
    },
    [agreementToImport, setAgreementFilesToImport],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept:
      p.importType === IMPORT_TYPES.AGREEMENT_PREVIEW
        ? process.env.REACT_APP_PREVIEW_AGREEMENT_TYPES
        : process.env.REACT_APP_AGREEMENT_TYPES,
    maxSize: parseInt(process.env.REACT_APP_MAX_AGREEMENT_SIZE || "25000000"),
  });

  const previewClass = p.importType === IMPORT_TYPES.AGREEMENT_PREVIEW ? "preview" : "";
  return (
    <>
      <div className={agreementToImport.length ? "selected-file-block" : "file-upload-block"}>
        <div className="form-group">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <>
                {agreementToImport.length ? (
                  <div className="selected-file-items">
                    <div className={`select-title ${previewClass}`}>
                      <strong>
                        {p.importType === IMPORT_TYPES.AGREEMENT_PREVIEW
                          ? `FileName: ${importFileNameSanitizing(agreementToImport[0]?.name)}`
                          : "Files:"}
                      </strong>
                    </div>
                    {p.importType !== IMPORT_TYPES.AGREEMENT_PREVIEW && (
                      <ul>
                        {agreementToImport.map((file: any) => (
                          <li key={file.name}>{importFileNameSanitizing(file.name)}</li>
                        ))}
                        <div className="add-more-to-upload">
                          <AiOutlinePlusCircle />
                          Add More
                        </div>
                      </ul>
                    )}
                  </div>
                ) : (
                  <>
                    <p>
                      Drop your file here, or <span className="browse-files">browse</span>
                    </p>
                    <span className="supported-files">
                      <div>
                        Supports:{" "}
                        {p.importType === IMPORT_TYPES.LICENSE
                          ? "PDF"
                          : p.importType === IMPORT_TYPES.AGREEMENT_PREVIEW
                          ? "Docs"
                          : "PDF, Docs, Word"}
                      </div>
                      {p.importType !== IMPORT_TYPES.AGREEMENT_PREVIEW && (
                        <div>
                          Bulk Import Upto {process.env.REACT_APP_MAX_AGREEMENTS_TO_IMPORT || "100"}{" "}
                          {p.importType === IMPORT_TYPES.LICENSE ? "Licenses" : "Agreements"}{" "}
                        </div>
                      )}
                      <div>
                        Maximum File Size:{" "}
                        {parseInt(process.env.REACT_APP_MAX_AGREEMENT_SIZE || "25000000") / 1000000}{" "}
                        MB
                      </div>
                    </span>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
