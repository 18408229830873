/* eslint-disable */
import { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent, DialogContentText, Input } from "@material-ui/core/";

import { TiCancel } from "react-icons/ti";
import { AiOutlineCheck } from "react-icons/ai";
import { FiShare } from "react-icons/fi";
import { FaEdit } from "react-icons/fa";
import { BsInfoCircleFill } from "react-icons/bs";

import { ToolTip, Button, ButtonGroups, TextInput, Checkbox } from "components";
import { USER_ROLES, NEW_AGREEMENT_STATUS, STRINGS } from "helpers";
import { Formik, Form, Field } from "formik";

import AvailableUsersList from "./availableUserList";

interface draftProps {
  isOpen: boolean;
  setOpen: Function;
  values: any;
  handleSubmit: any;
  setValues: any;
  agreementStatus: string;
  btnDisabled: any;
  usersList: usersInformation[];
  skipReviewStep?: any;
  submitForm: Function;
}

interface reviewerProps {
  role: any;
  values: any;
  handleSubmit: any;
  setValues: any;
  agreementStatus: string;
  comments?: any;
  btnDisabled: any;
  usersList: usersInformation[];
  validateForm: Function;
  submitForm: Function;
  isUserModleOpen: boolean;
  setUserModleOpen: Function;
}

interface approverProps {
  handleEStamp: Function;
  role: any;
  values: any;
  agreementStatus: string;
  btnDisabled: any;
  commentData: any;
  setCommentData: Function;
  handleRejectStatus: Function;
  onFinish: Function;
  handleEmailShare: any;
  approvedByAllApprovers?: string;
  openSharePopup: boolean;
  setOpenSharePopup: Function;
  isEmailModelOpen: boolean;
  setIsEmailModelOpen: Function;
}

interface vendorProps {
  values: any;
  agreementStatus: string;
  btnDisabled: any;
  // setSubmitType: Function;
  // handlePrint: Function;
  onFinish: Function;
}

export const DraftActionButton = ({
  role,
  btnDisabled,
  skipReviewStep,
  agreementStatus,
  setSubmitType,
  setOpen,
  handleSubmit,
  validateForm,
  setParentAgreementModalState,
}) => {
  const publishDraftHandler = async () => {
    if (!role) {
      //p.handleSubmit();
      const isValidForm = await validateForm();
      if (Object.keys(isValidForm).length !== 0) {
        handleSubmit();
        return;
      }
      setOpen(true);
    } else if (skipReviewStep === "true") {
      setSubmitType(NEW_AGREEMENT_STATUS["approve"]);
    } else {
      const isValidForm = await validateForm();
      if (Object.keys(isValidForm).length !== 0) {
        handleSubmit();
        // setSubmitType(NEW_AGREEMENT_STATUS["review"]);
        return;
      }
      setOpen(true);
    }
  };

  const PublicDraftButton = () => (
    <Button
      // className={"publish-btn "}
      variant="outlined"
      color="primary"
      type={!role ? "button" : "submit"}
      disabled={
        btnDisabled ||
        (skipReviewStep === "true"
          ? agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
          agreementStatus !== NEW_AGREEMENT_STATUS["draft"]
          : agreementStatus !== NEW_AGREEMENT_STATUS["reviewRejected"] &&
          agreementStatus !== NEW_AGREEMENT_STATUS["draft"])
      }
      onClick={publishDraftHandler}
      label={"Publish Draft"}
    />
  );

  return (
    <>
      {/* <Dropdown as={ButtonGroup}>
          <PublicDraftButton />
          <Dropdown.Toggle split id="dropdown-split-basic" className={"publish-btn"} />
          <Dropdown.Menu>
            <Dropdown.Item
              href="#"
              className={"select-parent"}
              onClick={() => {
                setParentAgreementModalState({ isOpen: true });
              }}
            >
              Select Parent
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      {agreementStatus === NEW_AGREEMENT_STATUS["reviewRejected"] ||
        agreementStatus === NEW_AGREEMENT_STATUS["draft"] ? (
        <ButtonGroups
          disabled={btnDisabled}
          options={[
            {
              name: "Publish Draft",
              onClick: () => {
                publishDraftHandler();
              },
              disabled:
                btnDisabled ||
                (skipReviewStep === "true"
                  ? agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
                  agreementStatus !== NEW_AGREEMENT_STATUS["draft"]
                  : agreementStatus !== NEW_AGREEMENT_STATUS["reviewRejected"] &&
                  agreementStatus !== NEW_AGREEMENT_STATUS["draft"]),
            },
            {
              name: "Select Parent",
              onClick: () => {
                !btnDisabled && setParentAgreementModalState({ isOpen: true });
              },
              disabled: btnDisabled,
            },
          ]}
        />
      ) : (
        <PublicDraftButton />
      )}
    </>
  );
};

export const DraftActions = (p: draftProps) => (
  <AvailableUsersList
    isOpen={p.isOpen}
    handleClose={() => p.setOpen(false)}
    role={p.skipReviewStep === "true" ? USER_ROLES["approver"] : USER_ROLES["reviewer"]}
    values={p.values}
    handleSubmit={p.submitForm}
    btnDisabled={p.btnDisabled}
    usersList={p.usersList}
    setValues={p.setValues}
    skipReviewStep={p.skipReviewStep}
  />
);

export const ReviewerActionButtons = ({
  btnDisabled,
  agreementStatus,
  selectedApproversByAllReviewers,
  handleSubmit,
  setSubmitType,
  setUserModleOpen,
  validateForm,
  handleRejectStatus,
  values,
}) => {
  return <>
    <Button
      type="button"
      disabled={
        btnDisabled ||
        (agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
          agreementStatus !== NEW_AGREEMENT_STATUS["review"])
      }
      onClick={() =>
        handleRejectStatus(NEW_AGREEMENT_STATUS["reviewRejected"], USER_ROLES["reviewer"], values)
      }
      label={
        <>
          <TiCancel className="mr-1" /> Return to Submitter
        </>
      }
      variant="outlined"
      isFullWidth={false}
    // className="!w-40"
    />

    <Button
      type={selectedApproversByAllReviewers !== "true" ? "button" : "submit"}
      onClick={async () => {
        if (selectedApproversByAllReviewers !== "true") {
          const isValidForm = await validateForm();
          if (Object.keys(isValidForm).length !== 0) {
            handleSubmit();
            return;
          }
          setUserModleOpen(true);
        } else {
          const isValidForm = await validateForm();
          if (Object.keys(isValidForm).length !== 0) {
            setSubmitType(NEW_AGREEMENT_STATUS["approve"]);
            return;
          }
          setUserModleOpen(true);
        }
      }}
      disabled={
        btnDisabled ||
        (agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
          agreementStatus !== NEW_AGREEMENT_STATUS["review"])
      }
      label={
        <>
          <AiOutlineCheck className="mr-1" /> Approve
        </>
      }
      variant="outlined"
      isFullWidth={false}
    // className="!w-24"
    />
  </>
}

export const ReviewerActions = (p: reviewerProps) => (
  <div className="form-header-row">
    {/* {p.agreementStatus === NEW_AGREEMENT_STATUS["approveRejected"]
      ? RejectedComments({ comments: p.comments })
      : null} */}
    <AvailableUsersList
      isOpen={p.isUserModleOpen}
      handleClose={() => p.setUserModleOpen(false)}
      role={USER_ROLES["approver"]}
      values={p.values}
      handleSubmit={p.submitForm}
      btnDisabled={p.btnDisabled}
      usersList={p.usersList}
      setValues={p.setValues}
    />
  </div>
);

export const ApproverActionButtons = ({
  agreementStatus,
  btnDisabled,
  role,
  onFinish,
  values,
  approvedByAllApprovers,
  setSubmitType,
  userData,
  setOpenSharePopup,
  setIsEmailModelOpen,
  handleRejectStatus,
  isFormValueSet,
}) => {
  return <>
    {![NEW_AGREEMENT_STATUS["approverPending"], NEW_AGREEMENT_STATUS["approverEStamp"]].includes(
      agreementStatus,
    ) && (
        <Button
          type="button"
          onClick={() =>
            handleRejectStatus(
              NEW_AGREEMENT_STATUS["approveRejected"],
              USER_ROLES["approver"],
              values,
            )
          }
          disabled={
            btnDisabled ||
            // !isFormValueSet ||
            (agreementStatus !== NEW_AGREEMENT_STATUS["approve"] &&
              agreementStatus !== NEW_AGREEMENT_STATUS["approverPending"] &&
              agreementStatus !== NEW_AGREEMENT_STATUS["rejected"])
          }
          label={
            <>
              <TiCancel className="mr-1" />
              Return to Submitter
            </>
          }
          variant="outlined"
          // className="!w-40"
          isFullWidth={false}
        />
      )}
    {agreementStatus !== NEW_AGREEMENT_STATUS["approverPending"] &&
      agreementStatus !== NEW_AGREEMENT_STATUS["approverEStamp"] ? (
      <>
        <Button
          type="button"
          onClick={() =>
            onFinish({ ...values, approver: userData.email }, NEW_AGREEMENT_STATUS["approved"])
          }
          disabled={
            btnDisabled ||
            // !isFormValueSet ||
            (agreementStatus !== NEW_AGREEMENT_STATUS["approve"] &&
              agreementStatus !== NEW_AGREEMENT_STATUS["rejected"])
          }
          label={
            <>
              <AiOutlineCheck className="mr-1" />
              Approve
            </>
          }
          variant="outlined"
          // className="!w-24"
          isFullWidth={false}
        />

        <Button
          onClick={() => {
            // if (!role) {
            setOpenSharePopup(true);
            // }
            // setSubmitType(NEW_AGREEMENT_STATUS["vendorReview"]);
          }}
          // type={!role ? "button" : "submit"}
          disabled={
            btnDisabled ||
            // !isFormValueSet ||
            !(
              agreementStatus === NEW_AGREEMENT_STATUS["approved"] &&
              approvedByAllApprovers === "true"
            )
          }
          label={
            <>
              <FiShare className="mr-1" />
              Share
            </>
          }
          variant="outlined"
          // className="!w-24"
          isFullWidth={false}
        />
      </>
    ) : null}
    {agreementStatus !== NEW_AGREEMENT_STATUS["approverPending"] ? null : (
      <>
        <Button
          type="button"
          onClick={() => setIsEmailModelOpen(true)}
          disabled={btnDisabled}
          label="EStamp"
          variant="outlined"
        />

        <Button
          type="button"
          onClick={() => onFinish(values, NEW_AGREEMENT_STATUS["rejected"])}
          disabled={btnDisabled || !isFormValueSet}
          label={
            <>
              <TiCancel className="mr-1" size={20} />
              Reject
            </>
          }
          variant="outlined"
        />
      </>
    )}
    {/* {agreementStatus !== NEW_AGREEMENT_STATUS["approverEStamp"] ? null : (
    <Button
      type="button"
      onClick={() => onFinish(values, NEW_AGREEMENT_STATUS["vendorEsign"])}
      disabled={
        btnDisabled ||
        !isFormValueSet ||
        !(agreementStatus === NEW_AGREEMENT_STATUS["approverEStamp"])
      }
      label="Send For E-sign"
      variant="outlined"
    />
  )} */}
  </>
};

export const ApproverActions = (p: approverProps) => {
  const [checked, setHandleChecked] = useState<boolean>(false);
  const [checked2, setHandleChecked2] = useState<boolean>(false);
  const [termDialog, setTermDialog] = useState<boolean>(false);
  const [signOnly, setSignOnly] = useState<boolean>(false);
  const [eSignUsername, setSignUserName] = useState<string>("");

  const handleTermPopup = () => {
    setHandleChecked(!checked);
    if (!checked) {
      setTermDialog(true);
    }
  };

  const closeDialogHandler = () => {
    setHandleChecked(false);
    setHandleChecked2(false);
    setTermDialog(false);
    setSignOnly(false);
    setSignUserName("");
  };

  const handleSignOnly = () => {
    setSignOnly(!signOnly);
  };

  return (
    <div className="form-header-row">
      <Formik
        initialValues={{
          vendorEmail: "",
          term_and_condition: false,
        }}
        // validationSchema={adminForm}
        onSubmit={async (values, { setSubmitting }) => {
          //
        }}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <>
              <div>
                <Dialog
                  open={termDialog}
                  onClose={() => {
                    closeDialogHandler();
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent className="w-[450px]">
                    {p.agreementStatus !== NEW_AGREEMENT_STATUS.approved ? (
                      <DialogContentText id="alert-dialog-description">
                        {`By Clicking on OK we are sending this agreement to ${p.commentData} with username ${eSignUsername}
               for internal e-sign which can't be roll back again. Please be careful while entering details`}{" "}
                        :
                      </DialogContentText>
                    ) : (
                      <DialogContentText id="alert-dialog-description">
                        {`By Clicking on OK we are sending this agreement to ${p.commentData}
              for vendor review which can't be roll back again. Please be careful while entering details`}{" "}
                        :
                      </DialogContentText>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setTermDialog(false)}
                      variant="contained"
                      label={"OK"}
                      isFullWidth={false}
                    />
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={p.isEmailModelOpen}
                  onClose={() => {
                    p.setIsEmailModelOpen(false);
                    setHandleChecked(false);
                    closeDialogHandler();
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent className="w-full mt-5 hidden">
                    <DialogContentText id="alert-dialog-description"></DialogContentText>
                  </DialogContent>
                  <DialogActions className="!w-[450px] !pt-3 !px-2 flex flex-col">
                    {/* {p.agreementStatus !== NEW_AGREEMENT_STATUS.approverPending ? */}
                    <>
                      <div className="w-11/12">
                        {/* <div className="auth-checkbox d-">
                    <input type="checkbox" checked={signOnly} onChange={handleSignOnly} />
                    <label>Sign Only</label>
                  </div> */}

                        <h3 className="al-user-label text-center mb-3">Send for Esign</h3>

                        <TextInput
                          type={"text"}
                          placeholder={"Enter name of signer"}
                          label={"Name of signer"}
                          name="username-1"
                          // disableUnderline={true}
                          // value={eSignUsername}
                          onCustomActionBlur={(e) => {
                            setSignUserName(e.target.value);
                          }}
                          className="mb-3"
                        />

                        <TextInput
                          type={"text"}
                          placeholder={STRINGS.ESIGN_PROCESS}
                          label="Email"
                          // disableUnderline={true}
                          name="email-1"
                          // value={p.commentData}
                          onCustomActionBlur={(e) => {
                            p.setCommentData(e.target.value);
                          }}
                        />

                        <div className="ml-2.5 mt-2">
                          <Checkbox
                            name="term_and_condition"
                            label="Term and condition"
                            checked={checked}
                            onChange={handleTermPopup}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end mt-3 mb-1 w-11/12">
                        <div className="flex  mr-1">
                          <Button
                            onClick={async () => {
                              setHandleChecked(false);
                              p.setIsEmailModelOpen(false);
                              await p.handleEStamp(
                                p.commentData,
                                eSignUsername,
                                p.values,
                                p.onFinish,
                              );

                              //await p.onFinish(p.values, NEW_AGREEMENT_STATUS["approverEStamp"]);
                              // setHandleChecked(!checked);
                            }}
                            // disabled={!p.commentData || !eSignUsername || !checked || p.btnDisabled}
                            disabled={!p.commentData || !checked || p.btnDisabled}
                            variant="contained"
                            label="Send"
                          />

                          <Button
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              p.setIsEmailModelOpen(false);
                              closeDialogHandler();
                            }}
                            disabled={p.btnDisabled}
                            variant="outlined"
                            label="Cancel"
                          />
                        </div>
                      </div>
                    </>
                  </DialogActions>
                </Dialog>
              </div>

              <Dialog
                open={p.openSharePopup}
                onClose={() => p.setOpenSharePopup(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent className="hidden">
                  <DialogContentText id="alert-dialog-description"></DialogContentText>
                </DialogContent>
                <DialogActions className="flex flex-col !py-5 !px-2 !w-[450px]">
                  <div className="w-11/12 mb-2">
                    <h4 className="text-center mb-5">Share With Vendor</h4>
                    <TextInput
                      type={"text"}
                      label={STRINGS.SHARE_TO_VENDOR}
                      placeholder={"Email"}
                      // disableUnderline={true}
                      value={p.commentData}
                      onCustomActionBlur={(e) => {
                        p.setCommentData(e.target.value);
                      }}
                      name="vendorEmail"
                    />
                  </div>

                  <div className="flex px-5 justify-between w-full flex-col">
                    <div className="ml-2">
                      <div>
                        <Checkbox
                          name="term_and_condition"
                          label="Term and condition"
                          checked={checked}
                          onChange={handleTermPopup}
                        />
                      </div>
                      <div>
                        <Checkbox
                          name="register_vendor"
                          label={
                            <>
                              Register Vendor{" "}
                              <ToolTip
                                title={STRINGS.VENDOR_REG_MESSAGE}
                                arrow={true}
                                placement="right"
                              >
                                <span>
                                  <BsInfoCircleFill className="reg-info-icon" />
                                </span>
                              </ToolTip>
                            </>
                          }
                          checked={checked2}
                          onChange={() => setHandleChecked2(!checked2)}
                        />
                      </div>
                    </div>
                    <div className="flex gap-x-2 mt-5 justify-end">
                      <div className="flex gap-2">
                        <Button
                          variant="contained"
                          onClick={() => p.handleEmailShare({ isVendorRegisterUser: checked2 })}
                          disabled={p.btnDisabled || !p.commentData || !checked}
                          label={"Send"}
                        />

                        <Button
                          onClick={() => p.setOpenSharePopup(false)}
                          disabled={p.btnDisabled}
                          // className="cancelDialogBtn"
                          variant="outlined"
                          label="Cancel"
                        />
                      </div>
                    </div>
                  </div>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export const VendorActions = (p: vendorProps) => {
  return (
    <div className="form-header-row-create">
      <Button
        type="button"
        // className="return-btn"
        onClick={() => p.onFinish(p.values, NEW_AGREEMENT_STATUS["approverPending"])}
        disabled={p.btnDisabled || !(p.agreementStatus === NEW_AGREEMENT_STATUS["vendorReview"])}
        label={
          <>
            <FiShare className="mr-1" /> Send to Approver
          </>
        }
        variant="outlined"
        // className="!w-40"
        isFullWidth={false}
      />

      {/* <Button
        type="button"
        // className="return-btn"
        onClick={() => window.open(p?.values?.e_sign_url)}
        disabled={p.btnDisabled || !(p.agreementStatus === NEW_AGREEMENT_STATUS["vendorEsign"])}
        label={
          <>
            <FaEdit className="mr-1" /> E-Sign
          </>
        }
        variant="outlined"
        isFullWidth={false}
        // className="!w-28"
      /> */}
    </div>
  );
};

// old

// /* eslint-disable */
// import { useState } from "react";
// import Dialog from "@material-ui/core/Dialog";
// import { DialogActions, DialogContent, DialogContentText, Input } from "@material-ui/core/";

// import { TiCancel } from "react-icons/ti";
// import { AiOutlineCheck } from "react-icons/ai";
// import { FiShare } from "react-icons/fi";
// import { FaEdit } from "react-icons/fa";
// import { BsInfoCircleFill } from "react-icons/bs";

// import { ToolTip, Button, ButtonGroups, TextInput, Checkbox } from "components";
// import { USER_ROLES, NEW_AGREEMENT_STATUS, STRINGS } from "helpers";
// import { Formik, Form, Field } from "formik";

// import AvailableUsersList from "./availableUserList";

// interface draftProps {
//   isOpen: boolean;
//   setOpen: Function;
//   values: any;
//   handleSubmit: any;
//   setValues: any;
//   agreementStatus: string;
//   btnDisabled: any;
//   usersList: usersInformation[];
//   skipReviewStep?: any;
//   submitForm: Function;
// }

// interface reviewerProps {
//   role: any;
//   values: any;
//   handleSubmit: any;
//   setValues: any;
//   agreementStatus: string;
//   comments?: any;
//   btnDisabled: any;
//   usersList: usersInformation[];
//   validateForm: Function;
//   submitForm: Function;
//   isUserModleOpen: boolean;
//   setUserModleOpen: Function;
// }

// interface approverProps {
//   handleEStamp: Function;
//   role: any;
//   values: any;
//   agreementStatus: string;
//   btnDisabled: any;
//   commentData: any;
//   setCommentData: Function;
//   handleRejectStatus: Function;
//   onFinish: Function;
//   handleEmailShare: any;
//   approvedByAllApprovers?: string;
//   openSharePopup: boolean;
//   setOpenSharePopup: Function;
//   isEmailModelOpen: boolean;
//   setIsEmailModelOpen: Function;
// }

// interface vendorProps {
//   values: any;
//   agreementStatus: string;
//   btnDisabled: any;
//   // setSubmitType: Function;
//   // handlePrint: Function;
//   onFinish: Function;
// }

// export const DraftActionButton = ({
//   role,
//   btnDisabled,
//   skipReviewStep,
//   agreementStatus,
//   setSubmitType,
//   setOpen,
//   handleSubmit,
//   validateForm,
//   setParentAgreementModalState,
// }) => {
//   const publishDraftHandler = async () => {
//     if (!role) {
//       //p.handleSubmit();
//       const isValidForm = await validateForm();
//       if (Object.keys(isValidForm).length !== 0) {
//         handleSubmit();
//         return;
//       }
//       setOpen(true);
//     } else if (skipReviewStep === "true") {
//       setSubmitType(NEW_AGREEMENT_STATUS["approve"]);
//     } else {
//       const isValidForm = await validateForm();
//       if (Object.keys(isValidForm).length !== 0) {
//         handleSubmit();
//         // setSubmitType(NEW_AGREEMENT_STATUS["review"]);
//         return;
//       }
//       setOpen(true);
//     }
//   };

//   const PublicDraftButton = () => (
//     <Button
//       // className={"publish-btn "}
//       variant="outlined"
//       color="primary"
//       type={!role ? "button" : "submit"}
//       disabled={
//         btnDisabled ||
//         (skipReviewStep === "true"
//           ? agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
//             agreementStatus !== NEW_AGREEMENT_STATUS["draft"]
//           : agreementStatus !== NEW_AGREEMENT_STATUS["reviewRejected"] &&
//             agreementStatus !== NEW_AGREEMENT_STATUS["draft"])
//       }
//       onClick={publishDraftHandler}
//       label={"Publish Draft"}
//     />
//   );

//   return (
//     <>
//       {/* <Dropdown as={ButtonGroup}>
//           <PublicDraftButton />
//           <Dropdown.Toggle split id="dropdown-split-basic" className={"publish-btn"} />
//           <Dropdown.Menu>
//             <Dropdown.Item
//               href="#"
//               className={"select-parent"}
//               onClick={() => {
//                 setParentAgreementModalState({ isOpen: true });
//               }}
//             >
//               Select Parent
//             </Dropdown.Item>
//           </Dropdown.Menu>
//         </Dropdown> */}
//       {agreementStatus === NEW_AGREEMENT_STATUS["reviewRejected"] ||
//       agreementStatus === NEW_AGREEMENT_STATUS["draft"] ? (
//         <ButtonGroups
//           options={[
//             {
//               name: "Publish Draft",
//               onClick: () => {
//                 publishDraftHandler();
//               },
//               disabled:
//                 btnDisabled ||
//                 (skipReviewStep === "true"
//                   ? agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
//                     agreementStatus !== NEW_AGREEMENT_STATUS["draft"]
//                   : agreementStatus !== NEW_AGREEMENT_STATUS["reviewRejected"] &&
//                     agreementStatus !== NEW_AGREEMENT_STATUS["draft"]),
//             },
//             {
//               name: "Select Parent",
//               onClick: () => {
//                 setParentAgreementModalState({ isOpen: true });
//               },
//               disabled: false,
//             },
//           ]}
//         />
//       ) : (
//         <PublicDraftButton />
//       )}
//     </>
//   );
// };

// export const DraftActions = (p: draftProps) => (
//   <AvailableUsersList
//     isOpen={p.isOpen}
//     handleClose={() => p.setOpen(false)}
//     role={p.skipReviewStep === "true" ? USER_ROLES["approver"] : USER_ROLES["reviewer"]}
//     values={p.values}
//     handleSubmit={p.submitForm}
//     btnDisabled={p.btnDisabled}
//     usersList={p.usersList}
//     setValues={p.setValues}
//     skipReviewStep={p.skipReviewStep}
//   />
// );

// export const ReviewerActionButtons = ({
//   btnDisabled,
//   agreementStatus,
//   selectedApproversByAllReviewers,
//   handleSubmit,
//   setSubmitType,
//   setUserModleOpen,
//   validateForm,
//   handleRejectStatus,
//   values,
// }) => (
//   <>
//     <Button
//       type="button"
//       disabled={
//         btnDisabled ||
//         (agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
//           agreementStatus !== NEW_AGREEMENT_STATUS["review"])
//       }
//       onClick={() =>
//         handleRejectStatus(NEW_AGREEMENT_STATUS["reviewRejected"], USER_ROLES["reviewer"], values)
//       }
//       label={
//         <>
//           <TiCancel className="mr-1" /> Return to Submitter
//         </>
//       }
//       variant="outlined"
//       isFullWidth={false}
//       // className="!w-40"
//     />

//     <Button
//       type={selectedApproversByAllReviewers !== "true" ? "button" : "submit"}
//       onClick={async () => {
//         if (selectedApproversByAllReviewers !== "true") {
//           const isValidForm = await validateForm();
//           if (Object.keys(isValidForm).length !== 0) {
//             handleSubmit();
//             return;
//           }
//           setUserModleOpen(true);
//         } else {
//           const isValidForm = await validateForm();
//           if (Object.keys(isValidForm).length !== 0) {
//             setSubmitType(NEW_AGREEMENT_STATUS["approve"]);
//             return;
//           }
//           setUserModleOpen(true);
//         }
//       }}
//       disabled={
//         btnDisabled ||
//         (agreementStatus !== NEW_AGREEMENT_STATUS["approveRejected"] &&
//           agreementStatus !== NEW_AGREEMENT_STATUS["review"])
//       }
//       label={
//         <>
//           <AiOutlineCheck className="mr-1" /> Approve
//         </>
//       }
//       variant="outlined"
//       isFullWidth={false}
//       // className="!w-24"
//     />
//   </>
// );

// export const ReviewerActions = (p: reviewerProps) => (
//   <div className="form-header-row">
//     {/* {p.agreementStatus === NEW_AGREEMENT_STATUS["approveRejected"]
//       ? RejectedComments({ comments: p.comments })
//       : null} */}
//     <AvailableUsersList
//       isOpen={p.isUserModleOpen}
//       handleClose={() => p.setUserModleOpen(false)}
//       role={USER_ROLES["approver"]}
//       values={p.values}
//       handleSubmit={p.submitForm}
//       btnDisabled={p.btnDisabled}
//       usersList={p.usersList}
//       setValues={p.setValues}
//     />
//   </div>
// );

// export const ApproverActionButtons = ({
//   agreementStatus,
//   btnDisabled,
//   role,
//   onFinish,
//   values,
//   approvedByAllApprovers,
//   setSubmitType,
//   userData,
//   setOpenSharePopup,
//   setIsEmailModelOpen,
//   handleRejectStatus,
//   isFormValueSet,
// }) => (
//   <>
//     {![NEW_AGREEMENT_STATUS["approverPending"], NEW_AGREEMENT_STATUS["approverEStamp"]].includes(
//       agreementStatus,
//     ) && (
//       <Button
//         type="button"
//         onClick={() =>
//           handleRejectStatus(
//             NEW_AGREEMENT_STATUS["approveRejected"],
//             USER_ROLES["approver"],
//             values,
//           )
//         }
//         disabled={
//           btnDisabled ||
//           !isFormValueSet ||
//           (agreementStatus !== NEW_AGREEMENT_STATUS["approve"] &&
//             agreementStatus !== NEW_AGREEMENT_STATUS["approverPending"] &&
//             agreementStatus !== NEW_AGREEMENT_STATUS["rejected"])
//         }
//         label={
//           <>
//             <TiCancel className="mr-1" />
//             Return to Submitter
//           </>
//         }
//         variant="outlined"
//         // className="!w-40"
//         isFullWidth={false}
//       />
//     )}
//     {agreementStatus !== NEW_AGREEMENT_STATUS["approverPending"] &&
//     agreementStatus !== NEW_AGREEMENT_STATUS["approverEStamp"] ? (
//       <>
//         <Button
//           type="button"
//           onClick={() =>
//             onFinish({ ...values, approver: userData.email }, NEW_AGREEMENT_STATUS["approved"])
//           }
//           disabled={
//             btnDisabled ||
//             !isFormValueSet ||
//             (agreementStatus !== NEW_AGREEMENT_STATUS["approve"] &&
//               agreementStatus !== NEW_AGREEMENT_STATUS["rejected"])
//           }
//           label={
//             <>
//               <AiOutlineCheck className="mr-1" />
//               Approve
//             </>
//           }
//           variant="outlined"
//           // className="!w-24"
//           isFullWidth={false}
//         />

//         <Button
//           onClick={() => {
//             // if (!role) {
//             setOpenSharePopup(true);
//             // }
//             // setSubmitType(NEW_AGREEMENT_STATUS["vendorReview"]);
//           }}
//           // type={!role ? "button" : "submit"}
//           disabled={
//             btnDisabled ||
//             !isFormValueSet ||
//             !(
//               agreementStatus === NEW_AGREEMENT_STATUS["approved"] &&
//               approvedByAllApprovers === "true"
//             )
//           }
//           label={
//             <>
//               <FiShare className="mr-1" />
//               Share
//             </>
//           }
//           variant="outlined"
//           // className="!w-24"
//           isFullWidth={false}
//         />
//       </>
//     ) : null}
//     {agreementStatus !== NEW_AGREEMENT_STATUS["approverPending"] ? null : (
//       <>
//         <Button
//           type="button"
//           onClick={() => setIsEmailModelOpen(true)}
//           disabled={btnDisabled}
//           label="EStamp"
//           variant="outlined"
//         />

//         <Button
//           type="button"
//           onClick={() => onFinish(values, NEW_AGREEMENT_STATUS["rejected"])}
//           disabled={btnDisabled || !isFormValueSet}
//           label={
//             <>
//               <TiCancel className="mr-1" size={20} />
//               Reject
//             </>
//           }
//           variant="outlined"
//         />
//       </>
//     )}
//     {/* {agreementStatus !== NEW_AGREEMENT_STATUS["approverEStamp"] ? null : (
//       <Button
//         type="button"
//         onClick={() => onFinish(values, NEW_AGREEMENT_STATUS["vendorEsign"])}
//         disabled={
//           btnDisabled ||
//           !isFormValueSet ||
//           !(agreementStatus === NEW_AGREEMENT_STATUS["approverEStamp"])
//         }
//         label="Send For E-sign"
//         variant="outlined"
//       />
//     )} */}
//   </>
// );

// export const ApproverActions = (p: approverProps) => {
//   const [checked, setHandleChecked] = useState<boolean>(false);
//   const [checked2, setHandleChecked2] = useState<boolean>(false);
//   const [termDialog, setTermDialog] = useState<boolean>(false);
//   // const [signOnly, setSignOnly] = useState<boolean>(false);
//   const [eSignUsername, setSignUserName] = useState<string>("");

//   const handleTermPopup = () => {
//     setHandleChecked(!checked);
//     setTermDialog(true);
//   };

//   // const handleSignOnly = () => {
//   //   setSignOnly(!signOnly);
//   // }

//   return (
//     <div className="form-header-row">
//       <Formik
//         initialValues={{
//           vendorEmail: "",
//           term_and_condition: false,
//         }}
//         // validationSchema={adminForm}
//         onSubmit={async (values, { setSubmitting }) => {
//           //
//         }}
//       >
//         {({ errors, touched, isSubmitting }) => {
//           return (
//             <>
//               <div>
//                 <Dialog
//                   open={termDialog}
//                   aria-labelledby="alert-dialog-title"
//                   aria-describedby="alert-dialog-description"
//                 >
//                   <DialogContent className="w-[450px]">
//                     {p.agreementStatus !== NEW_AGREEMENT_STATUS.approved ? (
//                       <DialogContentText id="alert-dialog-description">
//                         {`By Clicking on OK we are sending this agreement to ${p.commentData} with username ${eSignUsername}
//                for internal e-sign which can't be roll back again. Please be careful while entering details`}{" "}
//                         :
//                       </DialogContentText>
//                     ) : (
//                       <DialogContentText id="alert-dialog-description">
//                         {`By Clicking on OK we are sending this agreement to ${p.commentData}
//               for vendor review which can't be roll back again. Please be careful while entering details`}{" "}
//                         :
//                       </DialogContentText>
//                     )}
//                   </DialogContent>
//                   <DialogActions>
//                     <Button
//                       onClick={() => setTermDialog(false)}
//                       variant="contained"
//                       label={"OK"}
//                       isFullWidth={false}
//                     />
//                   </DialogActions>
//                 </Dialog>
//                 <Dialog
//                   open={p.isEmailModelOpen}
//                   onClose={() => p.setIsEmailModelOpen(false)}
//                   aria-labelledby="alert-dialog-title"
//                   aria-describedby="alert-dialog-description"
//                 >
//                   <DialogContent className="w-full mt-5 hidden">
//                     <DialogContentText id="alert-dialog-description"></DialogContentText>
//                   </DialogContent>
//                   <DialogActions className="!w-[450px] !pt-3 !px-2 flex flex-col">
//                     {/* {p.agreementStatus !== NEW_AGREEMENT_STATUS.approverPending ? */}
//                     <>
//                       <div className="w-11/12">
//                         {/* <div className="auth-checkbox d-">
//                     <input type="checkbox" checked={signOnly} onChange={handleSignOnly} />
//                     <label>Sign Only</label>
//                   </div> */}

//                         <h3 className="al-user-label text-center mb-3">Send for Esign</h3>

//                         <TextInput
//                           type={"text"}
//                           placeholder={"Enter name of signer"}
//                           label={"Name of signer"}
//                           name="username-1"
//                           // disableUnderline={true}
//                           // value={eSignUsername}
//                           onCustomActionBlur={(e) => {
//                             setSignUserName(e.target.value);
//                           }}
//                           className="mb-3"
//                         />

//                         <TextInput
//                           type={"text"}
//                           placeholder={STRINGS.ESIGN_PROCESS}
//                           label="Email"
//                           // disableUnderline={true}
//                           name="email-1"
//                           // value={p.commentData}
//                           onCustomActionBlur={(e) => {
//                             p.setCommentData(e.target.value);
//                           }}
//                         />

//                         <div className="ml-2.5 mt-2">
//                           <Checkbox
//                             name="term_and_condition"
//                             label="Term and condition"
//                             checked={checked}
//                             onChange={handleTermPopup}
//                           />
//                         </div>
//                       </div>
//                       <div className="flex justify-end mt-3 mb-1 w-11/12">
//                         <div className="flex  mr-1">
//                           <Button
//                             onClick={async () => {
//                               p.setIsEmailModelOpen(false);
//                               await p.handleEStamp(
//                                 p.commentData,
//                                 eSignUsername,
//                                 p.values,
//                                 p.onFinish,
//                               );
//                               //await p.onFinish(p.values, NEW_AGREEMENT_STATUS["approverEStamp"]);
//                             }}
//                             disabled={!p.commentData || !eSignUsername || !checked || p.btnDisabled}
//                             variant="contained"
//                             label="Send"
//                           />

//                           <Button
//                             style={{ marginLeft: "10px" }}
//                             onClick={() => p.setIsEmailModelOpen(false)}
//                             disabled={p.btnDisabled}
//                             variant="outlined"
//                             label="Cancel"
//                           />
//                         </div>
//                       </div>
//                     </>
//                   </DialogActions>
//                 </Dialog>
//               </div>

//               <Dialog
//                 open={p.openSharePopup}
//                 onClose={() => p.setOpenSharePopup(false)}
//                 aria-labelledby="alert-dialog-title"
//                 aria-describedby="alert-dialog-description"
//               >
//                 <DialogContent className="hidden">
//                   <DialogContentText id="alert-dialog-description"></DialogContentText>
//                 </DialogContent>
//                 <DialogActions className="flex flex-col !py-5 !px-2 !w-[450px]">
//                   <div className="w-11/12 mb-2">
//                     <h4 className="text-center mb-5">Share with Vendor</h4>
//                     <TextInput
//                       type={"text"}
//                       label={STRINGS.SHARE_TO_VENDOR}
//                       placeholder={"Email"}
//                       // disableUnderline={true}
//                       value={p.commentData}
//                       onCustomActionBlur={(e) => {
//                         p.setCommentData(e.target.value);
//                       }}
//                       name="vendorEmail"
//                     />
//                   </div>

//                   <div className="flex px-5 justify-between w-full flex-col">
//                     <div className="ml-2">
//                       <div>
//                         <Checkbox
//                           name="term_and_condition"
//                           label="Term and condition"
//                           checked={checked}
//                           onChange={handleTermPopup}
//                         />
//                       </div>
//                       <div>
//                         <Checkbox
//                           name="register_vendor"
//                           label={
//                             <>
//                               Register Vendor{" "}
//                               <ToolTip
//                                 title={STRINGS.VENDOR_REG_MESSAGE}
//                                 arrow={true}
//                                 placement="right"
//                               >
//                                 <span>
//                                   <BsInfoCircleFill className="reg-info-icon" />
//                                 </span>
//                               </ToolTip>
//                             </>
//                           }
//                           checked={checked2}
//                           onChange={() => setHandleChecked2(!checked2)}
//                         />
//                       </div>
//                     </div>
//                     <div className="flex gap-x-2 mt-5 justify-end">
//                       <div className="flex gap-2">
//                         <Button
//                           variant="contained"
//                           onClick={() => p.handleEmailShare({ isVendorRegisterUser: checked2 })}
//                           disabled={p.btnDisabled || !p.commentData || !checked}
//                           label={"Send"}
//                         />

//                         <Button
//                           onClick={() => p.setOpenSharePopup(false)}
//                           disabled={p.btnDisabled}
//                           // className="cancelDialogBtn"
//                           variant="outlined"
//                           label="Cancel"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </DialogActions>
//               </Dialog>
//             </>
//           );
//         }}
//       </Formik>
//     </div>
//   );
// };

// export const VendorActions = (p: vendorProps) => {
//   return (
//     <div className="form-header-row-create">
//       <Button
//         type="button"
//         // className="return-btn"
//         onClick={() => p.onFinish(p.values, NEW_AGREEMENT_STATUS["approverPending"])}
//         disabled={p.btnDisabled || !(p.agreementStatus === NEW_AGREEMENT_STATUS["vendorReview"])}
//         label={
//           <>
//             <FiShare className="mr-1" /> Send to Approver
//           </>
//         }
//         variant="outlined"
//         // className="!w-40"
//         isFullWidth={false}
//       />

//       {/* <Button
//         type="button"
//         // className="return-btn"
//         onClick={() => window.open(p?.values?.e_sign_url)}
//         disabled={p.btnDisabled || !(p.agreementStatus === NEW_AGREEMENT_STATUS["vendorEsign"])}
//         label={
//           <>
//             <FaEdit className="mr-1" /> E-Sign
//           </>
//         }
//         variant="outlined"
//         isFullWidth={false}
//         // className="!w-28"
//       /> */}
//     </div>
//   );
// };
