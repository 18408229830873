import { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./style.scss";
import { Button, Typography } from "@material-ui/core";
import VersionListData from "./versionListData";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { FaFileImport, FaHistory } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { AiOutlineEye } from "react-icons/ai";
import { formattedDate } from "../../../../helpers";

// ICONS
import { MdCompare } from "react-icons/md";

// COMPONENTS
import { ToolTip } from "components";


const VersionList = (p) => {
  // STATES
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // FUNCTIONS
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(0);
  };


  return (
    <div className="version-list-container">
      <div className="w-full">
        <span className="title">
          <div className="flex justify-evenly items-center">
            <FaHistory />
            <span className="versionHistory">Version History</span>
            {/* <GrCompare className="ml-10" size={18} onClick={versionCompareHandler} /> */}
          </div>
          <span onClick={() => p.close()}>
            <IoMdClose />
          </span>
        </span>
      </div>
      <div className="version-list-body">
        {p?.data?.length > 0 &&
          p?.data.map((i, key) => {
            return (
              <Card className="version-card-container" key={key}>
                <div className="grid-container w-full h-full" key={key}>
                  <div className="flex flex-col justify-center items-center grid-item  w-full h-full">
                    <CardContent className="!w-full !h-full">
                      {i.version_type === "imported" && (
                        <span className="importIcon">
                          <FaFileImport />
                        </span>
                      )}
                      <Typography className="versionNumber" gutterBottom>
                        Version: {i?.version}
                        <span
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            setCurrentIndex(key);
                            handleClickOpen();
                          }}
                        >
                          <AiOutlineEye />
                        </span>
                        {(p?.data?.length - 1) != key &&
                          <ToolTip title="Compare Document" arrow={true}>
                            <span
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                // setCurrentIndex(key);
                                p?.onClickVersionCompare(key);
                              }}
                            >
                              <MdCompare />
                            </span></ToolTip>}
                      </Typography>
                      {/* <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                          Created on : {i.created}
                        </Typography> */}
                      <Typography className="modifiedText" gutterBottom>
                        <span className="truncate">Modified by : {i.actioner_name}</span>
                        <span className="mt-2">Date : {formattedDate(i.created)}</span>
                      </Typography>
                    </CardContent>
                  </div>
                  {key === currentIndex ? (
                    <Dialog fullScreen open={open} onClose={handleClose}>
                      <AppBar style={{ position: "relative", height: "64px !important", backgroundColor: "#560BAD" }}>
                        <Toolbar>
                          <Typography variant="h5" component="div" style={{ flex: 1 }}>
                            <h4 className="title">Version {i?.version}</h4>
                          </Typography>
                          <Button onClick={handleClose} className="!bg-white">
                            Close
                          </Button>
                        </Toolbar>
                      </AppBar>
                      <VersionListData list={i} userTokens={p.userTokens} close={close} />
                    </Dialog>
                  ) : null}
                </div>
              </Card>
            );
          })}
      </div>
    </div>
  );
};
export default VersionList;
