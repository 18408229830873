import React from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";

import {
  Button as MuiButton,
  ButtonProps,
  styled,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@mui/material";
import { FaCaretDown } from "react-icons/fa";

//
import { Button } from "components";

import "./style.scss";

interface otherProps {
  className?: string;
  variant?: "contained" | "outlined";
  type?: "button" | "submit";
  disabled?: boolean;
  style?: object;
  onClick?: Function;
  color?: "primary" | "secondary";

  options: Array<{ name: string; onClick?: Function; disabled?: boolean }>;
  // readOnly?: boolean;
  // onAction?: Function;
  // onActionBlur?: Function;
  // highlightText?: string;
  // showRiskWarning?: boolean;
  // id?: string;
  // isRequired?: boolean;
}

const ButtonGroupComponent = (p: otherProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    console.info(`You clicked ${p?.options}`);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const customClasses = () => {
    let classList = "";

    if (p.variant === "contained" || p.variant === undefined) {
      switch (p.color) {
        case "primary":
        case undefined:
          classList += ` !bg-primary-500 !text-white disabled:opacity-70`;
          break;
        case "secondary":
          classList += ` !bg-secondary-500 !text-white disabled:opacity-70`;
          break;
      }
    } else if (p.variant === "outlined") {
      switch (p.color) {
        case "primary":
        case undefined:
          classList += ` !border !border-solid !border-primary-400 !text-primary-500 disabled:opacity-70`;
          break;
        case "secondary":
          classList += ` `;
          break;
      }
    }

    return classList;
  };

  return (
    <React.Fragment>
      <ButtonGroup ref={anchorRef} aria-label="split button">
        <Button
          label={p?.options[0].name}
          variant="outlined"
          onClick={() => {
            if (p?.options[0].onClick) {
              p?.options[0].onClick();
            }
          }}
          className="!rounded-e-none"
          disabled={p?.options[0].disabled}
        />
        <MuiButton
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className="!border !border-solid !border-primary-400 !text-primary-500 disabled:opacity-70 !rounded-e-md"
          sx={{
            ":hover": {
              bgcolor: "primary.main", // theme.palette.primary.main
              color: "white",
            },
            ":hover > svg": {
              color: "white",
            },
          }}
        >
          <FaCaretDown />
        </MuiButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className="w-36">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem className="flex flex-col ">
                  {p?.options &&
                    p?.options.map((option, index) => {
                      if (index !== 0) {
                        return (
                          <MenuItem
                            key={index}
                            onClick={(event) => {
                              if (p?.options[index]?.onClick) {
                                p?.options[index]?.onClick?.();
                              }
                              setOpen(false);
                            }}
                            className="hover:bg-primary-500 hover:text-white"
                          >
                            {option.name}
                          </MenuItem>
                        );
                      }
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );

  // return (
  //   <ColorButton
  //     {...p}
  //     variant={p.variant || "contained"}
  //     color={p.color || "primary"}
  //     type={p.type || "button"}
  //     disabled={p.disabled || false}
  //     className={`
  //     ${customClasses()}
  //     w-full !rounded-sm !py-1.5 !px-5 ${p.className}`}
  //     style={p.style}
  //   >
  //     {p.label}
  //   </ColorButton>
  // );
};

export default ButtonGroupComponent;
