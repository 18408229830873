import { toast } from "react-toastify";

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getClassName = (value) => {
  switch (value?.toLowerCase()) {
    case "review":
      return "status-review";
    case "rejected":
      return "status-rejected";
    case "approve rejected":
      return "status-approve-rejected";
    case "approver pending":
      return "status-approver-pending";
    case "draft":
      return "status-draft";
    case "approve":
      return "status-approve";
    case "approved":
      return "status-approved";
    case "vendor review":
      return "status-vendor-review";
    case "review rejected":
      return "status-review-rejected";
    case "pending-signature":
      return "status-pending-signature";
    case "approver estamp":
      return "status-approve-estamp";
    case "vendor esign":
      return "status-vendor-esign";
    case "signed":
      return "status-signed";
    case "executed":
      return "status-executed";
    case "imported":
      return "status-imported";
  }
};

export const showLogInConsole = (msg: string, data: object) => {
  // console.log(msg, data);
};

export const setDataInLocalStorage = (key: string, data: any) => {
  return localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return JSON.parse(data || JSON.stringify({}));
};

export const clearFromLocalStoarge = () => {
  localStorage.clear();
};

export const showToast = (msg: string, success: boolean) => {
  return toast.error(msg, {
    toastId: "toast",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    theme: "dark",
    draggable: true,
    progress: undefined,
    type: success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
  });
};

export const sanitizeUserData = async (data: Array<{ Name: string; Value: string }>) => {
  interface LooseObject {
    [key: string]: any;
  }
  const obj: LooseObject = {};
  for (const a of data) {
    let key = a["Name"];
    const val = a["Value"];
    if (key.includes("custom:")) {
      key = key.substr(key.indexOf(":") + 1, key.length);
    }
    obj[key] = val;
  }
  return obj;
};

export interface asgreementProps {
  data: {
    _id?: string;
    agreementDate: string;
    expiryDate: string;
    type: string;
    contract_name: string;
    firstPartyName: string;
    firstPartyAddress: string;
    secondPartyName: string;
    secondPartyAddress: string;
    termOfAgreement: string;
    agreementStatus: string;
    deliveryLocationAddress: string;
    incoterms: string;
    penalty: string;
    price: string;
    product: string;
    productTimeline: string;
    qualityRequired: string;
    comments: Array<any>;
    customerName?: string;
    reviewer?: string;
    approver?: string;
    vendor?: string;
    selectedApproversByAllReviewers?: string;
    approvedByAllApprovers?: string;
    skipReviewStep?: string;

    //fieds for rental agreement
    propertyDetails: string;
    monthlyRent: string;
    duration: string;
    negativeCovenant: string;
    securityDepositAmount?: string;
    anyLockIn: string;
    jurisdiction: string;

    //fields for LLA agreement
    firstPartyAge: string;
    firstPartyFatherName: string;
    lockInPeriod: string;
    licenseFeeRent: string;
    licenseFeeMonths: string;
    licenseFeePercent: string;
    period: string;
    monthlyLicenseFee: string;
    licensor: string;
    licensee: string;
    terminationNotice: string;
    compensationAmount: string;
    premisesB1: string;
    premisesB2: string;
    premisesB3: string;
    premisesB4: string;

    //fields for Business
    firstyPartyPan: string;
    secondPartyPan: string;
    purpose: string;
    businessDetails: string;
    transferredBusiness: string;
    includeDetails: string;
    purchaseConsideration: string;
    firstPartyNoticeDetails: string;
    secondPartyNoticeDetails: string;
    firstPartyRepresentative: string;
    secondPartyRepresentative: string;
    firstPartyBoardResolution: string;
    //secondPartyBoardResolution: string;
    firstPartyAttn: string;
    firstPartyEmail: string;
    secondPartyAttn: string;
    secondPartyEmail: string;

    //
    is_byot?: boolean;
    customFieldMappingData?: Array<object>;
  };
  handleField: Function;
  getSfdtText: Function;
  usersList: any;
  fetchData: Function;
  hasFeildReadonly: Function;
  handleEStamp: Function;
  handleAutoSave: Function;
  agreementRef?: any;
  createTempCopy?: any;
  setButtonDisabled: Function;
  setSubmitType: Function;
  buttonDisabled: boolean;
  submitType: any;
  setIsOpen: Function;
  isOpen: boolean;
  setCommentModleOpen: Function;
  isCommentModleOpen: boolean;
  isUserModleOpen: boolean;
  setUserModleOpen: Function;
  openSharePopup: boolean;
  setOpenSharePopup: Function;
  isEmailModelOpen: boolean;
  setIsEmailModelOpen: Function;
  setAgreementData: Function;

  // handleCustomField
  handleCustomField: Function;
}

export const getAgreementSchema = (Yup: any) => {
  return Yup.object().shape({
    contract_name: Yup.string().required("Required"),
    // agreementDate: Yup.date().label("agreementDate").required("Required"), //.min(new Date(Date.now() - 86400000), "Date cannot be in the past"),
    agreementDate: Yup.string().label("agreementDate").required("Required"),
    expiryDate: Yup.string().label("expiryDate").required("Required"),
    // .min(Yup.ref("agreementDate"), "Expire date can't be less than start date"),
    firstPartyName: Yup.string().required("Required"),
    firstPartyAddress: Yup.string().required("Required"),
    secondPartyName: Yup.string().required("Required"),
    secondPartyAddress: Yup.string().required("Required"),
    termOfAgreement: Yup.string().required("Required"),
    deliveryLocationAddress: Yup.string().required("Required"),
    incoterms: Yup.string().required("Required"),
    penalty: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    product: Yup.string().required("Required"),
    productTimeline: Yup.string().required("Required"),
    qualityRequired: Yup.string().required("Required"),
  });
};

export const getAgreementType4Schema = (Yup: any) => {
  return Yup.object().shape({
    contract_name: Yup.string().required("Required"),
    agreementDate: Yup.string().label("agreementDate").required("Required"), //.min(new Date(Date.now() - 86400000), "Date cannot be in the past"),
    //executionDate:Yup.string().label("executionDate").required("Required"),
    employmentDate: Yup.string().label("employmentDate").required("Required"),
    effectiveDate: Yup.string().label("effectiveDate").required("Required"),
    expiryDate: Yup.string().label("expiryDate").required("Required"),
    // .min(Yup.ref("agreementDate"), "Expire date can't be less than start date"),
    firstPartyName: Yup.string().required("Required"),
    firstPartyAddress: Yup.string().required("Required"),
    secondPartyName: Yup.string().required("Required"),
    secondPartyFatherName: Yup.string().required("Required"),
    identificationNumber: Yup.string().required("Required"),
    designation: Yup.string().required("Required"),
    reportingAuthority: Yup.string().required("Required"),
    compensationAmount: Yup.string().required("Required"),
  });
};

export const getAgreementType5Schema = (Yup: any) => {
  return Yup.object().shape({
    contract_name: Yup.string().required("Required"),
    agreementDate: Yup.string().label("agreementDate").required("Required"),
    firstPartyName: Yup.string().required("Required"),
    firstPartyAddress: Yup.string().required("Required"),
    secondPartyName: Yup.string().required("Required"),
    secondPartyFatherName: Yup.string().required("Required"),
    secondPartyAddress: Yup.string().required("Required"),
    offerLetterReleaseDt: Yup.string().label("offerLetterReleaseDt").required("Required"),
    letterAcceptedDate: Yup.string().label("letterAcceptedDate").required("Required"),
    designation: Yup.string().required("Required"),
    employmentDate: Yup.string().label("employmentDate").required("Required"),
    compensationAmount: Yup.string().required("Required"),
    secondPartyEmail: Yup.string().required("Required"),
  });
};
//fieds for other rental
export const getAgreementSchema2 = (Yup: any) => {
  return Yup.object().shape({
    contract_name: Yup.string().required("Required"),
    agreementDate: Yup.string().label("agreementDate").required("Required"),
    expiryDate: Yup.string().label("expiryDate").required("Required"),
    firstPartyName: Yup.string().required("Required"),
    firstPartyAddress: Yup.string().required("Required"),
    secondPartyName: Yup.string().required("Required"),
    secondPartyAddress: Yup.string().required("Required"),
    termOfAgreement: Yup.string().required("Required"),

    propertyDetails: Yup.string().required("Required"),
    monthlyRent: Yup.string().required("Required"),
    duration: Yup.string().required("Required"),
    negativeCovenant: Yup.string().required("Required"),
    securityDepositAmount: Yup.string().required("Required"),
    jurisdiction: Yup.string().required("Required"),
  });
};

//fieds for other LLA
export const getAgreementSchema3 = (Yup: any) => {
  return Yup.object().shape({
    contract_name: Yup.string().required("Required"),
    agreementDate: Yup.string().label("agreementDate").required("Required"),
    expiryDate: Yup.string().label("expiryDate").required("Required"),
    firstPartyName: Yup.string().required("Required"),
    firstPartyFatherName: Yup.string().required("Required"),
    firstPartyAddress: Yup.string().required("Required"),
    secondPartyName: Yup.string().required("Required"),
    secondPartyAddress: Yup.string().required("Required"),
    termOfAgreement: Yup.string().required("Required"),

    firstyPartyPan: Yup.string().required("Required"),
    firstPartyAge: Yup.string().required("Required"),
    premisesB1: Yup.string().required("Required"),
    premisesB2: Yup.string().required("Required"),
    premisesB3: Yup.string().required("Required"),
    premisesB4: Yup.string().required("Required"),
    licenseFeeRent: Yup.string().required("Required"),
    licenseFeeMonths: Yup.string().required("Required"),
    licenseFeePercent: Yup.string().required("Required"),
    period: Yup.string().required("Required"),
    monthlyLicenseFee: Yup.string().required("Required"),
    licensor: Yup.string().required("Required"),
    licensee: Yup.string().required("Required"),
    lockInPeriod: Yup.string().required("Required"),
    terminationNotice: Yup.string().required("Required"),
    compensationAmount: Yup.string().required("Required"),
    securityDepositAmount: Yup.string().required("Required"),
    jurisdiction: Yup.string().required("Required"),
  });
};

//fieds for other bussiness agrment
export const getAgreementSchema4 = (Yup: any) => {
  return Yup.object().shape({
    contract_name: Yup.string().required("Required"),
    agreementDate: Yup.string().label("agreementDate").required("Required"),
    expiryDate: Yup.string().label("expiryDate").required("Required"),
    firstPartyName: Yup.string().required("Required"),
    firstPartyAddress: Yup.string().required("Required"),
    secondPartyName: Yup.string().required("Required"),
    secondPartyAddress: Yup.string().required("Required"),
    //termOfAgreement: Yup.string().required("Required"),

    firstyPartyPan: Yup.string().required("Required"),
    secondPartyPan: Yup.string().required("Required"),
    purpose: Yup.string().required("Required"),
    businessDetails: Yup.string().required("Required"),
    transferredBusiness: Yup.string().required("Required"),
    includeDetails: Yup.string().required("Required"),
    purchaseConsideration: Yup.string().required("Required"),
    //firstPartyNoticeDetails: Yup.string().required("Required"),
    firstPartyAttn: Yup.string().required("Required"),
    firstPartyEmail: Yup.string().email("Invalid email").required("Required"),
    secondPartyAttn: Yup.string().required("Required"),
    secondPartyEmail: Yup.string().email("Invalid email").required("Required"),
    firstPartyRepresentative: Yup.string().required("Required"),
    secondPartyRepresentative: Yup.string().required("Required"),
    firstPartyBoardResolution: Yup.string().required("Required"),
    //secondPartyBoardResolution: Yup.string().required("Required"),
  });
};

//

export const getByotSchema = (Yup: any) => {
  return Yup.object().shape({
    // // agreementDate: Yup.date().label("agreementDate").required("Required"), //.min(new Date(Date.now() - 86400000), "Date cannot be in the past"),
    // agreementDate: Yup.string().label("agreementDate").required("Required"),
    // expiryDate: Yup.string().label("expiryDate").required("Required"),
    // // .min(Yup.ref("agreementDate"), "Expire date can't be less than start date"),
    // firstPartyName: Yup.string().required("Required"),
    // firstPartyAddress: Yup.string().required("Required"),
    // secondPartyName: Yup.string().required("Required"),
    template_name: Yup.string().required("Required"),
  });
};

//

export const autoLogoutUser = (tokenExpireAt: any) => {
  // return new Date() >= new Date(tokenExpireAt);
};

export const importFileNameSanitizing = (str: any) => {
  const ext = str.split(".").pop();
  let filename = str.substring(0, str.lastIndexOf(ext) - 1);
  filename = filename
    .replace(/[^a-zA-Z0-9]/g, " ")
    .replace(/  +/g, " ")
    .trim();
  return filename + `.${ext}`;
};

export const formattedDate = (date) => {
  const newDate = new Date(date);
  const month = newDate.toLocaleString("default", { month: "short" });
  const hours = newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours();
  const minutes = newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes();
  return `${newDate.getDate()} ${month} at ${hours}:${minutes}`;
};

export const addParentDetails = (values) => {
  const parentId = sessionStorage.getItem("parentId");
  const parentIndex = sessionStorage.getItem("parentIndex");
  const parentName = sessionStorage.getItem("parentName");

  if (parentId && parentIndex && parentName) {
    values.parent_id = parentId;
    values.parent_name = parentName;
    values.node_type = "child";
    values.parent_type = parentIndex;
  } else {
    if (values.parent_id || values.parent_name || values.parent_type) return values;
    const unassigned = "not_assigned";
    values.parent_id = unassigned;
    values.parent_name = unassigned;
    values.node_type = unassigned;
    values.parent_type = unassigned;
  }

  return values;
};


export const urlToFile = async (url: string) => {
  try {
    const fileRes = await fetch(url);
    const blob = await fileRes.blob();

    // let fileName = getFilenameFromContentDisposition(fileRes);
    // if (!fileName) {
    //   fileName = url.split("/").pop();
    // }

    const file = new File([blob], "test", {
      type: blob.type,
    });

    return file;
  } catch (error: any) {
    console.log(error.message);
  }
}

export const blobToFile = (blob: Blob, fileName: string) => {
  try {
    const file = new File([blob], fileName, {
      type: blob.type,
    });

    return file;
  } catch (error: any) {
    console.log(error.message);
  }
}

export const isFileCompareDataEmpty = (data: Array<any>) => {
  if (data.length <= 0) {
    return true;
  } else {
    return false;
  }
}