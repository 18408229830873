import { ReactElement, useEffect, useState } from "react";
import { TextInput, DateInput } from "components/FormFields";
import CollapseMode from "./collapse";

//import { asgreementProps } from "../../../../../helpers";

interface props {
  data: any;
  values: any;
  errors: any;
  hasFeildReadonly: Function;
  //   handleField: Function;
  handleCustomField: Function;
}

interface CustomFieldMappingData {
  defaultValue: string;
  isRequired: boolean;
  fieldApiName: string;
  fieldLabel: string;
  selectedField: string;
  fieldType: string;
  sequence: number;
}

const TypeByotForm = (p: props) => {
  const [isExpandAll, setIsExpandAll] = useState(true);
  const [dynamicElements, setDynamicElements] = useState<any>([]);

  const generateDynamicElements = async () => {
    try {
      const sortedSequenceData: Array<any> = p?.data?.customFieldMappingData.sort((a, b) => {
        return a.sequence - b.sequence;
      });

      const tempArray = sortedSequenceData.map((item: CustomFieldMappingData, index: number) => {
        switch (item.fieldType) {
          case "text":
            return (
              <TextInput
                name={item.fieldApiName}
                type="text"
                label={item.fieldLabel}
                placeholder={item.fieldLabel}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleCustomField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                key={index}
              />
            );
          case "date":
            return (
              <DateInput
                name={item.fieldApiName}
                label={item.fieldLabel}
                id="agreementDate"
                placeholder={item.fieldLabel}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleCustomField}
                // maxDate={p.values.agreementDate}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                key={index}
              />
            );

          case "number":
            return (
              <TextInput
                name={item.fieldApiName}
                label={item.fieldLabel}
                placeholder={item.fieldLabel}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleCustomField}
                // onCustomChange={(e: any) => {
                //   // let numbers = /^[0-9]+$/;
                //   // if (e.target.value.match(numbers)) {
                //   //   return false;
                //   // }

                //   // return true;
                // }}
                onKeyDown={(e: any) => {
                  var keyCode = e.keyCode || e.which;
                  if ((keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122)) {
                    e.preventDefault();
                    return false;
                  }
                }}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
                key={index}
              />
            );

          default:
            return "end";
        }
      });

      setDynamicElements(tempArray);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    generateDynamicElements();
  }, []);

  return (
    <>
      <div className="new-agreement-form-container">
        <span className="enterFieldText">Enter the fields to see the inputs on the left</span>
        <span className="collapseAllText" onClick={() => setIsExpandAll(!isExpandAll)}>
          {isExpandAll ? "Collapse All" : "Expand All"}
        </span>
        <CollapseMode
          title="Name of Contract"
          forceOpen={isExpandAll}
          children={
            <TextInput
              name={"contract_name"}
              type="text"
              label="File Name"
              placeholder={"File Name"}
              // className={"agreement-text-field"}
              id={"fileName"}
              readOnly={p.hasFeildReadonly({
                status: p?.data?.agreementStatus,
                forAll: true,
              })}
            />
          }
        />
        <CollapseMode
          title="General"
          forceOpen={isExpandAll}
          children={
            <>
              {dynamicElements.map((childItem) => childItem)}
              {/* <DateInput
                name={"agreementDate"}
                label="Execution Date"
                id="agreementDate"
                placeholder={"Execution Date"}
                className={"agreement-text-field"}
                maxDate={p.values.agreementDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyName"}
                type="text"
                label="First Party Name"
                placeholder={"First Party Name"}
                className={"agreement-text-field"}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyAddress"}
                type="text"
                label="First Party Address"
                placeholder={"First Party Address"}
                className={"agreement-text-field"}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyName"}
                type="text"
                label="Second Party Name"
                placeholder={"Second Party Name"}
                className={"agreement-text-field"}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyFatherName"}
                type="text"
                label="Second Party Father Name"
                placeholder={"Second Party Father Name"}
                className={"agreement-text-field"}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyAddress"}
                type="text"
                label="Second Party Address"
                placeholder={"Second Party Address"}
                className={"agreement-text-field"}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyEmail"}
                type="text"
                label="Second Party Email"
                placeholder={"Second Party Email"}
                className={"agreement-text-field"}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"offerLetterReleaseDt"}
                label="Offer Letter Release Date"
                placeholder={"Offer Letter Release Date"}
                className={"agreement-text-field"}
                maxDate={p.values.offerLetterReleaseDt}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"letterAcceptedDate"}
                label="Offer Letter Accepted Date"
                placeholder={"Offer Letter Accepted Date"}
                className={"agreement-text-field"}
                maxDate={p.values.letterAcceptedDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"designation"}
                type="text"
                label="Designation"
                placeholder={"Designation"}
                className={"agreement-text-field"}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"employmentDate"}
                label="Employment Date"
                placeholder={"Employment Date"}
                className={"agreement-text-field"}
                maxDate={p.values.employmentDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"compensationAmount"}
                type="text"
                label="Compensation Amount"
                placeholder={"Compensation Amount"}
                className={"agreement-text-field"}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              /> */}
            </>
          }
        />
      </div>
    </>
  );
};

export default TypeByotForm;
