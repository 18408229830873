import { useState } from "react";
import { TextInput, DateInput } from "components/FormFields";
import CollapseMode from "./collapse";

//import { asgreementProps } from "../../../../../helpers";

interface props {
  data: any;
  values: any;
  errors: any;
  hasFeildReadonly: Function;
  handleField: Function;
}

const Type5Form = (p: props) => {
  const [isExpandAll, setIsExpandAll] = useState(true);
  return (
    <>
      <div className="new-agreement-form-container">
        <span className="enterFieldText">Enter the fields to see the inputs on the left</span>
        <span className="collapseAllText" onClick={() => setIsExpandAll(!isExpandAll)}>
          {isExpandAll ? "Collapse All" : "Expand All"}
        </span>
        <CollapseMode
          title="Name of Contract"
          forceOpen={isExpandAll}
          children={
            <TextInput
              name={"contract_name"}
              type="text"
              label="File Name"
              placeholder={"File Name"}
              // className={"agreement-text-field"}
              id={"fileName"}
              readOnly={p.hasFeildReadonly({
                status: p?.data?.agreementStatus,
                forAll: true,
              })}
            />
          }
        />
        <CollapseMode
          title="General"
          forceOpen={isExpandAll}
          children={
            <>
              <DateInput
                name={"agreementDate"}
                label="Execution Date"
                id="agreementDate"
                placeholder={"Execution Date"}
                // className={"agreement-text-field"}
                className="my-1"
                maxDate={p.values.agreementDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyName"}
                type="text"
                label="First Party Name"
                placeholder={"First Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"firstPartyAddress"}
                type="text"
                label="First Party Address"
                placeholder={"First Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyName"}
                type="text"
                label="Second Party Name"
                placeholder={"Second Party Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyFatherName"}
                type="text"
                label="Second Party Father Name"
                placeholder={"Second Party Father Name"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyAddress"}
                type="text"
                label="Second Party Address"
                placeholder={"Second Party Address"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"secondPartyEmail"}
                type="text"
                label="Second Party Email"
                placeholder={"Second Party Email"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"offerLetterReleaseDt"}
                label="Offer Letter Release Date"
                placeholder={"Offer Letter Release Date"}
                // className={"agreement-text-field"}
                className="my-1"
                maxDate={p.values.offerLetterReleaseDt}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"letterAcceptedDate"}
                label="Offer Letter Accepted Date"
                placeholder={"Offer Letter Accepted Date"}
                // className={"agreement-text-field"}
                className="my-1"
                maxDate={p.values.letterAcceptedDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"designation"}
                type="text"
                label="Designation"
                placeholder={"Designation"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <DateInput
                name={"employmentDate"}
                label="Employment Date"
                placeholder={"Employment Date"}
                // className={"agreement-text-field"}
                className="my-1"
                maxDate={p.values.employmentDate}
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />

              <TextInput
                name={"compensationAmount"}
                type="text"
                label="Compensation Amount"
                placeholder={"Compensation Amount"}
                // className={"agreement-text-field"}
                className="my-1"
                onActionBlur={p.handleField}
                readOnly={p.hasFeildReadonly({
                  status: p?.data?.agreementStatus,
                  forAll: false,
                })}
              />
            </>
          }
        />
      </div>
    </>
  );
};

export default Type5Form;
