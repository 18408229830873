import React from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
//import { VscCalendar } from "react-icons/vsc";

import "./style.scss";

interface otherProps {
  label: string;
  readOnly?: boolean;
  onAction?: Function;
  onActionBlur?: Function;
  highlightText?: string;
  showRiskWarning?: boolean;
  minDate?: any;
  maxDate?: any;
  //
  isLabelShown?: boolean;
  isCustomInput?: boolean;
}

const DateField = (p: otherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(p);
  const { setFieldValue } = useFormikContext();

  const handleOnBlur = (d: any) => {
    if (p?.onActionBlur && !p?.readOnly) {
      const date = d;
      p.onActionBlur({ value: moment(date).format("DD/MM/YYYY"), name: field.name });
      setFieldValue(field.name, moment(date).format("DD/MM/YYYY"));
    }
  };

  const checkIfRiskExist = () => {
    if (field.name === "expiryDate") {
      const agreementDateInput = document?.getElementsByName(
        "agreementDate",
      )?.[0] as HTMLInputElement;
      if (agreementDateInput?.value && field.value) {
        const startDate = moment(agreementDateInput?.value, "DD-MM-YYYY");
        const endDate = moment(field.value, "DD-MM-YYYY");
        const monthDiff = endDate.diff(startDate, "months", true);
        const yearDiff = monthDiff / 12;
        if (yearDiff > parseInt(process.env.REACT_APP_TENURE_TO_HIT_AGREEMENT_RISK || "2")) {
          return (
            <div className="field-error risk-warning">{`Risk: The Tenure is more than ${
              process.env.REACT_APP_TENURE_TO_HIT_AGREEMENT_RISK || "2"
            } years.  `}</div>
          );
        }
        return null;
      }
    }
    return null;
  };

  return (
    <div
      className={
        p.isCustomInput === undefined || p.isCustomInput === false
          ? "form-group"
          : "custom-date-form-group"
      }
    >
      <>
        <label className="date-picker-wrapper" onClick={(e) => e.preventDefault()}>
          {p.isLabelShown === undefined || p.isLabelShown === true ? (
            <label>{p.label}</label>
          ) : null}
          <DatePicker
            {...field}
            className={p.className}
            placeholderText={p.placeholder}
            onChange={(date: Date) => handleOnBlur(date)}
            yearDropdownItemNumber={20}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            value={
              field.value && field.value !== "NaN/NaN/NaN" && field.value !== "Invalid date"
                ? moment(field.value, "DD/MM/YYYY").toDate()
                : ""
            }
            dateFormat="dd/MM/yyyy"
            selected={
              field.value && field.value !== "NaN/NaN/NaN" && field.value !== "Invalid date"
                ? moment(field.value, "DD/MM/YYYY").toDate()
                : ""
            }
            readOnly={p.readOnly || false}
            id={field.name}
            minDate={
              p?.minDate !== "NaN/NaN/NaN" && p?.minDate && p?.minDate !== "Invalid date"
                ? moment(p?.minDate, "DD/MM/YYYY").toDate()
                : null
            }
            maxDate={
              p?.maxDate !== "NaN/NaN/NaN" && p?.maxDate && p?.maxDate !== "Invalid date"
                ? moment(p?.maxDate, "DD/MM/YYYY").toDate()
                : null
            }
            todayButton="Today"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            required={p.required}
          />

          {/* <VscCalendar className='form-date-icon' /> */}
        </label>
      </>
      {meta.touched && meta.error ? (
        <div className="field-error" title={meta?.error}>
          {meta.error}
        </div>
      ) : p.showRiskWarning ? (
        checkIfRiskExist()
      ) : null}
    </div>
  );
};

export default DateField;
