/* eslint-disable */
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PlainInput from "../FormFields/PlainFormFields/PlainInput";
import { Checkbox, Button } from "components/FormFields";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";
import { InputAdornment } from "@mui/material";

// ICONS
import { FaEye, FaEyeSlash } from "react-icons/fa";


import "./styles.scss";

import {
  API_ROUTES,
  INPUT_FIELD_NAMES,
  postRequest,
  RESPONSE_MSGS,
  sanitizeUserData,
  showLogInConsole,
  showToast,
  STATUS_CODE,
  STRINGS,
  VALIDATION_MSGS,
  USER_ROLES,
} from "../../helpers";
import { useDispatch } from "react-redux";

//
import { updateUserData } from "../../redux/actions";

//
interface LoginSchema {
  email: string;
  password: string;
  captcha: string;
}

//
const SigninSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, VALIDATION_MSGS.TOO_SHORT)
    .max(50, VALIDATION_MSGS.TOO_LONG)
    .required(VALIDATION_MSGS.REQUIRED),
  email: Yup.string().email(VALIDATION_MSGS.INVALID_EMAIL).required(VALIDATION_MSGS.REQUIRED),
  captcha: Yup.string().required(VALIDATION_MSGS.REQUIRED),
});

const SigninForm = ({
  onLoginSuccess = (user: { email: string; persona: string; isTaskAssigned?: boolean }) => { },
}) => {
  const dispatch = useDispatch();

  // STATES
  const [pwdVisible, setPwdVisible] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);

  // FUNCTIONS
  const onFinish = async (values: LoginSchema, actions: any) => {
    try {
      setProcessing(true);
      const res: any = await postRequest(API_ROUTES.LOGIN, false, values);

      let { data } = res || {};
      let { ResponseMetadata, message } = data || {};
      let { HTTPStatusCode } = ResponseMetadata || {};


      if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
        showToast(message, false);
      } else {
        // showToast(RESPONSE_MSGS.LOGGED_IN, true);
        actions.resetForm({ email: "", password: "" });

        let { AuthenticationResult, user_attributes, user_details = {} } = data || {};
        let timeObject1 = new Date();
        timeObject1 = new Date(timeObject1.getTime() + AuthenticationResult.ExpiresIn * 1000);
        let userTokens = {
          TokenExpiresAt: timeObject1,
          AccessToken: AuthenticationResult.AccessToken,
          IdToken: AuthenticationResult.IdToken,
          RefreshToken: AuthenticationResult.RefreshToken,
        };
        const userData = await sanitizeUserData(user_attributes);
        if (!userData.persona) {
          console.log("user_attributes not have persona");
          userData.persona = USER_ROLES["vendor"];
          console.log("user_attributes persona added ", userData);
        }

        dispatch(updateUserData({ userData: { ...userData, ...user_details }, userTokens: userTokens }))

        onLoginSuccess({
          email: values.email,
          persona: userData?.persona,
          isTaskAssigned: data?.isTaskAssigned || false,
        });
      }
    } catch (err) {
      setProcessing(false);
      showLogInConsole("ERROR In Login ", err);
      showToast(RESPONSE_MSGS.ERROR, false);

    } finally {
      setProcessing(false);
    }
  };
  const pwdVisibilityToggler = () => {
    setPwdVisible(prev => !prev);
  }

  // EFFECTS
  useEffect(() => {
    loadCaptchaEnginge(4);
    return () => { };
  }, []);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        captcha: "",
      }}
      validationSchema={SigninSchema}
      onSubmit={(values, actions) => {
        if (!validateCaptcha(values.captcha)) {
          actions.setValues({ ...values, captcha: "" });
          showToast("Wrong captcha try again", false);
          return;
        }
        values.password = btoa(values.password);
        onFinish(values, actions);
      }}
    >
      {({ setValues, values, errors, touched }) => {
        return (
          <Form className="singin-form-container">

            {/* EMAIL */}
            <PlainInput
              // name={INPUT_FIELD_NAMES.EMAIL}
              // type="email"
              label="Username"
              placeholder={"Enter Username"}
              className="mt-2"
              onChange={(e) => {
                setValues({ ...values, email: e.target.value });
              }}
              autoComplete="off"
            />
            <div className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">
              {touched.email && errors.email}
            </div>

            {/* PASSWORD */}
            <PlainInput
              // name={INPUT_FIELD_NAMES.PASSWORD}
              type={pwdVisible ? "text" : "password"}
              label="Password"
              placeholder={"Enter Password"}
              // placeholder={STRINGS.ENTER_PASSWORD}
              className="mt-1"
              onChange={(e) => {
                setValues({ ...values, password: e.target.value });
                // e.target.type = "password";
              }}
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
              }}
              endAdornment={<InputAdornment position="end" className="cursor-pointer">
                {pwdVisible ? <FaEyeSlash size={22} onClick={pwdVisibilityToggler} /> : <FaEye size={22} onClick={pwdVisibilityToggler} />}
              </InputAdornment>}
            />



            <div className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">
              {touched.password && errors.password}
            </div>
            {/*  */}
            <div className="flex items-center">
              <LoadCanvasTemplate reloadText=" " />

              <div>
                <PlainInput
                  // name={INPUT_FIELD_NAMES.PASSWORD}
                  // type="password"
                  label="Captcha"
                  placeholder={"Enter Captcha"}
                  // placeholder={STRINGS.ENTER_PASSWORD}
                  className="mt-1"
                  onChange={(e) => {
                    setValues({ ...values, captcha: e.target.value });
                  }}
                  autoComplete="off"
                  value={values.captcha}
                />

                <div className="text-[#d32f2f] text-[0.75rem] w-full ml-2 h-[18px]">
                  {touched.captcha && errors.captcha}
                </div>
              </div>
            </div>

            {/*  */}
            <div className="w-full ml-4">
              <Checkbox value={"remember-me"} id={"remember_me"} label="Remember me" name="tmp" />
            </div>
            <Button
              label={STRINGS.LOGIN_TO_ACCOUNT}
              className="!mt-4"
              variant="contained"
              color="primary"
              type="submit"
              disabled={processing}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default SigninForm;
