/* eslint-disable */
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { TextInput, PasswordInput, Checkbox, Button } from "../FormFields";
import {
  API_ROUTES,
  INPUT_FIELD_NAMES,
  postRequest,
  RESPONSE_MSGS,
  showLogInConsole,
  showToast,
  STATUS_CODE,
  STRINGS,
  VALIDATION_MSGS,
} from "../../helpers";
import { useState } from "react";

import "./styles.scss";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneCodeRegExp = /^[^a-z][0-9]+$/;
const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: "",
  phoneNumber: "",
  password: "",
  companyName: "",
  country: "",
  cpassword: "",
};

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, VALIDATION_MSGS.TOO_SHORT)
    .max(50, VALIDATION_MSGS.TOO_LONG)
    .required(VALIDATION_MSGS.REQUIRED),
  // lastName: Yup.string()
  //   .min(2, VALIDATION_MSGS.TOO_SHORT)
  //   .max(50, VALIDATION_MSGS.TOO_LONG)
  //   .required(VALIDATION_MSGS.REQUIRED),
  email: Yup.string().email(VALIDATION_MSGS.INVALID_EMAIL).required(VALIDATION_MSGS.REQUIRED),
  // countryCode: Yup.string()
  //   .required(VALIDATION_MSGS.REQUIRED)
  //   .matches(phoneCodeRegExp, VALIDATION_MSGS.INVALID_CODE),
  // phoneNumber: Yup.string()
  //   .required(VALIDATION_MSGS.REQUIRED)
  //   .matches(phoneRegExp, VALIDATION_MSGS.INVALID_PHONENO),
  password: Yup.string()
    .min(8, VALIDATION_MSGS.TOO_SHORT)
    .max(50, VALIDATION_MSGS.TOO_LONG)
    .required(VALIDATION_MSGS.REQUIRED)
    .matches(passwordRegExp, VALIDATION_MSGS.PASSWORD_PATTERN_INVALID),
  cpassword: Yup.string()
    .required(VALIDATION_MSGS.REQUIRED)
    .test("passwords-match", "Passwords must match.", function (value) {
      return this.parent.password === value;
    }),
  // country: Yup.string()
  //   .min(2, VALIDATION_MSGS.TOO_SHORT)
  //   .max(50, VALIDATION_MSGS.TOO_LONG)
  //   .required(VALIDATION_MSGS.REQUIRED),
  // companyName: Yup.string()
  //   .min(2, VALIDATION_MSGS.TOO_SHORT)
  //   .max(50, VALIDATION_MSGS.TOO_LONG)
  //   .required(VALIDATION_MSGS.REQUIRED),
});

const SignupForm = ({ onRegisterSuccess = (user: { email: string }) => {} }) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const onFinish = (
    values: {
      companyName: string;
      country: string;
      countryCode: string;
      cpassword: string;
      email: string;
      firstName: string;
      lastName: string;
      password: string;
      phoneNumber: string;
    },
    actions: any,
  ) => {
    setProcessing(true);
    let { companyName, country, email, firstName, lastName, password } = values;

    postRequest(API_ROUTES.SIGN_UP, false, {
      company_name: companyName || "",
      country: country || "",
      password: password,
      phone_number: "",
      email: email,
      given_name: firstName,
      family_name: lastName || "",
    })
      .then((res: any) => {
        let { data } = res || {};
        let { ResponseMetadata, message } = data || {};
        let { HTTPStatusCode } = ResponseMetadata || {};
        if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
          showToast(message, false);
        } else {
          showToast(RESPONSE_MSGS.OTP_SENT, true);
          actions.resetForm(initialValues);
          onRegisterSuccess({ email: email });
        }
        setProcessing(false);
      })
      .catch((err) => {
        showLogInConsole("ERROR In Signup ", err);
        showToast(RESPONSE_MSGS.ERROR, false);
        setProcessing(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      // eslint-disable-next-line class-methods-use-this, no-unused-vars
      onSubmit={(values, actions) => {
        onFinish(values, actions);
      }}
    >
      {() => (
        <Form className="singup-form-container">
          <TextInput
            name={INPUT_FIELD_NAMES.NAME}
            type="text"
            label={STRINGS.FIRST_NAME}
            placeholder={STRINGS.FIRST_NAME}
          />
          <TextInput
            name={INPUT_FIELD_NAMES.EMAIL}
            type="email"
            label={STRINGS.ENTER_YOUR_EMAIL}
            placeholder={STRINGS.ENTER_YOUR_EMAIL}
          />

          <PasswordInput
            name={INPUT_FIELD_NAMES.PASSWORD}
            type="password"
            label={STRINGS.ENTER_PASSWORD}
            placeholder={STRINGS.ENTER_PASSWORD}
          />
          <PasswordInput
            name={INPUT_FIELD_NAMES.C_PASSWORD}
            type="password"
            label={STRINGS.CONFIRM_PASSWORD}
            placeholder={STRINGS.CONFIRM_PASSWORD}
          />

          {/* <div className="row">
            <TextInput
              name={INPUT_FIELD_NAMES.COMPANY_NAME}
              type="text"
              label=""
              placeholder={STRINGS.COMPANY_NAME}
              className={"singup-text-field"}
            />
          </div> */}

          {/* <div className="auth-checkbox">
            <input type="checkbox" value="remember-me" id="remember_me" />
            <label>
              By signing up you agree to our <span>term & conditions</span> and{" "}
              <span>privacy policy</span>{" "}
            </label>
          </div> */}

          <div className="w-full ml-4">
            <Checkbox
              value={"remember-me"}
              id={"remember_me"}
              label={
                <>
                  By signing up you agree to our{" "}
                  <span className="cursor-pointer text-primary-500">term & conditions</span> and
                  <span className="cursor-pointer text-primary-500">privacy policy</span>
                </>
              }
            />
          </div>

          {/* <div className="signup-submit">
            <button type="submit" className="form-button" disabled={processing}>
              {"Submit"}
            </button>
          </div> */}
          <Button
            label={STRINGS.SUBMIT}
            className="!mt-4"
            variant="contained"
            type="submit"
            disabled={processing}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
