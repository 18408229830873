import { useEffect, useState } from "react";
import { Checkbox, Grid } from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

// HELPERS
import { API_ROUTES, fetchAgreementList, getRequest, saveTemplateChanges } from "helpers";

// REDUX
import { setAgreementList as storeAgreementList } from "redux/actions";

// COMPONENTS
import DashboardLayout from "layouts/dashboardLayout";
import TableJs from "components/Table/table";
import { LoaderHOC, Button } from "components";



const AccessManagemnt = () => {
  const dispatch = useDispatch();

  // SELECTORS
  const user = useSelector((state: any) => state.common.userData) || [];
  const list = useSelector((state: any) => state.agreement.templateList) || [];

  // STATES
  const [agreementList, setAgreementList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);

  // FUNCTIONS
  const getAgreementList = async () => {
    try {

      if (list?.length) {
        setAgreementList(list);
        // setAgreementType({ ...list[0] });
      } else {
        const res: any = await getRequest(
          `${API_ROUTES.GET_AGREEMENT_LIST}?client_id=${user.client_id}`,
          true,
        );

        if (res.data && res.data.status === 200) {
          setIsLoading(false);
          const modedData = res.data?.response.sort((a, b) => b.createdDate.localeCompare(a.createdDate)).filter((item) => !item.is_thirdparty) || []
          setAgreementList(modedData);
          // setAgreementType({ _id: modedData?.[0]?._id });

          dispatch(storeAgreementList(modedData));
          // setAgreementType({ ...modedData[0] });
        }
      }

    } catch (err) {
      console.log("error ==>", err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAgreementList();
  }, []);

  const handleClick = (index: any, source: string, value: any) => {
    const row = agreementList[index];
    row[source] = value.toString();
    agreementList[index] = row;
    setAgreementList(agreementList);
  };

  const handleSaveChanges = async (data: any) => {
    const { _id, skipReviewStep } = data;
    await saveTemplateChanges({
      id: _id,
      skipReviewStep: skipReviewStep || false,
      setBtnDisabled: setBtnDisabled,
    });
  };

  const columns = [
    {
      Header: "Sr No.",
      accessor: "",
      Cell: (d: { cell: any; value: string; row: any }) => {
        const index = d?.row?.index;
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>{index + 1}</Grid>
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Template Name",
      accessor: "template_name",
      Cell: (d: { cell: any; value: string }) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>{d.value}</Grid>
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Template Type",
      accessor: "template_type",
      Cell: (d: { cell: any; value: string }) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>{d.value}</Grid>
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Skip Review",
      accessor: "skipReviewStep",
      Cell: (d: { cell: any; row: any }) => {
        const index = d?.row?.index;
        const skipReviewStep = d?.row?.original?.skipReviewStep;
        const checkedValue = skipReviewStep === "true" ? true : false;
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>
                <Checkbox
                  color="primary"
                  onChange={(e) => {
                    handleClick(index, "skipReviewStep", Boolean(e.target.checked));
                  }}
                  defaultChecked={checkedValue}
                />
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "",
      Cell: (d: { cell: any; value: boolean; row: any }) => {
        const data = d?.row?.original;
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    handleSaveChanges(data);
                  }}
                  variant="outlined"
                  disabled={btnDisabled}
                  label="Save"
                />
              </Grid>
            </Grid>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DashboardLayout
        hederTitle={"Welcome Admin"}
        childer={
          <>
            <div className="access-container" style={{ padding: 40 }}>
              <div className="access-content" style={{ backgroundColor: "white", padding: 10 }}>
                {/* table with templates list and roles checkboxes */}
                <div className="access-table-block">
                  <TableJs
                    columns={columns}
                    data={agreementList}
                    isRowSelectEnable={false}
                    headerProps={{ isEnable: true, type: 1 }}
                    isRowsPerPageChangeRequired={true}
                    isCurrentRowsCountVisibilityRequired={true}
                  />
                  {isLoading ? (
                    <div className="center-elements">
                      <LoaderHOC></LoaderHOC>
                    </div>
                  ) : agreementList.length ? null : (
                    <div className="mt-3 center-elements">No Data Found...</div>
                  )}
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
export default AccessManagemnt;
