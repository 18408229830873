import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaCheck, FaExclamationCircle } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useNavigate } from "react-router";

import FileUpload from "../fileUpload";
import ImportAgreementListItem from "../ImportAgreementListItem";
import {
  AGREEMENT_UPLOAD_STATUS,
  IMPORT_TYPES,
  postRequest,
  RESPONSE_MSGS,
  ROUTE_CONSTANTS,
  showToast,
  STATUS_CODE,
  STRINGS,
} from "../../helpers";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { previewFile, userLogout } from "../../redux/actions";
import SearchableParentAgreements, { ParentAgreement } from "../SearchableParentAgreements";
import { DocumentEditor } from "@syncfusion/ej2-react-documenteditor";
import LoaderHOC from "components/LoaderHOC";

interface otherProps {
  closePopup: () => {};
  refetchDataAfterImport?: Function;
  importType: string;
  onPreview?: Function;
  showParentAgreementSelection?: boolean;
  cautionMessage?: string;
  isUploading?: boolean;
  onUploadStart?: () => void;
  onUploadFinish?: () => void;
}

const UploadAgreementForm = (p: otherProps) => {
  const { closePopup, refetchDataAfterImport, isUploading, onUploadStart, onUploadFinish } = p;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // STATES
  const [processing, setProcessing] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [failedImportCount, setCountForFailedImport] = useState<number>(0);
  const [agreementToImport, setAgreementFilesToImport] = useState<any>([]);
  const [processedAgreement, setProcessedAgreement] = useState<Array<number>>([]);
  const [redirectToRepository, setRedirectToRepository] = useState<boolean>(false);
  const [parentObject, setParentObject] = useState<Partial<ParentAgreement>>({});

  const onFinish = async () => {
    try {
      if (p.onPreview) {
        onUploadStart();
        let documenteditor: DocumentEditor = new DocumentEditor({ enableSfdtExport: true, enableWordExport: true, enableTextExport: true });
        documenteditor.appendTo('#ej2-importDoc');

        const formData = new FormData();
        formData.append("files", agreementToImport[0]);

        const res: any = await fetch(
          "https://wordapi-dev.app.onecounsel.in/api/documenteditor/Import",
          {
            body: formData,
            method: "POST"
          }
        );
        const responseData = await res.json()
        sessionStorage.setItem("previewFile", URL.createObjectURL(agreementToImport[0]));
        const fileName = agreementToImport[0].name;
        sessionStorage.setItem("tempFileName", fileName);

        documenteditor.open(responseData);
        const sfdtData = documenteditor.serialize();

        await p.onPreview(sfdtData.toString());
        documenteditor.destroy();

        // ---- x ---- x ---- x ------
        // BELOW CODE THROWING CORS ERROR
        // postRequest("https://wordapi-dev.app.onecounsel.in/api/documenteditor/Import", false, formData)
        //   .then((res: any) => {
        //     const { data } = res || {};
        //     const { ResponseMetadata } = data || {};
        //     const { HTTPStatusCode } = ResponseMetadata || {};
        //     if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
        //       dispatch(previewFile(data));
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("err", err);
        //   });

        // const { data } = res || {};
        // const { ResponseMetadata } = data || {};
        // const { HTTPStatusCode } = ResponseMetadata || {};
        // if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
        //   dispatch(previewFile(data));
        // }

        // sessionStorage.setItem("previewFile", URL.createObjectURL(agreementToImport[0]));
        // const fileName = agreementToImport[0].name;
        // sessionStorage.setItem("tempFileName", fileName);
        // p.onPreview();

      } else {
        setProcessing(AGREEMENT_UPLOAD_STATUS.IMPORTING);
      }
    } catch (err) {
      console.log("err", err);
      showToast("Something Went Wrong", false);
      onUploadFinish();
    }
  };

  const setEachAgreementProcessingStatus = (index: number, value: number) => {
    const processedAgreementArr = processedAgreement;
    processedAgreementArr[index] = value;
    setProcessedAgreement(processedAgreementArr);
    let successCount = 0,
      failureCount = 0;
    for (let i = 0; i < processedAgreementArr.length; i++) {
      if (processedAgreementArr[i] === 0) {
        failureCount++;
      } else if (processedAgreementArr[i] === 1) {
        successCount++;
      }
    }
    setRedirectToRepository(true);
    if (
      successCount + failureCount === agreementToImport.length &&
      (successCount !== 0 || failureCount !== 0)
    ) {
      if (!failureCount) {
        setProcessing(AGREEMENT_UPLOAD_STATUS.DONE);
        showToast(RESPONSE_MSGS.AGREEMENT_UPLOAD_SUCCESS, true);
      } else {
        setCountForFailedImport(failureCount);
        setProcessing(AGREEMENT_UPLOAD_STATUS.PARTIAL_DONE);
        showToast(RESPONSE_MSGS.AGREEMENT_UPLOAD_FAILED, false);
      }
    }
  };

  const reUploadAgreement = (index: number) => {
    setProcessing(AGREEMENT_UPLOAD_STATUS.IMPORTING);
    processedAgreement[index] = 2;
    setProcessedAgreement(processedAgreement);
  };

  const renderFileList = () => {
    return agreementToImport.map((file: any, index: number) => (
      <ImportAgreementListItem
        parentObject={parentObject}
        file={file}
        index={index}
        setEachAgreementProcessingStatus={setEachAgreementProcessingStatus}
        reUploadAgreement={reUploadAgreement}
        key={`File-To-Import-${index}`}
        importType={p.importType}
      />
    ));
  };

  const handleOnClose = () => {
    closePopup();
    if (redirectToRepository) {
      navigate(ROUTE_CONSTANTS.Repository);
      refetchDataAfterImport && refetchDataAfterImport();
    }
  };

  useEffect(() => {
    let subTitle: string;
    subTitle = `Importing ${agreementToImport.length} ${p.importType === IMPORT_TYPES.LICENSE ? "license..." : "agreement..."
      }`;
    if (agreementToImport.length > 1) {
      subTitle = `Bulk importing ${agreementToImport.length} ${p.importType === IMPORT_TYPES.LICENSE ? "licenses..." : "agreements..."
        }`;
    }
    setSubTitle(subTitle);
  }, [agreementToImport.length]);

  return (
    <div
      className={`upload-agreement-container ${p.showParentAgreementSelection ? "parent-selector" : ""
        }`}
    >
      {isUploading ?
        <div className="h-[25.4rem]">
          <LoaderHOC />
        </div>
        : <>
          <button
            className="upload-cross-wrapper"
            title="Close"
            onClick={() => handleOnClose()}
            disabled={processing === AGREEMENT_UPLOAD_STATUS.IMPORTING}
          >
            <AiOutlineClose className="close" />
          </button>

          <h3
            className={`agreement-title ${processing === AGREEMENT_UPLOAD_STATUS.IMPORTING ? "upload-processing-title" : ""
              }`}
          >
            {p.importType === IMPORT_TYPES.LICENSE
              ? `${!processing ? "Import an License" : "Importing License"}`
              : p.importType === IMPORT_TYPES.AGREEMENT
                ? `${!processing ? "Import Agreement" : "Importing Agreement"}`
                : `${!processing ? "Agreement Preview" : "Previewing Agreement"}`}
          </h3>
          {p.showParentAgreementSelection ? (
            <div style={{ width: "90%", margin: "2rem 0 1.5rem" }}>
              <SearchableParentAgreements setParent={setParentObject} />
            </div>
          ) : null}

          {!processing ? (
            <>
              <p className="agreement-upload-content my-2">
                {p.importType === IMPORT_TYPES.LICENSE ? (
                  ""
                ) : (
                  <>
                    <span className="agreement-upload-content-text">
                      {/* Please drag and drop your existing agreements and let OneCounsel do its magic. */}
                      Drag and Drop you Agreement & Validate
                    </span>
                    <span className="dont-forget-to-validate">
                      Once uploaded, dont forget to validate
                    </span>
                  </>
                )}
                {p.cautionMessage && <span className="cautionMessage">{p.cautionMessage}</span>}
              </p>
              <FileUpload
                agreementToImport={agreementToImport}
                setAgreementFilesToImport={setAgreementFilesToImport}
                importType={p.importType}
              />
              <div
                className="upload-agreement-submit"
                {...(p.cautionMessage && {
                  style: { marginTop: "1rem" },
                })}
              >
                <button
                  type="submit"
                  disabled={!!processing || !agreementToImport.length}
                  onClick={onFinish}
                  className="text-white cursor-pointer"
                >
                  {isUploading ? "Uploading..." : STRINGS.UPLOAD}
                </button>
              </div>
            </>
          ) : (
            <div className="processing-view-content">
              <div className="processing-title">
                <div className="processing-loader">
                  {processing === AGREEMENT_UPLOAD_STATUS.DONE ? (
                    <FaCheck className="agreements-processed" />
                  ) : processing === AGREEMENT_UPLOAD_STATUS.PARTIAL_DONE ? (
                    <FaExclamationCircle className="agreements-failed" />
                  ) : (
                    <CircularProgress className="agreements-processing" />
                  )}
                </div>
                <div className="processing-file-message">
                  <p className="sub-title1">{subTitle}</p>
                  <p className="sub-title2">
                    {processing === AGREEMENT_UPLOAD_STATUS.DONE
                      ? "Done!"
                      : processing === AGREEMENT_UPLOAD_STATUS.PARTIAL_DONE
                        ? `${failedImportCount} ${p.importType === IMPORT_TYPES.LICENSE ? "licenses" : "agreements"
                        } import failed!`
                        : "Please wait!"}
                  </p>
                </div>
              </div>

              <div className="processing-file-list">{renderFileList()}</div>
            </div>
          )}
          <div id="ej2-importDoc" className="hidden"></div>
        </>}
    </div>
  );
};

export default UploadAgreementForm;