import { addParentDetails, IMAGES } from "../../../../helpers";
import { useLocation, useNavigate } from "react-router";
import { useRef } from "react";
import {
  getSIgnedURLFromS3,
  postRequest,
  RESPONSE_MSGS,
  showToast,
  NEW_AGREEMENT_STATUS,
  API_ROUTES,
  STATUS_CODE,
  ROUTE_CONSTANTS,
  USER_ROLES,
} from "helpers";
import { useSelector } from "react-redux";
import DashboardLayout from "layouts/dashboardLayout";
import { Button, LoaderHOC } from "components";
import { FaEdit } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { forceLogout } from "redux/services";
import { DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";

export default function signedDocAgreement() {
  const [url, setSignedURL] = useState("");
  const location: any = useLocation();
  const navigate = useNavigate();
  const userTokens = useSelector((state: any) => state.common.userTokens) || {};
  const [loading, setLoading] = useState(true);
  const [agreementData, setAgreementData] = useState<any>([]);
  const [btnDisabled, setButtonDisabled] = useState(false);
  const userData = useSelector((state: any) => state.common.userData) || {};

  //
  const [isSfdtLoaded, setIsSfdtLoaded] = useState(false);
  let docRef = useRef<DocumentEditorContainerComponent>(null);

  const fetchData = async (p: {
    _id: string;
    url: string;
    type?: string;
    current_user?: string;
  }) => {
    try {
      setLoading(true);
      const res = await postRequest(p.url, true, {
        _id: p._id,
        current_user: p.current_user,
      });
      const { data }: any = res;
      await forceLogout({ status: data?.status_code, message: data?.message });

      if (data && data.response) {
        const agreement = data.response || {};
        const sfdtText = agreement.editor_data || "";
        const type = location?.state?.template_type || agreement?.type;
        setAgreementData({ sfdt: sfdtText, formData: { ...agreement, type } } || {});

        setIsSfdtLoaded(true);

        // const key_name = location?.state?.contract_name || "";

        // getSIgnedURLFromS3(
        //   process?.env?.REACT_APP_AWS_REGION || "",
        //   process?.env?.REACT_APP_AWS_IDENTITY_POOL_ID || "",
        //   process?.env?.REACT_APP_AWS_USER_POOL || "",
        //   process?.env?.REACT_APP_AGREEMENT_BUCKET_NAME_LEGALITY || "",
        //   userTokens?.IdToken,
        //   // key_name + ".pdf",
        //   `${data.response._id}.pdf`,
        //   (signed_url: string) => {
        //     setSignedURL(signed_url);
        //     setLoading(false);
        //   },
        // );

        setLoading(false);
        //showToast(RESPONSE_MSGS.EXTRACTION_AGREEMENT_SUCCESS, true);
      } else {
        showToast(data?.message || RESPONSE_MSGS.ERROR, false);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      showToast(RESPONSE_MSGS.ERROR, false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData({
        _id: location?.state?._id,
        url: API_ROUTES.GET_NEW_USER_AGREEMENT,
        current_user: userData.email,
      });
    })();
  }, []);

  useEffect(() => {
    // console.log(docRef);

    // if (docRef.current) {

    // }
    // agreementData

    const timeStamp = setInterval(() => {
      if (docRef.current) {
        const tempRef: any = docRef?.current;

        tempRef.documentEditor?.open(agreementData.sfdt);
        clearInterval(timeStamp);
      }
    }, 1000);
  }, [isSfdtLoaded]);

  const handlePrint = () => {
    // window.open(url);
  };

  // const onFinish = async (values: any, status: any) => {
  //   try {
  //     setButtonDisabled(true);
  //     values = addParentDetails(values);
  //     const res = await postRequest(API_ROUTES.CREATE_NEW_AGREEMENT, true, {
  //       ...values,
  //       agreementStatus: status,
  //       actioner: userData?.email,
  //     });
  //     const { data }: any = res || {};
  //     await forceLogout({ status: data?.status_code, message: data?.message });

  //     if (data.status !== STATUS_CODE.SUCCESS) {
  //       if (data.status === 404) {
  //         showToast(data?.message, false);
  //       } else {
  //         showToast(RESPONSE_MSGS.ERROR, false);
  //       }
  //     } else if (data.status === 400) {
  //       showToast(data?.message, false);
  //       setButtonDisabled(false);
  //     } else {
  //       setButtonDisabled(false);
  //       showToast(RESPONSE_MSGS.NEW_AGREEMENT_SUCCESS, true);
  //       navigate(ROUTE_CONSTANTS.My_Tasks);
  //     }
  //     setButtonDisabled(false);
  //   } catch (err: any) {
  //     showToast(RESPONSE_MSGS.ERROR, false);
  //     setButtonDisabled(false);
  //   }
  // };

  // const eSignUrl = agreementData?.formData?.e_sign_url;

  return (
    <>
      <DashboardLayout
        hederTitle={<>{"Signed Agreement"}</>}
        sideText={""}
        childer={
          <div className="create-agreement-container">
            {loading ? (
              <div className="loading">
                <LoaderHOC />
              </div>
            ) : (
              <div className="agreement-content" style={{ justifyContent: "center" }}>
                <div className="agreement-viewer-section">
                  {agreementData?.formData?.agreementStatus ===
                    NEW_AGREEMENT_STATUS["vendorEsign"] &&
                  userData.persona === USER_ROLES["vendor"] ? (
                    <div className="flex vendor-action-btn ml-3 mt-3">
                      <Button
                        onClick={handlePrint}
                        title={"Download docx"}
                        label="Download"
                        isFullWidth={false}
                      />
                    </div>
                  ) : (
                    <Button
                      // className="docx-button"
                      onClick={handlePrint}
                      title={"Download docx"}
                      label="Download"
                      isFullWidth={false}
                    />
                  )}

                  {isSfdtLoaded ? (
                    <>
                      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                      <Viewer fileUrl={url} />
                    </Worker> */}
                      <DocumentEditorContainerComponent
                        id="ej2-container"
                        // ref={docRef}

                        ref={docRef}
                        serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
                        enableToolbar={false}
                        showPropertiesPane={false}
                        restrictEditing={true}
                        enableComment={false}
                        enableTrackChanges={false}
                        documentEditorSettings={{
                          formFieldSettings: {
                            shadingColor: "rgba(86, 11, 173,0)",
                          },
                          optimizeSfdt: true,
                        }}
                      />
                    </>
                  ) : (
                    <div className="no-agreement-found">
                      {" "}
                      <img src={IMAGES.NoDataFound} alt="" /> No doc found
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
}
