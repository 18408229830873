/* eslint-disable */
import { REHYDRATE } from "redux-persist/es/constants";
import {
  SET_AGREEMENT_LIST,
  SET_USER_AGREEMENTS,
  UPDATE_USER_AGREEMENT,
  LOGOUT_USER,
} from "../actions";

const initialState = {
  myTasks: [],
  templateList: [],
};

const AgreementReducer = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case SET_AGREEMENT_LIST: {
      return { ...state, templateList: action.data };
    }
    case SET_USER_AGREEMENTS: {
      return { ...state, myTasks: action.data };
    }
    case UPDATE_USER_AGREEMENT: {
      const data = action.data;
      let tasks: any = [];

      const task = state.myTasks.find((t: { _id: string }) => t._id === action.id);
      if (!task) {
        tasks = [...state.myTasks, { ...data, _id: data.contract_name }];
      } else {
        const allTasks = state.myTasks.filter((t: any) => t._id !== action.id);
        tasks = [...allTasks, { ...data, _id: data?._id || data.contract_name }];
      }

      return { ...state, myTasks: tasks };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    case REHYDRATE:
      let agreement = ((action || {}).payload || {}).agreement || initialState;
      return {
        ...initialState,
        templateList: agreement.templateList,
        //myTasks: agreement.myTasks,
      };
    default:
      return state;
  }
};

export default AgreementReducer;
