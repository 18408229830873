import { FC } from "react"

interface Props {
    className?: string
}

const HRLines: FC<Props> = ({ className = "" }) => {
    return (
        <hr
            style={{
                backgroundColor: "#eee",
                border: "0 none",
                color: "#eee",
                height: "0.1px",
                width: "100%"
            }}
            className={className}
        />
    )
}

export default HRLines