import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { Button } from "components"

const ConfirmationDialog = ({
    state = false,
    message = "Are you sure you want to exit?",
    onClose = (v: boolean) => { }
}) => {
    return (
        <Dialog
            open={state}
            onClose={() => onClose(false)}
            fullWidth={true}
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='!text-md'>
                Cofirm the action
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className='!text-sm'>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    label="No"
                    variant="outlined"
                    className="!w-fit !mx-0.5"
                    onClick={() => onClose(false)}
                />

                <Button
                    type="submit"
                    label="Yes"
                    variant="contained"
                    className="!w-fit !mx-0.5"
                    onClick={() => onClose(true)}
                />
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog