import styled from "styled-components";
import SideLogo from "../../../../assets/images/Hero_Image.png";
import Wave from "../../../../assets/images/Wave1.png";
import Lines from "../../../../assets/images/Lines_Pattern.png";
const LegalPlatform = () => {
  return (
    <Container>
      <img className="side-img" src={SideLogo} alt="side-logo" />
      <img className="wave-img" src={Wave} alt="wave" />

      <Wrapper>
        <p>INTRODUCING ONECOUNSEL</p>
        <h3>
          A <span className="bold">Legal platform</span> with real-time{" "}
        </h3>
        <h3>collaboration between enterprise and external parties</h3>
        <span className="text">
          Key Performance Indicators tracking and notifications to help reduce legal risks to
          improve compliance.
        </span>
        <div className="list">
          <ul>
            <li>Central Repository</li>
            <li>Contract Digitization</li>
            <li>Contract Automation</li>
            <li>Clause Risk Flag & Risk Grading</li>
          </ul>
          <ul>
            <li>E-Stamping</li>
            <li>E-Signature</li>
            <li>Real-time Collaboration</li>
            <li> Contract Insights & Reporting</li>
          </ul>
        </div>
      </Wrapper>
    </Container>
  );
};

export default LegalPlatform;

const Container = styled.div`
  position: relative;
  margin-top: 332px;
  background-image: url(${Lines});
  margin-bottom: 103px;
  .side-img {
    position: absolute;
    left: 0;
  }
  .wave-img {
    position: absolute;
    top: -469px;
    max-width: 100%;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: -1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 156px 60px 388px;
  p {
    font-family: "matter_sq_trialbold";
    font-size: 14px;
    color: #4361ee;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  h3 {
    font-family: "matter_sq_trialregular";
    font-size: 34px;
    line-height: 48px;
    color: #343a40;
    font-weight: normal;
    margin-bottom: 24px;
    margin: 0;
    .bold {
      font-family: "matter_sq_trialsemibold";
      font-weight: 700;
    }
  }
  .text {
    font-family: "matter_sq_trialregular";
    color: #adb5bd;
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 22px;
  }
  .list {
    font-family: "matter_sq_trialregular";
    display: flex;
    justify-content: space-between;
    padding-left: 17px;
    line-height: 40px;
    font-size: 17px;
    color: #adb5bd;
    max-width: 680px;
  }
`;
