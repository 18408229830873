import styled from "styled-components";
import Logo from "../../../../assets/images/Logo.png";
import FooterWave from "../../../../assets/images/FooterWave.png";

const Footer = () => {
  return (
    <Container>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-content">
            <div className="footer-content-wrapper">
              <div>
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img src={Logo} className="img-fluid" alt="logo" />
                    </a>
                  </div>
                  <div className="footer-text">
                    <h4>
                      <span>AI Powered</span>
                    </h4>
                    <h4>
                      <span>Knowledge Automation</span> Ecosystem
                    </h4>
                    <p>360-degree insights in to legal operations & business transactions</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Product</h3>
                  </div>
                  <ul>
                    <li>
                      <a href="#">One Counsel</a>
                    </li>
                    <li>
                      <a href="#">OneCounsel Central</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Company</h3>
                  </div>
                  <ul>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Career</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Support</h3>
                  </div>
                  <ul>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="copyright-text">
            <p>OneCounsel | 2022. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background-color: #060720;
  background-image: url(${FooterWave});
  background-position: center;
  background-repeat: no-repeat;
  .footer-section {
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 78px;
    // .container {
    //   padding-top: 57px;
    // }
  }
  .footer-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .footer-logo {
      margin-bottom: 37px;
    }
    h4 {
      font-size: 21px;
      line-height: 32px;
      font-family: "matter_sq_trialsemibold";
      color: #fff;
      span {
        color: #3f37c9;
      }
    }
    h3 {
      font-size: 21px;
      line-height: 46px;
      font-family: "matter_sq_trialsemibold";
      color: #fff;
    }
    p {
      color: #adb5bd;
      font-size: 17px;
      line-height: 28px;
      margin-top: 21px;
      font-family: "matter_sq_trialregular";
      max-width: 316px;
    }
    ul {
      list-style: none;
      padding-left: 0px;
    }
    li {
      a {
        color: #adb5bd;
        font-size: 17px;
        line-height: 40px;
        font-family: "matter_sq_trialregular";
        text-decoration: none;
      }
    }
  }
  .copyright-area {
    margin-top: 50px;
    p {
      font-size: 17px;
      line-height: 28px;
      font-family: "matter_sq_trialmedium";
      color: #fff;
      padding: 20px 15px 23px;
      text-align: center;
      border-top: 1px solid #adb5bd33;
    }
  }
`;
