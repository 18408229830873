import { store } from "redux/store";

//
import EclipseLogo from "../assets/images/legitron.svg";
import AgreementLogo from "../assets/images/agreement.svg";
import AssessmentLogo from "../assets/images/assessment.svg";
import ConsoleLogo from "../assets/images/console.svg";
import KpiLogo from "../assets/images/kpi.svg";
import NcentralLogo from "../assets/images/ncentral.svg";
import RepositoryLogo from "../assets/images/repository.svg";
import VectorLogo from "../assets/images/vector.svg";
import LinkedInLogo from "../assets/images/linkedIn.svg";
import AppleLogo from "../assets/images/apple.svg";
import GoogleLogo from "../assets/images/google.svg";
import UploadLogo from "../assets/images/upload.svg";
import Avtar from "../assets/images/avtar.png";
// import DashboardLogo from "../assets/images/LogoIcon.png";
import SidebarConnect from "../assets/images/sidebar-connect.svg";
import Sidebarlegal from "../assets/images/sidebar-legal.svg";
// import SidebarLogo from "../assets/images/LogoColored.png";
import NoDataFound from "../assets/images/no-data.svg";
import ResetPasswordLogo from "../assets/images/ResetPassword.svg";

export const IMAGES = {
  EclipseLogo: EclipseLogo,
  AgreementLogo: AgreementLogo,
  AssessmentLogo: AssessmentLogo,
  ConsoleLogo: ConsoleLogo,
  KpiLogo: KpiLogo,
  NcentralLogo: NcentralLogo,
  RepositoryLogo: RepositoryLogo,
  VectorLogo: VectorLogo,
  LinkedInLogo: LinkedInLogo,
  AppleLogo: AppleLogo,
  GoogleLogo: GoogleLogo,
  UploadLogo: UploadLogo,
  Avtar,
  // DashboardLogo,
  // SidebarLogo,
  SidebarConnect,
  Sidebarlegal,
  NoDataFound,
  ResetPasswordLogo,
};
