import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { DashboardScreen, EditProfileScreen, LibraryScreen, AdminLibraryScreen } from "../views/secured";
import { Admin } from "../views/secured/admin";
import AccessManagemnt from "../views/secured/accessManagement";
import KPIManagemnt from "../views/secured/adminPanel/KpiManagement";

import Repository from "../views/secured/repository";
import ValidateExtraction from "../views/secured/validateExtraction";
import ImportedDocuments from "customComponents/Repository/ImportedDocuments";
import CreateNewAgreement from "../views/secured/agreement/createNewAgreement";
import MyTasks from "../views/secured/myTasks";
import RiskAssessment from "../views/secured/riskAsseseement";
import ViewRiskAssessment from "../views/secured/riskAsseseement/assessmentView";
import SignedNewAgreement from "../views/secured/agreement/createNewAgreement/signedDocAgreement";

//
import { ROUTE_CONSTANTS, USER_ROLES } from "../helpers";

const AuthRouter = () => {
  const isLogin = useSelector((state: any) => state.common.userTokens?.IdToken.length > 0 || false);
  const isAdmin = useSelector((state: any) => state.common.userData?.persona === "admin" || false);
  const isVendor =
    useSelector((state: any) => state.common.userData?.persona) === USER_ROLES["vendor"] || false;
  const rootRoute = isAdmin
    ? ROUTE_CONSTANTS.ADMIN
    : isVendor
      ? ROUTE_CONSTANTS.My_Tasks
      : isLogin ? ROUTE_CONSTANTS.DASHBOARD : "/";

  //
  require("utils/intervals");
  // // schedule-here

  return (
    <React.Fragment>
      <Routes>
        {isAdmin ? (
          <>
            <Route path={ROUTE_CONSTANTS.ADMIN} element={<Admin />} />
            <Route path={ROUTE_CONSTANTS.ACCESS_MANAGEMENT} element={<AccessManagemnt />} />
            <Route path={ROUTE_CONSTANTS.KPI_MANAGEMENT} element={<KPIManagemnt />} />
            <Route path={ROUTE_CONSTANTS.LIBRARY} element={<AdminLibraryScreen />} />
          </>
        ) : (
          <>
            {!isVendor && (
              <>
                <Route path={ROUTE_CONSTANTS.DASHBOARD} element={<DashboardScreen />} />
                <Route path={ROUTE_CONSTANTS.Repository} element={<Repository />} />
                <Route path={ROUTE_CONSTANTS.EDIT_PROFILE} element={<EditProfileScreen />} />
                <Route path={ROUTE_CONSTANTS.LIBRARY} element={<LibraryScreen />} />
                <Route
                  path={ROUTE_CONSTANTS.VALIDATE_EXTRACTION}
                  element={<ValidateExtraction />}
                />
                <Route
                  path={`${ROUTE_CONSTANTS.Imported_Documents}/:id`}
                  element={<ImportedDocuments />}
                />
                <Route path={ROUTE_CONSTANTS.RISK_ASSESSMENT} element={<RiskAssessment />} />
                <Route
                  path={`${ROUTE_CONSTANTS.View_Risk_Agreement}/:id`}
                  element={<ViewRiskAssessment />}
                />
              </>
            )}
            <Route path={ROUTE_CONSTANTS.My_Tasks} element={<MyTasks />} />
            <Route
              path={`${ROUTE_CONSTANTS.Create_Agreement}/:type`}
              element={<CreateNewAgreement />}
            />
            <Route
              path={`${ROUTE_CONSTANTS.Final_Signed_Agreement}/:type`}
              element={<SignedNewAgreement />}
            />
          </>
        )}

        {/*  */}
        <Route path="*" element={<Navigate to={rootRoute} />} />
      </Routes>
    </React.Fragment>
  );
};

export default AuthRouter;
