export default function ChildAgreementRow({ row, rowProps, visibleColumns, data, loading }) {
    if (loading) {
      return null;
    }
    return (
        <>
            {data.map((x, i) => {
                return (
                    <tr
                        {...rowProps}
                        style={{
                            backgroundColor:'#F8F6FA'
                        }}
                        {... i === data.length - 1 && {
                            style: {
                                backgroundColor: '#F8F6FA',
                                borderBottom: '2px solid #560BAD'
                            }
                        }}
                        key={`${rowProps.key}-expanded-${i}`}
                    >
                        {row.cells.map((cell) => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                >
                                    {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell', {
                                        value:
                                            cell.column.accessor &&
                                            cell.column.accessor(x, i),
                                        row: { ...row, original: x, values:x }
                                    })}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </>
    );
}
