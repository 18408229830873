export const GET_KPI_BOX_DATA = "GET_KPI_BOX_DATA";
export const ACTIVE_KPI_BOX = "GET_KPI_BOX";

export const saveKpiBoxData = (data: any) => {
  return {
    type: GET_KPI_BOX_DATA,
    data: data,
  };
};

export const activeKpiBox = (data: any) => {
  return {
    type: ACTIVE_KPI_BOX,
    data: data,
  };
};
