import { useEffect } from "react";
import { DocumentEditor, DocumentEditorContainerComponent } from "@syncfusion/ej2-react-documenteditor";
import "./style.scss";

const VersionListData = (p) => {
  let documentRef: DocumentEditor = new DocumentEditor({});

  useEffect(() => {
    if (documentRef) {
      const sfdtText = p?.list?.editor_data || "";
      documentRef.showComments = false;
      documentRef.showRevisions = false;
      documentRef.open(sfdtText);
    }
  }, []);

  return (
    <>
      {p?.list?.editor_data ? (
        <>
          <DocumentEditorContainerComponent
            id="ej2-container-version-list"
            ref={(scope: DocumentEditorContainerComponent) => {
              documentRef = scope?.documentEditor
            }}
            toolbarItems={[
              "New",
              "Open",
              "Separator",
              "Undo",
              "Redo",
              "Separator",
              "Find",
              "Comments",
              "FormFields",
            ]}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
            enableToolbar={false}
            showPropertiesPane={false}
            restrictEditing={true}
          />
        </>
      ) : (
        <div style={{ display: "grid", textAlign: "center", justifyContent: "center" }}>
          NO DATA FOUND
        </div>
      )}
    </>
  );
};
export default VersionListData;
