import React, { useEffect, useState } from "react";
import { Button } from "components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { easeQuadInOut } from "d3-ease";

//
import AnimatedProgressBar from "./AnimatedProgressBar";
import { Typography, Tooltip, withStyles } from "@material-ui/core";

interface PercentageInformation {
  name: string;
  max: number;
  current: number;
  color: string;
}

export default function ExtractionSummary({
  setIsSummaryShown,
  setClickedProgressBarIndex,
  extractedBucketsData,
}: {
  setIsSummaryShown: Function;
  setClickedProgressBarIndex: Function;
  extractedBucketsData: any;
}) {
  const [extractedDataPointsValue, setExtractedDataPointsValue] = useState({
    completed: 0,
    remaining: 0,
    total: 0,
  });
  const colorList = [
    "#560BAD",
    "#B5179E",
    "#F72585",
    "#4CC9F0",

    //
    "#b5179e",
    "#f72585",
    "#3a0ca3",
    "#00b4d8",
    "#560bad",
    "#3f37c9",
    "#4361ee",
    "#4895ef",
    "#4cc9f0",
  ];

  const percentageData: Array<PercentageInformation> = extractedBucketsData!.extractedData.map(
    (item, index) => {
      const { bucket, total_question_count, current_count } = item;
      return {
        name: bucket,
        max: total_question_count,
        current: current_count,
        color: colorList[index],
      };
    },
  );

  const extractedDataPoints = () => {
    let completed = 0;
    let total = 0;

    percentageData.map((item) => {
      completed += item.current;
      total += item.max;
    });

    // const completedPercentage = (completed / total) * 100;
    // const remainingPercentage = 100 - completedPercentage;

    setExtractedDataPointsValue({
      completed: completed,
      remaining: total - completed,
      total: total,
    });

    // setExtractedDataPointsValue({
    //   completed: Math.round(completedPercentage),
    //   remaining: Math.round(remainingPercentage),
    // });
  };

  useEffect(() => {
    extractedDataPoints();
  }, []);

  const getSummaryTooltipsList = (bucketName) => {
    return Object.keys(
      extractedBucketsData.extractedData.find((item) => item.bucket === bucketName).questions,
    ).map((item) => item);
  };

  const CustomTooltip = withStyles({
    tooltip: {
      color: "#5b677a",
      backgroundColor: "#F3F2F7",
      borderRadius: "0.375rem",
      // boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.15), 0 2px 4px -1px rgba(0, 0, 0, 0.15)",
      boxShadow: "5px 5px 40px 0px rgba(0,0,0,0.2), 5px -5px 40px 0px rgba(0,0,0,0.2)",
      padding: "1rem",
    },
  })(Tooltip);

  return (
    <div>
      <div className="flex justify-end">
        <Button
          label={"See Extractions"}
          isFullWidth={false}
          onClick={() => {
            setIsSummaryShown(false);
          }}
        />
      </div>

      {/*  */}
      <div className="my-2 mb-5">
        <h4 className="text-gray-dark">Extraction Summary</h4>

        {/* <div className="grid grid-cols-4 my-5"> */}
        <div className=" my-5 flex flex-wrap justify-center items-center gap-6 gap-x-10 gap-y-16 w-full">
          {percentageData.map((item, i) => {
            return (
              <div className="" key={i}>
                <AnimatedProgressBar
                  valueStart={0}
                  valueEnd={item.current}
                  duration={0.5}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);

                    return (
                      <CustomTooltip
                        title={
                          <div className="max-h-96 overflow-y-auto">
                            {getSummaryTooltipsList(item.name).map((listItem) => {
                              return (
                                <div>
                                  <Typography color="inherit" variant="subtitle1">
                                    {listItem}
                                  </Typography>
                                </div>
                              );
                            })}
                          </div>
                        }
                        arrow={true}
                        placement="right"
                      >
                        <div
                          onClick={(e) => {
                            // move to next screen
                            setClickedProgressBarIndex(i);
                            setIsSummaryShown(false);
                          }}
                          style={{ width: 230, height: 230 }}
                        >
                          <CircularProgressbar
                            className="cursor-pointer"
                            value={value}
                            maxValue={item.max}
                            text={roundedValue as any}
                            styles={buildStyles({
                              textColor: "#000",
                              pathColor: item.color,
                            })}
                            strokeWidth={12}
                          />
                          <h4 className="text-gray-dark font-black text-center my-3">
                            {item.name}
                          </h4>
                        </div>
                      </CustomTooltip>
                    );
                  }}
                </AnimatedProgressBar>
              </div>
            );
          })}
        </div>
      </div>

      {/*  */}
      <div className="my-2 mt-20">
        <h4 className="text-gray-dark mb-2">Extracted DataPoints</h4>
        <AnimatedProgressBar
          valueStart={0}
          valueEnd={100}
          duration={0.8}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            // const roundedValue = Math.round(value);
            return (
              <div
                className="w-full relative bg-[#EA5455] my-1 rounded-2xl"
                style={{ width: `${value}%` }}
              >
                <AnimatedProgressBar
                  valueStart={0}
                  valueEnd={60}
                  duration={1}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    return (
                      <div
                        className="bg-[#560BAD] rounded-2xl"
                        style={{ width: `${value}%`, height: "1.6rem" }}
                      ></div>
                    );
                  }}
                </AnimatedProgressBar>
              </div>
            );
          }}
        </AnimatedProgressBar>

        {/*  */}
        <div className="flex justify-center gap-3">
          <div className="flex items-center gap-2">
            <AnimatedProgressBar
              valueStart={0}
              valueEnd={extractedDataPointsValue.completed}
              duration={1.5}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <>
                    <span className="font-bold">Extracted</span> <span>{roundedValue} /</span>
                  </>
                );
              }}
            </AnimatedProgressBar>

            <AnimatedProgressBar
              valueStart={0}
              valueEnd={extractedDataPointsValue.total}
              duration={1}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                const roundedValue = Math.round(value);
                return <>{roundedValue}</>;
              }}
            </AnimatedProgressBar>
          </div>
          {/* <div className="flex items-center gap-2">
            <AnimatedProgressBar
              valueStart={0}
              valueEnd={extractedDataPointsValue.completed}
              duration={1.5}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <>
                    <div className="h-3 w-3 bg-[#560BAD]"></div>
                    <span className="font-bold">Completed</span> <span>{roundedValue}</span>
                  </>
                );
              }}
            </AnimatedProgressBar>
          </div> */}
          {/* <div className="flex items-center gap-2">
            <AnimatedProgressBar
              valueStart={0}
              valueEnd={extractedDataPointsValue.remaining}
              duration={1}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                const roundedValue = Math.round(value);
                return (
                  <>
                    <div className="h-3 w-3 bg-[#EA5455]"></div>
                    <span className="font-bold">Remaining</span> <span>{roundedValue}</span>
                  </>
                );
              }}
            </AnimatedProgressBar>
          </div> */}
        </div>
      </div>
    </div>
  );
}
