import React, { useState, useEffect } from "react";
import { FaPlusCircle, FaTimes } from "react-icons/fa";
import Popup from "reactjs-popup";

import { UploadAgreementForm } from "..";
import NewAgreementList from "../../views/secured/agreement/newAgreementList";
import { fetchAgreementList, IMPORT_TYPES, USER_ROLES } from "../../helpers";
import { useSelector } from "react-redux";
import "./styles.scss";

const AddAgreements: React.FC = () => {
  const [displayWarning, setDisplayWarning] = useState(true);
  const [isAgListLoading, setAgListLoading] = useState(true);
  const [agreementList, setAgreementList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const userData = useSelector((state: any) => state.common.userData);

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps

  //   (async () => {
  //     await fetchAgreementList({
  //       setData: setAgreementList,
  //       setLoading: setAgListLoading,
  //     });
  //   })();
  // }, []);

  return (
    <>
      <div className="kpi-new-agreement-block">
        <div className="content">
          <div className={`no-agreement-warning hidden ${displayWarning ? "" : "hidden"}`}>
            <p>
              You do not have any Agreements in OneCounsel yet. Start Importing an existing
              agreement or Create a new agreement.
            </p>
            <FaTimes onClick={() => setDisplayWarning(false)} />
          </div>
          <div className="add-agreement-block">
            <div className="import-existing-agreement">
              <div className="content">
                <p className="title">Import & Digitize</p>
                <div className="instruction">
                  <p> Drag and drop your files or click to Import</p>
                  <span>( Please use MS Word or PDF Agreements format only)</span>
                </div>
                <div className="sub-btn">
                  {/* ### import License commented here */}
                  {/* <Popup
                    trigger={
                      <button disabled={userData.persona === USER_ROLES["vendor"]}>
                        <FaPlusCircle />
                        <span>Import License</span>
                      </button>
                    }
                    modal
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                    className="agreement-upload-popup"
                  >
                    {(close) => (
                      <UploadAgreementForm closePopup={close} importType={IMPORT_TYPES.LICENSE} />
                    )}
                  </Popup> */}
                  <Popup
                    trigger={
                      <button disabled={userData.persona === USER_ROLES["vendor"]}>
                        <FaPlusCircle />
                        <span>Import Agreement</span>
                      </button>
                    }
                    modal
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                    className="agreement-upload-popup"
                  >
                    {(close) => (
                      <UploadAgreementForm closePopup={close} importType={IMPORT_TYPES.AGREEMENT} />
                    )}
                  </Popup>
                </div>
              </div>
            </div>
            <div className="create-new-agreement">
              <div className="content">
                <p className="title">Create New Agreement</p>
                <div className="instruction">
                  <p> Select agreement type</p>
                  {/* <select defaultValue="1">
                    <option value="1">Purchase Agreement</option>
                  </select> */}
                </div>
                <div className="sub-btn">
                  <button
                    onClick={() => setOpen(true)}
                    disabled={userData.persona === USER_ROLES["vendor"]}
                  >
                    <FaPlusCircle />
                    <span>Create New Agreement</span>
                  </button>
                  {open && <NewAgreementList
                    isLoading={isAgListLoading}
                    data={agreementList}
                    isOpen={open}
                    handleClose={handleClose}
                    isNewAgreement={true}
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAgreements;
