import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";

//
import { Button, SelectInput } from "components";
import { getRequest } from "helpers";
import Modal, { ModalProps } from "../Modal";
//
import "./index.scss";

export interface ParentAgreement {
  agreement_id?: string;
  agreement_index?: string;
  agreement_name?: string;
}

export default function SearchableParentAgreements({ setParent, defaultValue = "" }) {
  const [options, setOptions] = React.useState([]);
  const controller = new AbortController();
  const clientId = useSelector((state: any) => state.common.userData.client_id || "");
  const defaultVal = defaultValue && defaultValue !== "not_assigned" ? defaultValue : "";

  useEffect(() => {
    async function getData() {
      try {
        const response: any = await getRequest(
          // `/auto-complete?search_text=${value}&client_id=${clientId}`,
          `/auto-complete?search_text=&client_id=${clientId}`,
          true,
          controller,
        );
        setOptions(response.data);
      } catch (e) {
        console.error(e);
      }
    }
    getData();
    return () => {
      controller.abort("component unmounted");
    };
  }, []);

  return (
    <>
      {/* <div className="mb-2 selectParent">Select Parent</div> */}
      {/* <Autocomplete
        freeSolo
        id="search-parent"
        style={{ width: "100%" }}
        defaultValue={{ agreement_name: sessionStorage.getItem("parentName") || defaultVal || "" }}
        onInputChange={onInputChange}
        getOptionSelected={(
          option: { agreement_name: string },
          value: { agreement_name: string },
        ) => option.agreement_name === value.agreement_name}
        getOptionLabel={(option: { agreement_name: string }) => {
          return option.agreement_name;
        }}
        onChange={(event: any, newValue: any) => {
          console.log(newValue);
          setParent && setParent(newValue);
        }}
        clearOnBlur={false}
        options={options}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search parent..." variant="outlined" />
        )}
        size="small"
        popupIcon={<AiOutlineSearch />}
      /> */}

      <SelectInput
        freeSolo
        id="search-parent"
        style={{ width: "100%" }}
        defaultValue={{ agreement_name: sessionStorage.getItem("parentName") || defaultVal || "" }}
        // onInputChange={onInputChange}
        getOptionSelected={(
          option: { agreement_name: string },
          value: { agreement_name: string },
        ) => option.agreement_name === value.agreement_name}
        getOptionLabel={(option: { agreement_name: string }) => {
          return option.agreement_name;
        }}
        clearOnBlur={false}
        options={options}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search parent..." variant="outlined" />
        )}
        popupIcon={<AiOutlineSearch />}
        label={
          <span>{options.length > 0 ? "Select Parent" : "No Agreement To Select As Parent"}</span>
        }
        name="parent"
        onCustomChange={(e, value: any) => {
          // console.log(e);
          // console.log(value);

          setParent && setParent(value);

          // onChange={(event: any, newValue: any) => {
          //   console.log(newValue);
          //   setParent && setParent(newValue);
          // }}

          // p.onSelect(value);
          // console.log({ [item.key]: innerItem.value });
          // setValues({ ...values, [item.key]: innerItem.value });
          // setSelectedValueOfSelectedType(item);
        }}
        disabled={options.length === 0}
      />
    </>
  );
}

export function ParentSelectionModal(props: Partial<ModalProps & { defaultValue: any }>) {
  const [parentObject, setParentObj] = useState<ParentAgreement>({});

  const onSave = () => {
    sessionStorage.setItem("parentId", parentObject?.agreement_id || "");
    sessionStorage.setItem("parentIndex", parentObject?.agreement_index || "");
    sessionStorage.setItem("parentName", parentObject?.agreement_name || "");
    props.onClose && props.onClose();
  };

  return (
    <Modal isOpen={!!props.isOpen}>
      <div className="parent-agreement-container p-5 w-[25rem]">
        <div className="title-wrapper mb-4 mt-0">
          <h4 className="chooseParentText">Choose a Parent Agreement</h4>

          <AiOutlineClose className="close cursor-pointer" onClick={props.onClose} />
        </div>
        <SearchableParentAgreements defaultValue={props?.defaultValue} setParent={setParentObj} />
        <div className="buttonFlex">
          <Button
            className="parentSelectionButton"
            onClick={onSave}
            variant="contained"
            label="Save"
          />
        </div>
      </div>
    </Modal>
  );
}
