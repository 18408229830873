/* eslint-disable */
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import forgot from "../../assets/images/forgot.png";
import resetScreen from "../../assets/images/resetScreen.png";
import {
  API_ROUTES,
  postRequest,
  RESPONSE_MSGS,
  showLogInConsole,
  showToast,
  STATUS_CODE,
  STRINGS,
} from "../../helpers";
import "./style.scss";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const OtpScreen = ({ onVerifySuccess = () => {}, email = "" }) => {
  const [otp, setOTP] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  useEffect(() => {
    setOTP("");
  }, []);

  const verifyOTP = () => {
    setProcessing(true);
    postRequest(API_ROUTES.SUBMIT_CODE, false, { code: otp, username: email })
      .then((res: any) => {
        setProcessing(false);
        let { data } = res || {};
        let { ResponseMetadata, message } = data || {};
        let { HTTPStatusCode } = ResponseMetadata || {};
        if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
          showToast(message, false);
        } else {
          onVerifySuccess();
          showToast(RESPONSE_MSGS.OTP_VERIFIED, true);
        }
      })
      .catch((err) => {
        setProcessing(false);
        showLogInConsole("ERROR In Login ", err);
        showToast(RESPONSE_MSGS.ERROR, false);
      });
  };

  const resendOTP = () => {
    postRequest(API_ROUTES.RESEND_CODE, false, { username: email })
      .then((res: any) => {
        showLogInConsole("Success In OTP ", res);
        let { data } = res || {};
        let { ResponseMetadata, message } = data || {};
        let { HTTPStatusCode } = ResponseMetadata || {};
        if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
          showToast(message, false);
        } else {
          showToast("OTP sent successfully!", true);
        }
      })
      .catch((err) => {
        showLogInConsole("ERROR In Login ", err);
        showToast(RESPONSE_MSGS.ERROR, false);
      });
  };

  //<span>Type your 6 digit security code</span>
  return (
    <>
      <div className="auth-breadcrumbs">
        <div className="inactive-breadcrumb">
          <img src={forgot} alt="forgot" />
          Account
        </div>
        <NavigateNextIcon fontSize="small" color="inherit" className="icon-arrow" />
        <div className="active-breadcrumb">
          <img src={resetScreen} alt="resetScreen" />
          Verification
        </div>
      </div>
      <div className="otp-verify-screen">
        <div className="otp-verify-wrapper">
          <h3 className="otp-title">{STRINGS.OTP_SCREEN_TITLE}</h3>
          <p>
            {STRINGS.OTP_SENT_LINE_1} <br /> {STRINGS.OTP_SENT_LINE_2}
          </p>
          <p className="otp-email">******doe@gmail.com</p>

          <div className="otp-input-block">
            <span>Type your 6 digit security code</span>
            <OtpInput
              value={otp}
              onChange={(code: string) => setOTP(code)}
              numInputs={6}
              separator={<span>{"\u00A0\u00A0"}</span>}
            />
          </div>

          <div className="otp-submit-btn">
            <button
              type="button"
              className="form-button"
              onClick={verifyOTP}
              disabled={otp.length !== 6 || processing}
            >
              {"Verify my account"}
            </button>
          </div>

          <div className="otp-form-footer">
            <span>Didn’t get the code?</span>

            <div className="otp-resend-link" onClick={resendOTP}>
              {STRINGS.RESEND_OTP}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpScreen;
