import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import AlertProvider from "react-alert-async";

import App from "./App";

//
import "react-alert-async/dist/index.css";
//
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
//
import "react-circular-progressbar/dist/styles.css";
//
import "./index.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
// import reportWebVitals from "./reportWebVitals";
//
import { StyledEngineProvider } from "@mui/material";

ReactDOM.render(
  <>
    <StyledEngineProvider injectFirst>
      <div className="app-container">
        <AlertProvider />
        <App />
      </div>
    </StyledEngineProvider>
  </>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
