import React from "react";
import { Link } from "react-router-dom";

import { STRINGS } from "../../helpers";
import "./style.scss";

const PublicFooter = () => {
  return (
    <footer className="public-footer">
      <div className="col-6 public-footer-link">
        <Link to={"/"}>{STRINGS.PRIVACY_POLICY}</Link>
      </div>
      <div className="col-6 public-footer-copyright">{STRINGS.COPYRIGHT}</div>
    </footer>
  );
};

export default PublicFooter;
