import React from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";

import { FormControl, InputLabel, OutlinedInput, TextField, FormHelperText } from "@mui/material";

import "./style.scss";

interface otherProps {
  label: string;
  readOnly?: boolean;
  onAction?: Function;
  onActionBlur?: Function;
  highlightText?: string;
  showRiskWarning?: boolean;
  id?: string;
  isRequired?: boolean;
  innerClassName?: string;

  //
  onCustomClick?: Function;
  onCustomChange?: Function;
  onCustomActionBlur?: Function;
  endAdornment?: any;
}

const getTerminationRisk = (val: number) => {
  if (val > parseInt(process.env.REACT_APP_LLA_Termination_NOTICE_AGREEMENT_RISK_2 || "5")) {
    return 5;
  } else if (val <= parseInt(process.env.REACT_APP_LLA_Termination_NOTICE_AGREEMENT_RISK || "2")) {
    return 2;
  } else {
    return null;
  }
};

const ShowRisk = (p: { name: string; value: string }) => {
  const val = parseInt(p.value || "");

  if (
    p.name === "price" &&
    parseInt((p.value || "").replaceAll(",", "")) >
    parseInt((process.env.REACT_APP_VALUE_TO_HIT_AGREEMENT_RISK || "2000").replaceAll(",", ""))
  ) {
    return (
      <div className="field-error risk-warning">
        {`Risk: Price is more than Rs. ${process.env.REACT_APP_VALUE_TO_HIT_AGREEMENT_RISK || "2000"
          }`}
      </div>
    );
  } else if (
    p.name === "termOfAgreement" &&
    val > parseInt(process.env.REACT_APP_LLA_TERM_AGREEMENT_RISK || "5")
  ) {
    return (
      <div className="field-error risk-warning-red">
        {`Risk: Term is beyond ${process.env.REACT_APP_LLA_TERM_AGREEMENT_RISK || "5"} years`}
      </div>
    );
  } else if (
    p.name === "licenseFeeRent" &&
    val > parseInt(process.env.REACT_APP_LLA_RISK_AGREEMENT_RISK || "5")
  ) {
    return (
      <div className="field-error risk-warning-red">
        {`Risk: Rent Escalation is beyond ${process.env.REACT_APP_LLA_RISK_AGREEMENT_RISK || "5"
          } %`}
      </div>
    );
  } else if (p.name === "terminationNotice" && getTerminationRisk(val)) {
    const terminRisk = getTerminationRisk(val);
    return terminRisk === 2 ? (
      <div className="field-error risk-warning">
        {`Risk: Termination notice is between ${process.env.REACT_APP_LLA_Termination_NOTICE_AGREEMENT_RISK || "1-2"
          } months`}
      </div>
    ) : (
      <div className="field-error risk-warning-red">
        {`Risk: Termination notice is beyond ${process.env.REACT_APP_LLA_Termination_NOTICE_AGREEMENT_RISK_2 || "5"
          } months`}
      </div>
    );
  }

  return null;
};

const Textinput = (p: otherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(p);
  const { setFieldValue } = useFormikContext();

  const handleOnAction = () => {
    if (p?.onAction) {
      let value = field.value;
      if (p.highlightText) {
        value = p.highlightText;
      }
      p.onAction(value);
    }

    if (p?.onCustomClick) {
      p?.onCustomClick();
    }
  };

  const handleOnBlur = (e) => {
    if (p?.onActionBlur) {
      if (field.name == "price") {
        const updatedPriceFormat: any =
          field.name === "price"
            ? field.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : field.value;
        p.onActionBlur({ value: updatedPriceFormat, name: field.name });
        setFieldValue(field.name, updatedPriceFormat);
      } else {
        const name = field.name;
        p.onActionBlur({ value: field.value, name });
      }
    }
    if (p?.onCustomActionBlur) {
      p?.onCustomActionBlur(e);
    }
  };

  return (
    <div className="form-group">
      {/* <>
        <label>{p.label}</label>
        <input
          {...field}
          className={p.className}
          id={p?.id || ""}
          placeholder={p.placeholder}
          type={p.type}
          readOnly={p.readOnly || false}
          onClick={handleOnAction}
          onBlur={handleOnBlur}
          required={p.isRequired || false}
        />
      </> */}

      {/* <CustomTextField
        {...field}
        label={p.label}
        InputLabelProps={{
          style: {
            // color: "#929cac",
          },
        }}
        variant="outlined"
        className={p.className}
        id={p?.id || ""}
        placeholder={p.placeholder}
        type={p.type}
        InputProps={{
          readOnly: p.readOnly || false,
        }}
        onClick={handleOnAction}
        onBlur={handleOnBlur}
        required={p.isRequired || false}
      /> */}

      <FormControl variant="outlined" className={p.className} color={p.color || "primary"}>
        <InputLabel
          htmlFor={p?.id || `${p.name}-id`}
          style={
            {
              // color: "#929cac",
              // marginTop: "-2px",
            }
          }
        // shrink
        >
          {p.label}
        </InputLabel>
        <OutlinedInput
          {...p}
          {...field}
          type={p.type}
          placeholder={p.placeholder}
          className={p.innerClassName}
          name={p?.name || ""}
          id={p?.id || `${p.name}-id`}
          label={p.label}
          inputProps={{
            readOnly: p.readOnly || false,
            style: {
              // padding: "14px !important",
            },
          }}
          onClick={handleOnAction}
          onBlur={handleOnBlur}
          required={p.isRequired || false}
          error={meta.touched && meta.error ? true : false}
          // notched
          onChange={(e) => {
            if (p.onCustomChange) {
              const isReturn = p.onCustomChange(e);
              if (isReturn) {
                return;
              }
            }
            setFieldValue(field.name, e.target.value);
          }}
          autoComplete="off"
        />
        <div
          style={{ height: "1rem" }}
          className={`${p?.onCustomActionBlur ? "hidden" : ""} ${meta.touched && meta.error ? "mb-2" : ""
            }`}
        >
          {meta.touched && meta.error ? (
            <FormHelperText error={meta.touched && meta.error ? true : false} className="-mt-0.5">
              {meta.error}
            </FormHelperText>
          ) : (
            p.showRiskWarning && (
              <>
                <ShowRisk name={p.name} value={p.value} />
              </>
            )
          )}
        </div>
      </FormControl>
    </div>
  );
};

export default Textinput;
