import React from "react";
import { FieldHookConfig, useField } from "formik";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { FormControl, InputLabel, OutlinedInput, FormHelperText } from "@mui/material";

import "./style.scss";

interface otherProps {
  label: string;
  innerClassName?: string;
}

const Textinput = (p: otherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(p);
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className="form-group">
      {/* <>
        <label>{p.label}</label>
        <Input
          type={values.showPassword ? "text" : "password"}
          {...field}
          className={`${p.className} form-control password-field`}
          placeholder={p.placeholder}
          disableUnderline={true}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </> */}

      <FormControl variant="outlined" className={p.className} color={p.color || "primary"}>
        <InputLabel
          htmlFor={`${p.name}-id`}
          style={
            {
              // color: "#929cac",
              // marginTop: "-2px",
            }
          }
          // shrink
        >
          {p.label}
        </InputLabel>
        <OutlinedInput
          {...p}
          {...field}
          type={values.showPassword ? "text" : "password"}
          placeholder={p.placeholder}
          className={p.innerClassName}
          style={{ fontFamily: values.showPassword ? "inherit" : "Verdana" }}
          name={p.name}
          id={`${p.name}-id`}
          label={p.label}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          error={meta.touched && meta.error ? true : false}
          inputProps={{
            style: {
              // padding: "14px !important",
            },
          }}
          // notched
        />
        <span style={{ height: "1rem" }} className={`${meta.touched && meta.error ? "mb-2" : ""}`}>
          <FormHelperText error={meta.touched && meta.error ? true : false} className="-mt-0.5">
            {meta.error}
          </FormHelperText>
        </span>
      </FormControl>
    </div>
  );
};

export default Textinput;
