import { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";

const useUnsavedChangesWarning = (
  message = "The Document would be autosaved in 30 seconds. Please stay or discard the changes.",
) => {
  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    //Detecting browser closing
    window.onbeforeunload = isDirty && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  const routerPrompt = <Prompt when={isDirty} message={message} />;

  return [routerPrompt, () => setDirty(true), () => setDirty(false)];
};

export default useUnsavedChangesWarning;
