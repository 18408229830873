import React, { useState, useEffect } from "react";
//import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import { LoaderHOC } from "../../../components";
import Singin from "../authentication/signIn";
import { setUserData, setUserToken } from "../../../redux/actions";
import {
  STRINGS,
  RESPONSE_MSGS,
  API_ROUTES,
  getRequest,
  showToast,
  STATUS_CODE,
  USER_ROLES,
} from "../../../helpers";

import "./style.scss";

const GuestLogin: React.FC = () => {
  //const navigate = useNavigate();
  const vendorDetails = queryString.parse(location.search);
  const [isLoading, setLoading] = useState(true);
  const token = vendorDetails.IdToken;
  const dispatch = useDispatch();

  const handleGuestLogin = async (values: { email?: string; token: string; tokenType: string }) => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${API_ROUTES.Guest_LOGIN}?IdToken=${values.token}&TokenType=${values.tokenType}`,
        false,
      );
      const { data }: any = res || {};

      if (data.statusCode !== STATUS_CODE.SUCCESS) {
        showToast(data?.message || RESPONSE_MSGS.ERROR, false);
        setLoading(false);
      } else {
        const AuthenticationResult = data.data?.AuthenticationResult || {},
          user_attributes = data.data?.user_attributes || {};
        let timeObject1 = new Date();
        timeObject1 = new Date(timeObject1.getTime() + AuthenticationResult.ExpiresIn || 0 * 1000);

        const userTokens = {
          ...AuthenticationResult,
          TokenExpiresAt: timeObject1,
          AccessToken: AuthenticationResult.IdToken,
        };

        dispatch(setUserToken(userTokens));
        const userData = { ...user_attributes, persona: USER_ROLES["vendor"] };
        dispatch(setUserData(userData));
        setLoading(false);
      }
    } catch (err) {
      showToast(RESPONSE_MSGS.ERROR, false);
      setLoading(false);
    }
  };

  useEffect(() => {
    //console.log("-------vendorDetails----l----", vendorDetails);

    (async () => {
      if (token) {
        await handleGuestLogin({
          tokenType: vendorDetails.TokenType,
          token: token,
          // appSecert: secert,
        });
      }
    })();
  }, [token]);

  return (
    <>
      {token ? (
        <div className="pass-auth-wrapper">
          <div className="auth-container">
            <div className="authentication_page">
              <div className="auth-bg-wrapper">
                <div className="auth-bg-section reset-password-bg" />
              </div>

              <div className="auth-form-section">
                <h3 className="auth-title">{"Login Guest user"}</h3>
                <p className="pg-content">{STRINGS.GUEST_LOGIN_SUBTITLE}</p>

                <div className="auth-form">
                  <div className="form-fields">
                    {isLoading && (
                      <div className="loading-container">
                        <LoaderHOC></LoaderHOC>
                      </div>
                    )}

                    <button
                      onClick={async () =>
                        await handleGuestLogin({
                          tokenType: vendorDetails.TokenType,
                          token: token,
                        })
                      }
                      className="form-button-created"
                      disabled={isLoading}
                    >
                      {"Login"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Singin />
      )}
    </>
  );
};

export default GuestLogin;
