/* eslint-disable */
import { REHYDRATE } from "redux-persist/es/constants";
import {
  SET_USER_DATA,
  SET_USER_TOKEN,
  SHOW_AUDIT_LOG,
  LOGOUT_USER,
  Add_BELL_NOTIFICATIONS,
  PREVIEW_FILE,
  FORM_DATA,
  DEFAULT_FORM_DATA,
  UPDATE_USER_DATA,
  FETCH_USER_DATA
} from "../actions";

const initialState: CommonReducer = {
  userTokens: {
    AccessToken: "",
    IdToken: "",
    RefreshToken: "",
    TokenExpiresAt: "",
  },
  showAuditLog: false,
  userData: {},
  bellNotifications: { count: 0, list: [] },
  fileData: null,
  formsData: {},
  defaultFormData: {},
};

const CommonReducer = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case SET_USER_TOKEN: {
      return { ...state, userTokens: action.tokens };
    }
    case SET_USER_DATA: {
      return { ...state, userData: action.data };
    }
    case SHOW_AUDIT_LOG: {
      return { ...state, showAuditLog: action.data };
    }
    case Add_BELL_NOTIFICATIONS: {
      return { ...state, bellNotifications: action.data };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    case PREVIEW_FILE: {
      return { ...state, fileData: action.data };
    }
    case FORM_DATA: {
      return { ...state, formsData: action.data };
    }
    case DEFAULT_FORM_DATA: {
      return { ...state, defaultFormData: action.data };
    }
    case UPDATE_USER_DATA: {
      return { ...state, userData: action.data.userData, userTokens: action.data.userTokens }
    };
    case FETCH_USER_DATA: {
      return { ...state, userData: action.data }
    };
    case REHYDRATE:
      let common = ((action || {}).payload || {}).common || initialState;
      return {
        ...initialState,
        userTokens: common.userTokens,
        userData: common.userData,
        dataToValidateExtraction: common.dataToValidateExtraction,
      };
    default:
      return state;
  }
};

export default CommonReducer;
