/* eslint-disable */
import { useEffect } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import RootRouter from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
// import '@fontsource/roboto';
import * as ej2Base from "@syncfusion/ej2-base";

import { createTheme, colors, ThemeProvider, CssBaseline } from "@mui/material";

function App() {
  useEffect(() => {
    try {
      ej2Base.registerLicense(
        "ORg4AjUWIQA/Gnt2V1hiQlBEfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5bdEFjW3xcdHxdRWZd",
      );

      //
    } catch (err) {
      console.log(err);
    }

    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  // #4361EE
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: "#560BAD",
      },
      secondary: {
        main: "#4361EE",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: ["Plus Jakarta Sans"].join(","),
    },
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: "14px",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          endAdornment: {
            // top: "3px",
            top: 0,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginTop: "-3px",
          },
          shrink: {
            marginTop: "0px",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: "14px !important",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <ToastContainer />
          <BrowserRouter>
            <RootRouter />
          </BrowserRouter>
        </Provider>
      </PersistGate>
    </ThemeProvider>
  );
}

export default App;
