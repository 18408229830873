import React, { useRef, useState, useEffect } from "react";
import {
  DocumentEditorContainer,
  DocumentEditorContainerComponent,
  Toolbar,
  ToolbarItem,
} from "@syncfusion/ej2-react-documenteditor";
import { Delete, CheckBox, Add, Visibility, VisibilityOff } from "@material-ui/icons";

import { v4 as uuidv4 } from "uuid";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Modal as MuiModal,
  Box,
  Typography,
  Fade,
  Backdrop,
  Button as MUIButton,
  Chip,
  Badge,
  ListItem,
  Paper,
} from "@mui/material";
import { alert, confirm, prompt } from "react-alert-async";
import moment from "moment";

//
import {
  API_ROUTES,
  DocxFileExtentions,
  STRINGS,
  getByotSchema,
  getRequest,
  postRequest,
  showToast,
} from "../../helpers";
import Popup from "reactjs-popup";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { BsInfoCircleFill } from "react-icons/bs";

//
import Modal from "components/Modal";
import ToolTip from "components/ToolTip";

//
import FileUpload from "customComponents/fileUpload";

//
import { LoaderHOC, Button, SelectInput, ButtonGroups, TextInput } from "components";
import { RootState } from "../../redux/store";

//
import "./style.scss";
import { MdTask } from "react-icons/md";
import DashboardLayout from "layouts/dashboardLayout";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

//
const UploadByotFormNew = (p: { closePopup: Function; refetchDataAfterImport: Function }) => {
  const { closePopup, refetchDataAfterImport } = p;

  // SELECTORS
  const userData = useSelector((state: any) => state?.common?.userData);

  // STATES
  const [isLoading, setIsLoading] = useState(false);
  const [sfdtJsonData, setSfdtJsonData] = useState<any>({
    json: {},
    uniqKeys: {},
    uniqKeysCount: {},
  });
  const [selectedFile, setSelectedFile] = useState<any>([]);
  const [stepNo, setStepNo] = useState(1);



  // FUNCTIONS
  // On file upload (click the upload button)
  const onFileUpload = async () => {
    try {
      if (selectedFile) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("files", selectedFile[0]);
        const res: any = await fetch(
          "https://wordapi-dev.app.onecounsel.in/api/documenteditor/Import",
          {
            body: formData,
            method: "POST"
          }
        );

        const jsonData = await res.json()

        customizeSyncFusionJson(jsonData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const customizeSyncFusionJson = (sfdtInputJson) => {
    try {
      setIsLoading(true);

      setSfdtJsonData({
        // json: sfdtInputJson,
        json: {
          ...sfdtInputJson,
        },
        uniqKeys: {},
        uniqKeysCount: 0,
      });
      setStepNo(2);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  // EFFECTS
  useEffect(() => {
    if (selectedFile.length > 0) {
      onFileUpload();
    }

    // return () => {};
  }, [selectedFile]);

  return (
    <div className="container-fluid h-full relative">
      {isLoading ? (
        <div style={{ zIndex: 3 }} className="absolute top-0 left-0 right-0 bottom-0 bg-white">
          <div className="loading-container h-full ">
            <LoaderHOC />
          </div>
        </div>
      ) : null}
      {stepNo === 1 ? (
        <div className={`custom-upload-agreement-container parent-selector`}>
          <button className="upload-cross-wrapper" title="Close" onClick={() => closePopup()}>
            <AiOutlineClose className="close" />
          </button>

          <h3 className={`agreement-title `}>Bring Your Own Template</h3>

          <p className="agreement-upload-content">
            <>
              <span className="agreement-upload-content-text">
                Please drag and drop your agreement template into the below area.
              </span>
              {/* <span className="dont-forget-to-validate">
                The dynamic fields should be tagged as {"<<FieldName>>"}
              </span> */}
            </>
            {/* {p.cautionMessage && <span className="cautionMessage">{p.cautionMessage}</span>} */}
          </p>
          <FileUpload
            agreementToImport={selectedFile}
            setAgreementFilesToImport={setSelectedFile}
            importType={DocxFileExtentions.ACCEPT_LIST}
          />

          {/* <button
              type="submit"
              // disabled={!!processing || !agreementToImport.length}
              onClick={() => {
                onFileUpload();
              }}
              disabled={selectedFile.length === 0 ? true : false}
            >
              {STRINGS.UPLOAD}
            </button> */}

          <Button
            label={STRINGS.UPLOAD}
            className="!mt-4 !w-1/4"
            variant="contained"
            color="primary"
            type="submit"
            disabled={selectedFile.length === 0 ? true : false}
            onClick={() => {
              onFileUpload();
            }}
          />
        </div>
      ) : stepNo === 2 ? (
        <Step2
          sfdtJsonData={sfdtJsonData}
          setStepNo={setStepNo}
          closePopup={closePopup}
          setIsLoading={setIsLoading}
          selectedFile={selectedFile}
          refetchDataAfterImport={refetchDataAfterImport}
          isAdmin={userData?.persona?.toLowerCase() == "admin" ?
            true :
            userData?.role?.toLowerCase() == "admin" ? true : false}
        />
      ) : stepNo === 3 ? (
        <MuiModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={true}
          onClose={() => {
            closePopup();
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={true}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
              className="rounded-lg !p-6 w-[31rem] relative"
            >
              <div className="absolute top-5 right-5">
                <AiOutlineClose className="close cursor-pointer" onClick={() => closePopup()} />
              </div>
              <div className="flex items-center justify-center flex-col gap-3 mb-2">
                <div className="h-20 w-20 rounded-full bg-primary-500 flex justify-center items-center">
                  <AiOutlineCheck size={50} color="#fff" />
                </div>
                <div className="flex items-center flex-col gap-3">
                  <h3>Template Created Successfully!</h3>

                  <h5 className="text-gray-text text-center">
                    As your template is saved, you can go ahead and start using it by clicking the
                    Create Agreement Button
                  </h5>
                </div>
              </div>
            </Box>
          </Fade>
        </MuiModal>
      ) : null}
      {/* <Step3 finalFieldsData={finalFieldsData} sfdtJsonData={sfdtJsonData} /> */}
    </div>
  );
};
export default UploadByotFormNew;

interface OffsetStructure {
  startOffset: string;
  endOffset: string;
}

interface FieldListData {
  defaultValue: string;
  offset: OffsetStructure;
  isActive?: boolean;
  bookmarkName: string;
}

interface FieldDataStructure {
  fieldApiName: string;
  fieldLabel: string;
  fieldType: string;
  list: Array<FieldListData>;
  sequence?: number;
}

const Step2 = ({
  finalFieldsData,
  sfdtJsonData,
  setStepNo,
  closePopup,
  setIsLoading,
  selectedFile,
  refetchDataAfterImport,
  isAdmin
}) => {
  //
  const [fieldsData, setFieldsData] = useState<Array<FieldDataStructure>>([]);
  let [tempTemplateTypeAutoComplete, setTempTemplateTypeAutoComplete] = useState<any>({});
  let [tempFieldAutoComplete, setTempFieldAutoComplete] = useState<any>({});
  const [currentlySelectedFieldData, setCurrentlySelectedFieldData] = useState<FieldDataStructure>({
    sequence: 0,
    fieldApiName: "",
    fieldLabel: "",
    fieldType: "",
    list: [],
  });
  const [orgConfigData, setOrgConfigData] = useState<object>({});
  const [dynamicFields, setDynamicFields] = useState<any>([
    // {
    //   label: "name",
    //   value: "name",
    //   type: "text",
    // },
    // {
    //   label: "address",
    //   value: "address",
    //   type: "text",
    // },
  ]);
  const [typesDataList, setTypesDataList] = useState<any>([
    // {
    //   label: "msa",
    //   value: "msa",
    //   type: "text",
    // },
    // {
    //   label: "mytype",
    //   value: "mytype",
    //   type: "text",
    // },
  ]);
  const [isSelectMappingPopupOpen, setIsSelectMappingPopupOpen] = useState(false);
  const [isListOfMatchShown, setIsListOfMatchShown] = useState(false);
  const [showAddFieldModel, setShowAddFieldModel] = useState(false);
  const [isCompleteModalShown, setIsCompleteModalShown] = useState(false);
  const [isAddTemplateTypeModalShown, setIsAddTemplateTypeModalShown] = useState(false);
  const docRef = useRef<DocumentEditorContainerComponent>(null);
  const commonInfo: CommonReducer = useSelector((state: RootState) => state.common);
  const [readOnlyFields, setReadOnlyFields] = useState({ "drafter": false, "reviewer": false, "approver": false });

  const [isDocLoaded, setIsDocLoaded] = useState(false);

  useEffect(() => {
    if (docRef && docRef.current !== null) {
      openDoc();

      const menuItems: Array<any> = [
        {
          text: "Map Field",
          id: "add_new_field",
          iconCss: "e-icons e-plus-small",
          // iconCss: "e-icons e-de-ctnr-find",
        },
      ];
      //Adding custom options
      docRef.current?.documentEditor.contextMenu.addCustomMenu(menuItems, true);
      //To handle contextmenu select event

      //  custom options hide/show functionality
      docRef!.current!.documentEditor!.customContextMenuSelect = (args: any): void => {
        const item: string = args.id;
        const id: string = docRef!.current.documentEditor.element.id;

        switch (item) {
          case id + "add_new_field": {
            const searchContent: string = docRef!.current.documentEditor.selection.text;
            if (!docRef!.current.documentEditor.selection.isEmpty && /\S/.test(searchContent)) {
              //
              // console.log(docRef?.current?.documentEditor.selection.getText(false));
              // console.log(docRef?.current?.documentEditor.selection);
              // console.log(docRef?.current?.documentEditor.selection.sfdt);
              // console.log(docRef?.current?.documentEditor.selection.contextType);
              // console.log(docRef?.current?.documentEditor.selection.endOffset);
              // console.log(docRef?.current?.documentEditor.selection.isInField);
              // console.log(docRef?.current?.documentEditor.selection.currentFormField);

              // ##########

              // const selectedText: string = docRef?.current?.documentEditor.selection.text || "";
              // const { startOffset, endOffset }: any = docRef?.current?.documentEditor
              //   ?.selection || { startOffset: 0, endOffset: 0 };
              // const { endOffsetArray } = {
              //   endOffsetArray: endOffset.split(";"),
              // };

              // if (selectedText.lastIndexOf(" ") === selectedText?.length - 1) {
              //   endOffsetArray[endOffsetArray.length - 1] =
              //     parseInt(endOffsetArray[endOffsetArray.length - 1]) - 1;

              //   docRef?.current?.documentEditor.selection.select(
              //     startOffset,
              //     endOffsetArray.join(";"),
              //   );
              // }

              docRef.current?.documentEditor.selection;

              setIsSelectMappingPopupOpen(true);
            } else {
              showToast("Please make a text selection", false);
            }
            break;
          }
        }
      };
    }
  }, []);
  useEffect(() => {
    const { client_id } = commonInfo.userData;

    (async () => {
      try {
        const {
          data: { response },
        } = (await getRequest(API_ROUTES.FETCH_ORG_CONFIG + `?clientId=${client_id}`, true)) as any;

        //
        setOrgConfigData(response);

        setTypesDataList(response.templateType);
        setDynamicFields(
          response.dynamicFields.sort((a, b) => a.fieldLabel.localeCompare(b.fieldLabel)),
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  useEffect(() => {
    if (isSelectMappingPopupOpen) {
      const t = setInterval(() => {
        if (document.getElementById("select-mapping-field-input")) {
          // document.getElementById("select-mapping-field-input")!.focus();
          document.getElementById("select-mapping-field-input")!.click();
          clearInterval(t);
        }

        // document.getElementById("select-mapping-field-input")!.focus();
      }, 300);
    }
  }, [isSelectMappingPopupOpen]);

  const openDoc = async () => {
    const tempRef: DocumentEditorContainer = docRef?.current;
    tempRef.documentEditor?.open(sfdtJsonData.json);

    setTimeout(() => {
      setIsDocLoaded(true);
    }, 100);
  };
  const handleAddingFields = async () => {
    try {
      const selectedText = docRef?.current.documentEditor.selection.text;
      let indexNo: any = 0;

      if (
        !tempFieldAutoComplete["fieldApiName"] ||
        tempFieldAutoComplete["fieldApiName"].length === 0 ||
        (docRef!.current.documentEditor.selection.isEmpty && !/\S/.test(selectedText))
      ) {
        showToast("Please make a text selection", false);
        return;
      }

      const { startOffset, endOffset } = docRef?.current?.documentEditor.selection || {
        startOffset: "",
        endOffset: "",
      };

      const isKeyAlreadyExist = fieldsData.find(
        (item) => item.fieldApiName === tempFieldAutoComplete["fieldApiName"],
      );

      let bookmarkName = `${tempFieldAutoComplete.fieldApiName.toString().trim()}_${isKeyAlreadyExist ? isKeyAlreadyExist.list.length + 1 : 1
        }`;

      let isFormFieldIncludedInSelection = false;

      if (docRef?.current?.documentEditor.selection.getCurrentFormField() !== undefined) {
        bookmarkName =
          docRef?.current?.documentEditor.selection
            ?.getCurrentFormField()
            ?.formFieldData.getFormFieldInfo().name || bookmarkName;
        isFormFieldIncludedInSelection = true;

        const regexObj = new RegExp("" + tempFieldAutoComplete.fieldApiName + "+_[0-9]+");

        const isMatch = regexObj.test(bookmarkName);

        if (!isMatch) {
          showToast(
            `Mismatch key in mapping please map with "${bookmarkName.split("_")[0]}" only!`,
            false,
          );
          return;
        }
      }

      if (isKeyAlreadyExist) {
        setFieldsData((oldData: any) => {
          return oldData.map((item: FieldDataStructure) => {
            if (isKeyAlreadyExist.fieldApiName === item.fieldApiName) {
              indexNo = item.sequence;

              if (isFormFieldIncludedInSelection) {
                item["list"] = item["list"].map((item) => {
                  if (item.bookmarkName === bookmarkName) {
                    return {
                      ...item,
                      defaultValue: selectedText,
                    };
                  }
                  return item;
                });
              } else {
                item["list"] = [
                  ...item.list,
                  {
                    defaultValue: selectedText,
                    offset: {
                      startOffset,
                      endOffset,
                    },
                    isActive: false,
                    bookmarkName,
                  },
                ];
              }
            }
            return item;
          });
        });
      } else {
        // docRef?.current?.documentEditor.selection.select(start, start);
        setFieldsData((oldData: any) => {
          indexNo = oldData.length;
          const { fieldApiName, fieldLabel, fieldType } = tempFieldAutoComplete;

          return [
            ...oldData,
            {
              fieldApiName,
              fieldLabel,
              fieldType,
              list: [
                {
                  defaultValue: selectedText,
                  offset: {
                    startOffset,
                    endOffset,
                  },
                  isActive: false,
                  bookmarkName,
                },
              ],
              sequence: oldData.length,
            },
          ];
        });
      }

      docRef?.current.documentEditor.editor.insertFormField("Text");

      const latestNameOfField = "Text1";

      const textfieldInfo: any = docRef?.current.documentEditor.getFormFieldInfo(
        latestNameOfField,
      ) as any;

      docRef?.current.documentEditor.setFormFieldInfo(latestNameOfField, {
        ...textfieldInfo,
        defaultValue: selectedText,
        name: bookmarkName,
      });
      setIsSelectMappingPopupOpen(false);
      setTempFieldAutoComplete({});

      let tempCurrentlySelectedFieldList: any = [];

      if (isFormFieldIncludedInSelection) {
        tempCurrentlySelectedFieldList = fieldsData.map((item: FieldDataStructure) => {
          if (item.fieldApiName === tempFieldAutoComplete.fieldApiName) {
            item.list = item.list.map((innerItem) => {
              if (innerItem.bookmarkName === bookmarkName) {
                innerItem.defaultValue = selectedText;
              }
              return innerItem;
            }) as any;
          }
          return item;
        });
      } else {
        if (isKeyAlreadyExist) {
          const tempSpread: any = fieldsData?.find(
            (item) => item.fieldApiName === tempFieldAutoComplete.fieldApiName,
          )?.list as any;
          tempCurrentlySelectedFieldList = [...tempSpread];
        } else {
          tempCurrentlySelectedFieldList = [
            // ...(isKeyAlreadyExist && fieldsData!.find((item) => item.fieldApiName === tempFieldAutoComplete.fieldApiName)!.list),
            ...tempCurrentlySelectedFieldList,
            {
              defaultValue: selectedText,
              bookmarkName,
              offset: {
                startOffset,
                endOffset,
              },
              isActive: false,
            },
          ];
        }
      }

      setCurrentlySelectedFieldData({
        fieldApiName: tempFieldAutoComplete.fieldApiName,
        fieldLabel: tempFieldAutoComplete.fieldLabel,
        fieldType: tempFieldAutoComplete.fieldType,
        list: tempCurrentlySelectedFieldList,
        sequence: indexNo,
      });
    } catch (err) {
      console.log(err);
    }
    // const start: string = docRef?.current?.documentEditor.selection.startOffset || "";

    // docRef?.current?.documentEditor.selection.select(start, start);
  };
  const addExistsFields = async () => {
    try {
      //
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreateByot = async (values) => {
    try {

      setIsLoading(true);
      setIsCompleteModalShown(false);
      const { templateName, templateType } = values;

      const currentDocx = await docRef?.current!.documentEditor.saveAsBlob("Docx");

      const formData = new FormData();
      formData.append("files", currentDocx);

      const res: any = await fetch(
        "https://wordapi-dev.app.onecounsel.in/api/documenteditor/Import",
        {
          body: formData,
          method: "POST"
        }
      );

      const jsonData = await res.json()

      const uniqId = uuidv4();

      const templateInfo = {
        template_name: templateName,
        template_id: uniqId,
        type: templateType,
        is_byot: true,
      };
      const uniqFieldKeys = {};

      fieldsData.map((item) => {
        uniqFieldKeys[item.fieldApiName] = "";

        item.list.map((innerItem) => {
          delete innerItem.isActive;

          return innerItem;
        });

        return item;
      });

      const { client_id } = commonInfo.userData;

      const reqObject = {
        ...templateInfo,
        field_json: {
          agreementStatus: "Draft",
          version: "1",
          ...uniqFieldKeys,
        },
        template_json: jsonData,
        customFieldMappingData: fieldsData,
        createdDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
        client_id,
        is_standard: isAdmin ? true : false,
        // readOnlyRoles: readOnlyFields
      };

      const finalOrgConfigData: any = {
        ...orgConfigData,
        dynamicFields: dynamicFields,
        templateType: typesDataList,
        "client-id": client_id,
      };

      await postRequest(API_ROUTES.ADD_BYOT, true, reqObject);

      await postRequest(API_ROUTES.UPDATE_ORG_CONFIG, true, finalOrgConfigData);

      // showToast("Template Added Successfully", true);

      refetchDataAfterImport && refetchDataAfterImport();

      // closePopup();

      setStepNo(3);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const handleDeleteFormField = async (selectedFormObjectData: FieldListData, itemIndex) => {
    try {
      const isConfirm = await confirm(
        () => {
          return (
            <div>
              <h4 className="!text-gray-text mb-5 !text-xl">Are you sure?</h4>
              {/*  */}
            </div>
          );
        },
        // {
        //   duration: 500,
        // },
      );

      if (isConfirm) {
        docRef?.current.documentEditor.editor.insertText(selectedFormObjectData.defaultValue);

        setFieldsData((previous) => {
          let currentSelectedList: any = [];
          const afterRemoveElementData = previous.filter((item) => {
            if (currentlySelectedFieldData.fieldApiName === item.fieldApiName) {
              item.list = item.list.filter((innerItem, innerIndex) => {
                if (innerIndex !== itemIndex) {
                  return innerItem;
                }
              });
              currentSelectedList = item.list;
            }

            if (item.list.length > 0) {
              return item;
            }
          });

          setCurrentlySelectedFieldData({
            ...currentlySelectedFieldData,
            list: currentSelectedList,
          });

          return afterRemoveElementData;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleScrollMappingItem = (item, index) => {
    const textfieldInfo: any = docRef?.current.documentEditor.getFormFieldInfo(
      item.bookmarkName,
    ) as any;

    docRef?.current.documentEditor.setFormFieldInfo(item.bookmarkName, {
      ...textfieldInfo,
    });
    docRef?.current.documentEditor.selection.selectField();

    setCurrentlySelectedFieldData((previous) => ({
      ...previous,
      list: previous.list.map((innerItem, innerIndex) => {
        if (innerIndex === index) {
          return {
            ...innerItem,
            isActive: true,
          };
        } else {
          return {
            ...innerItem,
            isActive: false,
          };
        }
      }),
    }));
  };
  const onReadOnlyFieldChange = (e) => {
    setReadOnlyFields(prev => { return { ...prev, [e.target.name]: e.target.checked } })
  }

  return (
    <div className="container-fluid" style={{ width: "97vw" }}>
      <div className="">
        <div className="flex justify-between mx-3 my-3">
          <div className="flex items-center gap-1 ">
            <MdTask size={20} color="#560BAD" />
            <h4 className=" text-gray-text">{selectedFile[0].name}</h4>
          </div>
          <div className="cursor-pointer">
            <AiOutlineClose className="close " onClick={() => closePopup()} />
          </div>
        </div>
        <div className="border-y-2 border-x-0 border-[#F3F2F7] border-solid flex gap-3 px-5 py-2">
          <div className="text-base w-3/4">
            <h5 className="font-semibold text-gray-text">Notes: </h5>
            Mapping a paragraph with sub-points are not supported as the mapping will not maintain
            the formatting.
          </div>
          <div className="w-1/4 flex justify-end items-center">
            <ButtonGroups
              options={[
                {
                  name: "Complete",
                  onClick: () => {
                    if (fieldsData.length > 0) {
                      // setIsAddingNFieldsDone(true);

                      setIsCompleteModalShown(true);
                    } else {
                      showToast("Please add atlease 1 field", false);
                    }
                  },
                },
                {
                  name: "Close",
                  onClick: () => {
                    closePopup();
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-1">
        <div className={`col-span-9 ${isDocLoaded ? "visible" : "invisible"}`} style={{ height: "97%" }}>
          <DocumentEditorContainerComponent
            id="ej2-container"
            ref={docRef}
            created={() => {
              docRef.current?.documentEditor.commentReviewPane.closePane();
            }}
            // toolbarItems={ToolBarItemArray}
            // toolbarClick={onToolbarClick}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
            enableToolbar={false}
            showPropertiesPane={false}
            // restrictEditing={true}
            restrictEditing={false}
            enableComment={false}
            enableTrackChanges={false}
            // contentChange={onContentChangeChangeUpdateAgreementForm}
            documentEditorSettings={{
              formFieldSettings: {
                // shadingColor: "rgba(34, 102, 141,0.5)",
                // shadingColor: "rgba(86, 11, 173,0.4)",
                shadingColor: "rgba(86, 11, 173,0.2)",
              },
              optimizeSfdt: true,
            }}
          />
        </div>
        {/*  */}
        <div className="col-span-3">
          {/* <Button
            variant="primary"
            onClick={() => {
              // console.log(docRef?.current?.documentEditor.selection.getText(false));
              // console.log(docRef?.current?.documentEditor.selection);
              // console.log(docRef?.current?.documentEditor.selection.sfdt);
              // console.log(docRef?.current?.documentEditor.selection.contextType);
              // console.log(docRef?.current?.documentEditor.selection.endOffset);
              // console.log(docRef?.current?.documentEditor.selection.isInField);
              // console.log(docRef?.current?.documentEditor.selection.currentFormField);

              // ##########
              const selectedText = docRef?.current.documentEditor.selection.text;

              docRef?.current.documentEditor.editor.insertFormField("Text");

              let formFieldsNames: string[] = docRef?.current.documentEditor.getFormFieldNames();
              console.log(formFieldsNames);

              const latestNameOfField = formFieldsNames[formFieldsNames.length - 1];
              let textfieldInfo: any = docRef?.current.documentEditor.getFormFieldInfo(
                latestNameOfField,
              ) as any;
              console.log("other");
              console.log(textfieldInfo);

              docRef?.current.documentEditor.setFormFieldInfo(latestNameOfField, {
                ...textfieldInfo,
                defaultValue: selectedText,
              });
            }}
          >
            get selected text
          </Button> */}
          {/* dont delete above button */}

          <div
            style={{ height: "75vh", maxHeight: "100vh", overflow: "auto", position: "relative" }}
            className="my-3"
          >
            <h4 className="ml-2 text-gray-text ">Field Mapping Tray</h4>
            <div className="flex flex-wrap mt-2">
              {/*  */}

              {fieldsData.map((item: FieldDataStructure, index: number) => {
                return (
                  <div key={index}>
                    <div
                      className={`flex justify-center items-center gap-1 m-1 font-medium  rounded-full border border-solid border-primary-500  
                          ${currentlySelectedFieldData.sequence === index
                          ? "bg-primary-500 text-white "
                          : "hover:bg-primary-200 "
                        }
                          group
                          `}
                    >
                      <span
                        className={`cursor-pointer flex items-center gap-1 p-1 `}
                        onClick={() => {
                          // handleDeleteFormField(item, index);

                          setCurrentlySelectedFieldData({ ...item, sequence: index });
                          setIsListOfMatchShown(true);
                        }}
                      >
                        <div
                          className={`inline-flex items-center justify-center w-6 h-6 text-xs text-[10px] font-bold text-white bg-primary-500 border-2 border-white rounded-full 
                            ${currentlySelectedFieldData.sequence === index
                              ? "!bg-white !text-black "
                              : ""
                            }
                            `}
                        >
                          {item.list.length}
                        </div>
                        <div className={`text-xs font-bold leading-none max-w-full `}>
                          {item.fieldLabel}
                        </div>
                      </span>
                      <div
                        className="flex cursor-pointer py-1 pr-1.5"
                        onClick={() => {
                          tempFieldAutoComplete = item;

                          handleAddingFields();
                        }}
                      >
                        <FaPlus size={13} />
                      </div>
                    </div>
                  </div>
                );
              })}
              {/*  */}
            </div>

            {isListOfMatchShown && currentlySelectedFieldData.list.length > 0 && (
              <div className="card absolute bottom-0 left-0 right-0 max-h-[40vh] overflow-y-auto mx-1 p-3 border border-solid border-[#d3d3d3] rounded-md ">
                {/*  */}
                <h4 className="mb-2 text-gray-text">
                  {currentlySelectedFieldData.fieldLabel} Mapping
                </h4>
                <div className="">
                  {currentlySelectedFieldData.list.map((item: FieldListData, index) => {
                    return (
                      <div
                        key={index}
                        className={`px-2 py-2 rounded-md border border-solid border-primary-500 mb-2 cursor-pointer relative group ${item.isActive
                          ? "bg-primary-500 text-white "
                          : "hover:bg-primary-200 hover:text-black"
                          }`}
                        onClick={() => {
                          handleScrollMappingItem(item, index);
                        }}
                      >
                        <div
                          style={{
                            maxWidth: "90%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.defaultValue}
                        </div>

                        <div className="absolute top-0 right-0 bottom-0 flex items-center mr-3">
                          <Delete
                            onClick={() => {
                              handleScrollMappingItem(item, index);
                              handleDeleteFormField(item, index);
                            }}
                            className={`${item.isActive ? "text-white" : "text-primary-500"} `}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {fieldsData.length === 0 && (
              <div className="h-full w-full flex justify-center items-center">No Field Found</div>
            )}
          </div>
        </div>
      </div>

      {/* select mapping */}
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isSelectMappingPopupOpen}
        onClose={() => {
          setIsSelectMappingPopupOpen(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isSelectMappingPopupOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
            className="rounded-lg !p-6 !pb-4"
          >
            <div className="text-gray-text mb-5">Select Mapping Field</div>
            <SelectInput
              id="select-mapping-field-input"
              options={dynamicFields}
              label={<span>Fields </span>}
              name="fields"
              required={true}
              value={tempFieldAutoComplete}
              onCustomChange={(e, value: any) => {
                setTempFieldAutoComplete(value);
                // p.onSelect(value);

                // console.log({ [item.key]: innerItem.value });
                // setValues({ ...values, [item.key]: innerItem.value });
                // setSelectedValueOfSelectedType(item);
              }}
              isOptionEqualToValue={(option, value) => option.fieldApiName === value.fieldApiName}
              getOptionLabel={(option) => option.fieldLabel || ""}
              PaperComponent={(props, options) => {
                return (
                  <Paper>
                    {props.children}
                    {/*  */}
                    <div
                      className={"px-3.5 py-2 cursor-pointer bg-[#c6c6c6] font-bold "}
                      onMouseDown={() => {
                        setShowAddFieldModel(true);
                      }}
                    >
                      + Map To A New Field
                    </div>
                  </Paper>
                );
              }}
            />

            <div className="mt-5 flex justify-end">
              <div className="flex gap-2">
                <Button
                  variant="contained"
                  className="bg-primary text-light py-1"
                  onClick={() => {
                    handleAddingFields();
                  }}
                  label="Add"
                />

                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsSelectMappingPopupOpen(false);
                  }}
                  label="Cancel"
                />
              </div>
            </div>
          </Box>
        </Fade>
      </MuiModal>

      {/* Create New Field */}
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showAddFieldModel}
        onClose={() => {
          setShowAddFieldModel(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showAddFieldModel}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
            className="rounded-lg !p-6 !pb-4"
          >
            <Formik
              initialValues={{
                typeOfField: "",
                fieldLabel: "",
              }}
              validationSchema={Yup.object().shape({
                typeOfField: Yup.string().required("required"),
                fieldLabel: Yup.string().required("required"),
              })}
              enableReinitialize={false}
              onSubmit={(values, acrions) => {
                const newField = {
                  fieldApiName: values.fieldLabel.replace(/\W+(.)/g, (match, chr) =>
                    chr.toUpperCase(),
                  ),
                  fieldLabel: values.fieldLabel,
                  fieldType: values.typeOfField,
                };
                setDynamicFields([...dynamicFields, newField]);

                setTempFieldAutoComplete(newField);

                setShowAddFieldModel(false);
              }}
            >
              {({ values, handleSubmit, setValues, errors, validateForm, dirty }) => {
                //

                return (
                  <FormikForm
                  // className="mt-3 px-5"
                  >
                    <div className="text-gray-text mb-5">Create New Field</div>

                    <TextInput
                      name="fieldLabel"
                      label="Field label"
                      placeholder="Enter Field Label"
                    />

                    <SelectInput
                      options={[
                        { label: "Date", value: "date" },
                        { label: "Text", value: "text" },
                        { label: "Number", value: "number" },
                      ].sort((a, b) => a.label > b.label)}
                      label={<span>Type </span>}
                      name="typeOfField"
                      required={true}
                      onCustomChange={(e, value: any) => {
                        setValues({
                          ...values,
                          typeOfField: value.value,
                        });
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.fieldApiName === value.fieldApiName
                      }
                      getOptionLabel={(option) => option.label || ""}
                      isFormik={true}
                    // PaperComponent={(props, options) => {
                    //   return (
                    //     <Paper>
                    //       {props.children}
                    //       {/*  */}
                    //       <div
                    //         className={"px-3.5 py-2 cursor-pointer bg-primary-200"}
                    //         onMouseDown={() => {
                    //           // setShowAddFieldModel(true);
                    //         }}
                    //       >
                    //         + Add New
                    //       </div>
                    //     </Paper>
                    //   );
                    // }}
                    />

                    <div className="mt-2.5 flex justify-end">
                      <div className="flex gap-2">
                        <Button
                          variant="contained"
                          className="bg-primary text-light py-1"
                          type="submit"
                          label="Add"
                        />

                        <Button
                          variant="outlined"
                          onClick={() => {
                            setShowAddFieldModel(false);
                          }}
                          label="Cancel"
                        />
                      </div>
                    </div>
                  </FormikForm>
                );
              }}
            </Formik>
          </Box>
        </Fade>
      </MuiModal>

      {/* complete screen modal */}
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isCompleteModalShown}
        onClose={() => {
          setIsCompleteModalShown(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isCompleteModalShown}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
            className="rounded-lg !p-6 !pb-4"
          >
            <Formik
              initialValues={{
                templateName: selectedFile[0].name.split(".")[0] || "",
                templateType: "",
              }}
              validationSchema={Yup.object().shape({
                templateName: Yup.string().required("required"),
                templateType: Yup.string().required("required"),
              })}
              enableReinitialize={false}
              onSubmit={(values, acrions) => {
                //

                handleCreateByot(values);
              }}
            >
              {({ values, handleSubmit, setValues, errors, validateForm, dirty }) => {
                //
                useEffect(() => {
                  setValues({
                    ...values,
                    templateType: tempTemplateTypeAutoComplete.name,
                  });
                }, [tempTemplateTypeAutoComplete]);

                return (
                  <FormikForm
                  // className="mt-3 px-5"
                  >
                    <div className="text-gray-text mb-5">Create BYOT</div>

                    <TextInput
                      name="templateName"
                      label="Template name"
                      placeholder="Enter Field Label"
                    />

                    <SelectInput
                      options={typesDataList}
                      label={<span>Template type </span>}
                      name="templateType"
                      required={true}
                      onCustomChange={(e, value: any) => {
                        setTempTemplateTypeAutoComplete({
                          name: value.name.toUpperCase(),
                        });

                        setValues({
                          ...values,
                          templateType: value.name.toUpperCase(),
                        });
                      }}
                      value={tempTemplateTypeAutoComplete}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name || ""}
                      isFormik={true}
                      PaperComponent={(props, options) => {
                        return (
                          <Paper>
                            {props.children}
                            {/*  */}
                            <div
                              className={"px-3.5 py-2 cursor-pointer bg-[#c6c6c6] font-bold "}
                              onMouseDown={() => {
                                setIsAddTemplateTypeModalShown(true);
                              }}
                            >
                              + Add New
                            </div>
                          </Paper>
                        );
                      }}
                    />

                    {/* Read-only */}
                    {/* <div className="my-3">
                      <h4 className="ml-2 text-gray-text ">Read-only</h4>
                      <div className="ml-2">
                        <FormControlLabel control={
                          <Checkbox
                            name="drafter"
                            size="small"
                            color="default"
                            onChange={onReadOnlyFieldChange}
                          />
                        } label="Drafter" />
                        <FormControlLabel control={
                          <Checkbox
                            name="reviewer"
                            size="small"
                            color="default"
                            onChange={onReadOnlyFieldChange}
                          />
                        } label="Reviewer" />
                        <FormControlLabel control={
                          <Checkbox
                            name="approver"
                            size="small"
                            color="default"
                            onChange={onReadOnlyFieldChange}
                          />
                        } label="Approver" />
                      </div>
                    </div> */}

                    <div className="mt-2.5 flex justify-end">
                      <div className="flex gap-2">
                        <Button
                          variant="contained"
                          className="bg-primary text-light py-1"
                          type="submit"
                          label="Create"
                        />

                        <Button
                          variant="outlined"
                          onClick={() => {
                            setIsCompleteModalShown(false);
                          }}
                          label="Cancel"
                        />
                      </div>
                    </div>
                  </FormikForm>
                );
              }}
            </Formik>
          </Box>
        </Fade>
      </MuiModal>
      {/*  */}

      {/*  */}

      {/* create new template type */}
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isAddTemplateTypeModalShown}
        onClose={() => {
          setIsAddTemplateTypeModalShown(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isAddTemplateTypeModalShown}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
            className="rounded-lg !p-6 !pb-4"
          >
            <Formik
              initialValues={{
                templateType: "",
              }}
              validationSchema={Yup.object().shape({
                templateType: Yup.string().required("required"),
              })}
              enableReinitialize={false}
              onSubmit={(values, acrions) => {
                const newType = {
                  name: values.templateType.toUpperCase(),
                };
                setTypesDataList([...typesDataList, newType]);

                setTempTemplateTypeAutoComplete(newType);

                setIsAddTemplateTypeModalShown(false);
              }}
            >
              {({ values, handleSubmit, setValues, errors, validateForm, dirty }) => {
                //

                return (
                  <FormikForm
                  // className="mt-3 px-5"
                  >
                    <div className="text-gray-text mb-5">Create New Template Type</div>

                    <TextInput
                      name="templateType"
                      label="Template type"
                      placeholder="Enter template type"
                    />

                    <div className="mt-2.5 flex justify-end">
                      <div className="flex gap-2">
                        <Button
                          variant="contained"
                          className="bg-primary text-light py-1"
                          type="submit"
                          label="Add"
                        />

                        <Button
                          variant="outlined"
                          onClick={() => {
                            setIsAddTemplateTypeModalShown(false);
                          }}
                          label="Cancel"
                        />
                      </div>
                    </div>
                  </FormikForm>
                );
              }}
            </Formik>
          </Box>
        </Fade>
      </MuiModal>
      {/*  */}
    </div>
  );
};
