import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { Form, Formik } from "formik";

//
import { setAgreementList as storeAgreementList } from "redux/actions";
import {
  LoaderHOC,
  Modal,
  SearchableParentAgreements,
  Button,
  SelectInput,
  ToolTip,
} from "components";
import { API_ROUTES, ROUTE_CONSTANTS, fetchAgreementList, getRequest } from "helpers";

//
import "./style.scss";

interface fetchProps {
  isOpen: boolean;
  handleClose: any;
  data?: { template_name: string; _id: string }[];
  isLoading?: boolean;
}

const ShowList = (p: {
  data: { template_name: string; _id: string }[];
  isLoading?: boolean;
  onSelect: any;
  selectedValue: any;
}) => {
  if (!p.data.length && !p.isLoading) {
    return <span className="not-found">No Data Found!</span>;
  }

  return (
    <>
      {p.isLoading ? (
        <div className="loading">
          <LoaderHOC />
        </div>
      ) : (
        <SelectInput
          options={p.data.map((d: any) => {
            return { value: d, label: d.template_name };
          })}
          // label={"Select an agreement to be created       "}
          label={<span>Select Template &nbsp;</span>}
          name="agreement_name"
          required={true}
          onCustomChange={(e, value: any) => {
            p.onSelect(value);

            // console.log({ [item.key]: innerItem.value });
            // setValues({ ...values, [item.key]: innerItem.value });
            // setSelectedValueOfSelectedType(item);
          }}
          value={{ value: p.selectedValue, label: p.selectedValue.template_name }}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <ToolTip title={option.label} arrow={true}>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {option.label}
                  </span>
                </ToolTip>
              </li>
            );
          }}
        />
      )}
      {/* <Select
           options={p.data.map((d: any) => {
             return { value: d, label: d.template_name };
           })}
           value={{ value: p.selectedValue, label: p.selectedValue.template_name }}
           onChange={p.onSelect}
         /> */}
    </>
  );
};

const NewAgreementList = (p: fetchProps & { isNewAgreement?: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // SELECTORS
  const user = useSelector((state: any) => state.common.userData) || [];
  const list = useSelector((state: any) => state.agreement.templateList) || [];


  // STATES
  const [parentObject, setParentObject] = useState({
    agreement_id: null,
    agreement_index: null,
    agreement_name: null,
  });
  const [agreementType, setAgreementType] = useState<{ _id?: any }>({});
  const [agreementList, setAgreementList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);


  // FUNCTIONS
  const getAgreementList = async () => {
    try {

      if (list?.length) {
        setAgreementList(list);
        setAgreementType({ ...list[0] });
      } else {
        const res: any = await getRequest(
          `${API_ROUTES.GET_AGREEMENT_LIST}?client_id=${user.client_id}`,
          true,
        );

        if (res.data && res.data.status === 200) {
          setIsLoading(false);
          const modedData = res.data?.response.sort((a, b) => b.createdDate.localeCompare(a.createdDate)).filter((item) => !item.is_thirdparty) || []
          setAgreementList(modedData);
          setAgreementType({ _id: modedData?.[0]?._id });

          dispatch(storeAgreementList(modedData));
          setAgreementType({ ...modedData[0] });
        }
      }

    } catch (err) {
      console.log("error ==>", err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAgreementList();
  }, [])

  const setParent = (value) => {
    setParentObject(value);
  };
  const onChange = (object: any) => {
    setAgreementType(object.value);
  };

  return (
    <>
      <Formik
        initialValues={{
          agreement_name: "",
          parent: "",
        }}
      >
        {() => (
          <Modal onClose={p.handleClose} isOpen={p.isOpen}>
            <div className="agreement-list-container">
              <div className="title-wrapper">
                <h4 className="title">Create Agreement</h4>
                <button className="close" title="Close" onClick={p.handleClose}>
                  <AiOutlineClose className="close" size={20} />
                </button>
              </div>
              <div className="agreement-list-body">
                <div style={{ width: "90%" }}>
                  <div className="my-5">
                    {!isLoading && <SearchableParentAgreements setParent={setParent} />}
                  </div>
                  <div className="my-5">
                    {/* <span className="sub-title mb-2">Select an agreement to be created</span> */}
                    <ShowList
                      data={agreementList}
                      isLoading={isLoading}
                      onSelect={onChange}
                      selectedValue={agreementType}
                    />
                  </div>
                  <div className="mb-2 flex justify-end">
                    <Button
                      label="Save"
                      variant="contained"
                      className="!w-fit !mx-0.5"
                      onClick={() => {
                        sessionStorage.setItem("parentId", parentObject?.agreement_id || "");
                        sessionStorage.setItem("parentIndex", parentObject?.agreement_index || "");
                        sessionStorage.setItem("parentName", parentObject?.agreement_name || "");
                        navigate(`${ROUTE_CONSTANTS.Create_Agreement}/${agreementType?._id}`, {
                          state: { ...agreementType, isNewAgreement: p.isNewAgreement },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default NewAgreementList;
