const config = {
  apiUrl: process.env.REACT_APP_RUN_FROM_DC_URL || window.location.origin,
};

if (process.env.NODE_ENV === "production") {
  config.apiUrl = "http://liveapi.com";
}

if (window.location.hostname == "localhost") {
  if (localStorage.getItem("apiUrl") === null) {
    const isLocalBackendUsed = confirm("Are You Sure To Use Backend Server ?");

    if (isLocalBackendUsed) {
      localStorage.setItem("apiUrl", "http://localhost:5001");
    } else {
      localStorage.setItem("apiUrl", "https://api-qa.app.onecounsel.in");
    }
  }

  config.apiUrl = localStorage.getItem("apiUrl") || "";
}

if (window.location.hostname.indexOf("192.168") != -1) {
  if (localStorage.getItem("apiUrl") === null) {
    const ipAddress: any = prompt("Enter Network Ip Address Of Your Patner Server", "192.168.1.37");

    localStorage.setItem(
      "apiUrl",
      ipAddress.length > 0 ? ipAddress : "https://api-dev.app.onecounsel.in",
    );
  }

  config.apiUrl = localStorage.getItem("apiUrl") || "";
}

export default config;
