/* eslint-disable */

import { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput, PasswordInput, Button } from "../FormFields";

import "./styles.scss";

import {
  API_ROUTES,
  INPUT_FIELD_NAMES,
  postRequest,
  RESPONSE_MSGS,
  ROUTE_CONSTANTS,
  showLogInConsole,
  showToast,
  STATUS_CODE,
  STRINGS,
  VALIDATION_MSGS,
} from "../../helpers";
import { useNavigate, useLocation } from "react-router";

const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;
const resetCodeExp = /^[0-9]{6,6}$/;

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email(VALIDATION_MSGS.INVALID_EMAIL).required(VALIDATION_MSGS.REQUIRED),
  resetCode: Yup.string()
    .required(VALIDATION_MSGS.REQUIRED)
    .matches(resetCodeExp, VALIDATION_MSGS.OTP_CODE_LENGTH),
  password: Yup.string()
    .min(8, VALIDATION_MSGS.TOO_SHORT)
    .max(50, VALIDATION_MSGS.TOO_LONG)
    .required(VALIDATION_MSGS.REQUIRED)
    .matches(passwordRegExp, VALIDATION_MSGS.PASSWORD_PATTERN_INVALID),
  cpassword: Yup.string()
    .required(VALIDATION_MSGS.REQUIRED)
    .test("passwords-match", "Passwords must match.", function (value) {
      return this.parent.password === value;
    }),
});

const ResetPasswordForm = () => {
  const [processing, setProcessing] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { email } = location?.state as any;

  //
  const onFinish = async (
    values: { email: string; password: string; resetCode: string },
    actions: any,
  ) => {
    try {
      setProcessing(true);
      let dataToSend = {
        email: values.email,
        new_password: values.password,
        code: values.resetCode,
      };
      const { data }: any = await postRequest(API_ROUTES.RESET_PASSWORD, false, dataToSend);

      let { ResponseMetadata, message } = data || {};
      let { HTTPStatusCode } = ResponseMetadata || {};
      if (HTTPStatusCode !== STATUS_CODE.SUCCESS) {
        showToast(message, false);
      } else {
        showToast(RESPONSE_MSGS.PASSWORD_CHANGED, true);
        // actions.resetForm(initialValues);
        navigate(ROUTE_CONSTANTS.LOGIN);
      }
      setProcessing(false);
    } catch (err) {
      showToast(RESPONSE_MSGS.ERROR, false);
      setProcessing(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: email || "",
        resetCode: "",
        password: "",
        cpassword: "",
      }}
      validationSchema={ResetPasswordSchema}
      // eslint-disable-next-line class-methods-use-this, no-unused-vars
      onSubmit={(values, actions) => {
        onFinish(values, actions);
      }}
    >
      {() => (
        <Form className="reset-pwd-form-container">
          <TextInput
            name={INPUT_FIELD_NAMES.EMAIL}
            type="email"
            label={STRINGS.EMAIL_ADDRESS}
            placeholder={STRINGS.EMAIL_ADDRESS}
            value={email || ""}
            className="my-0.5"
          />
          <TextInput
            name={INPUT_FIELD_NAMES.RESET_CODE}
            type="text"
            label={STRINGS.PASSWORD_RESET_CODE}
            placeholder={STRINGS.PASSWORD_RESET_CODE}
            className="my-0.5"
          />
          <PasswordInput
            name={INPUT_FIELD_NAMES.PASSWORD}
            type="password"
            label={STRINGS.NEW_PASSWORD}
            placeholder={STRINGS.NEW_PASSWORD}
            className="my-0.5"
            onPaste={(e) => {
              e.preventDefault();
            }}
          />
          <PasswordInput
            name={INPUT_FIELD_NAMES.C_PASSWORD}
            type="password"
            label={STRINGS.CONFIRM_PASSWORD}
            placeholder={STRINGS.CONFIRM_PASSWORD}
            className="my-0.5"
            onPaste={(e) => {
              e.preventDefault();
            }}
          />

          <Button
            type="submit"
            disabled={processing}
            label={STRINGS.UPDATE_PASSWORD}
            className="!mt-1"
          />
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
