import { useEffect, useState } from "react";
import { Checkbox, Grid } from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from "layouts/dashboardLayout";
import TableJs from "components/Table/table";
import { saveAdminKpiBoxData } from "redux/actions";
import { API_ROUTES, postRequest, showLogInConsole, saveKpiChanges } from "helpers";
//import Modal from "components/Modal";
import { LoaderHOC, Button } from "components";

import "./style.scss";

//@todo: open kpis for per organization
// const KpiForm = (p: { data: any }) => {
//   if (!p.data.length) {
//     return null;
//   }
//   console.log("--d", p.data);
//   return (
//     <div className="kpi-modal-container">
//       <span className="kpi-modal-title">Update Kpi</span>
//       <div className="kpi-body">
//         {p.data.map((i: any) => (
//           <div className="kpi-item">
//             <span title="Index">{i.id}</span>
//             <span title="Kpi type">{i.title}</span>
//             <input type="checkbox" title={"enable"} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

const KPIManagemnt = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  //const [isModalOpen, setModelOpen] = useState<boolean>(false);

  const kpiBoxes = useSelector((state: any) => state.admin?.kpiBoxes) || [];
  const userTokens = useSelector((state: any) => state.common.userTokens) || {};

  const fetchData = () => {
    setLoading(true);
    postRequest(API_ROUTES.GET_KPI_DATA, false, {
      idToken: userTokens.IdToken,
      organization_id: "abc",
    })
      .then(async (res: any) => {
        const { data } = res;
        const kpiData = data.data || [];
        dispatch(saveAdminKpiBoxData(kpiData));
      })
      .catch((err) => {
        showLogInConsole("ERROR In Ftech repo data ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchData();
  }, []);

  const handleClick = (index: any, source: string, value: any) => {
    const row = kpiBoxes[index];
    row[source] = value;
    kpiBoxes[index] = row;
    dispatch(saveAdminKpiBoxData(kpiBoxes));
  };

  const handleSaveChanges = async (data: any) => {
    const { id, organization_id, isEnable } = data;

    await saveKpiChanges({
      req: {
        id: id,
        organization_id: organization_id || "abc",
        isEnable: isEnable || false,
      },
      setBtnDisabled: setBtnDisabled,
    });
  };

  const columns = [
    {
      Header: "Id.",
      accessor: "id",
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: (d: { cell: any; value: string }) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>{d.value}</Grid>
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Organization",
      accessor: "organization_id",
      Cell: (d: { cell: any; value: string }) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>{d.value}</Grid>
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Is Enable",
      accessor: "isEnable",
      Cell: (d: { cell: any; row: any }) => {
        const index = d?.row?.index;
        const item = d?.row?.original?.isEnable;
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>
                <Checkbox
                  color="primary"
                  onChange={(e) => {
                    handleClick(index, "isEnable", Boolean(e.target.checked));
                  }}
                  defaultChecked={item}
                />
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "",
      Cell: (d: { cell: any; value: boolean; row: any }) => {
        const data = d?.row?.original;
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    handleSaveChanges(data);
                  }}
                  disabled={btnDisabled}
                  label="Save"
                />

                {/* <Button
                  onClick={() => {
                    setModelOpen(true);
                  }}
                  className="table-row-button"
                  disabled={btnDisabled}
                >
                  Edit
                </Button> */}
              </Grid>
            </Grid>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DashboardLayout
        hederTitle={"KPI Management"}
        childer={
          <>
            <div className="access-container" style={{ padding: 40 }}>
              {/* <Modal isOpen={isModalOpen} onClose={() => setModelOpen(false)}>
                <KpiForm data={kpiBoxes} />
              </Modal> */}
              <div className="access-content" style={{ backgroundColor: "white", padding: 10 }}>
                {/* table with templates list and roles checkboxes */}
                <div className="access-table-block">
                  <TableJs
                    sortBy={[{ id: "id", desc: false }]}
                    columns={columns}
                    data={kpiBoxes}
                    isRowSelectEnable={false}
                    headerProps={{ isEnable: true, type: 1 }}
                    isRowsPerPageChangeRequired={true}
                    isCurrentRowsCountVisibilityRequired={true}
                  />
                  {loading ? (
                    <div className="center-elements">
                      <LoaderHOC></LoaderHOC>
                    </div>
                  ) : kpiBoxes.length ? null : (
                    <div className="mt-3 center-elements">No Data Found...</div>
                  )}
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
export default KPIManagemnt;
