import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "@material-ui/core/Input";
import queryString from "query-string";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

import {
  ROUTE_CONSTANTS,
  STRINGS,
  RESPONSE_MSGS,
  API_ROUTES,
  postRequest,
  showToast,
  STATUS_CODE,
} from "../../../../helpers";

import "./style.scss";

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const navigate = useNavigate();
  const vendorEmail = queryString.parse(location.search);
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleGuestUserPasswordGeneration = async () => {
    setButtonDisabled(true);
    if (password === confirmPassword) {
      try {
        const res: any = await postRequest(API_ROUTES.Vendor_Reset_Password, false, {
          email: vendorEmail?.email,
          password: password,
        });
        const { data } = res;

        if (data.ResponseMetadata?.HTTPStatusCode !== STATUS_CODE.SUCCESS && data?.Error) {
          setButtonDisabled(false);
          showToast(data?.Error?.Message, false);
        } else {
          showToast(RESPONSE_MSGS.VENDOR_CREATED_SUCCESS, true);
          setButtonDisabled(false);
          navigate(ROUTE_CONSTANTS.LOGIN);
        }
      } catch (err) {
        showToast(RESPONSE_MSGS.ERROR, false);
        setButtonDisabled(false);
      }
    } else {
      setButtonDisabled(false);
      showToast("password and confirm password not matched", false);
    }
  };

  return (
    <div className="pass-auth-wrapper">
      <div className="auth-container">
        <div className="authentication_page">
          <div className="auth-bg-wrapper">
            <div className="auth-bg-section reset-password-bg" />
          </div>

          <div className="auth-form-section">
            <h3 className="auth-title">{STRINGS.CREATE_PASSWORD}</h3>
            <p className="pg-content">{STRINGS.RESET_PWD_SUBTITLE}</p>
            <div className="auth-form">
              <div className="form-fields">
                <label>{"New password"}</label>
                <Input
                  type={values.showPassword ? "text" : "password"}
                  className={"form-control password-field-guest"}
                  placeholder={STRINGS.PLACEHOLDER_VENDOR}
                  disableUnderline={true}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <label>{"Confirm password"}</label>
                <Input
                  type={values.showPassword ? "text" : "password"}
                  className={"form-control password-field-guest"}
                  placeholder={STRINGS.PLACEHOLDER_VENDOR}
                  disableUnderline={true}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setButtonDisabled(false);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <button
                  onClick={handleGuestUserPasswordGeneration}
                  className="form-button-created"
                  disabled={buttonDisabled}
                >
                  {"Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
