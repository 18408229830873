import { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import "./styles.scss";

// ASSETS
import { AiOutlineClose } from "react-icons/ai";

// HELPERS
import { API_ROUTES, postRequest, showToast } from "../../helpers";

// COMPONENTS
import FileUpload from "../fileUpload";
import { Button, TextInput, SelectInput } from "components";


const UploadAgreementForm = ({ closePopup = () => { }, fetchData = () => { } }) => {
  // VARIABLES & SCHEMAS
  const templateTypes = [{ label: "Free", value: "free" }, { label: "Paid", value: "paid" }];

  // SELECTORS
  const userData = useSelector((state: any) => state.common.userData);

  // STATES
  const [templateFiles, setTemplateFiles] = useState<any>([]);
  const [showForm, setshowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // FUNCTIONS
  const onUploadHandler = () => {
    if (templateFiles.length) {
      setshowForm(() => true);
    } else {
      showToast("Please choose file", false);
    }
  }
  const onBackHandler = () => {
    setshowForm(() => false);
  }
  const submitHandler = async (values, actions) => {
    try {
      setIsLoading(true);

      const formData = new FormData();

      formData.append("clientId", userData?.client_id ?? "");
      formData.append("templateName", values?.templateName ?? "");
      formData.append("category", values?.category ?? "");
      formData.append("description", values?.description ?? "");
      formData.append("files", templateFiles[0]);

      const res: any = await postRequest(API_ROUTES.UPLOAD_TEMPLATE_LIBRARY, true, formData);
      const { response } = res?.data;

      if (response == "success") {
        actions.resetForm();
        fetchData();
        showToast("Uploaded successfully", true);
      } else {
        showToast("Unable to upload!", false);
      }

    } catch (error) {
      console.log("error ===>", error);
    } finally {
      setIsLoading(false);
      closePopup();
    }
  }

  return <>
    <div className={`custom-upload-agreement-container parent-selector`}>
      <ClosePopupIcon disabled={isLoading} onClose={closePopup} />
      <h3 className="agreement-title"> Upload Template </h3>

      {showForm ? (
        <div className="w-full mt-10">
          <Formik
            initialValues={{
              templateName: "",
              category: "free",
              description: "",
            }}
            onSubmit={submitHandler}
          >
            {({ values, setValues }) => {
              return (
                <Form>
                  <div className="grid grid-cols-2 gap-x-3 gap-y-3">
                    <div className="w-full justify-start items-center">
                      <TextInput
                        name="templateName"
                        type="text"
                        label="Template Name"
                        placeholder={`Enter Template Name`}
                        isRequired={true}
                        disabled={isLoading}
                        required
                      />
                    </div>

                    <div className="w-full justify-start items-center">
                      <SelectInput
                        options={templateTypes}
                        label="Category"
                        name="category"
                        required={true}
                        disabled={isLoading}
                        onCustomChange={(_, innerItem: any) => {
                          setValues({ ...values, "category": innerItem.value });
                        }}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1">
                    <div className="w-full justify-start items-center">
                      <TextField
                        name="description"
                        type="text"
                        fullWidth
                        label="Template Description"
                        placeholder="Enter Template Description"
                        variant="outlined"
                        multiline
                        disabled={isLoading}
                        required
                        onChange={(e: any) => {
                          setValues({ ...values, "description": e.target.value });
                        }}
                      />
                    </div>
                  </div>


                  <div className="flex justify-end items-center mt-5">
                    <div className="flex gap-3 mt-2">
                      <Button
                        type="button"
                        label="Back"
                        variant="outlined"
                        className="!w-fit !mx-0.5"
                        onClick={onBackHandler}
                        disabled={isLoading}
                      />

                      <Button
                        type="submit"
                        label={isLoading ? "Please wait" : "Save"}
                        variant="contained"
                        className="!w-fit !mx-0.5"
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) :
        <UploadTemplate
          files={templateFiles}
          setFiles={setTemplateFiles}
          onUpload={onUploadHandler}
        />
      }
    </div>
  </>
};

export default UploadAgreementForm;


const ClosePopupIcon = ({ onClose = () => { }, disabled = false }) => {
  return <button
    className="upload-cross-wrapper"
    title="Close"
    onClick={onClose}
    disabled={disabled}
  >
    <AiOutlineClose className="close" />
  </button>
}
const UploadTemplate = ({ files = [], setFiles, onUpload = () => { } }) => {
  const importTypes = {
    // "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  }

  return <>
    <p className="agreement-upload-content-form my-3">
      <div className="dont-forget-to-validate mt-20">
        Drag and Drop you Template
      </div>
    </p>
    <FileUpload
      agreementToImport={files}
      setAgreementFilesToImport={setFiles}
      importType={importTypes}
    />
    <div className="upload-agreement-submit">
      <Button
        type="submit"
        label="Upload"
        variant="contained"
        className="w-fit !mx-0.5"
        onClick={onUpload}
      />
    </div></>
}
