import axios from "axios";

import config from "../config";
import { store } from "../redux/store";
import { API_ROUTES } from "./apis";
import { setUserToken } from "redux/actions";

declare module "axios" {
  //export interface AxiosRequestConfig {}
}

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  timeout: 60000,
  headers: {
    "content-type": "application/json",
  },
  withCredentials: true
});

// // Request interceptor for API calls
// axiosInstance.interceptors.request.use(
//   async config => {
//     const value = await redisClient.get(rediskey)
//     const keys = JSON.parse(value)
//     config.headers = {
//       'Authorization': `Bearer ${keys.access_token}`,
//       'Accept': 'application/json',
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }
//     return config;
//   },
//   error => {
//     Promise.reject(error)
// });

axiosInstance.interceptors.response.use(
  async (response) => {
    // if (response.data.message === "Unauthorized Access Expired Token.") {
    //   const {
    //     common: { userData, userTokens },
    //   } = store.getState();

    //   const { data }: any = await postRequest(API_ROUTES.REFRESH_TOKEN, false, {
    //     username: userData.username,
    //     refreshToken: userTokens.RefreshToken,
    //   });

    //   store.dispatch(setUserToken({ ...userTokens, ...data.AuthenticationResult }));
    // }

    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response.status == 499 || error.response.data.status_code == 499) {
      localStorage.clear();
      console.log('59 line calling');
      window.location.replace('/login');
      return Promise.reject();
    }

    if (error.response.data.message === "An error occurred (NotAuthorizedException) when calling the AdminInitiateAuth operation: Refresh Token has been revoked") {
      console.log('65 line calling');
      localStorage.clear();
      window.location.replace('/login');
      return Promise.reject();
    }
    if (error.response.data.message === "An error occurred (NotAuthorizedException) when calling the GlobalSignOut operation: Access Token has been revoked") {
      console.log('73 line calling');
      localStorage.clear();
      window.location.replace('/login');
      return Promise.reject();
    }

    // An error occurred (NotAuthorizedException) when calling the AdminInitiateAuth operation: Refresh Token has been revoked

    // if (error.response.status === 401 && !originalRequest._retry) {
    if (error.response.status === 401) {
      originalRequest._retry = true;

      const {
        common: { userData, userTokens },
      } = store.getState();

      const { data }: any = await postRequest(API_ROUTES.REFRESH_TOKEN, false, {
        username: userData.username,
        refreshToken: userTokens.RefreshToken,
      });

      store.dispatch(setUserToken({ ...userTokens, ...data.AuthenticationResult }));

      originalRequest.headers.Authorization = data.AuthenticationResult.IdToken || "";
      axios.defaults.headers.common["Authorization"] = data.AuthenticationResult.IdToken || "";

      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

const setTokenForAPICall = (authRequired: boolean) => {
  if (authRequired) {
    //applying token
    axiosInstance.defaults.headers.common["Authorization"] =
      store.getState().common?.userTokens?.IdToken || "";
  } else {
    //deleting the token from header
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

export const getRequest = (api: string, authRequired: boolean, controller?: AbortController) => {
  setTokenForAPICall(authRequired);
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(api, { signal: controller?.signal })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const postRequest = (api: string, authRequired: boolean, data: object) => {
  setTokenForAPICall(authRequired);
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(api, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const deleteRequest = (api: string, authRequired: boolean, data: object) => {
  setTokenForAPICall(authRequired);
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(api, { data: data })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const putRequest = (api: string, authRequired: boolean, data: object) => {
  setTokenForAPICall(authRequired);
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(api, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const patchRequest = (api: string, authRequired: boolean, data: object) => {
  setTokenForAPICall(authRequired);
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(api, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
