import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { showToast } from 'helpers';
import { Button, SelectInput, ToolTip, Modal } from 'components';
import IFrameFileCompareModal from './IFrameFileCompareModal';


interface Props {
    fileCompareData: {},
    isLoading: boolean,
    versionCompareModalState: boolean,
    onCompareHandler: (v: any) => void,
    onCloseVersionCompareModal: () => void;

    fileCompareModalState: boolean,
    onCloseFileCompareModal: (v: boolean) => void,

    confirmModalMsg: string,


    data: any[];
    selectedVersion: any,

}

const FileCompareDialog = ({
    fileCompareData = {},
    isLoading = false,
    versionCompareModalState = false,
    onCompareHandler = (v: any) => { },
    onCloseVersionCompareModal = () => { },

    fileCompareModalState = false,
    onCloseFileCompareModal = (v: boolean) => { },

    confirmModalMsg = 'Are you sure you want to exit?',

    data = [],
    selectedVersion = {},
}: Props) => {
    // STATES
    const [orgSelectedValue, setOrgSelectedValue] = useState({ value: selectedVersion?.version?.toString() || "", label: "Select Version" });
    const [modSelectedValue, setModSelectedValue] = useState<any>({ value: "", label: "Select Version" });
    const [filteredData, setFilteredData] = useState(() => {
        const index = data.findIndex((item: any) => item?.version?.toString() == selectedVersion?.version?.toString());
        const filteredVersions: any = data.slice(index + 1, data.length)?.map((item: any) => { return { editorData: item.editor_data, value: item.version.toString(), label: item.version.toString() } });
        return filteredVersions
    });

    // FUNCTIONS
    const orgDocHandler = (value: any) => {
        if (value === null) {
            setOrgSelectedValue({ value: "", label: "Select Version" });
            return;
        }

        const isLastElementSelected = data.slice(-1)?.[0]?.version?.toString() == value?.value?.toString();

        if (isLastElementSelected) {
            showToast("Can not select last version", false);
            setOrgSelectedValue({ value: "", label: "Select Version" });
            setModSelectedValue({ value: "", label: "Select Version" });
            return;
        }

        const index = data.findIndex((item: any) => item?.version?.toString() == value?.value?.toString());

        const filteredVersions: any = data.slice(index + 1, data.length)?.map((item: any) => { return { value: item.version.toString(), label: item.version.toString() } });

        setFilteredData(() => filteredVersions);
        setOrgSelectedValue(() => value);
    };
    const modifiedDocHandler = (value: any) => {
        if (value === null) {
            setModSelectedValue({ value: "", label: "Select Version" });
            return;
        }
        setModSelectedValue(() => value);
    };
    const onCloseFileCompareModalHandler = (value: boolean) => {
        onCloseFileCompareModal(value);
    }
    return (
        <>

            {versionCompareModalState && <Modal isOpen={versionCompareModalState} onClose={onCloseVersionCompareModal}>
                <div className='w-full h-[30%] rounded-xl bg-white'>
                    <div className='flex items-center w-full p-5 mt-5'>
                        <div className='w-[95%]'>
                            <h3 className='!font-bold text-primary-500 w-full flex justify-center items-center'>Compare Versions</h3>
                        </div>
                        <div className='w-[5%]'>
                            <AiOutlineClose className='cursor-pointer w-full text-primary-500' onClick={onCloseVersionCompareModal} />
                        </div>
                    </div>
                    <div className='w-[28rem] min-h-[10rem]'>
                        <div className='w-full flex justify-center items-center'>
                            <div className='w-[90%]'>
                                <SelectInput
                                    options={data.map((d: any) => {
                                        return { value: d.version.toString(), label: d.version.toString() };
                                    })}
                                    disabled={true}
                                    label={<span>Select Document Version &nbsp;</span>}
                                    name="orignal_doc"
                                    required={true}
                                    onCustomChange={(e, value: any) => {
                                        orgDocHandler(value);
                                    }}
                                    value={orgSelectedValue.value}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props}>
                                                <ToolTip title={option.label} arrow={true}>
                                                    <span
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            width: "100%",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {option.label}
                                                    </span>
                                                </ToolTip>
                                            </li>
                                        );
                                    }}
                                />
                                <SelectInput
                                    disabled={!orgSelectedValue.value}
                                    options={filteredData}
                                    label={<span>Select Compare Document Version &nbsp;</span>}
                                    name="modified_doc"
                                    required={true}
                                    onCustomChange={(e, value: any) => {
                                        modifiedDocHandler(value);
                                    }}
                                    className='mt-5'
                                    value={modSelectedValue.value}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props}>
                                                <ToolTip title={option.label} arrow={true}>
                                                    <span
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            width: "100%",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {option.label}
                                                    </span>
                                                </ToolTip>
                                            </li>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className='w-full mt-5 mb-5'>
                            <div className='w-[95%] flex justify-end items-center '>
                                <Button
                                    label={`${isLoading ? "Loading..." : "Compare"}`}
                                    variant="contained"
                                    className="!w-fit !mx-0.5"
                                    onClick={
                                        () => onCompareHandler(
                                            {
                                                baseVersion: orgSelectedValue.value.toString(),
                                                comparisonVersion: modSelectedValue.value.toString(),
                                                baseVersionData: selectedVersion?.editor_data,
                                                comparisonVersionData: modSelectedValue?.editorData
                                            }
                                        )
                                    }
                                    disabled={!modSelectedValue.value || isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>}

            {fileCompareModalState && <IFrameFileCompareModal
                data={fileCompareData}
                state={fileCompareModalState}
                confirmModalMsg={confirmModalMsg}
                onClose={onCloseFileCompareModalHandler}
            />}
        </>
    )
}

export default FileCompareDialog