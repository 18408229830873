import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineSearch } from "react-icons/ai";
import { IoFunnelOutline } from "react-icons/io5";
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import { InputAdornment, TextField } from "@material-ui/core";
import { Button } from "components";
import ReactSimpleTooltip from "react-simple-tooltip";

//
import { UploadAgreementForm, UploadByotFormNew } from "customComponents";
import UploadThirdPartyForm from "customComponents/ThirdPartyReview";
//
import NewAgreementList from "views/secured/agreement/newAgreementList";
import AdminModal from "views/secured/admin/adminModal";
import {
  API_ROUTES,
  fetchAgreementList,
  IMPORT_TYPES,
  postRequest,
  showToast,
  USER_ROLES,
} from "helpers";
import { BulkShare } from "customComponents";
import { removeRepositoryDocIds } from "redux/actions";
//

interface Props {
  name?: string;
  setGlobalFilter: Function;
  setPageSize?: any;
  isRowsPerPageChangeRequired: boolean;
  headerProps?: {
    isEnable: boolean;
    type?: number;
    refetchData?: Function;
    headerButtons?: any;
  };
}

interface SearchProps {
  value: string;
  handleOnchange: any;
  type?: number;
  refetchDataAfterImport?: Function;
  handleFilter: any;
  ref: any;
}

const useStyles = makeStyles({
  root: {
    // Mui Commented Here...#
    // "& .MuiOutlinedInput-root": {
    //   "&.Mui-focused fieldset": {
    //     border: " 0.5px solid grey",
    //   },
    //   "&.Mui-focused": {
    //     boxShadow: "0 0 white",
    //   },
    // },
  },
});

const SearchRowComponent: any = (p, ref) => {
  const dispatch = useDispatch();

  const searchInput = useRef<HTMLInputElement>(null);
  const [isAgListLoading, setAgListLoading] = useState(true);
  const [agreementList, setAgreementList] = useState<any>([]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [fetchingAgreementList, setFetchingAgreementList] = useState(false);
  const [byotText, setByotText] = useState("BYOT");

  //
  const userData = useSelector((state: any) => state.common.userData);
  const selectedDocs = useSelector((state: any) => state?.repository?.selectedDocIds);

  const userRole = userData?.persona ? userData?.persona : userData?.role ? userData?.role : "";

  const classes = useStyles();
  useImperativeHandle(ref, () => ({
    setFilter() {
      if (searchInput && searchInput.current && searchInput.current.value) {
        p.handleFilter(searchInput.current.value);
      }
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  // FUNCTIONS
  const bulkShareSubmitHandler = async (selectedUsers: any) => {
    try {
      const data = selectedUsers.map((usr) => {
        const modedUser: any = {
          userId: usr?.userId || "",
          "new-agreement": [],
          "historic-agreement": [],
        };
        selectedDocs.forEach((docItem) => {
          if (docItem.agreementStatus == "Executed") {
            modedUser["new-agreement"].push({
              documentId: docItem.documentId,
              accessLevel: docItem.accessLevel,
            });
          } else {
            modedUser["historic-agreement"].push({
              documentId: docItem.documentId,
              accessLevel: docItem.accessLevel,
            });
          }
        });
        return modedUser;
      });

      const response = await postRequest(API_ROUTES.SHARE_AGREEMENT, true, data);

      // console.log("bulkShareSubmitHandler response ===>", response);

      showToast("Documents shared", true);
    } catch (err) {
      console.log("bulkShareSubmitHandler ==>", err);
      showToast("something went wrong.", false);
    } finally {
      dispatch(removeRepositoryDocIds([]));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (p.type === 3) {
      (async () => {
        await fetchAgreementList({
          setLoading: setAgListLoading,
          setData: setAgreementList,
        });
      })();
    }
  }, [fetchingAgreementList]);

  return (
    <>
      {/* <div className="search-wrapper3">
        <div className="search-bar">
          <TextField
            size="small"
            className={`Search-wrapper ${classes.root}`}
            variant="outlined"
            onChange={p.handleOnchange}
            inputRef={searchInput}
            value={p.value}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ color: "#7D889B" }}>
                  <AiOutlineSearch />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div> */}
      <div className="table-header-buttons3">
        <div className="table-filter !hidden">
          <IoFunnelOutline />
          <span>Filter</span>
        </div>

        {p.type === 3 && userRole !== USER_ROLES["vendor"] && (
          <>
            {window.location.pathname !== "/repository" && (
              <>
                <Popup
                  trigger={
                    <ReactSimpleTooltip
                      content="Bring Your Own Template"
                      customCss={`white-space: nowrap;fontSize:12px !important;`}
                      zIndex={1}
                      radius={5}
                      placement="left"
                      fontSize="small"
                      padding={8}
                      arrow={7}
                    >
                      <Button
                        type="button"
                        label={byotText}
                        variant="outlined"
                        className="!mx-0.5"
                        isFullWidth={false}
                      />
                    </ReactSimpleTooltip>
                  }
                  modal
                  closeOnDocumentClick={false}
                  closeOnEscape={false}
                  className="byot-new-upload-popup"
                >
                  {(close: () => {}) => {
                    return (
                      <UploadByotFormNew
                        closePopup={close}
                        // refetchDataAfterImport={p.refetchDataAfterImport}
                        refetchDataAfterImport={() => {
                          setTimeout(() => {
                            setFetchingAgreementList(true);
                          }, 2000);
                        }}
                      />
                    );
                  }}
                </Popup>

                {userRole === USER_ROLES["drafter"] && (
                  <Popup
                    trigger={
                      <Button
                        type="button"
                        label={"3rd Party Agreement"}
                        variant="outlined"
                        className="!mx-0.5 "
                        isFullWidth={false}
                      />
                    }
                    modal
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                    className="byot-new-upload-popup"
                  >
                    {(close: () => {}) => {
                      return (
                        <UploadThirdPartyForm
                          closePopup={close}
                          // refetchDataAfterImport={p.refetchDataAfterImport}
                          refetchDataAfterImport={() => {
                            setTimeout(() => {
                              setFetchingAgreementList(true);
                            }, 2000);
                          }}
                        />
                      );
                    }}
                  </Popup>
                )}
              </>
            )}

            {userRole === USER_ROLES["drafter"] && (
              <>
                <Button
                  type="button"
                  label="Create Agreement"
                  variant="outlined"
                  className="!mx-0.5"
                  onClick={() => {
                    setOpen(true);
                  }}
                  isFullWidth={false}
                />

                <NewAgreementList
                  isLoading={isAgListLoading}
                  data={agreementList}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  isNewAgreement={true}
                />
              </>
            )}

            {/* <div className="table-filter"> */}
            <Popup
              // trigger={<span className="btn-import blue-text py-1">Import Agreement</span>}
              trigger={
                <Button
                  type="button"
                  label="Import Agreement"
                  variant="outlined"
                  className="!mx-0.5"
                  isFullWidth={false}
                />
              }
              modal
              closeOnDocumentClick={false}
              closeOnEscape={false}
              className="agreement-upload-popup parent-selector"
              disabled={userRole === "vendor"}
            >
              {(close: () => {}) => (
                <UploadAgreementForm
                  closePopup={close}
                  refetchDataAfterImport={p.refetchDataAfterImport}
                  importType={IMPORT_TYPES.AGREEMENT}
                  showParentAgreementSelection={true}
                />
              )}
            </Popup>
            {/* </div> */}
            {/* ### import License commented here */}
            {/* <div className="table-filter">
              <Popup
                trigger={<span>Import License</span>}
                modal
                closeOnDocumentClick={false}
                closeOnEscape={false}
                className="agreement-upload-popup"
              >
                {(close: () => {}) => (
                  <UploadAgreementForm
                    closePopup={close}
                    refetchDataAfterImport={p.refetchDataAfterImport}
                    importType={IMPORT_TYPES.LICENSE}
                  />
                )}
              </Popup>
            </div> */}

            {/* BULK SHARE DOCUMETS */}
            {/* <Popup
              modal
              trigger={
                <Button
                  type="button"
                  label="Bulk Share"
                  variant="outlined"
                  className="!mx-0.1"
                  isFullWidth={false}
                  disabled={selectedDocs.length == 0 ? true : false}
                />
              }
              contentStyle={{
                width: "480px",
                maxWidth: "480px",
                minHeight: "400px",
                maxHeight: "490px",
                borderRadius: "10px",
              }}
              closeOnEscape={true}
            >
              {(close: () => {}) => (
                <BulkShare
                  onCloseModal={() => {
                    close();
                    dispatch(removeRepositoryDocIds([]));
                  }}
                  onSubmit={bulkShareSubmitHandler}
                />
              )}
            </Popup> */}
            {/* END BULK SHARE DOCUMENTS */}
          </>
        )}
      </div>
    </>
  );
};

export const SearchRow: React.FC<SearchProps> = React.forwardRef(SearchRowComponent);

const PageSizeController = (prop: {
  setPageSize: Function;
  isRowsPerPageChangeRequired: boolean;
}) => {
  return (
    <>
      <div className="page-size-control-block">
        {prop.isRowsPerPageChangeRequired ? (
          <>
            <span>Show</span>
            <select
              onChange={(e) => {
                prop.setPageSize(e.target.value);
              }}
            >
              {[10, 20, 30, 40, 50].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </>
        ) : null}
      </div>
    </>
  );
};

const TableSearchBar: React.FC<Props> = React.forwardRef((p, ref) => {
  const [filterInput, setFilterInput] = useState<string>("");
  const headerType = p.headerProps?.type || 0;
  const refetchDataAfterImport = p.headerProps?.refetchData;
  if (!p.headerProps?.isEnable) {
    return null;
  }
  const handleFilterChange = (e: any) => {
    const value = e.target.value || "";
    p.setGlobalFilter(value);
    setFilterInput(value);
  };
  const handleFilter = (value) => {
    p.setGlobalFilter(value);
  };

  if ([2, 3].includes(headerType)) {
    return (
      <SearchRow
        value={filterInput}
        handleOnchange={handleFilterChange}
        type={headerType}
        refetchDataAfterImport={refetchDataAfterImport}
        ref={ref}
        handleFilter={handleFilter}
      />
    );
  }

  // Added for dashboard changes
  if ([11].includes(headerType)) {
    return (
      <>
        <SearchRow
          value={filterInput}
          handleOnchange={handleFilterChange}
          type={headerType}
          refetchDataAfterImport={refetchDataAfterImport}
          ref={ref}
          handleFilter={handleFilter}
        />
      </>
    );
  }

  return (
    <>
      <PageSizeController
        setPageSize={p.setPageSize}
        isRowsPerPageChangeRequired={p.isRowsPerPageChangeRequired}
      />
      <div className="search-wrapper">
        <div className="search-bar">
          <span>Search</span>
          <input
            type="text"
            className="Search-wrapper"
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Search"}
          />
        </div>

        <div className="table-header-buttons">
          {headerType === 1 ? (
            <div className="table-filter hidden">
              <IoFunnelOutline />
              <span>Filter</span>
            </div>
          ) : headerType === 0 ? (
            <AdminModal isEdit={false} />
          ) : headerType === 5 && p.headerProps.headerButtons ? (
            p.headerProps.headerButtons
          ) : (
            <>
              <button className="table-export table-header-button">Export</button>
              <button className="table-header-button">View Graph</button>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default TableSearchBar;
