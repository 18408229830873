import { useState } from "react";
import { useSelector } from "react-redux";

//
import { USER_ROLES, NEW_AGREEMENT_STATUS } from "helpers";
import { Modal, Button, RadioGroups } from "components";

//
interface usersProps {
  isOpen: boolean;
  handleClose: any;
  role: string;
  values: any;
  handleSubmit?: any;
  setValues?: any;
  skipReviewStep?: any;
  btnDisabled: boolean;
  usersList: usersInformation[];
}

const AvailableUsersList = (p: usersProps) => {
  const [agreementActioner, setAgreementActioner] = useState<string[]>([]);
  const userData = useSelector((state: any) => state.common.userData) || {};

  //
  const [activeIndexNo, setActiveIndexNo] = useState(-1);

  const handleUserList = (item: any) => {
    let allUser = agreementActioner;

    // To add multiple users
    /* const isExist = allUser.find((i: any) => i === e.target.value);
     * if (isExist) {
     *   allUser = allUser.filter((i: any) => i !== e.target.value);
     * } else {
     *   allUser = [...agreementActioner, e.target.value];
     * } */

    allUser = [item.email];
    setAgreementActioner(allUser);
    // setAgreementActioner([e.target.value]);
  };

  const closePopup = () => {
    setAgreementActioner([]);
    p.handleClose();
  };

  return (
    <Modal onClose={p.handleClose} isOpen={p.isOpen}>
      <div className="al-user-container">
        <h3 className="al-user-label">{`Select Your ${p.role[0].toUpperCase()}${p.role.slice(
          1,
        )}`}</h3>
        <div className="max-h-72 overflow-auto flex h-full px-5 my-5">
          {/* {p?.usersList?.map((user, index) => (
            <div className="user" key={index}>
              <input type="radio" value={user} name={p.role} onChange={handleUserList} />
              {user}
            </div>
          ))} */}
          {/* <RadioGroups
            options={p?.usersList}
            groupName={p.role}
            onChange={(e) => {
              console.log(e);

              handleUserList(e);
            }}
            label=""
            value={agreementActioner[0]}
          /> */}
          {/*  */}

          <div>
            {p.usersList.map((item, index) => {
              return (
                <div
                  className={`flex items-center space-x-4 my-3 p-3 rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)] cursor-pointer border border-solid border-[transparent] hover:border-primary-800 hover:bg-[#f1f1f1] ${
                    activeIndexNo === index && "border-primary-800 bg-[#ececec]"
                  }`}
                  key={index}
                  onClick={() => {
                    handleUserList(item);
                    setActiveIndexNo(index);
                  }}
                >
                  <div className="flex-shrink-0">
                    <img
                      className="w-8 h-8 rounded-full"
                      // src="https://www.pngarts.com/files/10/Default-Profile-Picture-PNG-Free-Download.png"
                      src={item.profile_img}
                      // alt="profile image"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-bold text-gray-text truncate ">
                      {item.first_name} {item.last_name} (Email: {item.email})
                    </p>
                    <p className="text-sm text-gray-500 truncate ">Username: {item.email}</p>
                  </div>
                  {/* <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                $320
              </div> */}
                </div>
              );
            })}
          </div>

          {/*  */}
        </div>
        <div className="flex justify-end mb-2">
          <div className="flex gap-2">
            <Button
              type="submit"
              onClick={() => {
                const arr =
                  agreementActioner.length === 1 ? agreementActioner[0] : agreementActioner;
                let agreementStatus = p.values.agreementStatus;
                const obj = { [p.role.toLowerCase()]: arr };
                if (p.role === USER_ROLES["reviewer"]) {
                  agreementStatus = NEW_AGREEMENT_STATUS["review"];
                } else {
                  //p.setSubmitType(NEW_AGREEMENT_STATUS["approve"]);
                  agreementStatus = NEW_AGREEMENT_STATUS["approve"];
                  if (p.skipReviewStep !== "true") {
                    obj.reviewer = userData.email;
                  }
                }
                //p.setValues({ ...p.values, ...obj  });
                p.handleSubmit({ ...p.values, ...obj }, agreementStatus);
                closePopup();
              }}
              disabled={p.btnDisabled || !Object.keys(agreementActioner).length}
              label="Confirm"
            />

            <Button
              onClick={closePopup}
              disabled={p.btnDisabled}
              label="Cancel"
              variant="outlined"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AvailableUsersList;
