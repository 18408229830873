import React from "react";
import { FieldHookConfig, useField } from "formik";
import {
  OutlinedInput,
  Autocomplete,
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";

import "./style.scss";

// interface option {
//   value: string; label: string;
// }

//interface options extends Array<option>{}

interface otherProps {
  label: string | any;
  options: Array<object>;
  innerClassName?: string;
  isFormik?: boolean;
  size?: "medium" | "small"

  //
  onCustomChange?: Function;
  renderOption?: Function;
}
// https://mui.com/material-ui/react-text-field/#select
const SelectInput = (p: otherProps & FieldHookConfig<string>) => {
  // const [field, meta] = useField(p);

  const [field, meta] = p.isFormik ? useField(p) : [null, null];

  return (
    <div className="form-group">
      <Autocomplete
        {...p}
        disablePortal
        options={p.options}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={p.label}
              className={`[&>div]:!p-0 ${p?.innerClassName} [&>.MuiInputBase-root>input]:max-w-[70%] `}
              error={meta !== null && meta.touched && meta.error ? true : false}
              required={p.required}
            />
          );
        }}
        onChange={(e, value: any) => {
          if (p.onCustomChange) {
            p.onCustomChange(e, value);
          }
        }}
        ListboxProps={{ style: { maxHeight: 200 } }}
      />
      {field !== null ? (
        <span style={{ height: "1rem" }} className="ml-[14px]">
          {meta.touched && meta.error ? (
            <FormHelperText error={meta.touched && meta.error ? true : false} className="-mt-0.5">
              {meta.error}
            </FormHelperText>
          ) : null}
        </span>
      ) : null}
    </div>
  );

  {
    /* <>
        <label>{p.label}</label>
        <Input
          type={values.showPassword ? "text" : "password"}
          {...field}
          className={`${p.className} form-control password-field`}
          placeholder={p.placeholder}
          disableUnderline={true}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </> */
  }
  // <div className="form-group">
  //   <>
  //     <label>{p.label}</label>
  //     <select
  //       // value={values.color}
  //       // onChange={handleChange}
  //       // onBlur={handleBlur}
  //       {...field}
  //       className={p.className}
  //       style={{ display: "block" }}
  //     >
  //       <option value="" label={p.placeholder} disabled hidden />
  //       {p.options.map((opt, index) => (
  //         <option key={index} value={opt.value} label={opt.label} />
  //       ))}
  //     </select>
  //   </>
  //   {meta.touched && meta.error ? (
  //     <div className="field-error" title={meta?.error}>
  //       {meta.error}
  //     </div>
  //   ) : null}
  // </div>
};

export default SelectInput;
