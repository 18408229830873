import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import UserForm from "./userForm";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
// import { FaEllipsisV } from "react-icons/fa";
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import { deleteUserServices } from "redux/services";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";

import { Button } from "components";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <div style={{ marginTop: -35, marginRight: -30, float: "right" }}>
          {" "}
          <CloseIcon onClick={onClose} />
        </div>
      ) : null}
      {children}
    </DialogTitle>
  );
};

const AdminModal = (props: any) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const { username } = props;
    await deleteUserServices(username);
  };

  return (
    <>
      {props?.isEdit ? (
        <>
          <EditTwoToneIcon
            onClick={handleClickOpen}
            style={{ color: "#560BAD", cursor: "pointer" }}
          />
          <DeleteTwoToneIcon
            onClick={handleDelete}
            style={{ marginLeft: -15, cursor: "pointer", color: "rgb(234, 84, 85)" }}
          />
        </>
      ) : (
        <Button label="Create User" variant="outlined" onClick={handleClickOpen} />
      )}
      {/* <button className="table-header-button" onClick={handleClickOpen}>
          Create User
        </button> */}

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="dialog"
      >
        <div className="admin-list-modal">
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <h3 className="form-title"> {props?.isEdit ? "Edit User Form" : "Add User Form"}</h3>
            <Typography
              style={{
                fontFamily: "Plus Jakarta Sans",
                color: "#5E5873",
                fontSize: "13px",
                textAlign: "center",
              }}
            >
              {props?.isEdit
                ? "Updating user detail will recieve a privicy audit"
                : "Add user detail will recieve a privicy audit"}
            </Typography>
          </BootstrapDialogTitle>

          <UserForm
            handleClose={handleClose}
            isEdit={props?.isEdit}
            id={props?.id}
            username={props?.username}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AdminModal;
