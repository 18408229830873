import { Formik, Form, Field } from "formik";
import DialogActions from "@material-ui/core/DialogActions";
import { useSelector, RootStateOrAny } from "react-redux";
import { Grid } from "@material-ui/core/";
import * as Yup from "yup";

import Tooltip from "components/ToolTip";
import { TextInput, Button } from "components";
import { userFormServices, userUpdateFormServices } from "../../../redux/services";
import { STRINGS, VALIDATION_MSGS } from "../../../helpers";
import "./style.scss";

const adminForm = Yup.object().shape({
  company_name: Yup.string().required(VALIDATION_MSGS.REQUIRED),
  status: Yup.string().required(VALIDATION_MSGS.REQUIRED),
  email: Yup.string().email(VALIDATION_MSGS.INVALID_EMAIL).required(VALIDATION_MSGS.REQUIRED),
});

const UserForm = (props: any) => {
  const { isEdit, id, username } = props;
  const { userFormData } = useSelector((state: RootStateOrAny) => state.admin);
  let initial: any = [];
  if (id || username) {
    const editedData = userFormData
      .filter(function (data: any) {
        return data.username === username;
      })
      .map(function (user: any) {
        return user;
      });
    const updatedData = editedData[0];
    console.log(updatedData);
    initial = {
      username: updatedData.username,
      email: updatedData.email,
      lastName: "",
      status: updatedData.status ? "Active" : "Disable",
      // role: ["vendor", "drafter"],
      role: updatedData.role,
      company_name: updatedData.company_name,
    };
  } else {
    initial = {
      username: "",
      email: "",
      lastName: "",
      status: "Active",
      role: "reviewer",
      company_name: "",
    };
  }

  return (
    <>
      <div className="user-body-row">
        <Formik
          initialValues={initial}
          validationSchema={adminForm}
          onSubmit={async (values, { setSubmitting }) => {
            const { email, company_name, status, role } = values;
            if (!isEdit && !id) {
              const v = {
                username: email,
                email: email,
                company_name: company_name,
                status: status,
                role: role,
              };
              await userFormServices(v, props.handleClose);
              setSubmitting(false);
            } else {
              const v = {
                username: values.username,
                email: values.email,
                company_name: values.company_name,
                status: values.status,
                role: values.role,
              };
              await userUpdateFormServices(id, v, props.handleClose);
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <>
              <Grid>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        name={"email"}
                        type="text"
                        label="Email"
                        placeholder={"Email"}
                        readOnly={isEdit && id}
                        // className={
                        //   errors.email ? "user-text-field user-text-error" : "user-text-field"
                        // }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        name={"company_name"}
                        type="text"
                        label="Company"
                        placeholder={"Company"}
                        // className={
                        //   errors.company_name
                        //     ? "user-text-field user-text-error"
                        //     : "user-text-field"
                        // }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className="form-group">
                        <label>{"Status"}</label>
                        <Field
                          component="select"
                          name="status"
                          className={
                            errors.status ? "user-text-field user-text-error" : "user-text-field"
                          }
                        >
                          <option value="Active">Active</option>
                          <option value="Disable">Disable</option>
                        </Field>
                        {touched.status && errors.status ? (
                          <div className="field-error">{errors.status}</div>
                        ) : null}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className="form-group">
                        <label>{"Role"}</label>
                        <Grid item xs={12} sm={12}>
                          <span className="margin-left">
                            <Tooltip title={STRINGS.DRAFTER_ACTION} arrow={true}>
                              <label>
                                <Field
                                  type="radio"
                                  name="role"
                                  value={"drafter" || "Drafter"}
                                  defaultChecked={true}
                                />
                                Drafter
                              </label>
                            </Tooltip>
                          </span>
                          <span className="margin-left">
                            <Tooltip title={STRINGS.REVIEWER_ACTION} arrow={true}>
                              <label>
                                <Field
                                  type="radio"
                                  name="role"
                                  value={"reviewer" || "Reviewer"}
                                  defaultChecked={true}
                                />
                                Reviewer
                              </label>
                            </Tooltip>
                          </span>
                          <span className="margin-left">
                            <Tooltip title={STRINGS.APPROVER_ACTION} arrow={true}>
                              <label>
                                <Field type="radio" name="role" value="approver" />
                                Approver
                              </label>
                            </Tooltip>
                          </span>
                          <span className="margin-left">
                            <Tooltip title={STRINGS.VENDOR_ACTION} arrow={true}>
                              <label>
                                <Field type="radio" name="role" value="vendor" />
                                Vendor
                              </label>
                            </Tooltip>
                          </span>
                          <span className="margin-left">
                            <Tooltip title={STRINGS.ADMIN_ACTION} arrow={true}>
                              <label>
                                <Field type="radio" name="role" value="admin" />
                                Admin
                              </label>
                            </Tooltip>
                          </span>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <DialogActions className="footer-button">
                    <Button
                      autoFocus
                      onClick={props.handleClose}
                      variant="outlined"
                      label="Discard"
                    />
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      label={isEdit ? "Update user" : "Create user"}
                    />
                  </DialogActions>
                </Form>
              </Grid>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UserForm;
