import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Questions = () => {
  return (
    <Container>
      <Wrapper>
        <div>
          <p className="sub-heading">HAVE QUESTIONS</p>
          <h3>Frequently Asked Questions</h3>
          <Accordion className="Ques">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>How can we start a project together?</Typography>
            </AccordionSummary>
            <AccordionDetails className="Ans">
              <p>
                Great question! The first step is to get in touch by filling out our contact form,
                which you’ll find here. We usually start our projects with a discovery call,
                followed by a short exploration phase where we work with you to define the project
                scope and services we can help you with.
              </p>
              <p>
                We’ll then share our cost estimate, prepare a statement of work, and schedule
                kickoff calls with our teams. Once the paperwork is complete and the deposit payment
                is settled, we get to work!
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="Ques">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>How much do projects usually cost?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Great question! The first step is to get in touch by filling out our contact form,
                which you’ll find here. We usually start our projects with a discovery call,
                followed by a short exploration phase where we work with you to define the project
                scope and services we can help you with. We’ll then share our cost estimate, prepare
                a statement of work, and schedule kickoff calls with our teams. Once the paperwork
                is complete and the deposit payment is settled, we get to work!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="ques">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>What sectors do you specialise in?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Great question! The first step is to get in touch by filling out our contact form,
                which you’ll find here. We usually start our projects with a discovery call,
                followed by a short exploration phase where we work with you to define the project
                scope and services we can help you with. We’ll then share our cost estimate, prepare
                a statement of work, and schedule kickoff calls with our teams. Once the paperwork
                is complete and the deposit payment is settled, we get to work!
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Questions;

const Container = styled.div`
  display: flex;
  padding: 0 158px;
  max-width: 1440px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 130px;
  .sub-heading {
    font-family: "matter_sq_trialbold";
    font-size: 14px;
    color: #4361ee;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  h3 {
    font-family: "matter_sq_trialsemibold";
    font-size: 34px;
    line-height: 48px;
    color: #343a40;
    font-weight: normal;
    margin: 0;
    margin-bottom: 40px;
  }
  // Mui Commented Here...#
  // .MuiAccordion-root {
  //   background: #ffffff;
  //   box-shadow: 0px 5px 50px rgba(20, 15, 108, 0.05);
  //   border-radius: 20px !important;
  //   margin-bottom: 40px;
  //   border: 2px solid transparent;

  //   &:before {
  //     display: none;
  //   }
  //   &.Mui-expanded {
  //     margin-bottom: 40px;
  //     border: 2px solid rgba(67, 97, 238, 0.5);
  //   }
  //   .MuiAccordionSummary-root {
  //     padding: 0 40px;
  //     .MuiAccordionSummary-expandIcon {
  //       border-radius: 10px;
  //       padding: 4px;
  //       background: rgba(186, 191, 199, 0.12);

  //       .MuiSvgIcon-root {
  //         color: #82868b;
  //       }
  //       &.Mui-expanded {
  //         background: rgba(67, 97, 238, 0.12);
  //         margin-top: 20px;
  //         .MuiSvgIcon-root {
  //           color: #4361ee;
  //         }
  //       }
  //     }
  //     .MuiAccordionSummary-content {
  //       margin: 30px 0;
  //     }

  //     p {
  //       margin: 0;
  //       font-size: 21px;
  //       line-height: 48px;
  //       color: #6c757d;
  //       font-family: "matter_sq_trialmedium";
  //     }
  //     .MuiAccordionSummary-content.Mui-expanded {
  //       margin: 30px 0 10px;
  //       p {
  //         color: #343a40;
  //       }
  //     }
  //   }
  // }
  // .MuiAccordionDetails-root {
  //   padding: 0 40px 40px;
  //   display: grid;
  //   gap: 19px;
  //   p {
  //     margin: 0px;
  //     font-size: 17px;
  //     line-height: 28px;
  //     font-family: "matter_sq_trialregular";
  //     color: #adb5bd;
  //   }
  // }
`;
