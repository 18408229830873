import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
// import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";
import { GroupAdd } from "@material-ui/icons";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PersonAddDisabled from "@material-ui/icons/PersonAddDisabled";
import PersonOutlineTwoToneIcon from "@material-ui/icons/PersonOutlineTwoTone";
import EmojiPeopleTwoToneIcon from "@material-ui/icons/EmojiPeopleTwoTone";
import StorageTwoToneIcon from "@material-ui/icons/StorageTwoTone";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
//
import AdminModal from "./adminModal";
import { getUserServices } from "redux/services";
//
import { LoaderHOC } from "components";
import Tooltip from "components/ToolTip";
import TableJs from "components/Table/table";
import Profile from "components/Profile";
import DashboardLayout from "layouts/dashboardLayout";
import { STRINGS } from "helpers";
//
import "./style.scss";

const DbItem = (p: {
  title: string;
  subTitle: string;
  index: number;
  icon: React.ReactElement;
}) => {
  return (
    <>
      <Card className="">
        <CardContent className="!p-5">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <div className="text-xs text-primary-600">{p.subTitle}</div>
              <div className="font-bold">{p.title}</div>
            </div>

            {p.icon}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export const Admin: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const userFormData = useSelector((state: any) => state.admin.userFormData);

  useEffect(() => {
    dispatch(getUserServices(setLoading));
    // setLoading(false)
  }, []);

  const columns = [
    {
      Header: "Name",
      accessor: "username",
      Cell: (d: { cell: any; value: string }) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item>{Profile(d.value)}</Grid>
              <Grid item>{d.value}</Grid>
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: (d: { cell: any; value: string }) => {
        const arr = [d.value];
        return (
          <>
            <div className="grid ">
              {arr.map((i) => {
                if (i == "reviewer") {
                  return (
                    <Tooltip title={STRINGS.REVIEWER_ACTION} arrow={true}>
                      <div className="flex gap-2">
                        <VisibilityTwoToneIcon style={{ color: "#560BAD" }} /> {d.value}
                      </div>
                    </Tooltip>
                  );
                }
                if (i == "drafter") {
                  return (
                    <Tooltip title={STRINGS.DRAFTER_ACTION} arrow={true}>
                      <div className="flex gap-2">
                        <StorageTwoToneIcon style={{ color: "#560BAD" }} /> {i}
                      </div>
                    </Tooltip>
                  );
                }
                if (i == "admin") {
                  return (
                    <Tooltip title={STRINGS.ADMIN_ACTION} arrow={true}>
                      <div className="flex gap-2">
                        <PersonOutlineTwoToneIcon style={{ color: "#560BAD" }} /> {i}
                      </div>
                    </Tooltip>
                  );
                }
                if (i == "vendor") {
                  return (
                    <Tooltip title={STRINGS.VENDOR_ACTION} arrow={true}>
                      <div className="flex gap-2">
                        <EmojiPeopleTwoToneIcon style={{ color: "#560BAD" }} /> {i}
                      </div>
                    </Tooltip>
                  );
                }
                if (i == "approver") {
                  return (
                    <Tooltip title={STRINGS.APPROVER_ACTION} arrow={true}>
                      <div className="flex gap-2">
                        <CheckCircleTwoToneIcon style={{ color: "#560BAD" }} /> {i}
                      </div>
                    </Tooltip>
                  );
                }
              })}
            </div>
          </>
        );
      },
    },
    {
      Header: "Company",
      accessor: "company_name",
      Cell: (d: { cell: any; value: string }) => {
        return <div>{d.value}</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (d: { cell: any; value: string }) => {
        return (
          <div className={d.value ? "status-success" : "status-inactive"}>
            {d.value ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "email",
      Cell: (d: { cell: any; value: string; row: any }) => {
        const userName = d?.row?.original?.username || "";
        return (
          <div className="table-cell">
            <div className="table-actions">
              <AdminModal isEdit={true} id={d.value} username={userName} />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <DashboardLayout
        hederTitle={"Welcome Admin"}
        childer={
          <>
            <div className="admin-container">
              <div className="admin-content">
                <div>
                  <div className="grid gap-6 grid-cols-3 w-screen max-w-4xl mt-5">
                    <div className="">
                      <DbItem
                        title={"21,900"}
                        subTitle={"Total Users"}
                        index={1}
                        icon={
                          <div className="circle">
                            <PersonOutlineOutlinedIcon color="primary" fontSize="large" />
                          </div>
                        }
                      />
                    </div>

                    <div className="">
                      <DbItem
                        title={`19,860`}
                        subTitle={"Active Users"}
                        index={3}
                        icon={
                          <div className="circle">
                            {" "}
                            <GroupAdd color="primary" fontSize="large" />
                          </div>
                        }
                      />
                    </div>
                    <div>
                      <DbItem
                        title={`273`}
                        subTitle={"Pending Users"}
                        index={4}
                        icon={
                          <div className="circle">
                            {" "}
                            <PersonAddDisabled color="primary" fontSize="large" />
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
                {loading ? (
                  <LoaderHOC></LoaderHOC>
                ) : userFormData.length === 0 ? (
                  <div className="admin-table-block">NO DATA</div>
                ) : (
                  <div className="admin-table-block">
                    <TableJs
                      columns={columns}
                      data={userFormData}
                      isRowSelectEnable={false}
                      headerProps={{ isEnable: true, type: 0 }}
                      isRowsPerPageChangeRequired={true}
                      isCurrentRowsCountVisibilityRequired={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
