import styled from "styled-components";
import KPIDashboard from "../../../../assets/images/KPIDashboard.png";
// import Wave from '../../assets/images/Wave1.png'
// import Lines from '../../assets/images/Lines_Pattern.png'

const OneCounsel = () => {
  return (
    <Container>
      <Wrapper>
        <img className="KPIDashboard-img" src={KPIDashboard} alt="KPI Dashboard" />

        <div>
          <p>WHY ONECOUNSEL</p>
          <h3>
            <span className="bold">OneCounsel</span> is an intelligent{" "}
          </h3>
          <h3>choice for Top Enteprises</h3>
          <span className="text">
            Streamline your enterprise-wide contracting process using digital workflows and AI.
            Submit requests, review redlined documents, manage approvals and execute contracts with
            e-signatures effortlessly. OneCounsel enables enterprises to efficiently create and
            manage a high volume and variety of contracts for procurement, sales, services, legal,
            NDA, HR, or any other type of business operations.
          </span>
        </div>
      </Wrapper>
    </Container>
  );
};

export default OneCounsel;

const Container = styled.div`
  position: relative;

  .KPIDashboard-img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1440px;
  grid-gap: 74px;
  margin: 0 auto;
  margin-bottom: 130px;
  padding: 0px 79px;
  p {
    font-family: "matter_sq_trialbold";
    font-size: 14px;
    color: #4361ee;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  h3 {
    font-family: "matter_sq_trialregular";
    font-size: 34px;
    line-height: 48px;
    color: #343a40;
    font-weight: normal;
    margin-bottom: 24px;
    margin: 0;
    .bold {
      font-family: "matter_sq_trialsemibold";
      font-weight: 700;
    }
  }
  .text {
    font-family: "matter_sq_trialregular";
    color: #adb5bd;
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 22px;
  }
`;
