import { forceLogout } from "../redux/services";
import { store } from "../redux/store";
import { getRequest, API_ROUTES, postRequest, showToast } from "./";

export const fetchAgreementList = async (p: {
  apiName?: string;
  token?: string;
  setLoading: Function;
  setData: Function;
}) => {
  const api = p.apiName || API_ROUTES.GET_AGREEMENT_LIST;

  try {
    p.setLoading(true);

    const res: any = await getRequest(
      `${api}?client_id=${store.getState().common.userData.client_id}`,
      true,
    );

    if (res.data && res.data.status === 200) {
      p.setLoading(false);
      p.setData(
        res.data?.response
          .sort((a, b) => {
            return b.createdDate.localeCompare(a.createdDate);
          })
          .filter((item) => !item.is_thirdparty) || [],
      );
      return;
    }
    await forceLogout({ status: res?.data?.status_code, message: res?.data?.message });
    p.setLoading(false);
    return;
  } catch (err) {
    p.setLoading(false);
    console.log("--fetchAgreementList--", err);
  }
};

export const fetchAuditLog = async (p: { id: string; setData: Function; setLoading: Function }) => {
  const api = API_ROUTES.GET_AUDIT_LOGS;
  try {
    p.setLoading(true);
    const res: any = await postRequest(api, true, {
      _id: p.id,
    });

    if (res?.data && res?.status === 200) {
      p.setData(res?.data?.response || []);
      p.setLoading(false);
      return;
    }
    await forceLogout({ status: res?.data?.status_code, message: res?.data?.message });

    p.setLoading(false);
    return;
  } catch (err) {
    p.setLoading(false);
  }
};

export const saveTemplateChanges = async (p: {
  id: string;
  skipReviewStep: boolean;
  setBtnDisabled: Function;
}) => {
  const api = API_ROUTES.AGREEMENT_TEMPLATE_UPDATE;
  try {
    p.setBtnDisabled(true);
    const res: any = await postRequest(api, true, {
      id: p.id,
      skipReviewStep: p.skipReviewStep,
    });

    if (res?.data && res?.status === 200) {
      p.setBtnDisabled(false);
      showToast("Changes Saved Successfully!", true);
      return;
    }
    await forceLogout({ status: res?.data?.status_code, message: res?.data?.message });

    p.setBtnDisabled(false);
    return;
  } catch (err) {
    p.setBtnDisabled(false);
  }
};

export const saveKpiChanges = async (p: {
  req: { id: string; organization_id: string; isEnable: boolean };
  setBtnDisabled: Function;
}) => {
  const api = API_ROUTES.KPI_UPDATE;
  try {
    p.setBtnDisabled(true);
    const res: any = await postRequest(api, true, p.req);
    if (res?.data && res?.status === 200) {
      p.setBtnDisabled(false);
      showToast("Changes Saved Successfully!", true);
      return;
    }
    await forceLogout({ status: res?.data?.status_code, message: res?.data?.message });

    p.setBtnDisabled(false);
    return;
  } catch (err) {
    p.setBtnDisabled(false);
  }
};

export const fetchNotifications = async (p: {
  customer_name: string;
  setData: Function;
  setLoading: Function;
}) => {
  const api = API_ROUTES.GET_NOTIFICATIONS;
  try {
    p.setLoading(true);
    const res: any = await postRequest(api, true, { customer_name: p.customer_name });
    if (res?.data && res?.status === 200) {
      const res_arr = res?.data?.data || [];
      const unread_count = res?.data?.unread_notification_count || 0;
      p.setData({ data: res_arr, count: unread_count });
      p.setLoading(false);
    }
    await forceLogout({ status: res?.data?.status_code, message: res?.data?.message });
    p.setLoading(false);
    return;
  } catch (err) {
    p.setLoading(false);
  }
};

export const readNotifications = async (p: { id: string; customer_name: string }) => {
  const api = API_ROUTES.READ_NOTIFICATIONS;
  try {
    await postRequest(api, true, {
      id: p.id,
      customer_name: p.customer_name,
      isRead: true,
    });
  } catch (err) {
    return;
  }
};

export const updateNotificationCount = async (customer_name: string) => {
  const api = API_ROUTES.UPDATE_NOTIFICATION_COUNT;
  try {
    await postRequest(api, true, {
      customer_name: customer_name,
    });
  } catch (err) {
    return;
  }
};

export const markAllAsRead = async (customer_name: string) => {
  const api = API_ROUTES.MARK_ALL_AS_READ;
  try {
    await postRequest(api, true, {
      customer_name: customer_name,
    });
  } catch (err) {
    return;
  }
};
