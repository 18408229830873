import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Form, Formik, useFormikContext } from "formik";
import $ from "jquery";
import { ConnectedFocusError } from "focus-formik-error";
import * as Yup from "yup";

import CommonForm from "./formFields/common";
import RentalForm from "./formFields/rental";
import Type2Form from "./formFields/type2";
import Type3Form from "./formFields/type3";
import {
  DraftActions,
  ReviewerActions,
  ApproverActions,
  // VendorActions
} from "./agreementActions";
import {
  ROUTE_CONSTANTS,
  API_ROUTES,
  postRequest,
  RESPONSE_MSGS,
  showToast,
  STATUS_CODE,
  USER_ROLES,
  NEW_AGREEMENT_STATUS,
  asgreementProps,
  getAgreementSchema,
  getAgreementSchema2,
  getAgreementSchema3,
  getAgreementSchema4,
  TemplateType,
  addParentDetails,
  getAgreementType4Schema,
  getAgreementType5Schema,
} from "../../../../helpers";
import { setAgreementList, updateUserAgreement } from "../../../../redux/actions";
import useUnsavedChangesWarning from "./useUnsavedChangesWarning";
import { forceLogout } from "../../../../redux/services";

import "./style.scss";
import Type4Form from "./formFields/type4";
import Type5Form from "./formFields/type5";
import TypeByotForm from "./formFields/typeByot";

const getRandomString = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

const verifyAgreementType = (p: { type: string; isByot?: boolean }) => {
  if (
    [TemplateType.Rental, TemplateType.Rental2, TemplateType.Lease, TemplateType.Lease2].includes(
      p.type,
    )
  ) {
    return "type1";
  } else if (p.type === TemplateType.LLA) {
    return "type2";
  } else if (p.type === TemplateType.BTAgreement) {
    return "type3";
  } else if (p.type === TemplateType.PSA1) {
    return "type";
  } else if (p.type === TemplateType.Consultant) {
    return "type4";
  } else if (
    p.type === TemplateType.EmploymentAgreement ||
    p.type === TemplateType.CXMCEmploymentAgreement
  ) {
    return "type5";
  } else if (p.isByot) {
    return "byot";
  }
  return "type1";
};

const FormObserver = (p: {
  handleSubmit: Function;
  isDirty: boolean;
  clearInterval: boolean;
  disableFormButton: Function;
  agreementRef: any;
  data: any;
  createTempCopy: any;
}) => {
  const [Prompt, setDirty, setPristine]: any = useUnsavedChangesWarning();
  const { values, isSubmitting, setFieldValue, handleSubmit, validateForm }: any =
    useFormikContext();

  useEffect(() => {
    p.agreementRef.current.setFieldValue = setFieldValue;
    // p.agreementRef.current.setValues = setValues;
    p.agreementRef.current.createNewAgreement = () => p.createTempCopy(values);
    p.agreementRef.current.handleSubmit = handleSubmit;
    p.agreementRef.current.validateForm = validateForm;
    p.agreementRef.current.values = values;
  });

  useEffect(() => {
    //
    const urlParams = new URLSearchParams(window.location.search);
    const isThirdParty = urlParams.get("isThirdParty");

    //
    const handleAutosaveInterval = async () => {
      if (values && !isSubmitting) {
        //enables the page leave functionaliy
        if (values.agreementStatus === NEW_AGREEMENT_STATUS["draft"]) {
          await p.handleSubmit(values, values?.agreementStatus, null, isSubmitting);
        } else {
          await p.handleSubmit(values, values?.agreementStatus, p.disableFormButton);
        }
        setPristine();
        clearInterval(interval);

        return;
      }
      setPristine();
      clearInterval(interval);
    };

    if (isThirdParty) {
      handleAutosaveInterval();
    }

    if (!p.isDirty) return;
    //console.log("--vlues---",values, "isdirty:", isSubmitting);
    setDirty();
    const interval = setInterval(handleAutosaveInterval, 30000);

    return () => clearInterval(interval);
  }, [values]);

  useEffect(() => {
    //clean---force---interval
    setPristine();
  }, [p.clearInterval]);

  return <>{Prompt}</>;
};

const AgreementForm = (p: asgreementProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [commentData, setCommentData] = useState<string>("");
  const user = useSelector((state: any) => state.common.userData) || {};
  const [cleanInterval, setInterval] = useState(false);
  const clientId = useSelector((state: any) => state.common.userData.client_id || "");

  useEffect(() => {
    localStorage.removeItem("_id");
  }, []);

  const onFinish = async (values: any, status?: any, action?: any, isSubmitting = true) => {
    try {
      console.log("onFinish called !!!!");
      const sfdtText = p.getSfdtText();
      if (isSubmitting) p.setButtonDisabled(true);
      values = {
        ...values,
        type: values.template_type,
        editor_data: sfdtText,
        agreementStatus: status || p.submitType || p.data?.agreementStatus,
        customerName:
          p.data?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]
            ? user?.email
            : p.data?.customerName,
        actioner: user?.email,
        // agreementDate: $("#agreementDate").val(),
        // expiryDate: $("#expiryDate").val(),
        version_type: "drafted",
      };
      const id = localStorage.getItem("_id");
      if (id && p.data?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]) {
        values._id = id;
      }
      if (p.data?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]) {
        values.client_id = clientId;
      }

      values = addParentDetails(values);

      const res = await postRequest(API_ROUTES.CREATE_NEW_AGREEMENT, true, values);

      const { data }: any = res || {};
      await forceLogout({ status: data?.status_code, message: data?.message });
      if (data.status !== STATUS_CODE.SUCCESS) {
        if (data.status === 404 || data.status === 400) {
          showToast(data?.message, false);
        } else {
          showToast(RESPONSE_MSGS.ERROR, false);
        }
        if (isSubmitting) p.setButtonDisabled(false);
        return;
      } else {
        if (!isSubmitting) {
          window.history.replaceState(
            null,
            "New Page Title",
            `${ROUTE_CONSTANTS.Create_Agreement}/${data._id}?isAutosave=true`,
          );
          showToast("Save", true);
        }

        if (data._id) {
          localStorage.setItem("_id", data._id);
        }
        dispatch(updateUserAgreement(values._id, values));
        if (action) {
          action.setValues({ ...values, _id: values?._id });
        }
        if (isSubmitting) p.setButtonDisabled(false);
        if (p.submitType === p.data?.agreementStatus || status === p.data?.agreementStatus) {
          return;
        }
        showToast(RESPONSE_MSGS.NEW_AGREEMENT_SUCCESS, true);

        if (
          p.submitType === NEW_AGREEMENT_STATUS["approved"] ||
          p.submitType === NEW_AGREEMENT_STATUS["approverEStamp"] ||
          status === NEW_AGREEMENT_STATUS["approved"] ||
          status === NEW_AGREEMENT_STATUS["approverEStamp"]
        ) {
          p.fetchData();
        } else {
          setInterval(true);
          setTimeout(() => {
            navigate(ROUTE_CONSTANTS.My_Tasks);
          }, 1000);
        }
      }
    } catch (err: any) {
      showToast(RESPONSE_MSGS.ERROR, false);
      if (isSubmitting) p.setButtonDisabled(false);
    }
  };

  const handleRejectStatus = async (agreementStatus: string, role: string, formValues?: any) => {
    try {
      p.setButtonDisabled(true);

      await p.handleAutoSave({ ...formValues, actioner: user?.email }, agreementStatus);
      const res = await postRequest(API_ROUTES.REVIEW_REJECT_COMMENTS, true, {
        _id: p.data?._id,
        reviewerName: user.email,
        role: role,
        // comment: commentData,
        comment: [],
        agreementStatus: agreementStatus,
        actioner: user?.email,
      });

      const { data }: any = res || {};
      await forceLogout({ status: data?.status_code, message: data?.message });

      if (data.status !== STATUS_CODE.SUCCESS) {
        showToast(RESPONSE_MSGS.ERROR, false);
      } else {
        //dispatch(updateUserAgreement(p.data?._id || "", { ...p.data, agreementStatus }));
        showToast(RESPONSE_MSGS.AGREEMENT_REJECTED_STATUS, true);

        setInterval(true);
        setTimeout(() => {
          navigate(ROUTE_CONSTANTS.My_Tasks);
        }, 1000);
      }
      p.setButtonDisabled(false);
    } catch (err: any) {
      //console.log(err);
      showToast(RESPONSE_MSGS.ERROR, false);
      p.setButtonDisabled(false);
    }
  };

  const handleEmailShare = async (e: { isVendorRegisterUser?: boolean }) => {
    try {
      p.setButtonDisabled(true);
      // await p.handleAutoSave(p.data, p.data?.agreementStatus);
      const response: any = await postRequest(API_ROUTES.SHARE_AGREEMENT_EMAIL_API, true, {
        vendor: commentData,
        _id: p.data?._id,
        isVendorRegistorUser: e?.isVendorRegisterUser || false,
        client_id: clientId,
      });
      const responseStatusCode = response?.data?.status;
      await forceLogout({ status: response?.status_code, message: response?.message });

      if (
        response?.data?.response !== "success" ||
        (responseStatusCode && responseStatusCode !== STATUS_CODE.SUCCESS)
      ) {
        showToast(response?.data?.message || RESPONSE_MSGS.ERROR, false);
      } else {
        const res: any = await postRequest(API_ROUTES.CREATE_NEW_AGREEMENT, true, {
          contract_name: p.data?.contract_name,
          agreementStatus: NEW_AGREEMENT_STATUS["vendorReview"],
          _id: p.data?._id,
          customerName: p.data?.customerName,
          vendor: commentData,
          actioner: user?.email,
          version_type: "drafted",
        });

        const { data } = res;
        await forceLogout({ status: data?.status_code, message: data?.message });

        if (data.status !== STATUS_CODE.SUCCESS) {
          showToast(RESPONSE_MSGS.ERROR, false);
        } else {
          showToast(RESPONSE_MSGS.AGREEMENT_SHARED_STATUS, true);

          setInterval(true);
          setTimeout(() => {
            navigate(ROUTE_CONSTANTS.My_Tasks);
          }, 300);
        }
        // setButtonDisabled(false);
      }
      p.setButtonDisabled(false);
    } catch (err) {
      showToast(RESPONSE_MSGS.ERROR, false);
      p.setButtonDisabled(false);
    }
  };

  // byot start
  const generateDynamicValidationSchema = (Yup: any) => {
    const customValidationSchema: object = {};

    p.data?.customFieldMappingData?.map((item: any) => {
      customValidationSchema[item.fieldApiName] = Yup.string().required("Required");
    });

    return Yup.object().shape(customValidationSchema);
  };
  // byot end

  const formType = verifyAgreementType({ type: p.data.type, isByot: p.data?.is_byot });

  const contractName =
    p.data?.contract_name || sessionStorage.getItem("initial-contract-name") || getRandomString();

  const formikFormInitialValues = () => {
    const { secondPartyAddress, penalty } = p.data;
    const tempObject = {
      ...p.data,
      contract_name: contractName,
      //#### penalty: p.data?.penalty || "10$",
    };

    if (penalty) {
      tempObject["penalty"] = penalty;
    }
    if (secondPartyAddress) {
      tempObject["secondPartyAddress"] = secondPartyAddress;
    }

    return tempObject;
  };

  return (
    <>
      <Formik
        initialValues={formikFormInitialValues()}
        // initialValues={{
        //   ...p.data,
        //   penalty: p.data?.penalty || "10$",
        //   contract_name: contractName,
        //   // contract_name: p.data?.contract_name || getRandomString(),
        //   secondPartyAddress: p.data?.secondPartyAddress || "",
        // }}
        validationSchema={
          formType === "type1"
            ? getAgreementSchema2(Yup)
            : formType === "type2"
              ? getAgreementSchema3(Yup)
              : formType === "type3"
                ? getAgreementSchema4(Yup)
                : formType === "type4"
                  ? getAgreementType4Schema(Yup)
                  : formType === "type5"
                    ? getAgreementType5Schema(Yup)
                    : formType === "byot"
                      ? generateDynamicValidationSchema(Yup)
                      : getAgreementSchema(Yup)
        }
        // enableReinitialize={true}
        enableReinitialize={false}
        onSubmit={(values, action) => {
          onFinish(values, "", action);
        }}
      >
        {/* {({ values, handleSubmit, setValues, setTouched, errors, validateForm, dirty }) => { */}
        {({ values, handleSubmit, setValues, errors, validateForm, dirty }) => {
          if (values.contract_name) {
            sessionStorage.setItem("initial-contract-name", values.contract_name);
          }

          useEffect(() => {
            p.setAgreementData((data: any) => {
              return { ...data, formData: { ...(data?.formData || {}), ...values } };
            });
          }, [values]);
          return (
            <Form>
              <FormObserver
                handleSubmit={
                  p.data?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]
                    ? onFinish
                    : p.handleAutoSave
                }
                createTempCopy={p.createTempCopy}
                data={p.data}
                agreementRef={p.agreementRef}
                isDirty={dirty}
                clearInterval={cleanInterval}
                disableFormButton={p.setButtonDisabled}
              />
              <ConnectedFocusError />

              <div className="new-agreement-form-container">
                <>
                  {
                    /** START: For Draft State Only */
                    p.data.agreementStatus === NEW_AGREEMENT_STATUS["draft"] ||
                      user.persona === USER_ROLES["drafter"] ||
                      p.data.customerName === user.email ||
                      !user.persona ? (
                      <div className="form-header-row-create vednor-btns">
                        <DraftActions
                          isOpen={p.isOpen}
                          values={values}
                          usersList={p?.usersList || []}
                          submitForm={onFinish}
                          setValues={setValues}
                          btnDisabled={p.buttonDisabled}
                          skipReviewStep={p.data.skipReviewStep}
                          agreementStatus={p.data.agreementStatus}
                          handleSubmit={handleSubmit}
                          setOpen={p.setIsOpen}
                        />
                      </div>
                    ) : null
                    /** END: For Draft State Only */
                  }
                  {
                    /** START: For Reviewer Role Only */
                    user.persona === USER_ROLES["reviewer"] &&
                      p.data.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] &&
                      p.data.customerName !== user.email ? (
                      <ReviewerActions
                        btnDisabled={p.buttonDisabled}
                        agreementStatus={p.data.agreementStatus}
                        role={p.data.approver}
                        values={values}
                        handleSubmit={handleSubmit}
                        submitForm={onFinish}
                        setValues={setValues}
                        comments={p.data.comments}
                        isUserModleOpen={p.isUserModleOpen}
                        setUserModleOpen={p.setUserModleOpen}
                        usersList={p?.usersList || []}
                        validateForm={validateForm}
                      />
                    ) : null
                    /** END: For Reviewer Role Only */
                  }
                  {
                    /** START: For Approver Role Only */
                    user.persona === USER_ROLES["approver"] &&
                      p.data.agreementStatus !== NEW_AGREEMENT_STATUS["draft"] &&
                      p.data.customerName !== user.email ? (
                      <ApproverActions
                        isEmailModelOpen={p.isEmailModelOpen}
                        openSharePopup={p.openSharePopup}
                        commentData={commentData}
                        setCommentData={setCommentData}
                        handleRejectStatus={handleRejectStatus}
                        handleEmailShare={handleEmailShare}
                        handleEStamp={p.handleEStamp}
                        agreementStatus={p.data.agreementStatus}
                        btnDisabled={p.buttonDisabled}
                        role={p.data.vendor}
                        onFinish={onFinish}
                        values={values}
                        approvedByAllApprovers={p.data.approvedByAllApprovers}
                        setOpenSharePopup={p.setOpenSharePopup}
                        setIsEmailModelOpen={p.setIsEmailModelOpen}
                      />
                    ) : null
                    /** END: For Approver Role Only */
                  }
                </>
                {formType === "type1" ? (
                  <RentalForm
                    data={p.data}
                    values={values}
                    errors={errors}
                    hasFeildReadonly={p.hasFeildReadonly}
                    handleField={p.handleField}
                  // getRandomString={getRandomString}
                  />
                ) : formType === "type2" ? (
                  <Type2Form
                    data={p.data}
                    values={values}
                    errors={errors}
                    hasFeildReadonly={p.hasFeildReadonly}
                    handleField={p.handleField}
                  />
                ) : formType === "type3" ? (
                  <Type3Form
                    data={p.data}
                    values={values}
                    errors={errors}
                    hasFeildReadonly={p.hasFeildReadonly}
                    handleField={p.handleField}
                  />
                ) : formType === "type4" ? (
                  <Type4Form
                    data={p.data}
                    values={values}
                    errors={errors}
                    hasFeildReadonly={p.hasFeildReadonly}
                    handleField={p.handleField}
                  />
                ) : formType === "type5" ? (
                  <Type5Form
                    data={p.data}
                    values={values}
                    errors={errors}
                    hasFeildReadonly={p.hasFeildReadonly}
                    handleField={p.handleField}
                  />
                ) : formType === "byot" ? (
                  <TypeByotForm
                    data={p.data}
                    values={values}
                    errors={errors}
                    hasFeildReadonly={p.hasFeildReadonly}
                    // handleField={p.handleField}
                    handleCustomField={p.handleCustomField}
                  />
                ) : (
                  <CommonForm
                    data={p.data}
                    values={values}
                    errors={errors}
                    hasFeildReadonly={p.hasFeildReadonly}
                    handleField={p.handleField}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AgreementForm;
