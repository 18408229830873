import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSimpleTooltip from "react-simple-tooltip";
import { useNavigate } from "react-router";
import Popup from "reactjs-popup";
import "./style.scss";

// ACTIONS
import { forceLogout } from "redux/services";
import {
  setDataToValidateExtraction,
  setIsRepositoryDataFetching,
  setRepositoryList,
  addRepositoryDocIds,
  removeRepositoryDocIds
} from "redux/actions";

// ASSETS
import { BsStack } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";
import { GoShareAndroid } from "react-icons/go";
import { AiOutlinePrinter } from "react-icons/ai";
import { IoPeople } from "react-icons/io5";

// HELPER
import { RootState } from "redux/store";
import {
  API_ROUTES,
  postRequest,
  showLogInConsole,
  ROUTE_CONSTANTS,
  RESPONSE_MSGS,
  getSIgnedURLFromS3,
  deleteRequest,
  STATUS_CODE,
  showToast,
  NEW_AGREEMENT_STATUS,
} from "helpers";

// COMPONENTS
import DashboardLayout from "layouts/dashboardLayout";
import TableJs from "components/Table/table";
import { LoaderHOC } from "components";
import ChildAgreementRow from "components/ChildAgreementRow";
import { SingleDocShare, BulkShare } from "customComponents";


type Agreement = {
  parent_name: string;
  Contract_name: string;
  node_type: string;
};

// FUNCTIONS
const parentProps = (expanded) => ({
  style: {
    backgroundColor: expanded ? "#F4ECFD" : "#F8F6FA",
  },
});
function filterChildsWhoParentsAreInList(data: Agreement[]) {
  const hash = data.reduce((acc, d) => ({ ...acc, [d.Contract_name]: true }), {});
  return data.filter((agreement: Agreement) => {
    return !hash[agreement.parent_name];
  });
}
function isAgreementParent(data: Agreement) {
  return data.node_type == "parent";
}
function SubRowAsync({ row, rowProps, visibleColumns }) {
  // const [loading, setLoading] = React.useState(true);
  // const [data, setData] = React.useState([]);
  // const userData = useSelector((state: any) => state.common.userData) || {};
  // const dispatch = useDispatch();
  // React.useEffect(() => {
  //   const fetchChild = (parentId) => {
  //     async function getData() {
  //       try {
  //         return postRequest("/get-child-agreements", true, {
  //           customerName: userData.email,
  //           parent_id: parentId,
  //         });
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //     getData()
  //       .then((res: any) => {
  //         if (Array.isArray(res.data) && res.data.length) {
  //           setData(res.data);
  //         } else {
  //           dispatch(showToast("No child agreements found!", false));
  //         }
  //       })
  //       .catch(() => {
  //         setLoading(false);
  //       });
  //   };
  //   setLoading(true);
  //   fetchChild(row.original._id);
  // }, []);

  return (
    <ChildAgreementRow
      row={row}
      rowProps={rowProps}
      visibleColumns={visibleColumns}
      data={[]}
      loading={false}
    // data={data}
    // loading={loading}
    />
  );
}

const Repository: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchRef = useRef(null);

  // SELECTORS
  const userTokens = useSelector((state: RootState) => state.common.userTokens) || {};
  const tableDataSelector = useSelector((state: RootState) => state.repository.repoList) || [];
  const isDataFetching = useSelector((state: RootState) => state.repository.isDataFetching);
  const userData = useSelector((state: RootState) => state.common.userData) || {};
  const selectedDocsIds = useSelector((state: RootState) => state.repository.selectedDocIds) || [];

  // STATES
  const [loading, setLoading] = useState(true);
  const [fileInfo, setFileInfo] = useState({
    isShown: false,
    fileUrl: "",
    name: "",
    fileExtension: "",
  });
  const [shareModalState, setShareModalState] = useState(false);
  const [singleDocShare, setSingleDocShare] = useState(false);
  const [tableData, setTableData] = useState<any>([])
  // const [docSharedWith, setDocSharedWith] = useState(false);



  const agreementAction = (cell, action) => {
    const data = cell?.row?.original || {};
    const parentId = cell?.row?.original?.parent_id !== "not_assigned" ? cell?.row?.original?.parent_id : cell?.row?.original?._id;
    const parentData: any = cell?.page?.find((item: any) => item?.original?._id == parentId)?.original;

    const awsRegion = process.env.REACT_APP_AWS_REGION || "";
    const awsPoolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID || "";
    const awsUserPool = process.env.REACT_APP_AWS_USER_POOL || "";

    const { license } = data;

    let key_name = data?.executedPath || parentData?.executedPath || parentData?.fileUrl || "";
    let bucket = data?.exceutedBucketName || parentData?.exceutedBucketName || parentData?.bucketName || "";
    if (license && license?.toString() === "true") {
      bucket = process.env.REACT_APP_LICENSE_BUCKET_NAME || "";
    }

    if (key_name && bucket && awsRegion && awsPoolId && awsUserPool) {
      getSIgnedURLFromS3(
        awsRegion,
        awsPoolId,
        awsUserPool,
        bucket,
        userTokens.IdToken,
        key_name,
        (signed_url: string) => {
          if (action === "download") {
            let link = document.createElement("a") as HTMLAnchorElement;
            link.download = data.Key.split("/")[1];
            link.href = signed_url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // window.open(signed_url);
            return;
          } else if (action === "delete") {
            deleteRequest(API_ROUTES.DELETE_EXTRACTION, true, {
              contract_name: cell.row.values.Contract_name,
            })
              .then(async (res: any) => {
                const { status } = res || {};
                if (status !== STATUS_CODE.SUCCESS) {
                  showToast(RESPONSE_MSGS.ERROR, false);
                } else {
                  setTimeout(() => {
                    fetchData();
                  }, 2000);
                  showToast(RESPONSE_MSGS.EXTRACTION_DELETE_SUCCESS, true);
                  dispatch(setDataToValidateExtraction({}));
                }
              })
              .catch((err) => {
                // showLogInConsole("ERROR In delete document repo data ", err);
              });
            return;
          }
        },
      );
    }
  };

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
    ),
    [],
  );

  const columns = [
    // {
    //   id: "selection",
    //   Header: (d) => {
    //     tableRef.current = d;
    //     const { getToggleAllRowsSelectedProps, toggleAllRowsSelected, rows } = d

    //     const selectAllHandler = (e) => {
    //       let selectedDocs = selectedDocsIds;
    //       toggleAllRowsSelected();

    //       if (!d.isAllRowsSelected) {
    //         const docs: any = rows.map(item => {
    //           return {
    //             documentId: item.original?._id || "",
    //             documentName: item.original?.Contract_name || "",
    //             agreementStatus: item.original?.agreementStatus || "",
    //             accessLevel: item.original?.accessLevel || "Read-Only"
    //           }
    //         });
    //         selectedDocs = docs;
    //       } else {
    //         selectedDocs = [];
    //       }
    //       dispatch(addRepositoryDocIds(selectedDocs));
    //     }

    //     return <div className="mr-3">
    //       <input
    //         id="table-header-checkbox"
    //         className="table-checkbox"
    //         type="checkbox"
    //         onClick={selectAllHandler}
    //         {...getToggleAllRowsSelectedProps()}
    //       />
    //     </div>
    //   },
    //   Cell: (d) => {
    //     const { row } = d;

    //     const markSpecificDocHandler = (data) => {
    //       let selectedDocs = selectedDocsIds;
    //       if (!data.isSelected) {
    //         if (!selectedDocs.find(item => item?.documentId == data.original._id)) {
    //           selectedDocs.push({
    //             documentId: data?.original?._id || "",
    //             documentName: data?.original?.Contract_name || "",
    //             agreementStatus: data?.original?.agreementStatus || "",
    //             accessLevel: "Read-Only"
    //           });
    //         }
    //       } else {
    //         if (selectedDocs.find(item => item?.documentId == data.original._id)) {
    //           const index = selectedDocs.findIndex(item => item?.documentId == data?.original?._id);
    //           if (index > -1) {
    //             selectedDocs.splice(index, 1);
    //           }
    //         }
    //       }
    //       dispatch(addRepositoryDocIds(selectedDocs));
    //     }

    //     return <div className="mr-3">
    //       <input
    //         className="table-checkbox"
    //         type="checkbox"
    //         onClick={() =>
    //           markSpecificDocHandler(row)}
    //         {...row.getToggleRowSelectedProps()}
    //       />
    //     </div>
    //   },
    // },
    {
      // Make an expander cell
      Header: () => null, // No header
      id: "expander", // It needs an ID
      Cell: ({ row }) =>
        // Use Cell to render an expander for each row.
        // We can use the getToggleRowExpandedProps prop-getter
        // to build the expander.
        isAgreementParent(row.original) && (
          <span
            {...row.getToggleRowExpandedProps()}
            onClick={(e) => {
              const { onClick } = row.getToggleRowExpandedProps();
              onClick();
              e.stopPropagation();
            }}
          >
            <img src={row.isExpanded ? "/expanded.svg" : "/collapsed.svg"} alt={"collapsed"} />
          </span>
        ),
    },
    {
      // Header: "Contract Name",
      Header: "Agreement Name",
      accessor: "Contract_name",
      Cell: (d: { cell: any; value: string; row: any }) => {
        const data = d?.row?.original || {};

        if (d?.row?.canExpand) {
          const parentClickHandler = () => {
            if (data.Processing) {
              showToast("The agreement is being processed. Please try again in some time", false);
            } else if (data.isNewUpload) {
              dispatch(setDataToValidateExtraction(data));
              navigate(`${ROUTE_CONSTANTS.Imported_Documents}/${data._id}`);
            } else if (!data.Processing) {
              dispatch(setDataToValidateExtraction(data));
              navigate(ROUTE_CONSTANTS.VALIDATE_EXTRACTION);
            }
          }

          return (
            <div
              onClick={parentClickHandler}
              title={d.value}
              className={`Contract_name_in_repo ${data.Processing ? "disabled-Contract_name_in_repo" : ""
                }`}
            >
              {d.value}
            </div>
          );

        } else {
          if (
            (data?.agreementStatus?.toLowerCase() === NEW_AGREEMENT_STATUS?.["imported"]?.toLowerCase() ||
              data?.Status?.toLowerCase() === NEW_AGREEMENT_STATUS["imported"].toLowerCase()
            ) && data.isNewUpload
          ) {
            return (
              <div
                onClick={() => {
                  if (data.Processing) {
                    showToast("The agreement is being processed. Please try again in some time", false);
                    return;
                  }

                  if (data.agreementStatus.toLowerCase() == "imported") {
                    dispatch(setDataToValidateExtraction(data));
                    navigate(`${ROUTE_CONSTANTS.Imported_Documents}/${data._id}`);
                    return;
                  }
                }}
                title={d.value}
                className={`Contract_name_in_repo ${data.Processing ? "disabled-Contract_name_in_repo" : ""
                  }`}
              >
                {d.value}
              </div>
            );
          }

          if (
            data.agreementStatus === NEW_AGREEMENT_STATUS["executed"] ||
            data.agreementStatus === NEW_AGREEMENT_STATUS["imported"] ||
            data.Status === NEW_AGREEMENT_STATUS["executed"]
          ) {
            return (
              <div
                onClick={() => {
                  if (data.Processing) {
                    showToast("The agreement is being processed. Please try again in some time", false);
                    return;
                  }

                  if (!data.Processing) {
                    dispatch(setDataToValidateExtraction(data));
                    navigate(ROUTE_CONSTANTS.VALIDATE_EXTRACTION);
                  }
                }}
                title={d.value}
                className={`Contract_name_in_repo ${data.Processing ? "disabled-Contract_name_in_repo" : ""
                  }`}
              >
                {d.value}
              </div>
            );
          }

          data.dynamicAgreement = true;
          const handleAgreementRoutes = () => {
            if (data.Processing) {
              showToast("The agreement is being processed. Please try again in some time", false);
              return;
            } else {
              if (
                data?.agreementStatus === NEW_AGREEMENT_STATUS["vendorEsign"] ||
                data?.agreementStatus === NEW_AGREEMENT_STATUS["reviewRejected"] ||
                data?.agreementStatus === NEW_AGREEMENT_STATUS["review"] ||
                data?.agreementStatus === NEW_AGREEMENT_STATUS["approverEStamp"] ||
                data?.agreementStatus === NEW_AGREEMENT_STATUS["draft"]
                // data.agreementStatus === NEW_AGREEMENT_STATUS["executed"]
              ) {
                dispatch(setDataToValidateExtraction(data));
                navigate(`${ROUTE_CONSTANTS.Final_Signed_Agreement}/${data?._id || "template_1"}`, {
                  state: data,
                });
              }

              if (data.agreementStatus.toLowerCase() == "validate") {
                dispatch(setDataToValidateExtraction(data));
                navigate(`${ROUTE_CONSTANTS.Imported_Documents}/${data._id}`);
                return;
              }
              // else {
              //   dispatch(setDataToValidateExtraction(data));
              //   navigate(ROUTE_CONSTANTS.VALIDATE_EXTRACTION);
              // }
            }
            // if (!data.Processing) {
            //   if (
            //     data.agreementStatus === NEW_AGREEMENT_STATUS["vendorEsign"] ||
            //     data.agreementStatus === NEW_AGREEMENT_STATUS["executed"]
            //   ) {
            //     navigate(`${ROUTE_CONSTANTS.Final_Signed_Agreement}/${data?._id || "template_1"}`, {
            //       state: data,
            //     });
            //   } else {
            //     navigate(`${ROUTE_CONSTANTS.Create_Agreement}/${data?._id || "template_1"}`, {
            //       state: data,
            //     });
            //   }
            // }
          };

          return (
            <div
              onClick={handleAgreementRoutes}
              title={d.value}
              className={`Contract_name_in_repo ${data.Processing ? "disabled-Contract_name_in_repo" : ""
                }`}
            >
              {d.value}
            </div>
          );

        }
      },
    },
    {
      Header: "Type",
      accessor: "Type",
      Cell: (d: { cell: any; value: string }) => (
        <div className="type_grey repo_cell">{d.value}</div>
      ),
    },
    {
      Header: "Customer",
      accessor: "CustomerName",
      Cell: (d: { cell: any; value: string }) => (
        <div className="type_black repo_cell">{d.value}</div>
      ),
    },
    {
      Header: "Requester",
      accessor: "RequesterName",
      Cell: (d: { cell: any; value: string }) => (
        <div className="type_black repo_cell">{d.value}</div>
      ),
    },
    {
      Header: "Effective Date",
      accessor: "EffectiveDate",
      Cell: (d: { cell: any; value: string }) => (
        <div className="type_grey repo_cell">{d.value}</div>
      ),
    },
    {
      Header: "Expiry Date",
      accessor: "ExpiryDate",
      Cell: (d: { cell: any; value: string }) => (
        <div className="type_grey repo_cell">{d.value}</div>
      ),
    },
    {
      Header: "Status",
      accessor: "agreementStatus",
      Cell: (d: { cell: any; value: string }) => {
        const getClassName = () => {
          switch (d?.value?.toLowerCase()) {
            case "validate":
              return "status-validate";
            case "imported":
              return "status-imported";
            case "executed":
              return "status-executed";
          }
        };
        return (
          <div className={`status status-my-repo ${getClassName()}`}>
            {d?.value
              ? `${d?.value[0]?.toUpperCase()}${d?.value?.substr(1, d?.value?.length)}`
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Region",
      accessor: "Region",
      Cell: (d: { cell: any; value: string }) => (
        <div
          className="type_grey repo_cell "
          title={d.value}
          style={{
            width: "130px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {d.value}
        </div>
      ),
    },
    {
      Header: "Value",
      accessor: "amounts",
      Cell: (d: { cell: any; value: string }) => {
        const data = d?.row?.original || {};
        return (
          <div className="type_grey repo_cell">
            {data.Processing
              ? "Processing.."
              : "Extraction Completed"}
            {/* {data.Processing
              ? "Processing.."
              : d.value === "Processing.."
                ? "Extraction Completed"
                : d.value} */}
          </div>
        );
        //  return <div className="type_grey repo_cell">{d.value}</div>
      },
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: (d: { cell: any; value: string; rows: any; row: { values, original } }) => {
        const data = d?.row?.original || {};
        return (
          <div className="flex">
            <span className="table-actions-wrapper hidden">
              <AiOutlinePrinter
                className="table-actions-icon"
                onClick={() => agreementAction(d, "print")}
              />
            </span>
            <span className={`table-actions-wrapper`}>
              <IoMdDownload
                // className="table-actions-icon table-actions-download-icon"
                className="download-icon"
                onClick={() => agreementAction(d, "download")}
              />
            </span>
            <div className="table-actions-wrapper">
              {
                data?.isShared ?
                  <ReactSimpleTooltip
                    content={<p className="text-xs">Shared</p>}
                    arrow={7}
                    background="#000"
                    border="#000"
                    color="#fff"
                    customCss={{
                      opacity: 0.8,
                    }}
                    fadeDuration={200}
                    fadeEasing="linear"
                    fixed={false}
                    fontFamily="inherit"
                    fontSize="inherit"
                    offset={0}
                    padding={5}
                    placement="top"
                    radius={5}
                    zIndex={1}
                  >
                    <IoPeople className="shared-doc-icon" />
                  </ReactSimpleTooltip>
                  :
                  <GoShareAndroid
                    className="share-icon"
                    onClick={() => {
                      if (selectedDocsIds.length != data?.length) {
                        singleDocShareHandler(data);
                      }
                    }}
                  />
              }
            </div>

            {/* <span className="table-actions-wrapper">
              <FaUsers
                className="table-actions-icon table-actions-download-icon"
                onClick={() => setDocSharedWith(true)} />
            </span> */}
          </div>
          // <div className="table-cell">
          //   <div className="table-actions">
          //     <span className="table-actions-wrapper hidden">
          //       <AiOutlinePrinter
          //         className="table-actions-icon"
          //         onClick={() => agreementAction(d, "print")}
          //       />
          //     </span>
          //     <span className={`table-actions-wrapper`}>
          //       <IoMdDownload
          //         className="table-actions-icon table-actions-download-icon"
          //         onClick={() => agreementAction(d, "download")}
          //       />
          //     </span>
          //     <span className="table-actions-wrapper">
          //       <GoShareAndroid
          //         className="table-actions-icon table-actions-download-icon"
          //         onClick={() => {
          //           if (selectedDocsIds.length != data?.length) {
          //             singleDocShareHandler(data);
          //           }
          //         }}
          //       />
          //     </span>
          //     <span className="table-actions-wrapper">
          //       <FaUsers
          //         className="table-actions-icon table-actions-download-icon"
          //         onClick={() => setDocSharedWith(true)} />
          //     </span>
          //   </div>
          // </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      dispatch(setRepositoryList([]));
      setLoading(true);

      let combinedData: any = [];

      // postRequest(API_ROUTES.GET_SHARED_AGREEMENT_DATA, true, {
      //   userId: userData?.user_id || "",
      //   client_id: userData?.client_id || ""
      // }).then(({data}: any) => {
      //   if (data?.response?.length > 0) {
      //     combinedData = data?.response;
      //   }
      // }).catch((err) => {
      //   console.log(`${API_ROUTES.GET_SHARED_AGREEMENT_DATA} Error ===>`, err);
      // })

      const { data }: any = await postRequest(API_ROUTES.GET_REPO_DATA, true, {
        customerName: userData.email,
        userId: userData?.user_id || ""
      });

      data.sort((a, b) => {
        if (a.agreementStatus === "Executed") {
          return 0;
        }

        return a?.imported_at.localeCompare(b?.imported_at);
      });

      await forceLogout({ status: data?.status_code, message: data?.message });

      combinedData = [...data, ...combinedData];

      const filteredData = filterChildsWhoParentsAreInList(combinedData);
      if (typeof data === "string") {
        dispatch(setRepositoryList([]));
      } else {
        dispatch(setRepositoryList(filteredData || []));
        setTableData(filteredData || [])
        if (searchRef && searchRef.current) {
          (searchRef.current as any).setFilter();
        }
      }
    } catch (err) {
      dispatch(setRepositoryList([]));
    } finally {
      setLoading(false);
    }
  };
  const singleDocShareHandler = (doc) => {
    let selectedDocs = selectedDocsIds;
    const index = selectedDocs.find(item => item?.documentId == doc._id);
    if (!index) {
      selectedDocs.push(
        {
          documentId: doc?._id || "",
          documentName: doc?.Contract_name || "",
          agreementStatus: doc?.agreementStatus || "",
          accessLevel: "Read-Only",
          indexName: (doc?.agreementStatus == "Executed" ? "new-agreement" : "historic-agreement"),
        }
      );
      dispatch(addRepositoryDocIds(selectedDocs));
      setSingleDocShare(true);
    }
  }
  const onCloseShareModal = () => {
    dispatch(removeRepositoryDocIds([]));
    setShareModalState(false);
  }
  const shareSubmitHandler = async (selectedUsers: any) => {
    try {
      const selectedDocs = selectedDocsIds;

      const data = {
        document_id: selectedDocs?.[0]?.documentId || "",
        index_name: selectedDocs?.[0]?.indexName || "",
        users_list: selectedUsers?.map(item => item?.userId) || [],
        documentName: selectedDocs?.[0]?.documentName || "",
        loggedInUserEmail: userData?.email || ""
      }

      const response: any = await postRequest(API_ROUTES.SHARE_AGREEMENT_WITH_USERS, true, data);
      if (response?.data == "success") {
        showToast("Document Shared", true);
      } else {
        showToast("Something went wrong", false);
      }

    } catch (err) {
      console.log("shareSubmitHandler error ==>", err);
      showToast("something went wrong.", false);
    } finally {
      dispatch(removeRepositoryDocIds([]));
    }
  }
  const closeSingleDocShare = () => {
    dispatch(removeRepositoryDocIds([]));
    setSingleDocShare(false);
  }
  const searchHandler = (e) => {
    const filteredData = tableDataSelector?.filter((item: any) => (item?.Contract_name?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
      item?.Type?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
      item?.agreementStatus?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
      item?.amounts?.toLowerCase()?.includes(e.target.value?.toLowerCase())));

    setTableData(filteredData);

    if (e.target.value == "") {
      setTableData(tableDataSelector);
    }
  }
  // const openDocSharedWithHandler = () => {
  //   setDocSharedWith(true);
  // }
  // const closeDocSharedWithHandler = () => {
  //   setDocSharedWith(false);
  // }

  // EFFECTS
  useEffect(() => {
    dispatch(removeRepositoryDocIds([]));
    fetchData();
  }, []);
  useEffect(() => {
    if (isDataFetching) {
      //
      fetchData();
      dispatch(setIsRepositoryDataFetching(false));
    }
  }, [isDataFetching]);
  useEffect(() => {
    function handleEscape(e) {
      if (e.key === "Escape") {
        setSingleDocShare(false);
        dispatch(removeRepositoryDocIds([]))
      }
    }
    document.addEventListener('keydown', handleEscape);
    return function cleanup() {
      document.removeEventListener('keydown', handleEscape);
    }
  }, []);


  return (
    <>
      <DashboardLayout
        hederTitle={"My Repository"}
        headerLogo={<BsStack />}
        childer={
          <>
            <div className="repo-container">
              <div className="repo-table">
                <TableJs
                  columns={columns}
                  // sortBy={[{ id: "Contract_name", desc: true }]}
                  sortBy={[]}
                  data={tableData}
                  isRowSelectEnable={false}
                  headerProps={{ isEnable: true, type: 3, refetchData: () => fetchData() }}
                  isRowsPerPageChangeRequired={false}
                  isCurrentRowsCountVisibilityRequired={false}
                  ref={searchRef}
                  getRowProps={(row: any) => {
                    if (isAgreementParent(row.original)) {
                      return parentProps(row.isExpanded);
                    }
                    return {};
                  }}
                  searchHandler={searchHandler}
                // renderRowSubComponent={renderRowSubComponent}
                />

                {loading ? (
                  <LoaderHOC></LoaderHOC>
                ) : tableData.length ? null : (
                  <div className="mt-3">No Data Found...</div>
                )}
              </div>
            </div>
          </>
        }
      />

      {/* BulkShare Modal */}
      {/* <Popup
        modal
        open={shareModalState}
        contentStyle={{
          width: "480px",
          maxWidth: "480px",
          minHeight: "400px",
          maxHeight: "auto",
          borderRadius: "10px",
        }}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <BulkShare
          onCloseModal={onCloseShareModal}
          onSubmit={shareSubmitHandler}
        />
      </Popup> */}

      {/* SingleShare Modal */}
      <Popup
        modal
        open={singleDocShare}
        contentStyle={{
          width: "480px",
          maxWidth: "480px",
          minHeight: "400px",
          maxHeight: "auto",
          borderRadius: "10px",
        }}
        closeOnDocumentClick={false}
      >
        <div className='w-full h-full flex flex-col justify-center items-center'>
          <SingleDocShare
            onCloseModal={closeSingleDocShare}
            onSubmit={shareSubmitHandler}
          />
        </div>
      </Popup >

      {/* <Popup
        modal
        open={docSharedWith}
        contentStyle={{
          width: "480px",
          maxWidth: "480px",
          minHeight: "400px",
          maxHeight: "auto",
          borderRadius: "10px",
        }}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className='w-full h-full flex flex-col justify-center items-center'>
          <DocSharedWith
            onCloseModal={closeDocSharedWithHandler}
          // onSubmit={shareSubmitHandler}
          />
        </div>
      </Popup > */}
    </>
  );
};

export default Repository;
