import React, { useState } from "react";
import ContentLoader from "react-content-loader";

const SquareSkeletonCard = ({ ...props }) => {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth / 3.46);

  return (
    <ContentLoader
      width={currentWidth}
      height={257}
      viewBox={`0 0 ${currentWidth} 257`}
      // backgroundColor="#f0f0f0"
      // foregroundColor="#dedede"
      backgroundColor="#ffffff"
      speed={2}
      {...props}
    >
      {/* <rect x="43" y="304" rx="4" ry="4" width="271" height="9" /> */}
      {/* <rect x="44" y="323" rx="3" ry="3" width="119" height="6" /> */}
      <rect
        x="0"
        y="0"
        rx="5"
        ry="5"
        // width="350"
        width={currentWidth}
        height="257"
        //  width="388" height="217"
      />
    </ContentLoader>
  );
};

export default SquareSkeletonCard;
