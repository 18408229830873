import React, { useEffect, useState, useRef } from "react";
import { searchPlugin } from "@react-pdf-viewer/search";
import FileViewer from "react-file-viewer";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//
import { Button, PlainDateField, Switch } from "components";
import { API_ROUTES, IMAGES, getSIgnedURLFromS3, postRequest, showToast } from "helpers";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { FaChevronDown } from "react-icons/fa6";

//
import { PlainInput } from "components";

//
import "./style.scss";

export default function ExtractionPanel({
  clickedProgressBarIndex,
  extractedBucketsData,
}: {
  clickedProgressBarIndex: number;
  extractedBucketsData: any;
}) {
  const [url, setSignedURL] = useState("");
  const [openedAccordiansIndex, setOpenedAccordiansIndex] = useState({});

  //
  const extractedData = useSelector(
    (state: RootState) => state.repository.dataToValidateExtraction,
  );
  const userTokens = useSelector((state: RootState) => state.common.userTokens) || {};

  //
  const [extractionDataList, setExtractionDataList] = useState<Array<any>>([]);
  const [selectedExtractionDataList, setSelectedExtractionDataList] = useState<Array<number> | any>(
    [],
  );

  //
  const searchPluginInstance = searchPlugin();
  const { Search, ShowSearchPopoverButton } = searchPluginInstance;

  //
  const navigate = useNavigate();

  //
  const colorList = [
    "#560BAD",
    "#B5179E",
    "#F72585",
    "#4CC9F0",

    //
    "#b5179e",
    "#f72585",
    "#3a0ca3",
    "#00b4d8",
    "#560bad",
    "#3f37c9",
    "#4361ee",
    "#4895ef",
    "#4cc9f0",
  ];

  //
  useEffect(() => {
    const { fileUrl } = extractedData;

    getSIgnedURLFromS3(
      process.env.REACT_APP_AWS_REGION || "",
      process.env.REACT_APP_AWS_IDENTITY_POOL_ID || "",
      process.env.REACT_APP_AWS_USER_POOL || "",
      process.env.REACT_APP_IMPORT_AGREEMENTS || "",
      userTokens.IdToken,
      fileUrl,
      (signed_url: string) => {
        setSignedURL(signed_url);
      },
    );

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const tempResponse: Array<any> = extractedBucketsData.response;
      // console.log(tempResponse);
      const accordianTemp: any = {};

      tempResponse.map((bucketItem, bucketIndex) => {
        Object.keys(bucketItem.questions).map((questionItem, questionIndex) => {
          accordianTemp[`${bucketItem.bucket}_${questionIndex.toString()}`] = {
            open: false,
          };
        });
      });
      setOpenedAccordiansIndex(accordianTemp);

      setExtractionDataList(tempResponse);
      if (clickedProgressBarIndex === -1) {
        setSelectedExtractionDataList(tempResponse.map((item, index) => index));
      } else {
        setSelectedExtractionDataList(
          tempResponse.map((item, index) =>
            clickedProgressBarIndex === index ? index : undefined,
          ),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  //
  const saveAsDraft = async (isReload = false) => {
    try {
      const {
        data: { response, message },
      } = (await postRequest(API_ROUTES.UPDATE_AI_EXTRACTIONDATA, true, {
        ...extractedBucketsData,
      })) as any;
      if (isReload) {
        showToast("Saved Successfully", true);
        navigate("/repository", { replace: true });
      }
    } catch (err) {
      console.log(err);
      showToast("Something want wrong", false);
    }
  };

  //
  const validateNowHandle = async () => {
    try {
      let validateInformation: any = {
        validate: true,
        wrongKeys: [],
      };

      extractedBucketsData.response.map((item) => {
        Object.keys(item.questions).map((key, indexNo) => {
          if (
            (key.toLocaleLowerCase().includes("date") &&
              !moment(item.questions[key], "DD/MM/YYYY").isValid()) ||
            (key
              .toLocaleLowerCase()
              .split(" ")
              .find((item) => item === "date") &&
              item.questions[key].length !== 10)
          ) {
            validateInformation.validate = false;
            validateInformation.wrongKeys.push({
              key: key,
              index: `${item.bucket}_${indexNo}`,
            });
            // validateInformation.wrongKeys.push(item.questions[key]);
          }
        });
      });

      if (validateInformation.validate) {
        const {
          data: { response, message },
        } = (await postRequest(API_ROUTES.VALIDATE_HISTORIC_AGREEMENT, true, {
          ...extractedBucketsData,
        })) as any;
        showToast("Validation Done Successfully", true);
        // showToast(message, true);
        navigate("/repository", { replace: true });
      } else {
        showToast(
          `${validateInformation.wrongKeys.map((item) => item.key)} must be in Date format`,
          false,
        );

        const tempOpen = {};
        validateInformation.wrongKeys.map((item) => {
          tempOpen[item.index] = { open: true };
        });

        setOpenedAccordiansIndex({
          ...openedAccordiansIndex,
          ...tempOpen,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //
  const handleInputChange = (value, key, index) => {
    setExtractionDataList((prevData) => {
      return prevData.map((prevItem, prevItemIndex) => {
        if (prevItemIndex === index) {
          prevItem.questions[key] = value;
        }
        return prevItem;
      });
    });
  };

  return (
    <>
      <div className=" w-[60%] relative pr-2">
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            padding: "4px",
          }}
        >
          <ShowSearchPopoverButton />
        </div>
        <div className="extraction-panel-agreement-viewer-section">
          {url &&
          (extractedData.agreementStatus?.toLowerCase() === "imported" ||
            extractedData.agreementStatus?.toLowerCase() === "validate") &&
          extractedData.fileExtension === ".docx" ? (
            <FileViewer
              fileType={"docx"}
              filePath={url}
              errorComponent={<div> Error in File Redering</div>}
              onError={() => console.log("Error in File Rendering ....")}
            />
          ) : url &&
            (extractedData.agreementStatus?.toLowerCase() === "imported" ||
              extractedData.agreementStatus?.toLowerCase() === "validate") &&
            extractedData.fileExtension === ".pdf" ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
              <Viewer fileUrl={url} plugins={[searchPluginInstance]} />
            </Worker>
          ) : (
            <div className="no-agreement-found">
              <img src={IMAGES.NoDataFound} alt="" /> No doc found
            </div>
          )}
        </div>
      </div>
      {/*  */}
      <div className="extraction-validate-form-section gap-2 w-[40%]">
        {/* {extractedData.agreementStatus?.toLowerCase() === "validate"} */}

        {extractedData.agreementStatus?.toLowerCase() !== "validate" && (
          <div className="flex gap-3 justify-end">
            <Button
              onClick={() => {
                //
                saveAsDraft(true);
              }}
              variant="outlined"
              label="Save As Draft"
              isFullWidth={false}
            />

            <Button
              onClick={() => {
                //
                validateNowHandle();
              }}
              variant="outlined"
              label="Validate Now"
              isFullWidth={false}
            />
          </div>
        )}
        <div>
          <div>
            <div className="p-3 bg-white border-solid border-[0.5px] border-gray-light rounded-md shadow-md mx-1 my-2 ">
              <h5 className="text-gray-text">Extractions</h5>

              <div className="grid grid-cols-2 mt-2">
                {extractionDataList.map((item, index: number) => {
                  const colorCode = colorList[index];
                  return (
                    <div
                      key={index}
                      // rounded-full
                      className={`m-1 font-medium rounded-lg
                    border-solid border border-[grey] 
                          ${selectedExtractionDataList[index] !== undefined ? `text-white ` : ``}
                          group cursor-pointer transition-all
                          `}
                      style={{
                        backgroundColor:
                          selectedExtractionDataList[index] !== undefined ? colorCode : "",
                      }}
                      onClick={() => {
                        // aasasas
                        // setSelectedExtractionDataIndex(index);
                        // setSelectedExtractionDataList
                        setSelectedExtractionDataList(
                          selectedExtractionDataList.map((innerItem, innerIndex) => {
                            if (innerIndex === index) {
                              if (innerItem === undefined) {
                                return innerIndex;
                              }
                              return undefined;
                            }

                            return innerItem;
                          }),
                        );
                      }}
                    >
                      <span className={`flex items-center gap-1 py-1 px-2 `}>
                        <div
                          className={`inline-flex items-center justify-center w-6 h-6 text-xs text-[10px] font-bold text-white border-2 border-white rounded-full
                            ${
                              selectedExtractionDataList[index] !== undefined
                                ? "!bg-white !text-black "
                                : ""
                            }
                            `}
                          style={{ backgroundColor: colorCode }}
                        >
                          {Object.keys(item.questions).length}
                        </div>
                        <div className={`text-xs font-bold leading-none max-w-full `}>
                          {item.bucket}
                        </div>
                      </span>
                    </div>
                  );
                })}
              </div>
              {/* <div className="flex flex-wrap mt-2">
              {extractionDataList.map((item, index: number) => {
                
                return (
                  <Switch
                    onChange={(e) => {
                      console.log(e);
                    }}
                    color="#D9B5EB"
                    handlerColor="#560BAD"
                    labelText={item.fieldLabel}
                  />
                );
              })}
            </div> */}
            </div>
          </div>

          {/*  */}

          {!selectedExtractionDataList.every((activeIndex) => activeIndex === undefined) && (
            <div>
              <div className="p-3 bg-white border-solid border-[0.5px] border-gray-light rounded-lg shadow-md mx-1 my-2 ">
                <div className="flex justify-between">
                  <h5 className="text-gray-text">Validate Extraction</h5>
                  <h5
                    className="underline text-primary-500 cursor-pointer"
                    onClick={() => {
                      const tempAccorian = {};
                      for (const key in openedAccordiansIndex) {
                        tempAccorian[key] = { open: false };
                      }
                      setOpenedAccordiansIndex(tempAccorian);
                    }}
                  >
                    Collapse All
                  </h5>
                </div>

                <div className="flex flex-wrap mt-2">
                  <div className="max-h-[50vh] overflow-scroll px-2 w-full">
                    {/* <Accordion /> */}

                    {extractionDataList.map((item, index) => {
                      return (
                        <div key={index}>
                          {selectedExtractionDataList[index] !== undefined &&
                            Object.keys(item.questions).map((key, keyIndex) => {
                              return (
                                <Accordion
                                  className="!rounded-md my-2"
                                  key={keyIndex}
                                  expanded={
                                    openedAccordiansIndex[`${item.bucket}_${keyIndex}`].open
                                  }
                                  onChange={async (e, expanded) => {
                                    if (expanded) {
                                      const searchKeyword = item.questions[key];
                                      searchPluginInstance.clearHighlights();

                                      const found = await searchPluginInstance.highlight([
                                        searchKeyword,
                                      ]);

                                      if (found.length === 0) {
                                        if (searchKeyword.length > 20) {
                                          searchPluginInstance.highlight([
                                            searchKeyword.slice(0, 20),
                                          ]);
                                        } else if (searchKeyword.length > 10) {
                                          searchPluginInstance.highlight([
                                            searchKeyword.slice(0, 10),
                                          ]);
                                        } else {
                                          searchPluginInstance.highlight([
                                            searchKeyword.slice(0, 7),
                                          ]);
                                        }

                                        // const allKeywords = item.questions[key]
                                        //   .split(" ")
                                        //   .map((i) => i);
                                        // setTimeout(() => {
                                        //   searchPluginInstance.highlight(allKeywords);
                                        // }, 600);
                                      }
                                    } else {
                                      searchPluginInstance.clearHighlights();
                                    }
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<FaChevronDown className="text-white" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={`w-full !px-4 !text-white !rounded-md`}
                                    style={{ backgroundColor: colorList[index] }}
                                    onClick={(e: any) => {
                                      // //
                                      // setOpenedAccordiansIndex({
                                      //   ...openedAccordiansIndex,
                                      //   [`${item.bucket}_${keyIndex}`]: {
                                      //     open: !openedAccordiansIndex[`${item.bucket}_${keyIndex}`]
                                      //       .open,
                                      //   },
                                      // });
                                      const tempIndex = {};
                                      for (const currentIndexKey in openedAccordiansIndex) {
                                        const indexKey = `${item.bucket}_${keyIndex}`;

                                        tempIndex[currentIndexKey] = {
                                          open:
                                            currentIndexKey == indexKey
                                              ? !openedAccordiansIndex[indexKey].open
                                              : false,
                                        };
                                      }
                                      setOpenedAccordiansIndex(tempIndex);
                                    }}
                                  >
                                    <Typography>{key}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {key.toLowerCase().includes("date") ? (
                                      <PlainDateField
                                        label="Value"
                                        placeholder={"Effective Date"}
                                        // className={"agreement-text-field"}
                                        className="my-1"
                                        onActionBlur={(value) => {
                                          if (
                                            extractedData.agreementStatus?.toLowerCase() !==
                                            "validate"
                                          ) {
                                            // adadadad
                                            handleInputChange(value.value, key, index);
                                            saveAsDraft();
                                          }
                                        }}
                                        // readOnly={p.hasFeildReadonly({
                                        //   status: p?.data?.agreementStatus,
                                        //   forAll: false,
                                        // })}
                                        optionalValue={item.questions[key]}
                                        readOnly={
                                          extractedData.agreementStatus?.toLowerCase() ===
                                          "validate"
                                        }
                                      />
                                    ) : (
                                      <PlainInput
                                        label="Value"
                                        placeholder={"Enter your answer"}
                                        className="mt-2 p-0"
                                        onChange={(e) => {
                                          // setValues({ ...values, email: e.target.value });
                                          // console.log(extractionDataList[index].questions);

                                          handleInputChange(e.target.value, key, index);
                                          // setExtractionDataList((prevData) => {
                                          //   return prevData.map((prevItem, prevItemIndex) => {
                                          //     if (prevItemIndex === index) {
                                          //       prevItem.questions[key] = e.target.value;
                                          //     }
                                          //     return prevItem;
                                          //   });
                                          // });
                                        }}
                                        onBlur={() => {
                                          if (
                                            extractedData.agreementStatus?.toLowerCase() !==
                                            "validate"
                                          ) {
                                            saveAsDraft();
                                          }
                                        }}
                                        value={item.questions[key]}
                                        multiline={true}
                                        maxRows={5}
                                        readOnly={
                                          extractedData.agreementStatus?.toLowerCase() ===
                                          "validate"
                                        }
                                      />
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              );
                            })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <button
          onClick={() => {
            searchPluginInstance.highlight([
              {
                keyword: "Roommate",
                matchCase: true,
              },
            ]);
          }}
        >
          roommate
        </button>
        <button
          onClick={() => {
            searchPluginInstance.highlight([
              {
                keyword: "agreement",
                matchCase: true,
              },
            ]);
          }}
        >
          agreement
        </button>

        <button
          onClick={() => {
            searchPluginInstance.highlight([
              {
                keyword: "SIGNATURE",
                matchCase: true,
              },
            ]);
          }}
        >
          signature
        </button> */}
      </div>
    </>
  );
}
