import styled from "styled-components";
import Image_01 from "../../../../assets/images/Image_01.png";
import Image_02 from "../../../../assets/images/Image_02.png";
import Image_03 from "../../../../assets/images/Image_03.png";
import Image_04 from "../../../../assets/images/Image_04.png";

const CompanyBanner = () => {
  return (
    <Container>
      <Wrapper>
        <ul>
          <li>
            <img src={Image_01} alt="image01" />
          </li>
          <li>
            <img src={Image_02} alt="image02" />
          </li>
          <li>
            <img src={Image_03} alt="image03" />
          </li>
          <li>
            <img src={Image_04} alt="image04" />
          </li>
        </ul>
      </Wrapper>
    </Container>
  );
};

export default CompanyBanner;

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 140px;
  padding: 0px 79px 0px 79px;
  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 75px;
  }
  li {
    display: inline;
  }
`;
