import SideBar from "./sidebar";
import Header from "./Header";
import "./style.scss";

interface Props {
  isFooterDisable?: boolean;
  childer: React.ReactElement;
  hederTitle?: string | React.ReactElement;
  sideText?: string | React.ReactElement;
  headerLogo?: any;
}

const DashboardLayout = (p: Props) => {
  return (
    <>
      <div className="dashboard-layout">
        <SideBar />
        <div className="dashboard-layout-content">
          <Header title={p?.hederTitle || ""} sideText={p?.sideText} titleLogo={p.headerLogo} />

          <div className="db-contetn">{p.childer}</div>
          {!p.isFooterDisable && (
            <div className="db-footer">
              <span>&#169; 2022 OneCounsel Inc. All rights reserved.</span>
              <span>{/* Powered by <span className="powered-by">Legitron</span> */}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
