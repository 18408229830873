import { combineReducers } from "redux";
import CommonReducer from "./common";
import AgreementReducer from "./agreement";
import RepositoryReducer from "./repository";
import AdminReducer from "./admin";
import DashboardReducer from "./dashboard";
import RiskReducer from "./risk";

const rootReducer = combineReducers({
  common: CommonReducer,
  agreement: AgreementReducer,
  repository: RepositoryReducer,
  admin: AdminReducer,
  dashboard: DashboardReducer,
  riskAgreement: RiskReducer,
});

export default rootReducer;
