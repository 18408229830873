import Tooltip from "@material-ui/core/Tooltip";
import "./styles.scss";

interface Props {
  title: string;
  arrow: boolean;
  children: React.ReactElement;
  classes?: { popper?: string; tooltip: string };
  placement?: any;
}

const ToolTip: React.FC<Props> = (p) => {
  const placement = p?.placement || "bottom";
  let config: any = { title: p.title, arrow: p.arrow, classes: p.classes };
  if (placement) {
    config = { ...config, placement };
  }

  return (
    <>
      <Tooltip {...config}>{p.children}</Tooltip>
    </>
  );
};

export default ToolTip;
