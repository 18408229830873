import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FaArrowLeftLong, FaArrowLeft } from "react-icons/fa6";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Popup from "reactjs-popup";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import { highlightPlugin } from "@react-pdf-viewer/highlight";

import { TextInput } from "components/FormFields";
import {
  AGREEMENT_IN_REPO_STATUS,
  API_ROUTES,
  getSIgnedURLFromS3,
  IMAGES,
  postRequest,
  RESPONSE_MSGS,
  ROUTE_CONSTANTS,
  showLogInConsole,
  showToast,
  STATUS_CODE,
  deleteRequest,
} from "helpers";
import "./style.scss";
import { useNavigate, useParams } from "react-router";
import { setDataToValidateExtraction } from "redux/actions";
import { ExtractionValidateConfirmation, LoaderHOC } from "components";
import { forceLogout } from "redux/services";
import { AiOutlineClose } from "react-icons/ai";
import DashboardLayout from "layouts/dashboardLayout";
import { RootState } from "redux/store";

//
import ExtractionSummary from "./ExtractionSummary";
import ExtractionPanel from "./ExtractionPanel";

const ImportedDocuments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSummaryShown, setIsSummaryShown] = useState<boolean>(true);
  const [clickedProgressBarIndex, setClickedProgressBarIndex] = useState<number>(-1);
  const [extractedBucketsData, setExtractedBucketsData] = useState([]);

  //
  const extractedData = useSelector(
    (state: RootState) => state.repository.dataToValidateExtraction,
  );

  //
  const navigate = useNavigate();
  const params = useParams();

  //
  useEffect(() => {
    fetchData();
  }, []);

  //
  const fetchData = async () => {
    try {
      const {
        data: { response },
      } = (await postRequest(API_ROUTES.FETCH_AI_EXTRACTION_DATA, true, {
        agreementId: params.id,
      })) as any;

      setExtractedBucketsData(response);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <DashboardLayout
        hederTitle={
          <div className="flex items-center gap-2">
            <FaArrowLeft
              className="text-primary-500 cursor-pointer"
              onClick={() => {
                if (isSummaryShown) {
                  navigate("/repository");
                } else {
                  setIsSummaryShown(true);
                  setClickedProgressBarIndex(-1);
                }
              }}
              title="Go back"
            />
            <span
              className="text-primary-500"
              style={{
                width: "350px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={extractedData.Contract_name}
            >
              {extractedData.Contract_name}
            </span>
          </div>
        }
        childer={
          <div className="agreement-container">
            <div
              className={
                extractedData.agreementStatus === "Executed"
                  ? "agreement-executed-content"
                  : "agreement-content"
              }
            >
              {isLoading ? (
                <div className="h-[84vh] w-[95%] flex justify-center items-center">loading</div>
              ) : (
                <div className={`bg-white p-5 w-full ${!isSummaryShown ? "flex" : ""}`}>
                  <>
                    {isSummaryShown ? (
                      <>
                        <ExtractionSummary
                          setIsSummaryShown={setIsSummaryShown}
                          setClickedProgressBarIndex={setClickedProgressBarIndex}
                          extractedBucketsData={extractedBucketsData}
                        />
                      </>
                    ) : (
                      <>
                        {/* <div className="agreement-viewer-section">
                      {url &&
                      // extractedData.ContractStatus?.toLowerCase() !== "executed" &&
                      extractedData.ContractStatus?.toLowerCase() !== "imported" &&
                      extractedData.fileExtension === ".docx" ? (
                        <FileViewer
                          fileType={"docx"}
                          filePath={url}
                          errorComponent={<div> Error in File Redering</div>}
                          onError={() => console.log("Error in File Rendering ....")}
                        />
                      ) : url &&
                        // extractedData.ContractStatus?.toLowerCase() === "executed" &&
                        extractedData.ContractStatus?.toLowerCase() === "imported" &&
                        extractedData.fileExtension === ".pdf" ? (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                          <Viewer fileUrl={url} plugins={[searchPluginInstance]} />
                        </Worker>
                      ) : (
                        <div className="no-agreement-found">
                          <img src={IMAGES.NoDataFound} alt="" /> No doc found
                        </div>
                      )}
                    </div> */}
                        {/*  */}
                        {/* <div className="extraction-validate-form-section">
                      <button
                        onClick={() => {
                          searchPluginInstance.highlight([
                            {
                              keyword: "Roommate",
                              matchCase: true,
                            },
                          ]);
                        }}
                      >
                        roommate
                      </button>
                      <button
                        onClick={() => {
                          searchPluginInstance.highlight([
                            {
                              keyword: "agreement",
                              matchCase: true,
                            },
                          ]);
                        }}
                      >
                        agreement
                      </button>

                      <button
                        onClick={() => {
                          searchPluginInstance.highlight([
                            {
                              keyword: "SIGNATURE",
                              matchCase: true,
                            },
                          ]);
                        }}
                      >
                        signature
                      </button>
                    </div> */}
                        <ExtractionPanel
                          clickedProgressBarIndex={clickedProgressBarIndex}
                          extractedBucketsData={extractedBucketsData}
                        />
                      </>
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

export default ImportedDocuments;

// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

// import FileViewer from "react-file-viewer";
// import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
// import { Form, Formik } from "formik";
// import * as Yup from "yup";
// import Popup from "reactjs-popup";
// import { Worker, Viewer } from "@react-pdf-viewer/core";

// import { TextInput } from "components/FormFields";
// import {
//   AGREEMENT_IN_REPO_STATUS,
//   API_ROUTES,
//   getSIgnedURLFromS3,
//   IMAGES,
//   postRequest,
//   RESPONSE_MSGS,
//   ROUTE_CONSTANTS,
//   showLogInConsole,
//   showToast,
//   STATUS_CODE,
//   deleteRequest,
// } from "helpers";
// import "./style.scss";
// import { useNavigate } from "react-router";
// import { setDataToValidateExtraction } from "redux/actions";
// import { ExtractionValidateConfirmation, LoaderHOC } from "components";
// import { forceLogout } from "redux/services";
// import { AiOutlineClose } from "react-icons/ai";

// const ImportedDocuments = ({
//   fileUrl,
//   bucket,
//   close,
//   fileName,
//   fileExtension,
// }: ImportDocumentComponent) => {
//   const extractedData = useSelector((state: any) => state.repository.dataToValidateExtraction);
//   const userTokens = useSelector((state: any) => state.common.userTokens) || {};
//   const [url, setSignedURL] = useState("");

//   useEffect(() => {
//     getSIgnedURLFromS3(
//       process.env.REACT_APP_AWS_REGION || "",
//       process.env.REACT_APP_AWS_IDENTITY_POOL_ID || "",
//       process.env.REACT_APP_AWS_USER_POOL || "",
//       bucket,
//       userTokens.IdToken,
//       fileUrl,
//       (signed_url: string) => setSignedURL(signed_url),
//     );
//   }, []);

//   return (
//     <>
//       <div className="import-agreement-pdf-container">
//         <div className="title-wrapper">
//           <h4 className="title">{fileName.split(".")[0]}</h4>
//           <button className="close" title="Close" onClick={() => close()}>
//             <AiOutlineClose className="close" />
//           </button>
//         </div>
//         <div className="pdf-container-body">
//           {/* {url && extractedData.ContractStatus?.toLowerCase() !== "executed" ? (
//                   <FileViewer
//                     fileType={"docx"}
//                     filePath={url}
//                     errorComponent={<div> Error in File Redering</div>}
//                     onError={() => console.log("Error in File Rendering ....")}
//                   />

//                 ) : url && extractedData.ContractStatus?.toLowerCase() === "executed" ? ( */}
//           {url.length > 0 ? (
//             <>
//               {fileExtension == ".pdf" ? (
//                 <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
//                   <Viewer fileUrl={url} defaultScale={1.5} />
//                 </Worker>
//               ) : fileExtension == ".doc" || fileExtension == ".docx" ? (
//                 <FileViewer
//                   fileType={fileExtension.replace(".", "")}
//                   filePath={url}
//                   errorComponent={<div> Error in File Redering</div>}
//                   onError={() => console.log("Error in File Rendering ....")}
//                 />
//               ) : (
//                 <span>Other format rendering will coming soon</span>
//               )}
//             </>
//           ) : (
//             <LoaderHOC></LoaderHOC>
//           )}
//           {/* ) : (
//              <div className="no-agreement-found">
//                {" "}
//                <img src={IMAGES.NoDataFound} alt="" /> No doc found
//              </div>
//            )} */}
//         </div>
//       </div>

//       {/* <DashboardLayout
//         hederTitle={<>{extractedData.Contract_name}</>}
//         childer={
//           <div className="agreement-container">
//             <div
//               className={
//                 extractedData.ContractStatus === "Executed"
//                   ? "agreement-executed-content"
//                   : "agreement-content"
//               }
//             >
//               <div className="agreement-viewer-section">
//                 {url && extractedData.ContractStatus?.toLowerCase() !== "executed" ? (
//                   <FileViewer
//                     fileType={"docx"}
//                     filePath={url}
//                     errorComponent={<div> Error in File Redering</div>}
//                     onError={() => console.log("Error in File Rendering ....")}
//                   />
//                 ) : url && extractedData.ContractStatus?.toLowerCase() === "executed" ? (
//                   <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
//                     <Viewer fileUrl={url} />
//                   </Worker>
//                 ) : (
//                   <div className="no-agreement-found">
//                     {" "}
//                     <img src={IMAGES.NoDataFound} alt="" /> No doc found
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         }
//       /> */}
//     </>
//   );
// };

// export default ImportedDocuments;
