import React, { useState } from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { VscCalendar } from "react-icons/vsc";
import { IoCalendarClearOutline, IoCalendarNumberOutline } from "react-icons/io5";

import { TextInput, PlainInput } from "components";

import "./style.scss";
import { FormHelperText } from "@mui/material";

interface otherProps {
  label: string;
  readOnly?: boolean;
  onAction?: Function;
  onActionBlur?: Function;
  highlightText?: string;
  placeholder?: string;
  className?: string;

  //
  minDate?: string;
  maxDate?: string;
  optionalValue?: string;
}

const PlainDateField = (p: otherProps) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [dateValue, setDateValue] = useState("");

  const handleOnBlur = (d: any) => {
    if (p?.onActionBlur && !p?.readOnly) {
      const date = d;
      p.onActionBlur({ value: moment(date).format("DD/MM/YYYY") });
      setDateValue(moment(date).format("DD/MM/YYYY"));
      // setFieldValue(field.name, moment(date).format("DD/MM/YYYY"));
    }
  };

  return (
    <div className="form-group">
      <>
        <DatePicker
          className={p.className}
          popperClassName="z-10"
          placeholderText={p.placeholder}
          onChange={(date: Date) => handleOnBlur(date)}
          yearDropdownItemNumber={20}
          scrollableYearDropdown={true}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          value={
            dateValue && dateValue !== "NaN/NaN/NaN" && dateValue !== "Invalid date"
              ? moment(dateValue, "DD/MM/YYYY").toDate()
              : "" || p.optionalValue
          }
          dateFormat="dd/MM/yyyy"
          selected={
            dateValue && dateValue !== "NaN/NaN/NaN" && dateValue !== "Invalid date"
              ? moment(dateValue, "DD/MM/YYYY").toDate()
              : ""
          }
          readOnly={p.readOnly || false}
          // id={field.name}
          minDate={
            p?.minDate !== "NaN/NaN/NaN" && p?.minDate && p?.minDate !== "Invalid date"
              ? moment(p?.minDate, "DD/MM/YYYY").toDate()
              : null
          }
          maxDate={
            p?.maxDate !== "NaN/NaN/NaN" && p?.maxDate && p?.maxDate !== "Invalid date"
              ? moment(p?.maxDate, "DD/MM/YYYY").toDate()
              : null
          }
          todayButton="Today"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          customInput={
            <PlainInput
              label={p.label}
              placeholder={"DD/MM/YYYY"}
              onCustomClick={() => {
                setIsPickerOpen(true);
              }}
              onCustomActionBlur={() => {
                setTimeout(() => {
                  setIsPickerOpen(false);
                }, 1200);
              }}
              readOnly={true}
              endAdornment={
                <IoCalendarClearOutline
                // className="form-date-icon"
                />
              }
              className={p?.className}
            />
          }
        />
      </>
    </div>
  );
};

export default PlainDateField;
