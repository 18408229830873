export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_DATA = "SET_USER_DATA";
export const SHOW_AUDIT_LOG = "SHOW_AUDIT_LOG";
export const Add_BELL_NOTIFICATIONS = "Add_BELL_NOTIFICATIONS";
export const LOGOUT_USER = "LOGOUT_USER";
export const PREVIEW_FILE = "PREVIEW_FILE";
export const FORM_DATA = "FORM_DATA";
export const DEFAULT_FORM_DATA = "DEFAULT_FORM_DATA"
export const UPDATE_USER_DATA = "UPDATE_USER_DATA"
export const FETCH_USER_DATA = "FETCH_USER_DATA"

export const setUserToken = (tokens: object) => {
  return {
    type: SET_USER_TOKEN,
    tokens: tokens,
  };
};

export const setUserData = (data: object) => {
  return {
    type: SET_USER_DATA,
    data: data,
  };
};

export const showAuditLog = (data: any) => {
  return {
    type: SHOW_AUDIT_LOG,
    data: data,
  };
};

export const addBellNotifications = (data: { list: any; count: number }) => {
  return {
    type: Add_BELL_NOTIFICATIONS,
    data: data,
  };
};

export const userLogout = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const previewFile = (data) => {
  return {
    type: PREVIEW_FILE,
    data: data
  };
};

export const formsData = (data) => {
  return {
    type: FORM_DATA,
    data: data
  };
};

export const defaultFormData = (data) => {
  return {
    type: DEFAULT_FORM_DATA,
    data: data
  };
};

export const updateUserData = ({ userData, userTokens }: any) => {
  return {
    type: UPDATE_USER_DATA,
    data: { userData, userTokens }
  };
}

export const fetchUserData = (userData) => {
  return {
    type: FETCH_USER_DATA,
    data: userData
  };
}
