import React from "react";
import { Pie } from "@ant-design/plots";
import dummyData from "./data.json";

import "./styles.scss";

interface ChartProps {
  data?: any;
  shape?: string;
  theme?: string;
  size?: string;
}

export const PieChart: React.FC<ChartProps> = ({ data }) => {
  const config: any = {
    data: data || dummyData,
    autoFit: true,
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    theme: "default", // 'dark',
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
        fill: "#000",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  return (
    <div className="pie-container">
      <Pie {...config} />
    </div>
  );
};

export default PieChart;
