/* eslint-disable */
import {
  SET_RISK_LIST,
  //UPDATE_RISK_AGREEMENT,
  LOGOUT_USER,
} from "../actions";

const initialState = {
  agreements: [],
};

const RiskReducer = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case SET_RISK_LIST: {
      return { ...state, agreements: action.data };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default RiskReducer;
