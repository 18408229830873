/* eslint-disable */

import { useNavigate } from "react-router-dom";

import { STRINGS, IMAGES, ROUTE_CONSTANTS } from "../../helpers";
import "./style.scss";

interface headerProps {
  btn_link?: string;
  btn_text?: string;
}

const PublicHeader = (p: headerProps) => {
  const navigate = useNavigate();
  return (
    <header>
      <div className="">
        <div className="public-header-logo">
          <img
            src={IMAGES.EclipseLogo}
            alt={`${STRINGS.NEUTRON} Logo`}
            onClick={() => navigate(ROUTE_CONSTANTS.ROOT)}
          />
        </div>
        {/* <div className="col-6 public-header-links">
          <Link to={"/"}>{STRINGS.ABOUT_US}</Link>
          <Link to={"/"}>{STRINGS.CONTACT_US}</Link>
          <Link to={"/"}>{STRINGS.TERMS_AND_CONDITIONS}</Link>
        </div>
        <div className="col-3 sign-up-header-btn">
          <button type="button" onClick={() => navigate(p.btn_link)}>
            {p.btn_text}
          </button>
        </div> */}
      </div>
    </header>
  );
};

export default PublicHeader;
