import React, { useState } from "react";
import Switch from "react-switch";

import "./style.scss";

interface otherProps {
  // className?: string;
  // variant?: "contained" | "outlined";
  // type?: "button" | "submit";
  // disabled?: boolean;
  // style?: object;
  // onClick?: Function;
  // color?: "primary" | "secondary";
  // label: string;
  // options: Array<string>;
  // groupName: string;
  onChange?: Function;
  color?: string;
  handlerColor?: string;
  labelText?: string;
  // value?: string;
  // // readOnly?: boolean;
  // // onAction?: Function;
  // // onActionBlur?: Function;
  // // highlightText?: string;
  // // showRiskWarning?: boolean;
  // // id?: string;
  // // isRequired?: boolean;
}

const SwitchComponent = (p: otherProps) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <label className="flex gap-2">
        <span>{p.labelText}</span>
        <Switch
          onChange={(e) => {
            setIsChecked(e);
            if (p.onChange) {
              p.onChange(e);
            }
          }}
          checked={isChecked}
          onColor={p.color ? p.color : "#86d3ff"}
          onHandleColor={p.handlerColor ? p.handlerColor : "#2693e6"}
          handleDiameter={25}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={48}
          className="react-switch"
          id="material-switch"
        />
      </label>
    </>
  );
};

export default SwitchComponent;
