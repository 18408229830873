/* eslint-disable */
import { REHYDRATE } from "redux-persist/es/constants";
import {
  SET_REPOSITORY_LIST,
  SET_AGREEMENT_FILES_TO_IMPORT,
  SET_DATA_TO_VALIDATE_EXTRACTION,
  LOGOUT_USER,
  SET_IS_REPOSITORY_DATA_FETCHING,
  ADD_REPOSITORY_DOC_IDS,
  REMOVE_REPOSITORY_DOC_IDS
} from "../actions";

const initialState = {
  repoList: [],
  agreementToImport: [],
  dataToValidateExtraction: {
    Version: 0,
    Contract_name: "Contract Name",
    Key: "MSA2.docx",
    Extension: "docx",
  },
  isDataFetching: false,
  selectedDocIds: []
};

const RepositoryReducer = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case SET_REPOSITORY_LIST: {
      return { ...state, repoList: action.data };
    }
    case SET_AGREEMENT_FILES_TO_IMPORT: {
      return { ...state, agreementToImport: action.data };
    }
    case SET_DATA_TO_VALIDATE_EXTRACTION: {
      return { ...state, dataToValidateExtraction: action.data };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    case REHYDRATE:
      let repository = ((action || {}).payload || {}).repository || initialState;
      return {
        ...initialState,
        dataToValidateExtraction: repository.dataToValidateExtraction,
      };
    case SET_IS_REPOSITORY_DATA_FETCHING: {
      return {
        ...state,
        isDataFetching: action.data,
      };
    }
    case ADD_REPOSITORY_DOC_IDS: {
      return {
        ...state,
        selectedDocIds: action.data,
      };
    }
    case REMOVE_REPOSITORY_DOC_IDS: {
      return {
        ...state,
        selectedDocIds: action.data,
      };
    }
    default:
      return state;
  }
};

export default RepositoryReducer;
