export const SET_AGREEMENT_LIST = "SET_AGREEMENT_LIST";
export const SET_USER_AGREEMENTS = "SET_USER_AGREEMENTS";
export const UPDATE_USER_AGREEMENT = "UPDATE_USER_AGREEMENT";


export const setAgreementList = (data: any) => {
  return {
    type: SET_AGREEMENT_LIST,
    data: data,
  };
};

export const setUserAgreements = (data: any) => {
  return {
    type: SET_USER_AGREEMENTS,
    data: data,
  };
};

export const updateUserAgreement = (id: string, data: any) => {
  return {
    type: UPDATE_USER_AGREEMENT,
    id: id,
    data: data,
  };
};
