import styled from "styled-components";

const HowItWork = () => {
  return (
    <Container>
      <Wrapper>
        <div className="text-wrapper">
          <p>HOW IT WORKS</p>
          <h3>
            <span className="bold">80% </span>reduced contract{" "}
            <span className="bold">turnaround time </span>enabled by Artificial Intelligence
          </h3>
          <span className="text">
            Experience the customer-acclaimed “Knowledge Automation” platform that helps to drive
            efficieincies in the Legal Operations.
          </span>

          <div className="list">
            <ul>
              <li>Risk Reduction</li>
              <li> Manual Tasks Elimination</li>
            </ul>
            <ul>
              <li> Actionable Obligations</li>
              <li> Legal Costs Optimization</li>
            </ul>
          </div>
          <div className="action-btn">
            <button variant="contained" color="primary">
              Explore More
            </button>
            <button color="primary">Download Product Brief</button>
          </div>
        </div>
        <div className="rigth-section">
          <div className="border-section"></div>
          <div className="side-list">
            <ul>
              <li>Create compliant contracts, at scale</li>
              <li>KPI Tracking & Reporting</li>
              <li>Collaborate with ease at one place</li>
              <li>Low effort, high impact</li>
              <li>Contract Automation</li>
            </ul>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default HowItWork;

const Container = styled.div`
  position: relative;
  max-width: 1260px;
  margin: 0 auto;
  margin-bottom: 130px;
`;

const Wrapper = styled.div`
  display: flex;

  padding: 0 79px;
  box-shadow: 0px 5px 50px rgba(20, 15, 108, 0.05);
  border-radius: 20px;
  padding: 59px 78px;
  justify-content: center;
  .rigth-section {
    display: flex;
    align-items: center;
  }
  .text-wrapper {
    max-width: 548px;
    p {
      font-family: "matter_sq_trialbold";
      font-size: 14px;
      color: #4361ee;
      line-height: 17px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    h3 {
      font-family: "matter_sq_trialregular";
      font-size: 34px;
      line-height: 48px;
      color: #343a40;
      font-weight: normal;
      margin-bottom: 24px;
      margin-top: 0;
      .bold {
        font-family: "matter_sq_trialsemibold";
        font-weight: 700;
      }
    }
    .text {
      font-family: "matter_sq_trialregular";
      color: #adb5bd;
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 22px;
    }
    .list {
      font-family: "matter_sq_trialregular";
      display: flex;
      justify-content: space-between;
      padding-left: 17px;
      line-height: 40px;
      font-size: 17px;
      color: #adb5bd;
      margin-bottom: 40px;
    }
    .action-btn {
      button {
        font-family: "matter_sq_trialsemibold";
        height: 53px;
        font-size: 17px;
        font-weight: 600;
        border-radius: 5px;
        padding: 10px 46px;
        border: none;
        :first-child {
          background: linear-gradient(265.31deg, #4361ee 0%, #3f37c9 0.01%);
          color: #fff;
        }
        :last-child {
          background: transparent;
          color: #4631ee;
        }
      }
    }
  }
  .border-section {
    border: 1px dashed #adb5bd;
    width: 130px;
    margin-left: 70px;
    height: 100%;
    border-radius: 0 25px 25px 0;
    border-left: 0;
  }
  .side-list {
    padding: 50px 0;
    ul {
      padding-left: 34px;
    }
    li {
      font-family: "matter_sq_trialmedium";
      font-size: 17px;
      line-height: 25px;
      height: 60px;
      padding: 0 28px;
      display: flex;
      align-items: center;
      margin-bottom: 21px;
      border-radius: 10px;
      position: relative;
      ::after {
        content: "";
        display: inline-block;
        box-shadow: 0px 0px 20px rgba(21, 38, 117, 0.2);
        border-radius: 20px;
        width: 9px;
        height: 9px;
        background-color: #4361ee;
        position: absolute;
        left: -45px;
        border: 6px solid #fff;
        box-sizing: unset;
      }
      ::before {
        content: "";
        display: inline-block;
        width: 34px;
        border: 1px dashed #adb5bd;
        position: absolute;
        left: -36px;
      }
      :first-child {
        background-color: #ebf5ff;
        color: #007aff;
      }
      :nth-child(2) {
        background-color: #fcf2ed;
        color: #fb9a78;
      }
      :nth-child(3) {
        background-color: #f5f3ff;
        color: #6334ff;
      }
      :nth-child(4) {
        background-color: #ebf7f1;
        color: #47754f;
      }
      :nth-child(5) {
        background-color: #fbf7eb;
        color: #cb8856;
        margin: 0;
      }
    }
  }
`;
