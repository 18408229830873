import { useEffect, useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

const CollapseMode = (p: {
  title: string;
  subTitle?: string;
  children: any;
  forceOpen?: boolean | null;
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    // if (p.forceOpen) {
    setIsHidden(!p.forceOpen);
    // }
  }, [p.forceOpen]);

  return (
    <>
      <div className="agreement-form-collapse">
        <div className="collapse-header items-center mb-3">
          <div className="my-4">
            <span>{p.title}</span>
            {p.subTitle && <span className="sub-title">{p.subTitle}</span>}
          </div>
          {isHidden ? (
            <MdKeyboardArrowDown onClick={() => setIsHidden(false)} />
          ) : (
            <MdKeyboardArrowUp onClick={() => setIsHidden(true)} />
          )}
        </div>
        {!isHidden && <div className="collapse-body-row">{p.children}</div>}
      </div>
    </>
  );
};

export default CollapseMode;
