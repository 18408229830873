import { Link, useLocation } from "react-router-dom";
import { AiOutlineTeam, AiOutlineKey, AiOutlineAreaChart } from "react-icons/ai";
import { MdTask } from "react-icons/md";
import { BsStack } from "react-icons/bs";
//import { TiDocument } from "react-icons/ti";
import { HiPresentationChartBar } from "react-icons/hi";
import { IoLibrary } from "react-icons/io5";
import { useSelector } from "react-redux";

import { ROUTE_CONSTANTS, USER_ROLES } from "helpers";
import Tooltip from "components/ToolTip";
import "./style.scss";

interface sideBarItemProps {
  title: string;
  itemIcon: any;
  path: string;
  showTitle?: boolean;
  className?: string;
  isSidebarOpen?: boolean;
}

interface SidbarBodyProps {
  isSidebarOpen: boolean;
}

const isPathActive = (path: string, location: { pathname: string }) => {
  if (path === location.pathname) return true;
};

const SideBarItem = (p: sideBarItemProps) => {
  const location = useLocation();
  const isActive = isPathActive(p.path, location);
  const isOpen = p.isSidebarOpen ? "sidebar-item-open" : "";

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Link to={p.path}>
          <Tooltip title={p.title} arrow={true} classes={{ tooltip: "tooltip-custom" }}>
            <div className={`${isOpen} sidebar-item ${isActive && "active-item"} ${p?.className}`}>
              {p.itemIcon}
              {p.isSidebarOpen && p.title}
            </div>
          </Tooltip>
        </Link>
      </div>
    </>
  );
};

const SidebarBody: React.FC<SidbarBodyProps> = (p) => {
  const isAdmin = useSelector((state: any) => state.common.userData?.persona === "admin" || false);
  const isVendor =
    useSelector((state: any) => state.common.userData?.persona) === USER_ROLES["vendor"] || false;

  return (
    <div className="siderbar-body">
      <div className="upper-section">
        {isAdmin ? (
          <>
            <SideBarItem
              title={"Admin"}
              itemIcon={<AiOutlineTeam />}
              path={ROUTE_CONSTANTS.ADMIN}
              isSidebarOpen={p.isSidebarOpen}
            />
            <SideBarItem
              title={"Workflow Manager"}
              itemIcon={<AiOutlineKey />}
              path={ROUTE_CONSTANTS.ACCESS_MANAGEMENT}
              isSidebarOpen={p.isSidebarOpen}
            />
            <SideBarItem
              title={"KPI Manager"}
              itemIcon={<AiOutlineAreaChart />}
              path={ROUTE_CONSTANTS.KPI_MANAGEMENT}
              isSidebarOpen={p.isSidebarOpen}
            />
            <SideBarItem
              title={"Library"}
              itemIcon={<IoLibrary />}
              path={ROUTE_CONSTANTS.LIBRARY}
              isSidebarOpen={p.isSidebarOpen}
            />
          </>
        ) : (
          <>
            {isVendor && (
              <SideBarItem
                title={"My Tasks"}
                itemIcon={<MdTask />}
                path={ROUTE_CONSTANTS.My_Tasks}
                isSidebarOpen={p.isSidebarOpen}
              />
            )}
            {!isVendor && (
              <>
                <SideBarItem
                  title={"KPI Dashboard"}
                  itemIcon={<HiPresentationChartBar />}
                  path={ROUTE_CONSTANTS.DASHBOARD}
                  isSidebarOpen={p.isSidebarOpen}
                  className={"sidebar-item"}
                />
                <SideBarItem
                  title={"My Tasks"}
                  itemIcon={<MdTask />}
                  path={ROUTE_CONSTANTS.My_Tasks}
                  isSidebarOpen={p.isSidebarOpen}
                  className={"sidebar-item"}
                />
                <SideBarItem
                  title={"My Repository"}
                  itemIcon={<BsStack />}
                  path={ROUTE_CONSTANTS.Repository}
                  isSidebarOpen={p.isSidebarOpen}
                  className={"sidebar-item"}
                />
                <SideBarItem
                  title={"Library"}
                  itemIcon={<IoLibrary />}
                  path={ROUTE_CONSTANTS.LIBRARY}
                  isSidebarOpen={p.isSidebarOpen}
                />

                {/* <SideBarItem
                  title={"Risk Assessment"}
                  itemIcon={<AiFillSignal />}
                  path={ROUTE_CONSTANTS.RISK_ASSESSMENT}
                  isSidebarOpen={p.isSidebarOpen}
                  className={"sidebar-item"}
                /> */}
              </>
            )}
          </>
        )}
        {/* <SideBarItem
            title={"Due Diligence Records"}
            itemIcon={<TiDocument />}
            path={"/"}
            isSidebarOpen={p.isSidebarOpen}
            />
            <SideBarItem
            title={"Connect"}
            itemIcon={<img src={IMAGES.SidebarConnect} alt="" />}
            path={"/"}
            isSidebarOpen={p.isSidebarOpen}
            />
            <SideBarItem
            title={"Legal Thesaurus"}
            itemIcon={<img src={IMAGES.Sidebarlegal} alt="" />}
            path={"/"}
            isSidebarOpen={p.isSidebarOpen}
            /> */}
      </div>
    </div>
  );
};

export default SidebarBody;
