import styled from "styled-components";
import HeroBannerImage from "../../../../assets/images/Brain.png";
const HomeBanner = () => {
  return (
    <Container>
      <Wrapper>
        <div className="text-section">
          <h1>
            AI <span>Powered</span>
          </h1>
          <h1>Knowledge Automation</h1>
          <h1>
            <span>Ecosystem</span>
          </h1>
          <p>360-degree insights in to legal operations & business transactions</p>
          <div className="action-btn">
            <button variant="contained" color="primary">
              Learn More
            </button>
            <button color="primary">Watch Video</button>
          </div>
        </div>
        <div className="img-section">
          <img src={HeroBannerImage} alt="hero-banner" />
        </div>
      </Wrapper>
    </Container>
  );
};

export default HomeBanner;

const Container = styled.div`
  margin-top: 48px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 70px;
  gap: 50px;

  .text-section {
    padding-left: 40px;
    line-height: 64px;
    font-size: 53px;
    h1 {
      font-family: "matter_sq_trialsemibold";
      color: #343a40;
      line-height: 64px;
      font-size: 53px;
      span {
        font-family: "matter_sq_trialregular";
        font-weight: normal;
      }
    }
    p {
      font-family: "matter_sq_triallight";
      color: #adb5bd;
      font-size: 27px;
      line-height: 33px;
      margin: 16px 0 40px;
    }
    .action-btn {
      button {
        font-family: "matter_sq_trialsemibold";
        height: 53px;
        font-size: 17px;
        font-weight: 600;
        border-radius: 5px;
        padding: 10px 46px;
        border: none;
        :first-child {
          background: linear-gradient(265.31deg, #4361ee 0%, #3f37c9 0.01%);
          color: #fff;
        }
        :last-child {
          background: transparent;
          color: #4631ee;
        }
      }
    }
  }
`;
