export const SET_RISK_LIST = "SET_RISK_LIST";
export const UPDATE_RISK_AGREEMENT = "UPDATE_RISK_AGREEMENT";

export const setRiskList = (data: any) => {
  return {
    type: SET_RISK_LIST,
    data: data,
  };
};

export const updateRiskAgreement = (id: string, data: any) => {
  return {
    type: UPDATE_RISK_AGREEMENT,
    id: id,
    data: data,
  };
};
