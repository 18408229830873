const Profile = (name: string) => {
  const firstLetter = name && name.charAt(0).toUpperCase();
  return (
    <>
      <div
        className="count-img"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 38,
          height: 40,
          width: 40,
          backgroundColor: "#ffebee",
          color: "#EA5455",
          fontWeight: 900,
        }}
      >
        {firstLetter}
      </div>
    </>
  );
};

export default Profile;
