import Footer from "./Footer";
import Header from "./Header";
import OneCounsel from "./OneCounsel";
import CompanyBanner from "./CompanyBanner";
import HeroBanner from "./HeroBanner";
import HowItWork from "./HowItWork";
import LegalPlatform from "./LegalPlatform";
import Integration from "./Integration";
import Question from "./Question";

const Home = () => {
  return (
    <div>
      <Header />
      <HeroBanner />
      <LegalPlatform />
      <HowItWork />
      <OneCounsel />
      <Integration />
      <CompanyBanner />
      <Question />
      <Footer />
    </div>
  );
};

export default Home;
