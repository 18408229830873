import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SignInForm, OTPVerificationScreen } from "../../../components";
import { ROUTE_CONSTANTS, STRINGS, USER_ROLES } from "../../../helpers";
import logo from "../../../assets/images/logo_icon.png";
import "./style.scss";

const lognInSubText = "Please sign in to your account and start the Legalism";

const Singin: React.FC = () => {
  const navigate = useNavigate();

  // STATES
  const [showOtpScreen, setShowOtpScreen] = useState<boolean>(false);
  const [user, setUser] = useState<{ email: string }>({ email: "" });


  // FUNCTIONS
  const onVerifySuccess = () => {
    navigate(ROUTE_CONSTANTS.DASHBOARD);
  };
  const onLoginSuccess = (user: { email: string; persona: string; isTaskAssigned?: boolean }) => {
    setShowOtpScreen(false);
    setUser(user);
    return;

    // if (user.persona === USER_ROLES["vendor"]) {
    //   // window.location.replace(ROUTE_CONSTANTS.My_Tasks);
    //   setTimeout(() => {
    //     navigate(ROUTE_CONSTANTS.My_Tasks);
    //   }, 200);
    //   return;
    // } else if (user.persona === USER_ROLES["admin"]) {
    //   navigate(ROUTE_CONSTANTS.ADMIN);
    //   return;
    // }

    // navigate(ROUTE_CONSTANTS.DASHBOARD);
    // return;
  };

  return (
    <div className="login-auth-wrapper">
      <div className="auth-container">
        <div className="authentication_page">
          <div className="auth-bg-wrapper">
            <div className={`auth-bg-section ${showOtpScreen ? "otp-bg" : "login-bg"}`}>
              <img src={logo} alt="logo" className="logo" />
              <div className="logo-text">
                <h2 className="logo-heading">Welcome to One Counsel</h2>
                <span className="logo-sub-heading">
                  360-degree insights in to legal operations & business transactions
                </span>
              </div>
            </div>
          </div>

          <div className="auth-form-section">
            {showOtpScreen ? (
              <OTPVerificationScreen onVerifySuccess={() => onVerifySuccess()} email={user.email} />
            ) : (
              <>
                <h3 className="auth-title">{STRINGS.LOGIN_PAGE_TITLE}</h3>
                <p className="pg-content">
                  <span className="pg-content-text" title={lognInSubText}>
                    {lognInSubText}
                  </span>
                </p>
                <div className="auth-form">
                  <SignInForm onLoginSuccess={onLoginSuccess} />
                </div>

                <div className="login-form-footer">
                  <div className="auth-signup-link">
                    <span>New to our platform?</span>
                    {/* <Link to={ROUTE_CONSTANTS.SIGN_UP}>
                      </Link> */}
                    <span>Ask us to create an account</span>
                  </div>
                </div>
                <div className="login-form-footer text-center">
                  <div className="forgot-pwd-link">
                    <Link to={ROUTE_CONSTANTS.FORGOT_PASSWORD}>{STRINGS.FORGOT_PASSWORD}</Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Singin;
