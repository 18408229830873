import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Popup from "reactjs-popup";
import FileViewer from "react-file-viewer";
import { useSelector } from 'react-redux';
import { MenuItem, Select } from '@material-ui/core';
import { AppBar, Button as MButton, Dialog, Toolbar, Typography } from '@mui/material';
import ReactSimpleTooltip from "react-simple-tooltip"
import "./style.scss";

// HELPER
import { API_ROUTES, getRequest, postRequest } from 'helpers';

// ASSETS
import { IoMdDownload } from 'react-icons/io';
import { IoLibrary } from "react-icons/io5";
import { AiOutlineEye } from 'react-icons/ai';

// COMPONENTS
import { Button, LoaderHOC } from 'components';
import TableJs from "components/Table/table";
import DashboardLayout from 'layouts/dashboardLayout';
import { UploadByotFormNew, UploadTemplateForm } from 'customComponents';

const index = () => {
    const categories = ["Free", "Paid"];

    // SELECTORS
    const userData = useSelector((state: any) => state.common.userData);

    // STATES
    const [tableData, setTableData] = useState<any>([]);
    const [templateData, setTemplateData] = useState<any>([]);
    const [category, setCategory] = useState("");
    const [loading, setLoading] = useState(false);
    const [previewDoc, setPreviewDoc] = useState(false);
    const [selectedDocUrl, setSelectedDocUrl] = useState("");

    // FUNCTIONS
    const generateFileUrl = async (fileUrl) => {
        try {
            let generatedUrl = "";

            const response: any = await postRequest(API_ROUTES.SIGNED_URL, true, {
                bucketName: "oc-template-library",
                key: fileUrl
            });

            if (response?.data) {
                generatedUrl = response?.data;
            } else {
                generatedUrl = "";
            }

            return generatedUrl;
        } catch (err) {
            console.log("error ==>", err);
        }
    }
    const fetchData = async () => {
        try {
            setLoading(true);
            const res: any = await getRequest(API_ROUTES.GET_LIBRARY_TEMPLATES, true);
            if (res.data.status == "success") {
                setTemplateData(res.data.response);
                setTableData(res.data.response);
            }
        } catch (err) {
            console.log("error ==>", err);
        } finally {
            setLoading(false);
        }
    }
    const searchHandler = (e) => {
        const filteredData = templateData?.filter((item: any) => (item["_source"]?.templateName?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
            item["_source"]?.createdDate?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
            item["_source"]?.description?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
            item["_source"]?.category?.toLowerCase()?.includes(e.target.value?.toLowerCase())));

        setTableData(filteredData);

        if (e.target.value == "") {
            setTableData(templateData);
        }
    }
    const chooseCategoryHadler = (e) => {
        const selectedCategory = e.target.value.toLowerCase();
        setCategory(e.target.value);

        if (e.target.value == "") {
            setTableData(templateData);
        }
        const filteredData = templateData?.filter((item: any) => item["_source"]?.category?.toLowerCase()?.includes(selectedCategory));
        setTableData(filteredData);
    }
    const downloadTemplateHandler = async (url) => {
        try {
            const fileExtention = url?.split(".")?.reverse()?.[0];

            const generatedUrl = await generateFileUrl(url);

            if (generatedUrl) {
                const link = document.createElement("a");
                link.href = generatedUrl;
                link.download = `${uuidv4()}.${fileExtention}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (err) {
            console.log("error ==>", err);
        }
    }
    const openPreviewModal = async (data) => {
        const generatedFileUrl: any = await generateFileUrl(data?.value || "");
        setSelectedDocUrl(generatedFileUrl);
        setPreviewDoc(() => true);
    }
    const closePreviewModal = () => {
        setPreviewDoc(() => false);
        setSelectedDocUrl("");
    }

    // EFFECTS
    useEffect(() => {
        fetchData();
    }, [])

    // TABLEJS COLUMN
    const columns = [
        {
            Header: "Name",
            accessor: "_source.templateName",
            Cell: (data) => {
                return <div className="Contract_name_in_repo cursor-pointer type_black w-[150px] text-[#7F47C1] whitespace-nowrap overflow-hidden text-ellipsis">{data?.value}</div>
            },
        },
        {
            Header: "Description",
            accessor: "_source.description",
            Cell: (data) => {
                return <div className="type_black w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">{data?.value}</div>
            }
        },
        {
            Header: "Category",
            accessor: "_source.category",
            Cell: (data) => {
                return <div className="type_grey w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">{data?.value}</div>
            },
        },
        {
            Header: "Created Date",
            accessor: "_source.createdDate",
            Cell: (data) => {
                return <div className="type_grey w-[150px] whitespace-nowrap overflow-hidden text-ellipsis">{data?.value}</div>
            }
        },
        {
            Header: "Actions",
            accessor: "_source.templateUrl",
            Cell: (data) => {
                return (
                    <div className="flex">
                        <span className={`table-actions-wrapper`}>
                            <IoMdDownload
                                className='download-icon'
                                onClick={() => downloadTemplateHandler(data?.value)}
                            />
                        </span>
                        <span className={`table-actions-wrapper`}>
                            <AiOutlineEye
                                className='eye-icon'
                                onClick={() => openPreviewModal(data)}
                            />
                        </span>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <DashboardLayout
                hederTitle={"Library"}
                headerLogo={<IoLibrary />}
                childer={
                    <div className="library-container">
                        <div className="library-table">
                            <TableJs
                                columns={columns}
                                sortBy={[{ id: "templateName", desc: true }]}
                                data={tableData}
                                isRowSelectEnable={false}
                                isOtherMenuEnable={false}
                                headerProps={{ isEnable: true, type: 3, refetchData: () => fetchData() }}
                                isRowsPerPageChangeRequired={false}
                                isCurrentRowsCountVisibilityRequired={false}
                                searchHandler={searchHandler}
                                headerContainerClass="!justify-start"
                                customHeaderComponent={
                                    <div className='w-full flex justify-between items-center'>
                                        <div>
                                            <Select
                                                value={category}
                                                displayEmpty
                                                placeholder='Choose Category'
                                                onChange={chooseCategoryHadler}
                                                variant='outlined'
                                                className='ml-2 !w-[220px]'
                                            >
                                                <MenuItem value="">
                                                    All
                                                </MenuItem>
                                                {categories?.map((item, index) => {
                                                    return <MenuItem
                                                        key={index}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </div>
                                        <div>
                                            <Popup
                                                trigger={
                                                    <ReactSimpleTooltip
                                                        content="Bring Your Own Template"
                                                        customCss={`white-space: nowrap;fontSize:12px !important;`}
                                                        zIndex={1}
                                                        radius={5}
                                                        placement="left"
                                                        fontSize="small"
                                                        padding={8}
                                                        arrow={7}
                                                    >
                                                        <Button
                                                            type="button"
                                                            label="BYOT"
                                                            variant="outlined"
                                                            className="!mx-0.5"
                                                            isFullWidth={false}
                                                        />
                                                    </ReactSimpleTooltip>
                                                }
                                                modal
                                                closeOnDocumentClick={false}
                                                closeOnEscape={false}
                                                className="byot-new-upload-popup"
                                            >
                                                {(close: () => {}) => {
                                                    return (
                                                        <UploadByotFormNew
                                                            closePopup={close}
                                                            refetchDataAfterImport={() => {
                                                                // setTimeout(() => {
                                                                //     setFetchingAgreementList(true);
                                                                // }, 2000);
                                                            }}
                                                        />
                                                    );
                                                }}
                                            </Popup>
                                            <Popup
                                                trigger={
                                                    <Button
                                                        type="button"
                                                        label="Upload Template"
                                                        variant="outlined"
                                                        className="!mx-0.5"
                                                        isFullWidth={false}
                                                    />
                                                }
                                                modal
                                                closeOnDocumentClick={false}
                                                closeOnEscape={false}
                                                className="agreement-upload-popup parent-selector"
                                                disabled={false}
                                            >
                                                {(close: () => {}) => (
                                                    <UploadTemplateForm
                                                        closePopup={close}
                                                        fetchData={fetchData}
                                                    />
                                                )}
                                            </Popup>
                                        </div>
                                    </div>
                                }
                            />
                            {loading && <LoaderHOC />}
                            {(!loading && tableData?.length <= 0) && <div className="mt-3">No Data Found...</div>}
                        </div>

                        {/* DOC PREVIEW MODAL */}
                        <Dialog fullScreen open={previewDoc} onClose={closePreviewModal}>
                            <AppBar style={{ position: "relative", height: "64px !important", backgroundColor: "#560BAD" }}>
                                <Toolbar>
                                    <Typography variant="h5" component="div" style={{ flex: 1 }}>
                                        <h4 className="title">Template Preview</h4>
                                    </Typography>
                                    <MButton onClick={closePreviewModal}>
                                        Close
                                    </MButton>
                                </Toolbar>
                            </AppBar>
                            <FileViewer
                                fileType="docx"
                                filePath={selectedDocUrl}
                                errorComponent={<div> Error reading file</div>}
                                onError={() => console.log("Error in File Redering ....")}
                            />
                        </Dialog>
                    </div >
                }
            />
        </>
    )
}

export default index