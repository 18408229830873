import React from "react";
import { FieldHookConfig, useField, useFormikContext } from "formik";

import { Checkbox, FormControlLabel } from "@mui/material";

import "./style.scss";

interface otherProps {
  value?: string;
  id?: string;
  label?: string;

  onChange?: Function;
  // readOnly?: boolean;
  // onAction?: Function;
  // onActionBlur?: Function;
  // highlightText?: string;
  // showRiskWarning?: boolean;
  // id?: string;
  // isRequired?: boolean;
}

const CustomCheckbox = (p: otherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(p);
  const { setFieldValue } = useFormikContext();

  // const handleOnAction = () => {
  //   if (p?.onAction) {
  //     let value = field.value;
  //     if (p.highlightText) {
  //       value = p.highlightText;
  //     }
  //     p.onAction(value);
  //   }
  // };

  // const handleOnBlur = () => {
  //   if (p?.onActionBlur) {
  //     if (field.name == "price") {
  //       const updatedPriceFormat: any =
  //         field.name === "price"
  //           ? field.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //           : field.value;
  //       p.onActionBlur({ value: updatedPriceFormat, name: field.name });
  //       setFieldValue(field.name, updatedPriceFormat);
  //     } else {
  //       const name = field.name;
  //       p.onActionBlur({ value: field.value, name });
  //     }
  //   }
  // };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            value={p.value}
            name={p.value}
            color="secondary"
            size="small"
            onChange={(e) => {
              if (p.onChange) {
                p.onChange(e);
              }
            }}
          />
        }
        label={p.label}
        className=" text-gray-light "
      />
    </>
  );
  {
    /* <FormControl variant="outlined" className={p.className}>
      <InputLabel
        htmlFor={p?.id || `${field.name}-id`}
        style={
          {
            // color: "#929cac",
          }
        }
      >
        {p.label}
      </InputLabel>
      <OutlinedInput
        {...field}
        type={p.type}
        placeholder={p.placeholder}
        name={field.name}
        id={p?.id || `${field.name}-id`}
        label={p.label}
        inputProps={{
          readOnly: p.readOnly || false,
        }}
        onClick={handleOnAction}
        onBlur={handleOnBlur}
        required={p.isRequired || false}
      />
      <span className="my-1">
        {meta.touched && meta.error ? (
          <div className="field-error" title={meta?.error}>
            {meta.error}
          </div>
        ) : (
          p.showRiskWarning && (
            <>
              <ShowRisk name={field.name} value={field.value} />
            </>
          )
        )}
      </span>
    </FormControl> */
  }
};

export default CustomCheckbox;
