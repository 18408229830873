import React from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";

const LoaderHOC: React.FC = () => {
  const loading = useSelector((state: any) => state.common.loading);
  return (
    <React.Fragment>
      {!loading ? (
        <div
          style={{
            alignItems: "center",
            bottom: 0,
            display: "flex",
            height: "100%",
            right: 0,
            top: 0,
            zIndex: 9999999,
          }}
        >
          <Loader type="ThreeDots" color="#2e3653" height={80} width={80}></Loader>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default LoaderHOC;
