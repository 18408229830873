import { FC, useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import "./styles.scss"

// HELPER
import { API_ROUTES, postRequest } from 'helpers';

// ASSETS
import { FiSearch } from "react-icons/fi";
import { AiOutlineClose } from 'react-icons/ai';
import { GoAlert } from "react-icons/go";
import { MdExpandMore, MdDelete } from "react-icons/md";
import { GrDropbox } from "react-icons/gr";

// TYPES
import { IProps } from "./types"

// COMPONENTS
import AvatarCard from "./AvatarCard"
import { Button, LoaderHOC } from 'components';
import { addRepositoryDocIds } from 'redux/actions';

const BulkShare: FC<IProps> = ({ onCloseModal, onSubmit }) => {
    const dispatch = useDispatch();

    // STATES
    const [isFetching, setIsFetching] = useState(false);
    const [users, setUsers] = useState<any>([]);
    const [searchInput, setSearchInput] = useState("");
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [showWarning, setShowWarning] = useState<boolean>(true);

    // SELECTORS
    const loginUserDetails = useSelector((state: RootState) => state.common?.userData) || {};
    const selectedDocs = useSelector((state: RootState) => state)?.repository?.selectedDocIds || [];

    // FUNCTIONS
    const fetchUsers = async () => {
        try {
            setIsFetching(() => true);
            const postData = { email: loginUserDetails?.email };
            const response: any = await postRequest(API_ROUTES.SHARE_WITH, true, postData);
            setUsers(response.data || []);
        } catch (err) {
            console.log(err);
        } finally {
            setIsFetching(() => false);
        }
    }
    const onClose = () => {
        onCloseModal();
    }
    const selectUserHandler = (user, action) => {
        if (action == "add") {
            setSelectedUsers(() => [...selectedUsers, user]);
        } else {
            const filteredUsers = selectedUsers.filter(item => item?.userId !== user?.userId);
            setSelectedUsers(filteredUsers);
        }
    }
    const searchUserHandler = (value: string) => {
        setSearchInput(value);
    }
    const onShare = () => {
        onSubmit(selectedUsers);
        onCloseModal();
    }
    const removeDocHandler = (doc) => {
        const tempSelectedDocs = selectedDocs;
        const updatedDocs = tempSelectedDocs.filter(item => item?.documentId !== doc?.documentId)
        dispatch(addRepositoryDocIds(updatedDocs));
    }
    const filterSearchingUsers = (data) => {
        const filteredData = data.filter(item => item?.first_name.toLowerCase().includes(searchInput.toLowerCase())
            || item?.last_name.toLowerCase().includes(searchInput.toLowerCase())
            || item?.username.toLowerCase().includes(searchInput.toLowerCase()));

        return filteredData
    }

    // EFFECTS
    useEffect(() => {
        fetchUsers();
    }, [])

    return (
        <>
            {
                isFetching
                    ?
                    <div className='w-full h-[400px] flex justify-center items-center'>
                        <LoaderHOC />
                    </div>
                    :
                    <div className='w-full h-full flex flex-col justify-center items-center'>
                        <div className='w-[95%] h-[10%] flex justify-between items-center mt-2 mb-2'>
                            <div className='w-full h-full  flex justify-start items-center'>
                                <p className='text-sm font-bold text-[#560BAD]'>Bulk Share</p>
                            </div>
                            <div>
                                <AiOutlineClose color="#560BAD" className="close cursor-pointer" onClick={onClose} />
                            </div>
                        </div>
                        <div className='w-[95%] !max-h-[440px] no-scrollbar overflow-y-auto pl-1 pr-1'>
                            <div className='w-full h-[90%]'>
                                {/* Warning */}
                                {showWarning &&
                                    <div className='mb-2 p-1 rounded-md bg-[#FFF7EA] flex justify-center items-center w-full h-[10%]'>
                                        <div className='flex justify-between items-center w-[98%] h-full'>
                                            <div className='flex justify-center items-center'>
                                                <GoAlert size={12} color="#FFA003" />
                                                <p className='ml-2 text-[12px] text-[#FFA003]'>This is a private file that you should be careful about sharing.</p>
                                            </div>
                                            <button
                                                type='button'
                                                className='cursor-pointer !bg-white rounded-md text-[10px] border-none w-8 h-6 text-[#FFA003]'
                                                onClick={() => setShowWarning(false)}
                                            >
                                                Ok
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* Accordion */}
                                <div className='flex justify-center items-center mb-2 mt-1'>
                                    <Accordion
                                        className='w-full !min-h-[40px] !rounded-lg !shadow-none !bg-[#F4ECFD]'
                                    >
                                        <AccordionSummary
                                            className='w-full !font-semibold !pl-2 !pr-2 !text-[12px] !min-h-[40px] !max-h-[40px]'
                                            expandIcon={<MdExpandMore size={18} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            Documents to be shared
                                        </AccordionSummary>
                                        {selectedDocs?.map((item) => {
                                            return <div className='w-full flex flex-col justify-center items-center'>
                                                <hr className='!max-w-[90%]' />
                                                <AccordionDetails className='w-full flex justify-between items-center !text-[12px] !pl-2 !pr-2 !pb-1 !pt-1'>
                                                    <p className='w-[95%] select-none'>
                                                        {item?.documentName?.length > 55
                                                            ? item?.documentName.slice(0, 55) + "..."
                                                            : item?.documentName}

                                                    </p>
                                                    <MdDelete
                                                        size={16}
                                                        color='#DD4C43'
                                                        className='w-[5%] cursor-pointer'
                                                        onClick={() => removeDocHandler(item)}
                                                    />
                                                </AccordionDetails>
                                            </div>
                                        })}


                                    </Accordion>
                                </div>

                                {/* Search field */}
                                <div className={`w-full h-[15%] mb-2 flex flex-col items-center`}>
                                    <TextField
                                        sx={{
                                            border: 'none', "& fieldset": {
                                                border: 'solid 1px #5B0BAD',
                                                margin: "0px !important",
                                            },
                                            "& input::placeholder": {
                                                fontSize: "12px",
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: <FiSearch size={22} />,
                                            sx: {
                                                height: "40px",
                                            }
                                        }}
                                        label=""
                                        variant="outlined"
                                        className='w-full'
                                        placeholder='You can share the file with other people in your organization.'
                                        value={searchInput}
                                        onChange={(e) => searchUserHandler(e.target.value)}
                                    />
                                </div>

                                {/* Users List */}
                                <div className='no-scrollbar overflow-scroll bg-[#F4ECFD] rounded-lg w-full h-[15rem] p-2'>
                                    {
                                        filterSearchingUsers(users)?.length <= 0 &&
                                        <div className='w-full h-full flex flex-col justify-center items-center'>
                                            <GrDropbox size={68} color="#D6BEF2" />
                                            <p className='text-[#BE94EE] mt-1 text-sm'>No Data</p>
                                        </div>
                                    }
                                    <div className='w-full h-auto'>
                                        {
                                            filterSearchingUsers(users).map(
                                                (item: any) => <AvatarCard
                                                    key={item?.client_id}
                                                    userId={item?.user_id}
                                                    userImage={item?.profile_img}
                                                    userName={`${item?.first_name} ${item?.last_name}`}
                                                    userEmail={item?.email}
                                                    isSelected={selectedUsers.find((usr) => usr.userId == item?.user_id) ? true : false}
                                                    onClick={selectUserHandler}
                                                />)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mb-2 mt-2 flex justify-end">
                                <Button
                                    label="Share"
                                    variant="contained"
                                    className="!w-fit !mx-0.5"
                                    onClick={onShare}
                                    disabled={(selectedUsers?.length == 0 || selectedDocs?.length == 0) || false}
                                />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default BulkShare